import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Auth } from 'aws-amplify';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Avatar as MaterialAvatar } from '@mui/material';
import { queryClient } from 'services/apiService';
import CommonText from 'components/common/textOutput/CommonText';
import { useQueryDynamodbUser } from 'services/deliveryQueryHooks';

import { DashboardContext } from 'utils/context/Dashboard';

import avatarImage from 'assets/img/avatar.png';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      zIndex: 5,
      height: 45,
      width: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    avatarWithBoarder: {
      height: '90%', // off center?
      width: '90%',
    },
    popperPaper: {
      borderRadius: 20,
      minWidth: 80,
    },
    menuListItem: {
      display: 'flex',
      justifyContent: 'center',
      '&:hover': { borderRadius: 20 },
    },
  })
);

export default function Avatar({
  noBorder = false,
  imageURL,
  className,
  componentRef,
}: {
  noBorder?: boolean;
  imageURL: string | undefined;
  className?: string;
  componentRef?: (ref: HTMLElement) => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const prevMenuOpen = useRef(menuOpen);
  const { setCurrentDashboard, currentDashboard } =
    useContext(DashboardContext);
  const dynamodbUserQuery = useQueryDynamodbUser();
  const dynamodbUser = dynamodbUserQuery.data;
  useEffect(() => {
    if (prevMenuOpen.current === true && menuOpen === false) {
      menuAnchorRef.current?.focus();
    }
    prevMenuOpen.current = menuOpen;
  }, [menuOpen]);

  const handleMenuClose = (event) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  return noBorder ? (
    <MaterialAvatar
      ref={(ref) => {
        componentRef && componentRef(ref as HTMLElement);
      }}
      className={clsx(classes.root, { [className as string]: className })}
      src={imageURL ? imageURL : avatarImage}
    />
  ) : (
    <Paper
      ref={menuAnchorRef}
      className={clsx(classes.root, { [className as string]: className })}
    >
      <MaterialAvatar
        ref={(ref) => {
          componentRef && componentRef(ref as HTMLElement);
        }}
        className={classes.avatarWithBoarder}
        src={imageURL ? imageURL : avatarImage}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      />
      <Popper
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.popperPaper}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {currentDashboard !== 'delivery' &&
                    dynamodbUser?.applicationAccess === 'all' && (
                      <MenuItem
                        onClick={() => {
                          setCurrentDashboard('delivery');
                          localStorage.setItem('currentDashboard', 'delivery');
                          setMenuOpen(false);
                          history.push('/dashboard');
                        }}
                        className={classes.menuListItem}
                      >
                        <CommonText textVariant="12 light">
                          Gå til prosjektlevering
                        </CommonText>
                      </MenuItem>
                    )}
                  {currentDashboard !== 'ops' &&
                    dynamodbUser?.applicationAccess === 'all' && (
                      <MenuItem
                        onClick={() => {
                          setCurrentDashboard('ops');
                          localStorage.setItem('currentDashboard', 'ops');
                          setMenuOpen(false);
                          history.push('/dashboard');
                        }}
                        className={classes.menuListItem}
                      >
                        <CommonText textVariant="12 light">
                          Gå til OPS dash
                        </CommonText>
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={() => {
                      Auth.signOut().then(() => {
                        queryClient.clear();
                      });
                    }}
                    className={classes.menuListItem}
                  >
                    <CommonText textVariant="12 light">Log ut</CommonText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Paper>
  );
}
