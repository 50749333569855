import React from 'react';

import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';

library.add(faGoogle, faMicrosoft);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      paddingLeft: 15,
      paddingRight: 15,
      justifyContent: 'space-between',
    },
    button: {
      height: 40,
      paddingLeft: 10,
      paddingRight: 10,
      width: '48%',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',

      buttonText: {
        fontWeight: 400,
        fontSize: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'clip',
        wordWrap: 'break-word',
      },
    },
    focusVisble: {
      color: 'rgba(113, 146, 176, 0.22)',
    },
    buttonIcon: { marginRight: 10 },
    labelText: {
      paddingBottom: 20,
    },
  })
);

export default function FederatedSignIn({
  complimentaryText,
}: {
  complimentaryText?: string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {complimentaryText && (
        <CommonText textVariant="12 light" className={classes.labelText}>
          {complimentaryText}
        </CommonText>
      )}
      <div className={classes.buttonContainer}>
        <Fab
          variant="extended"
          className={classes.button}
          style={{ backgroundColor: 'white' }}
          TouchRippleProps={{
            classes: { ripple: classes.focusVisble },
          }}
          onClick={() => {
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            });
          }}
        >
          <FontAwesomeIcon
            className={classes.buttonIcon}
            size="lg"
            color="#768AA3"
            icon={faGoogle}
          />
          <CommonText style={{ color: '#768AA3' }} textVariant="14 light">
            Logg på med Google
          </CommonText>
        </Fab>
        <Fab
          variant="extended"
          className={classes.button}
          style={{ backgroundColor: '#1065d9' }}
          TouchRippleProps={{
            classes: { ripple: classes.focusVisble },
          }}
          onClick={() => {
            Auth.federatedSignIn({
              customProvider: 'Microsoft',
            });
          }}
        >
          <FontAwesomeIcon
            className={classes.buttonIcon}
            size="lg"
            color="white"
            icon={faMicrosoft}
          />
          <CommonText style={{ color: 'white' }} textVariant="14 light">
            Logg på med Microsoft
          </CommonText>
        </Fab>
      </div>
    </div>
  );
}
