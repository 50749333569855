import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonFab from 'components/common/CommonFab';

import YmPointDialog from 'components/opsDash/ym/dialogs/YmPointDialog';

import { YmMapContext } from 'utils/context/YmMap';

import theme from 'config/theme';
import { useYmPointQueries } from 'services/ymQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: { position: 'absolute', width: '100%', height: '100%' },
    menu: {
      position: 'absolute',
      height: '100%',
      zIndex: 4,
      backgroundColor: theme.colors.base.white,
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
    },
    menuContent: {
      paddingTop: 30,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    menuButton: {
      position: 'absolute',
      zIndex: 3,
      marginLeft: 10,
      marginTop: 10,
    },
  })
);

export default function SideMenu({
  ymPointQueries,
}: {
  ymPointQueries: ReturnType<typeof useYmPointQueries>;
}) {
  const classes = useStyles();

  const { ymMapState, setYmMapState } = useContext(YmMapContext);
  const [openCreateYmDialog, setOpenCreateYmDialog] = useState(false);

  return (
    <div className={classes.root}>
      <CommonFab
        className={classes.menuButton}
        icon="menu"
        buttonSize="large"
        onClick={() => {
          setYmMapState((prevState) => {
            return { ...prevState, sideMenuOpen: true };
          });
        }}
      />
      <div
        className={classes.menu}
        style={{
          transition: 'width 250ms ease-out',
          width: ymMapState.sideMenuOpen ? '5%' : '0%',
        }}
      >
        {ymMapState.sideMenuOpen && (
          <div
            className={classes.menuContent}
            style={{
              transition: 'width 250ms ease-out',
              opacity: ymMapState.sideMenuOpen ? '1' : '0',
            }}
          >
            <CommonFab
              noShadow
              tooltip={{ placement: 'right', text: 'Lukk' }}
              buttonSize="large"
              icon="arrow-left-circle"
              onClick={() => {
                setYmMapState((prevState) => {
                  return { ...prevState, sideMenuOpen: false };
                });
              }}
            />
            <CommonFab
              noShadow
              tooltip={{ placement: 'right', text: 'Legg til nytt målepunkt' }}
              buttonSize="large"
              icon="plus-circle"
              onClick={() => {
                setOpenCreateYmDialog(true);
              }}
            />
            <YmPointDialog
              newYmPoint={true}
              isOpened={openCreateYmDialog}
              onClose={() => {
                setOpenCreateYmDialog(false);
              }}
              onSave={() => {
                setTimeout(() => {
                  ymPointQueries.refetch();
                }, 2000);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
