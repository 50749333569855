import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import FeatherIcon from 'feather-icons-react';

import InitialLoadingContent from 'components/deliveryDash/project/pointcloudsCard/InitialLoadingContent';
import CardContent from 'components/deliveryDash/project/pointcloudsCard/CardContent';
import CommonCard from 'components/common/cards/CommonCard';
import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';

import { useQueryPointclouds } from 'services/deliveryQueryHooks';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100%',
      maxHeight: '100%',
      width: '100%',
    },
    headerContent: {
      width: '100%',
      paddingRight: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    button: {
      marginLeft: 10,
      marginRight: 10,
    },
    disabled: {
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      height: '100%',
      borderRadius: 30,
      backgroundColor: 'rgba(255, 255, 255, 0.70)',
    },
    noDocumentsText: {
      paddingLeft: 10,
      paddingRight: 30,
    },
  })
);

export default function ProjectPointcloudsCard({
  projectId,
  disabled,
}: {
  projectId: string;
  disabled?: boolean;
}) {
  const documentsQuery = useQueryPointclouds(projectId);
  const pointclouds = documentsQuery.data;

  const classes = useStyles();

  const headerContent = (
    <div className={classes.headerContent}>
      {!documentsQuery.isFetching && !pointclouds && (
        // show message for read only access users when there are no pointclouds available
        <React.Fragment>
          <FeatherIcon
            icon="info"
            color={theme.colors.icons.noState.light}
            size={25}
          />
          <CommonText
            className={classes.noDocumentsText}
            textVariant="14 medium"
            colorOverride="white"
          >
            Det finnes ingen punktskyer enda
          </CommonText>
        </React.Fragment>
      )}
      <CommonFab
        className={classes.button}
        text="Oppdater"
        icon="refresh-cw"
        divider
        spinnerAsIcon={documentsQuery.isFetching}
        onClick={() => documentsQuery.refetch()}
      />
    </div>
  );

  const bodyContent =
    documentsQuery.isFetching && !pointclouds ? (
      // displaying placeholder content and loading indicator while fetching first documents
      <InitialLoadingContent loading={true} />
    ) : (
      // no longer fetching initial documents, showing documents table or initial drop zone
      <CardContent pointclouds={pointclouds} />
    );

  return (
    <CommonCard
      disabled={disabled}
      title="Punktskyer"
      headerRightComponent={headerContent}
      bodyComponent={bodyContent}
    />
  );
}
