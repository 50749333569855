import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Auth } from 'aws-amplify';
import { queryClient } from 'services/apiService';
import CircularProgress from '@mui/material/CircularProgress';

import DeliveryDashboard from 'components/deliveryDash/DeliveryDashboard';
import OpsDashboard from 'components/opsDash/OpsDashboard';
import FrontPageContent from 'components/frontPage/FrontPageContent';
import CommonText from 'components/common/textOutput/CommonText';
import CommonButton from 'components/common/CommonButton';

import { DashboardContext } from 'utils/context/Dashboard';
import { useQueryDynamodbUser } from 'services/deliveryQueryHooks';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

export default function MainDashboard() {
  const classes = useStyles();
  const { setCurrentDashboard, currentDashboard } =
    useContext(DashboardContext);
  const dynamodbUserQuery = useQueryDynamodbUser();
  const dynamodbUserApplicationAccess =
    dynamodbUserQuery.data && dynamodbUserQuery.data.applicationAccess;

  useEffect(() => {
    const previousDashboard = localStorage.getItem('currentDashboard');
    if (dynamodbUserApplicationAccess) {
      if (dynamodbUserApplicationAccess === 'all') {
        if (!previousDashboard) {
          setCurrentDashboard('delivery');
          localStorage.setItem('currentDashboard', 'delivery');
        } else {
          setCurrentDashboard(previousDashboard);
        }
      } else {
        if (!previousDashboard) {
          setCurrentDashboard(dynamodbUserApplicationAccess);
          localStorage.setItem(
            'currentDashboard',
            dynamodbUserApplicationAccess
          );
        } else {
          setCurrentDashboard(previousDashboard);
        }
      }
    }
  }, [setCurrentDashboard, dynamodbUserApplicationAccess]);

  return dynamodbUserQuery.isLoading ? (
    <FrontPageContent
      rightColumnContent={
        <div className={classes.centeringContainer}>
          <CircularProgress
            classes={{ circle: classes.spinnerCircle }}
            size={75}
          />
        </div>
      }
    />
  ) : currentDashboard === 'delivery' ? (
    <DeliveryDashboard />
  ) : currentDashboard === 'ops' ? (
    <OpsDashboard />
  ) : (
    <FrontPageContent
      rightColumnContent={
        <div className={classes.centeringContainer}>
          <CommonText textVariant="20 medium">
            Du har ingen applikasjonstilgang enda
          </CommonText>
          <div style={{ height: 50 }} />
          <CommonButton
            text="Logg ut"
            onClick={() => {
              Auth.signOut().then(() => {
                queryClient.clear();
              });
            }}
          />
        </div>
      }
    />
  );
}
