import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import ProjectTable from 'components/deliveryDash/listView/ProjectTable';
import TextField from 'components/common/textInputs/TextField';
import { useQueryProjects } from 'services/deliveryQueryHooks';
import { CompanyProjectRelation } from 'services/deliveryApi';
import { Box, Paper } from '@mui/material';
import CommonFab from 'components/common/CommonFab';
import useComponentSize from 'utils/hooks/useComponentSize';
import { formatDateToLocalDate } from 'utils/dateTimeHandler';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headerRoot: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 125,
      maxHeight: 125,
      paddingLeft: 135,
      paddingRight: 135,
      paddingTop: 20,
      height: '100%',
      flex: 1,
    },
    topView: {
      width: '100%',
    },
    bottomView: {
      width: '100%',
      height: '100%',
      padding: 135,
      paddingTop: 20,
      paddingBottom: 130,
    },
    menuList: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
    },
    searchBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'width 350ms ease-out',
      borderRadius: 25,
      height: 100,
    },
  })
);

export default function ListView() {
  const classes = useStyles();

  const projectQuery = useQueryProjects();
  const projects = projectQuery.data;
  useEffect(() => {
    projectQuery.refetch();
  }, [projectQuery]);

  const [searchedProjects, setSearchedProjects] = useState<
    CompanyProjectRelation[] | undefined
  >();
  const [menuOpen, setMenuOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [checkboxState, setCheckboxState] = React.useState({
    companyName: true,
    projectName: true,
    projectNumber: true,
    customerProjectNumber: true,
    projectDescription: true,
    startDate: true,
    endDate: true,
  });

  const {
    companyName,
    projectName,
    projectNumber,
    customerProjectNumber,
    projectDescription,
    startDate,
    endDate,
  } = checkboxState;

  const onSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
    searchString: string | undefined,
    checkboxEvent: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    let newCheckboxState: { [key: string]: boolean } = {}; // workaround to avoid using useEffect on change in checkboxState
    if (checkboxEvent)
      newCheckboxState = {
        ...checkboxState,
        [checkboxEvent.target.name]: checkboxEvent.target.checked,
      };

    if (projects) {
      setSearchedProjects(
        projects.filter((project) => {
          let combinedStringArray: (string | false | undefined)[] = [];
          if (checkboxEvent)
            combinedStringArray = [
              newCheckboxState.companyName && project.companyName,
              newCheckboxState.projectName && project.projectName,
              newCheckboxState.projectNumber && project.projectNumber,
              newCheckboxState.customerProjectNumber &&
                project.customerProjectNumber,
              newCheckboxState.projectDescription && project.projectDescription,
              newCheckboxState.startDate &&
                project.startDate &&
                formatDateToLocalDate(project.startDate),
              newCheckboxState.endDate &&
                project.endDate &&
                formatDateToLocalDate(project.endDate),
            ];
          else
            combinedStringArray = [
              companyName && project.companyName,
              projectName && project.projectName,
              projectNumber && project.projectNumber,
              customerProjectNumber && project.customerProjectNumber,
              projectDescription && project.projectDescription,
              startDate &&
                project.startDate &&
                formatDateToLocalDate(project.startDate),
              endDate &&
                project.endDate &&
                formatDateToLocalDate(project.endDate),
            ];

          return combinedStringArray
            .filter(Boolean)
            .join(' ')
            .toLowerCase()
            .includes(
              event
                ? event.target.value.toLowerCase()
                : searchString
                ? searchString.toLowerCase()
                : ''
            );
        })
      );
    }
  };

  let shownProjects = searchedProjects ? searchedProjects : projects;

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const menuButtonWidth = useComponentSize(
    menuButtonRef.current as HTMLElement,
    60
  ).width;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(undefined, searchValue, event);
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <Paper
          className={classes.searchBar}
          style={{
            width: menuOpen ? '100%' : menuButtonWidth,
            justifyContent: menuOpen ? 'flex-start' : 'center',
            height: menuOpen ? 100 : menuButtonWidth,
          }}
        >
          {menuOpen && (
            <Box className={classes.menuList}>
              <Box
                sx={{
                  py: 5,
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: 'row',
                }}
              >
                <TextField
                  editable
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    onSearch(e, undefined, undefined);
                  }}
                  placeholder="Søk etter prosjekt"
                  keepActive
                  value={searchValue}
                />
              </Box>

              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={companyName}
                        onChange={handleChange}
                        name="companyName"
                      />
                    }
                    label="Firmanavn"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={projectName}
                        onChange={handleChange}
                        name="projectName"
                      />
                    }
                    label="Prosjektnavn"
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                required
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={projectNumber}
                        onChange={handleChange}
                        name="projectNumber"
                      />
                    }
                    label="Prosjektnummer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customerProjectNumber}
                        onChange={handleChange}
                        name="customerProjectNumber"
                      />
                    }
                    label="Prosjektnummer, kunde"
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                required
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={projectDescription}
                        onChange={handleChange}
                        name="projectDescription"
                      />
                    }
                    label="Prosjektbeskrivelse"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={startDate}
                        onChange={handleChange}
                        name="startDate"
                      />
                    }
                    label="Startdato"
                  />
                </FormGroup>
              </FormControl>
              <FormControl
                required
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={endDate}
                        onChange={handleChange}
                        name="endDate"
                      />
                    }
                    label="Sluttdato"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          )}

          <CommonFab
            componentRef={menuButtonRef}
            onClick={() => {
              setMenuOpen(!menuOpen);
              setSearchedProjects(projects);
            }}
            selected={menuOpen}
            noShadow={menuOpen}
            icon="search"
            buttonSize="large"
          />
        </Paper>
      </Box>

      <Box className={classes.bottomView}>
        <ProjectTable projects={shownProjects} />
      </Box>
    </Box>
  );
}
