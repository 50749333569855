import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Map from 'components/deliveryDash/mapView/Map';
import { useQueryProjects } from 'services/deliveryQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    mapContainer: {
      zIndex: 0,
      width: '100%',
      height: '100%',
    },
  })
);

export default function MapView() {
  const projectQuery = useQueryProjects();
  const projects = projectQuery.data;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mapContainer}>
        <Map projects={projects} />
      </div>
    </div>
  );
}
