import { useQuery } from 'react-query';
import { authorizedTrafficApi } from 'services/apiService';
import {
  TrafficSensorCounter,
  TrafficSensorReading,
} from 'services/trafficApi/models';

export const useQueryTrafficDataBySensorIdAndYearMonth = (
  sensorId: string,
  yearMonth: string
): {
  data: TrafficSensorReading[] | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ['trafficData ' + sensorId, sensorId, yearMonth],
    async () => {
      const api = await authorizedTrafficApi();
      return await api.getTrafficSensorReadings({
        id: sensorId as string,
        yearMonth: yearMonth as string,
      });
    },
    { retry: 1 }
  );

  return { data, refetch, isLoading };
};

export const useQueryTrafficSensorCounterByIdAndYear = (
  sensorId: string,
  year: string
): {
  data: TrafficSensorCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ['trafficData2 ' + sensorId, sensorId, year],
    async () => {
      const api = await authorizedTrafficApi();
      return await api.getTrafficSensorCounters({
        id: sensorId as string,
        year: year as string,
      });
    },
    { retry: 1 }
  );

  return { data, refetch, isLoading };
};
