/* tslint:disable */
/* eslint-disable */
/**
 * Ameta nvdb API
 * A rest api for managing the ameta nvdb system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NvdbObjectGeojsonGeometry
 */
export interface NvdbObjectGeojsonGeometry {
    /**
     * 
     * @type {Array<number>}
     * @memberof NvdbObjectGeojsonGeometry
     */
    coordinates?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof NvdbObjectGeojsonGeometry
     */
    type?: string;
}

export function NvdbObjectGeojsonGeometryFromJSON(json: any): NvdbObjectGeojsonGeometry {
    return NvdbObjectGeojsonGeometryFromJSONTyped(json, false);
}

export function NvdbObjectGeojsonGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NvdbObjectGeojsonGeometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function NvdbObjectGeojsonGeometryToJSON(value?: NvdbObjectGeojsonGeometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': value.coordinates,
        'type': value.type,
    };
}


