import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';

import CustomScroll from 'react-custom-scroll';
import 'assets/css/commonScroll.css';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: { padding: 50 },
    question: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonContainer: {
      height: '100%',
      padding: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    headCell: {
      backgroundColor: theme.colors.base.white,
    },
    button: { width: 200 },
  })
);

function ConfirmSharing({
  completedSharingList,
  onCancel,
  onAccept,
}: {
  completedSharingList: {
    name: string;
    value: string;
    type: string;
    access: string;
  }[];
  onCancel: () => void;
  onAccept: (share) => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CommonText textVariant="20 medium">
        Dette er de som nå vil få tilgang til prosjektet:
      </CommonText>

      <TableContainer
        style={{ height: 250, paddingTop: 50 }} // minus 25 to account for paper header shadow
        className={'common-scroll'}
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={80}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headCell}>
                  <CommonText textVariant="12 light">Navn</CommonText>
                </TableCell>
                <TableCell className={classes.headCell}>
                  <CommonText textVariant="12 light">
                    Tilgangstype (Firma/Bruker)
                  </CommonText>
                </TableCell>
                <TableCell className={classes.headCell}>
                  <CommonText textVariant="12 light">Datatilgang</CommonText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedSharingList.map((listElement) => {
                return (
                  <TableRow key={listElement.name}>
                    <TableCell>
                      <CommonText textVariant="12 light">
                        {listElement.name}
                      </CommonText>
                    </TableCell>
                    <TableCell>
                      <CommonText textVariant="12 light">
                        {listElement.type === 'user' ? 'Bruker' : 'Firma'}
                      </CommonText>
                    </TableCell>
                    <TableCell>
                      <CommonText textVariant="12 light">
                        {listElement.access === 'reader' ? 'Leser' : 'Tilbyder'}
                      </CommonText>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
      <div className={classes.question}>
        <CommonText textVariant="20 medium">
          Er du sikker på at du vil dele prosjektet?
        </CommonText>
      </div>
      <div className={classes.buttonContainer}>
        <CommonFab
          className={classes.button}
          textColor="error"
          onClick={onCancel}
          text="Nei, gå tilbake"
        />
        <CommonFab
          className={classes.button}
          textColor="success"
          onClick={() => {
            onAccept(completedSharingList);
          }}
          text="Ja, del projeket"
        />
      </div>
    </div>
  );
}

export default ConfirmSharing;
