import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import theme from 'config/theme';

import TopView from 'components/opsDash/TopView';
import PassingsPerHour from 'components/opsDash/traffic/PassingsPerHour';
import PassingsVehicleSize from 'components/opsDash/traffic/PassingsVehicleSize';
import PassingsPastThreeYears from 'components/opsDash/traffic/PassingsPastThreeYears';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    pageContent: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
    },
    rowElement: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    trafficView: {
      flex: 1,
      padding: 10,
      height: '100%',
      width: '100%',
    },
    card: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.icons.noState.light,
    },
  })
);

function TrafficView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopView title="Trafikkvisning" />
      <div className={classes.pageContent}>
        <div className={classes.rowElement}>
          <div className={classes.trafficView}>
            <PassingsPerHour />
          </div>
          <div className={classes.trafficView}>
            <PassingsVehicleSize />
          </div>
        </div>
        <div className={classes.rowElement}>
          <div className={classes.trafficView}>
            <PassingsPastThreeYears />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrafficView;
