import React from 'react';

import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: { width: '100%', color: '#1065d9' },
    labelRoot: {
      fontSize: '10px !important',
      lineHeight: '12px !important',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '14px !important',
        lineHeight: '18px !important',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '18px !important',
        lineHeight: '22px !important',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '28px !important',
        lineHeight: '36px !important',
      },
      fontStyle: 'normal',
      fontWeight: 400,
      color: theme.colors.text.main + ' !important',
    },
    labelFocused: {
      color: '#1065d9 !important',
      fontSize: '12px !important',
      lineHeight: '14px !important',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '16px !important',
        lineHeight: '20px !important',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '30px !important',
        lineHeight: '38px !important',
      },
    },
    notchedOutline: {
      borderWidth: '1.5px',
      borderColor: '#e7eef1 !important',
    },
    text: {
      color: theme.colors.text.main,
      fontSize: '12px !important',
      lineHeight: '14px !important',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '16px !important',
        lineHeight: '20px !important',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '30px !important',
        lineHeight: '38px !important',
      },
    },
    focused: {},
    outline: {
      fontSize: '12px !important',
      lineHeight: '14px !important',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '16px !important',
        lineHeight: '20px !important',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '30px !important',
        lineHeight: '38px !important',
      },
      '&$focused $notchedOutline': { borderColor: '#1065d9 !important' },
    },
  })
);

export default function CommonTextField({
  id,
  label,
  error,
  type,
  value,
  disabled,
  onFocus,
  onChange,
}: {
  id: string;
  label: string;
  error?: boolean;
  type: 'email' | 'password' | 'tel' | 'text';
  value?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id={id}
      name={type}
      autoComplete={type === 'email' || type === 'password' ? 'on' : 'off'}
      label={label}
      type={type}
      value={value}
      error={error}
      variant="outlined"
      disabled={disabled}
      onFocus={() => {
        onFocus && onFocus();
      }}
      onChange={(event) => {
        onChange && onChange(event);
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          input: classes.text,
          root: classes.outline,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
        },
      }}
    />
  );
}
