import React from 'react';

import { Tooltip } from 'react-leaflet';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonText from 'components/common/textOutput/CommonText';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 50,
      borderRadius: 7,
      padding: 20,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    image: { width: 100, padding: 5 },

    title: {
      marginBottom: 5,
    },
    divider: { marginLeft: 5, marginRight: 5, marginTop: 10, marginBottom: 10 },
  })
);

export default function EnvironmentCardMapMarkerPopup({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  const classes = useStyles();

  return (
    <Tooltip
      offset={[20, 6]}
      direction="right"
      className={classes.root}
      opacity={1}
    >
      <CommonText className={classes.title} textVariant="20 medium">
        {title}
      </CommonText>
      <CommonText textVariant="20 medium">{subtitle}</CommonText>
    </Tooltip>
  );
}
