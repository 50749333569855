import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonCard from 'components/common/cards/CommonCard';
import Description from 'components/common/textInputs/Description';
import CommonFab from 'components/common/CommonFab';

import { Project } from 'services/deliveryApi/models/Project';
import { authorizedProjectApi } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    cardBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    descriptionContainer: { flex: 3 },
    description: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
    },
    saveDescription: {
      paddingRight: 20,
    },
    locationPickerContainer: {
      flex: 5,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    locationPicker: {
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    editLocationButton: {
      zIndex: 1,
      position: 'absolute',
      padding: 10,
      right: 10,
    },

    saveLocationButton: {
      zIndex: 1,
      position: 'absolute',
      padding: 10,
      right: 150,
    },
    saveDescriptonButton: {
      marginRight: 10,
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      paddingRight: 20,
    },
  })
);

export default function ProjectDescriptionLocationCard({
  editable,
  className,
  project,
  disabled,
  refetch,
}: {
  editable?: boolean;
  project?: Project;
  className?: string;
  disabled?: boolean;
  refetch?: () => void;
}) {
  const classes = useStyles();

  const [descriptionText, setDescriptionText] = useState(
    project?.projectDescription
  );

  const [editDescriptionMode, seteditDescriptionMode] = useState(false);

  const [savedDescription, setSavedDescription] = useState(false);

  function onSaveClick() {
    authorizedProjectApi().then((api) => {
      api
        .updateProject({
          projectId: String(project?.id),
          updateProject: {
            projectDescription: descriptionText,
          },
        })
        .then(refetch);
    });
  }

  return (
    <div
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
    >
      <CommonCard
        disabled={disabled}
        title={'Prosjektbeskrivelse'}
        headerRightComponent={
          editable ? (
            <div className={classes.buttonsContainer}>
              {savedDescription || editDescriptionMode ? (
                <CommonFab
                  className={classes.saveDescriptonButton}
                  text={savedDescription ? 'Lagret' : 'Lagre'}
                  staticComponent={savedDescription}
                  onClick={() => {
                    onSaveClick();
                    setSavedDescription(true);
                    seteditDescriptionMode(false);
                  }}
                  buttonSize="medium"
                  textColor="success"
                  selected={savedDescription}
                />
              ) : null}

              <CommonFab
                icon="edit-2"
                onClick={() => {
                  seteditDescriptionMode(!editDescriptionMode);
                  setSavedDescription(false);
                }}
                selected={editDescriptionMode}
                buttonSize="medium"
              />
            </div>
          ) : null
        }
        bodyComponent={
          <div style={{ height: '100%', padding: 20, paddingTop: 5 }}>
            <Description
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setDescriptionText(event.currentTarget.value);
                setSavedDescription(false);
              }}
              onClick={() => {
                editable && seteditDescriptionMode(true);
              }}
              text={descriptionText}
              className={classes.description}
              editable={editDescriptionMode}
              placeholder={'Skriv en prosjektbeskrivelse...'}
            />
          </div>
        }
      />
    </div>
  );
}
