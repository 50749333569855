/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Delivery API
 * This API manages the projects and docuements that are stored in the Ameta Document Delivery. The API used oAuth2 flow for authorization.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeoJSONLocation,
    GeoJSONLocationFromJSON,
    GeoJSONLocationFromJSONTyped,
    GeoJSONLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewProject
 */
export interface NewProject {
    /**
     * 
     * @type {string}
     * @memberof NewProject
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof NewProject
     */
    projectNumber: string;
    /**
     * 
     * @type {GeoJSONLocation}
     * @memberof NewProject
     */
    projectLocation?: GeoJSONLocation;
    /**
     * 
     * @type {string}
     * @memberof NewProject
     */
    projectDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProject
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProject
     */
    customerProjectNumber?: string;
}

export function NewProjectFromJSON(json: any): NewProject {
    return NewProjectFromJSONTyped(json, false);
}

export function NewProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectName': json['projectName'],
        'projectNumber': json['projectNumber'],
        'projectLocation': !exists(json, 'projectLocation') ? undefined : GeoJSONLocationFromJSON(json['projectLocation']),
        'projectDescription': !exists(json, 'projectDescription') ? undefined : json['projectDescription'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'customerProjectNumber': !exists(json, 'customerProjectNumber') ? undefined : json['customerProjectNumber'],
    };
}

export function NewProjectToJSON(value?: NewProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectName': value.projectName,
        'projectNumber': value.projectNumber,
        'projectLocation': GeoJSONLocationToJSON(value.projectLocation),
        'projectDescription': value.projectDescription,
        'startDate': value.startDate,
        'customerProjectNumber': value.customerProjectNumber,
    };
}


