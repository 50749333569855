import { Auth } from 'aws-amplify';
import { QueryClient } from 'react-query';
import {
  CompanyApi,
  DocumentApi,
  ProjectApi,
  UserCompanyApi,
} from 'services/deliveryApi/apis';
import { UserApi, UserTenantApi } from 'services/userApi/apis';
import { TrafficApi } from 'services/trafficApi/apis';
import { YmApi } from 'services/ymApi/apis';
import { NvdbApi } from 'services/nvdbApi/apis';
import { MockApi } from 'services/mockApi/apis';
import { AvailabilityApi } from 'services/availabilityApi/apis';
import {
  SecurityEventApi,
  SecurityCounterApi,
} from 'services/securityApi/apis';
import {
  RuhReportApi,
  SjaReportApi,
  NhReportApi,
  HmsCounterApi,
  DeleteReportApi,
} from 'services/hmsApi';

import { Configuration as DeliveryConfiguration } from 'services/deliveryApi/runtime';
import { Configuration as TrafficConfiguration } from 'services/trafficApi/runtime';
import { Configuration as YmConfiguration } from 'services/ymApi/runtime';
import { Configuration as NvdbConfiguration } from 'services/nvdbApi/runtime';
import { Configuration as UserConfiguration } from 'services/userApi/runtime';
import { Configuration as MockConfiguration } from 'services/mockApi/runtime';
import { Configuration as AvailabilityConfiguration } from 'services/availabilityApi/runtime';
import { Configuration as SecurityConfiguration } from 'services/securityApi/runtime';
import { Configuration as HmsConfiguration } from 'services/hmsApi/runtime';

export const queryClient = new QueryClient();

export const authorizedProjectApi: () => Promise<ProjectApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/delivery',
  });

  return new ProjectApi(apiConfig);
};

export const authorizedDocumentApi: () => Promise<DocumentApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/delivery',
  });

  return new DocumentApi(apiConfig);
};

export const authorizedUserApi: () => Promise<UserApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new UserConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/users',
  });

  return new UserApi(apiConfig);
};

export const authorizedCompanyApi: () => Promise<CompanyApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/delivery',
  });
  return new CompanyApi(apiConfig);
};

export const authorizedUserCompanyApi: () => Promise<UserCompanyApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new DeliveryConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/delivery',
  });
  return new UserCompanyApi(apiConfig);
};

export const authorizedTrafficApi: () => Promise<TrafficApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new TrafficConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/traffic',
  });
  return new TrafficApi(apiConfig);
};
export const authorizedYmApi: () => Promise<YmApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new YmConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/ym',
  });
  return new YmApi(apiConfig);
};

export const authorizedNvdbApi: () => Promise<NvdbApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new NvdbConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/nvdb',
  });
  return new NvdbApi(apiConfig);
};

export const authorizedMockApi: () => Promise<MockApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new MockConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/mock',
  });
  return new MockApi(apiConfig);
};

export const authorizedAvailabilityApi: () => Promise<AvailabilityApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new AvailabilityConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/availability',
  });
  return new AvailabilityApi(apiConfig);
};

export const getUserTenantRecord: () => Promise<UserTenantApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new UserConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/users',
  });
  return new UserTenantApi(apiConfig);
};

export const authorizedSecurityEventApi: () => Promise<SecurityEventApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new SecurityConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/security',
  });
  return new SecurityEventApi(apiConfig);
};

export const authorizedSecurityCounterApi: () => Promise<SecurityCounterApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new SecurityConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/security',
  });
  return new SecurityCounterApi(apiConfig);
};

export const authorizedHmsCounterApi: () => Promise<HmsCounterApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/hms',
  });
  return new HmsCounterApi(apiConfig);
};

export const authorizedRuhReportApi: () => Promise<RuhReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/hms',
  });
  return new RuhReportApi(apiConfig);
};

export const authorizedDeleteReportApi: () => Promise<DeleteReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/hms',
  });
  return new DeleteReportApi(apiConfig);
};

export const authorizedSjaReportApi: () => Promise<SjaReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/hms',
  });
  return new SjaReportApi(apiConfig);
};

export const authorizedNhEventApi: () => Promise<NhReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + '/hms',
  });
  return new NhReportApi(apiConfig);
};
