import React from 'react';
import clsx from 'clsx';
import Typography, { TypographyProps } from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import theme from 'config/theme';

function CommonText({
  typographyProps,
  children,
  component,
  href,
  className,
  style,
  textVariant,
  colorOverride,
  noWrap,
  alignCenter,
}: {
  typographyProps?: TypographyProps;
  component?: 'a' | 'div';
  href?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  textVariant:
    | '8 light'
    | '8 medium'
    | '10 light'
    | '10 medium'
    | '12 light'
    | '12 medium'
    | '14 light'
    | '14 medium'
    | '16 medium'
    | '20 light'
    | '20 medium'
    | '50 medium'
    | '32 medium'
    | '90 medium';
  colorOverride?:
    | 'secondary'
    | 'light'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
    | 'positive'
    | 'info'
    | 'unknown';
  noWrap?: boolean;
  alignCenter?: boolean;
}) {
  let styleOverride: React.CSSProperties = {};

  switch (textVariant) {
    case '8 light':
      styleOverride = {
        fontSize: '6px',
        lineHeight: '8px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '8px',
          lineHeight: '10px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '13px',
          lineHeight: '15px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
        fontStyle: 'normal',
        fontWeight: 350,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '8 medium':
      styleOverride = {
        fontSize: '6px',
        lineHeight: '10px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '8px',
          lineHeight: '12px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '13px',
          lineHeight: '15px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '10 light':
      styleOverride = {
        fontSize: '8px',
        lineHeight: '10px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '10px',
          lineHeight: '13px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '24px',
          lineHeight: '28px',
        },
        fontStyle: 'normal',
        fontWeight: 350,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '10 medium':
      styleOverride = {
        fontSize: '8px',
        lineHeight: '10px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '10px',
          lineHeight: '13px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '24px',
          lineHeight: '28px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '12 light':
      styleOverride = {
        fontSize: '10px',
        lineHeight: '12px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '12px',
          lineHeight: '16px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '18px',
          lineHeight: '22px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '28px',
          lineHeight: '30px',
        },
        fontStyle: 'normal',
        fontWeight: 350,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '12 medium':
      styleOverride = {
        fontSize: '10px',
        lineHeight: '12px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '12px',
          lineHeight: '16px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '18px',
          lineHeight: '22px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '28px',
          lineHeight: '30px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '14 light':
      styleOverride = {
        fontSize: '10px',
        lineHeight: '12px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '14px',
          lineHeight: '18px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '32px',
          lineHeight: '38px',
        },
        fontStyle: 'normal',
        fontWeight: 350,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '14 medium':
      styleOverride = {
        fontSize: '10px',
        lineHeight: '12px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '14px',
          lineHeight: '18px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '32px',
          lineHeight: '38px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '16 medium':
      styleOverride = {
        fontSize: '12px',
        lineHeight: '14px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '22px',
          lineHeight: '26px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '34px',
          lineHeight: '40px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '20 light':
      styleOverride = {
        fontSize: '16px',
        lineHeight: '20px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '20px',
          lineHeight: '26px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '28px',
          lineHeight: '32px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '40px',
          lineHeight: '52px',
        },
        fontStyle: 'normal',
        fontWeight: 350,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '20 medium':
      styleOverride = {
        fontSize: '16px',
        lineHeight: '20px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '20px',
          lineHeight: '26px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '28px',
          lineHeight: '32px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '40px',
          lineHeight: '52px',
        },
        fontStyle: 'normal',
        fontWeight: 500,
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '32 medium':
      styleOverride = {
        fontSize: '18px',
        lineHeight: '24px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '32px',
          lineHeight: '50px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '36px',
          lineHeight: '40px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '60px',
          lineHeight: '80px',
        },
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '50 medium':
      styleOverride = {
        fontSize: '30px',
        lineHeight: '40px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '50px',
          lineHeight: '75px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '65px',
          lineHeight: '75px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '100px',
          lineHeight: '150px',
        },
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
    case '90 medium':
      styleOverride = {
        fontSize: '50px',
        lineHeight: '70px',
        [theme.breakpoints.up('fullhd')]: {
          fontSize: '90px',
          lineHeight: '140px',
        },
        [theme.breakpoints.up('wqhd')]: {
          fontSize: '120px',
          lineHeight: '140px',
        },
        [theme.breakpoints.up('uhd')]: {
          fontSize: '190px',
          lineHeight: '280px',
        },
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colorOverride
          ? theme.colors.text[colorOverride]
          : theme.colors.text.main,
      };
      break;
  }

  const useStyles = makeStyles(() =>
    createStyles({ root: { textTransform: 'none', ...styleOverride } })
  );
  const classes = useStyles();
  const additionalProps = {};

  if (component) {
    additionalProps['component'] = component;
  }
  if (href) {
    additionalProps['href'] = href;
  }

  return (
    <Typography
      align={alignCenter ? 'center' : 'left'}
      noWrap={noWrap}
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
      variant={undefined}
      {...typographyProps}
      {...additionalProps}
      style={{ ...style }}
    >
      {children}
    </Typography>
  );
}

export default CommonText;
