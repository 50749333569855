import React, { useState } from 'react';

import CustomScroll from 'react-custom-scroll';

import clsx from 'clsx';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import CommonText from 'components/common/textOutput/CommonText';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { chunckArrayToPercentageArray } from 'utils/chunckArrayToPercentageArray';
import ProjectTableItem from 'components/deliveryDash/listView/ProjectTableItem';
import { CompanyProjectRelation } from 'services/deliveryApi/models';
import useComponentSize from 'utils/hooks/useComponentSize';

import 'assets/css/commonScroll.css';

import theme from 'config/theme';
import { getComparator, stableSort } from 'utils/sorting';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '100%',
      minHeight: '100%',
      borderRadius: 30,
      padding: 10,
    },
    headCell: {
      padding: 0,
      borderBottom: 'none',
      backgroundColor: theme.colors.base.white,
    },
    headCellContent: {
      width: '100%',
      height: '100%',
      padding: 10,
      borderBottom: '20px solid',
      borderImage:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.0) 0%, rgba(166, 190, 203, 0.25) 100%)',
      borderImageSlice: 50,
    },
    headCellBorder: { borderLeft: '2px solid rgba(230, 237, 241, 0.7)' },
    searchField: {
      backgroundColor: theme.colors.base.background,
      width: '25%',
    },
  })
);

export default function ProjectTable({
  projects,
}: {
  projects: CompanyProjectRelation[] | undefined;
}) {
  const [paperRef, setPaperRef] = useState<HTMLElement>();

  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('about');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  // Works like how flexBox works. First element is first cell width, second element is second element width and so on
  const chunckArray = [1, 1.5, 1, 2, 1];
  const percentageArray = chunckArrayToPercentageArray(chunckArray);

  const headCells: {
    disablePadding: boolean;
    id: string;
    label: string;
    align: 'center' | 'left' | 'right';
  }[] = [
    {
      id: 'companyName',
      align: 'left',
      disablePadding: false,
      label: 'Eier',
    },
    {
      id: 'projectName',
      align: 'left',
      disablePadding: false,
      label: 'Prosjektnavn',
    },
    {
      id: 'projectNumber',
      align: 'left',
      disablePadding: false,
      label: 'Prosjektnummer',
    },
    {
      id: 'about',
      align: 'left',
      disablePadding: false,
      label: 'Om prosjektet',
    },
    {
      id: 'startDate', // sorts this column on startDate
      align: 'left',
      disablePadding: false,
      label: 'Tidsperiode',
    },
  ];

  return (
    <Paper
      ref={(ref) => {
        setPaperRef(ref as HTMLElement);
      }}
      className={classes.root}
    >
      <TableContainer
        style={{
          height: 65, //HACK
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  className={clsx(classes.headCell, {
                    [classes.headCellBorder]: index > 0,
                  })}
                  key={headCell.id}
                  align={headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  width={percentageArray[index]}
                >
                  <div className={classes.headCellContent}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      <CommonText textVariant="12 light">
                        {headCell.label}
                      </CommonText>
                    </TableSortLabel>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer
        style={{
          height: useComponentSize(paperRef, 700).height - 100,
        }}
        className={'common-scroll'}
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={100}>
          <Table stickyHeader>
            <TableBody>
              {projects &&
                stableSort(
                  projects as unknown as {
                    [key: string]: string | number;
                  }[],
                  getComparator(order, orderBy)
                ).map((sortedItem, index, array) => {
                  return (
                    <ProjectTableItem
                      project={sortedItem as unknown as CompanyProjectRelation}
                      key={String(index)}
                      lastElement={index === array.length - 1}
                      percentageArray={percentageArray}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
    </Paper>
  );
}
