import {
  CompanyProjectRelation,
  ProjectDocumentRelation,
  ProjectPointcloudRelation,
  Project,
  Company,
  UserCompanyRelation,
} from 'services/deliveryApi/models';
import { User } from 'services/userApi/models';
import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import {
  authorizedProjectApi,
  authorizedUserApi,
  authorizedCompanyApi,
  authorizedUserCompanyApi,
} from 'services/apiService';

export const useQueryProjects = (): {
  data: CompanyProjectRelation[] | undefined;
  refetch: () => void;
} => {
  const { data, refetch } = useQuery(['projects'], async () => {
    const api = await authorizedProjectApi();
    return api.listProjects();
  });

  return { data, refetch };
};

export const useQueryProjectById = (
  projectId: string
): { data: Project | undefined; refetch: () => void } => {
  const { data, refetch } = useQuery(
    ['projectById', projectId],
    async () => {
      const api = await authorizedProjectApi();
      return await api.getProject({ projectId: projectId });
    },
    { enabled: !!projectId && projectId !== undefined }
  );

  return { data, refetch };
};

export const useQueryDocuments = (
  projectId: string
): {
  data: ProjectDocumentRelation[] | undefined;
  refetch: () => void;
  isFetching: boolean;
} => {
  const { data, refetch, isFetching } = useQuery(
    ['documents'],
    async () => {
      const api = await authorizedProjectApi();
      try {
        const response = await api.listDocuments({ projectId });
        return response;
      } catch (err) {
        return undefined;
      }
    },
    {
      enabled: !!projectId && projectId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  return { data, refetch, isFetching };
};

export const useQueryPointclouds = (
  projectId: string
): {
  data: ProjectPointcloudRelation[] | undefined;
  refetch: () => void;
  isFetching: boolean;
} => {
  const { data, refetch, isFetching } = useQuery(
    ['pointclouds'],
    async () => {
      const api = await authorizedProjectApi();
      try {
        const response = await api.listPointclouds({ projectId });
        return response;
      } catch (err) {
        return undefined;
      }
    },
    { enabled: !!projectId && projectId !== undefined }
  );

  return { data, refetch, isFetching };
};

export const useQueryCognitoUser: () => {
  data:
    | {
        firstName: string;
        imageUrl: string;
        username: string;
        authToken: string;
      }
    | undefined;
  refetch: () => void;
} = () => {
  const { data, refetch } = useQuery('cognitoUser', async () => {
    const cognitoUser = await Auth.currentSession();
    const token = cognitoUser.getIdToken();
    return {
      firstName: token.payload.given_name,
      imageUrl: token.payload.picture,
      username: token.payload.username,
      authToken: token.getJwtToken(),
    };
  });
  return { data, refetch };
};

export const useQueryUserCompany: () => {
  data: UserCompanyRelation | undefined;
} = () => {
  const { data } = useQuery('userCompany', async () => {
    const api = await authorizedUserCompanyApi();
    const userInfo = await Auth.currentSession();
    const token = userInfo.getIdToken();
    const userId = token.payload['email'];
    try {
      const userCompany = await api.userCompany({ userId });
      return userCompany;
    } catch (err) {
      return undefined;
    }
  });
  return { data };
};

export const useQueryDynamodbUser: () => {
  data: User | undefined;
  isLoading: boolean;
  refetch: () => void;
} = () => {
  const { data, refetch, isLoading } = useQuery(
    'dynamodbUser',
    async () => {
      const api = await authorizedUserApi();
      const userInfo = await Auth.currentSession();
      const token = userInfo.getIdToken();
      const userId = token.payload['email'];
      return api.getUser({ userId });
    },
    { retry: 1 }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useQueryProjectCompaniesList = (
  projectId: string
): {
  data: CompanyProjectRelation[] | undefined;
  refetch: () => void;
} => {
  const { data, refetch } = useQuery(
    ['projectCompaniesList', projectId],
    async () => {
      const api = await authorizedProjectApi();
      return api.listProjectCompanies({
        projectId: projectId as string,
      });
    },
    { enabled: !!projectId && projectId !== undefined }
  );

  return { data: data, refetch: refetch };
};

export const useQueryProjectUsersList = (
  projectId: string
): {
  data: User[] | undefined;
  refetch: () => void;
} => {
  const { data, refetch } = useQuery(
    ['projectUsersList', projectId],
    async () => {
      const api = await authorizedProjectApi();
      return api.listProjectUsers({
        projectId: projectId as string,
      });
    },
    { enabled: !!projectId && projectId !== undefined }
  );

  return { data: data, refetch: refetch };
};

export const useQueryCompanyById = (
  companyId: string
): { data: Company | undefined; refetch: () => void } => {
  const { data, refetch } = useQuery(
    ['companyById', companyId],
    async () => {
      const api = await authorizedCompanyApi();
      return await api.getCompany({ companyId: companyId as string });
    },
    { enabled: !!companyId && companyId !== undefined }
  );

  return { data, refetch };
};

export const useQueryAllCompanies = (): {
  data: Company[] | undefined;
  refetch: () => void;
  isFetching: boolean;
} => {
  const { data, refetch, isFetching } = useQuery(['allCompanies'], async () => {
    const api = await authorizedCompanyApi();
    return await api.getCompanies();
  });

  return { data, refetch, isFetching };
};
