import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 125,
      maxHeight: 125,
      paddingLeft: 75,
      paddingRight: 75,
    },
    leftView: {
      height: '100%',
      alignItems: 'center',
      flex: 1,
    },
    rightView: {
      height: '100%',
      alignItems: 'center',
      flex: 1,
    },
  })
);

export default function TopView({
  leftSideComponents,
  rightSideComponents,
}: {
  leftSideComponents: React.ReactNode;
  rightSideComponents?: React.ReactNode;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.leftView}>{leftSideComponents}</div>
      <div className={classes.rightView}>{rightSideComponents}</div>
    </div>
  );
}
