import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  Bar,
  BarChart as ReachartsBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Label,
} from 'recharts';
import CustomizedAxisTick from '../CustomizedAxisTick';
import CommonText from 'components/common/textOutput/CommonText';

import theme from 'config/theme';

const useStyles = makeStyles(() => createStyles({ root: {} }));

export default function BarChart({
  dataKeys,
  data,
  colorArray,
  margin,
  className,
  yAxisLabel,
}: {
  dataKeys: string[];
  data: { [key: string]: number | string }[];
  colorArray?: string[];
  margin?: { top: number; bottom: number; left: number; right: number };
  className?: string;
  yAxisLabel?: string;
}) {
  const classes = useStyles();

  return (
    <ResponsiveContainer
      className={clsx(classes.root, {
        [className as string]: className,
      })}
      width="99%"
      height="100%"
    >
      <ReachartsBarChart
        data={data}
        margin={margin ? margin : { top: 50, bottom: 50, left: 10, right: 10 }}
        barCategoryGap="20%"
        barGap={'1%'}
      >
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          stroke={theme.colors.text.main}
          interval={0}
          tick={<CustomizedAxisTick />}
          dataKey="label"
        />
        <Legend
          payload={dataKeys.map((item, index) => ({
            id: item,
            type: 'circle',
            value: item,
            color: colorArray ? colorArray[index] : undefined,
          }))}
          content={(props) => {
            const { payload } = props;

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  padding: 10,
                }}
              >
                {payload &&
                  payload.map((entry, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',

                        alignItems: 'center',
                        paddingRight: 20,
                      }}
                    >
                      <div
                        style={{
                          height: 10,
                          width: 10,
                          borderRadius: '50%',
                          marginRight: 5,
                          backgroundColor: entry.color ? entry.color : 'black',
                        }}
                      />
                      <CommonText textVariant="12 light">
                        {entry.value}
                      </CommonText>
                    </div>
                  ))}
              </div>
            );
          }}
          align="right"
          verticalAlign="top"
        />
        <YAxis
          label={
            yAxisLabel ? (
              <Label
                value={yAxisLabel}
                position="top"
                fill={theme.colors.text.main}
                offset={10}
              />
            ) : undefined
          }
          stroke={theme.colors.text.main}
        />
        {dataKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            fill={colorArray ? colorArray[index] : undefined}
          />
        ))}

        <Tooltip
          labelStyle={{
            color: 'black',
          }}
          itemStyle={{
            color: 'black',
          }}
          wrapperStyle={{
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
          isAnimationActive={false}
        />
      </ReachartsBarChart>
    </ResponsiveContainer>
  );
}
