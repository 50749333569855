import { useState } from 'react';

const useComponentSize = (
  elementRef: HTMLElement | undefined,
  initialHeight?: number
): { height: number; width: number } => {
  const [height, setHeight] = useState<number>(
    initialHeight ? initialHeight : 0
  );

  const [width, setWidth] = useState<number>(initialHeight ? initialHeight : 0);

  const [firstRun, setFirstRun] = useState(true);

  if (firstRun && elementRef) {
    setHeight(elementRef.getBoundingClientRect().height);
    setWidth(elementRef.getBoundingClientRect().width);
    setFirstRun(false);
  } else if (elementRef) {
    window.addEventListener('resize', () => {
      setHeight(elementRef.getBoundingClientRect().height);
      setWidth(elementRef.getBoundingClientRect().width);
    });
  }
  return { height, width };
};

export default useComponentSize;
