import React, { useState } from 'react';

import Paper from '@mui/material/Paper';
import * as materialColors from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';

import CommonText from 'components/common/textOutput/CommonText';

import BarChart from 'components/common/charts/BarChart/BarChart';

import { format } from 'date-fns';

import theme from 'config/theme';
import { SecurityCounter, SecurityYearObject } from 'services/securityApi';
import { CircularProgress } from '@mui/material';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import { yearsListDropdown } from 'utils/yearsListDropDown';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      border: 'solid',
      backgroundColor: theme.colors.base.base1,
      borderColor: 'white',
      borderWidth: 5,
    },
    paperHeader: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      backgroundColor: theme.colors.base.white,
      flex: 10,
      width: '100%',
      display: 'flex',
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      flexDirection: 'column',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    pickers: { display: 'flex', alignItems: 'center', paddingRight: 20 },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
  })
);

interface GraphData {
  label: string;
  'Lettere skadet'?: number;
  'Alvorlig skadet'?: number;
  'Meget alvorlig skadet'?: number;
  'Påkjørt rekkverk'?: number;
  'Andre ulykker uten personskade'?: number;
  Drepte?: number;
}

const labelMonthConverter = {
  m1: 'Jan',
  m2: 'Feb',
  m3: 'Mar',
  m4: 'Apr',
  m5: 'Mai',
  m6: 'Jun',
  m7: 'Jul',
  m8: 'Aug',
  m9: 'Sep',
  m10: 'Okt',
  m11: 'Nov',
  m12: 'Des',
};

function SecurityBarChart({
  securityCounterData,
  isLoading,
}: {
  securityCounterData: SecurityCounter | undefined;
  isLoading: boolean;
}) {
  const classes = useStyles();

  const [selectedYear, setSelectedYear] = useState(format(Date.now(), 'yyyy'));

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const secuityNumbersYearMonthMapper: (
    year: SecurityYearObject | undefined
  ) => GraphData[] = (year: SecurityYearObject | undefined) => {
    const securityNumbers: GraphData[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      if (year && year.months[key]) {
        securityNumbers.push({
          label: value,
          ...(year.months[key].accidentsWithLightPersonalInjuries && {
            'Lettere skadet':
              year.months[key].accidentsWithLightPersonalInjuries,
          }),
          ...(year.months[key].accidentsWithSeriousPersonalInjuries && {
            'Alvorlig skadet':
              year.months[key].accidentsWithSeriousPersonalInjuries,
          }),
          ...(year.months[key].accidentsWithSignificantPersonalInjuries && {
            'Meget alvorlig skadet':
              year.months[key].accidentsWithSignificantPersonalInjuries,
          }),
          ...(year.months[key].fatalAccidents && {
            Drepte: year.months[key].fatalAccidents,
          }),
          ...(year.months[key].hitRailings && {
            'Påkjørt rekkverk': year.months[key].hitRailings,
          }),
          ...(year.months[key].otherAccidents && {
            'Andre ulykker uten personskade': year.months[key].otherAccidents,
          }),
        });
      } else {
        securityNumbers.push({
          label: value,
        });
      }
    });
    return securityNumbers;
  };

  const reportYear =
    securityCounterData &&
    securityCounterData.data &&
    securityCounterData.data.years &&
    securityCounterData.data.years[yearStringConverter(selectedYear)];
  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div
          className={classes.paperHeaderTop}
          style={{ display: 'flex', justifyContent: '' }}
        >
          <CommonText textVariant="20 medium" colorOverride="white">
            Så langt i {selectedYear}
          </CommonText>

          <div className={classes.pickerContainer} style={{ display: 'flex' }}>
            <CommonText
              textVariant="14 medium"
              colorOverride="white"
              className={classes.pickerLabel}
            >
              År:
            </CommonText>
            <OptionsPicker
              className={classes.picker}
              reverse
              optionsList={yearsListDropdown()}
              initialvalue={selectedYear}
              parentCallback={(value) => {
                setSelectedYear(value);
              }}
            />
          </div>
        </div>

        <Divider />
      </div>
      <div className={classes.paperBody}>
        {isLoading ? (
          <div className={classes.centeringContainer}>
            <CircularProgress
              size={150}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        ) : (
          <BarChart
            margin={{ top: 20, bottom: 20, left: 0, right: 50 }}
            dataKeys={[
              'Lettere skadet',
              'Alvorlig skadet',
              'Meget alvorlig skadet',
              'Drepte',
              'Påkjørt rekkverk',
              'Andre ulykker uten personskade',
            ]}
            colorArray={[
              materialColors.lime[500],
              materialColors.yellow[700],
              materialColors.deepOrange[400],
              materialColors.red[900],
              materialColors.blueGrey[700],
              materialColors.green[500],
            ]}
            data={
              secuityNumbersYearMonthMapper(reportYear) as unknown as {
                [key: string]: string | number;
              }[]
            }
          />
        )}
      </div>
    </Paper>
  );
}

export default SecurityBarChart;
