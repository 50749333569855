import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import theme from 'config/theme';

import TopView from 'components/opsDash/TopView';

import AvailabilityCard from 'components/opsDash/mainView/AvailabilityCard';
import EnvironmentCard from 'components/opsDash/mainView/environmentCard/EnvironmentCard';
import NandHValueCard from 'components/opsDash/mainView/NandHValueCard';
import RUHCard from 'components/opsDash/mainView/RUHCard';
import SecurityCard from 'components/opsDash/mainView/securityCard/SecurityCard';
import { useQueryHmsCounter } from 'services/hmsQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    cards: {
      padding: 32,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    rowElement: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    card: {
      flex: 1,
      padding: 8,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    doubleCard: {
      flex: 2,
      padding: 8,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menu: {
      height: '100%',
      width: '4%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 60,
      backgroundColor: theme.colors.base.white,
    },
  })
);

function MainDash() {
  const classes = useStyles();

  const hmsCounterQuery = useQueryHmsCounter();

  const hmsCounterData = hmsCounterQuery.data;

  return (
    <div className={classes.root}>
      <TopView title="Dashboard" />

      <div className={classes.cards}>
        <div className={classes.rowElement}>
          <div className={classes.card}>
            <AvailabilityCard />
          </div>
          <div className={classes.doubleCard}>
            <EnvironmentCard />
          </div>
        </div>
        <div className={classes.rowElement}>
          <div className={classes.card}>
            <NandHValueCard hmsCounterData={hmsCounterData} />
          </div>

          <div className={classes.card}>
            <RUHCard hmsCounterData={hmsCounterData} />
          </div>

          <div className={classes.card}>
            <SecurityCard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDash;
