import React from 'react';
import {
  YAxis,
  Bar,
  BarChart,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

import theme from 'config/theme';

export default function BarIndicatior({
  value,
  threshold,
  domain,
}: {
  value: number;
  threshold?: number;
  domain?: number[];
}) {
  return (
    <ResponsiveContainer height="100%" minHeight={100}>
      <BarChart
        margin={{ left: -30, top: 5, bottom: 5, right: 0 }}
        data={[{ value }]}
      >
        <YAxis
          interval={0}
          domain={
            domain
              ? domain
              : [
                  0,
                  threshold && threshold > value
                    ? Math.round(threshold * 1.3)
                    : Math.round(value * 1.3),
                ]
          }
        />

        <Bar radius={10} dataKey="value" fill={theme.colors.base.base1} />

        <ReferenceLine
          y={threshold}
          stroke={theme.colors.indicators.serious}
          strokeWidth={3}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
