import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonDialog from 'components/common/CommonDialog';
import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';

import { authorizedDocumentApi } from 'services/apiService';
import { ProjectDocumentRelation } from 'services/deliveryApi/models';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      maxHeight: '4vh',
      minHeight: '4vh',
      width: 300,
      padding: 10,
      // backgroundColor: theme.colors.messageAndAlerts.notificationInfo,
      // '&:hover': {
      //   backgroundColor: theme.colors.messageAndAlerts.notificationInfo,
      // },
    },
    actions: {
      width: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  })
);

function DocumentDeleteDialog({
  open,
  onClose,
  documentToDelete,
  refetchDocuments,
}: {
  open: boolean;
  onClose: () => void;
  documentToDelete: ProjectDocumentRelation;
  refetchDocuments: () => void;
}) {
  const classes = useStyles();

  return (
    <CommonDialog
      open={open}
      dialogContent={
        <div className={classes.dialogContent}>
          <CommonText textVariant="20 medium">
            Er du sikker på du vil slette {documentToDelete.documentName}?
          </CommonText>
        </div>
      }
      dialogActions={
        <div className={classes.actions}>
          <CommonFab
            className={classes.button}
            text="Avbryt"
            onClick={onClose}
          />
          <div style={{ width: 50 }} />
          <CommonFab
            className={classes.button}
            icon="trash-2"
            iconColor={theme.colors.messageAndAlerts.error}
            text="Ja, slett dokumentet"
            onClick={() => {
              authorizedDocumentApi().then((api) => {
                api
                  .deleteDocument({ documentId: documentToDelete.sort })
                  .then(() => {
                    refetchDocuments();
                  });
              });
              onClose();
            }}
          />
        </div>
      }
      onClose={onClose}
    />
  );
}

export default DocumentDeleteDialog;
