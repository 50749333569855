import React, { useRef, useState, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CommonText from 'components/common/textOutput/CommonText';

import DocumentDeleteDialog from 'components/deliveryDash/project/documentsCard/DocumentDeleteDialog';

import { ProjectDocumentRelation } from 'services/deliveryApi/models';
import { downloadFile } from 'services/fileService';
import FeatherIcon from 'feather-icons-react';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    popperPaper: {
      minWidth: 50,
      minHeight: 45,
      borderRadius: 7,
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    menuListItem: {
      display: 'flex',
      justifyContent: 'center',
      '&:hover': { backgroundColor: 'white' },
    },
  })
);

function DocumentTableItemMenu({
  document,
  hideDelete,
  refetchDocuments,
}: {
  document: ProjectDocumentRelation;
  hideDelete?: boolean;
  refetchDocuments: () => void;
}) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const prevMenuOpen = useRef(menuOpen);

  useEffect(() => {
    if (prevMenuOpen.current === true && menuOpen === false) {
      menuAnchorRef.current?.focus();
    }

    prevMenuOpen.current = menuOpen;
  }, [menuOpen]);

  const handleMenuClose = (event) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  const [deleteDialogOpen, setDeleteDialigOpen] = useState(false);

  let iconSize = 20;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 25;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 30;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 42;
  }

  return (
    <React.Fragment>
      <DocumentDeleteDialog
        documentToDelete={document}
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialigOpen(false);
        }}
        refetchDocuments={refetchDocuments}
      />
      <IconButton
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        ref={menuAnchorRef}
        size="large">
        <FeatherIcon icon="more-horizontal" size={iconSize} />
      </IconButton>

      <Popper
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.popperPaper}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    className={classes.menuListItem}
                    onClick={() => {
                      downloadFile(document.sort);
                      setMenuOpen(false);
                    }}
                  >
                    <CommonText colorOverride="success" textVariant="12 medium">
                      Last ned
                    </CommonText>
                  </MenuItem>
                  {!hideDelete && (
                    <MenuItem
                      className={classes.menuListItem}
                      onClick={() => {
                        setDeleteDialigOpen(true);
                        setMenuOpen(false);
                      }}
                    >
                      <CommonText colorOverride="error" textVariant="12 medium">
                        Slett
                      </CommonText>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default DocumentTableItemMenu;
