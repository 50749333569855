import { SectionCounter, ReportObject } from 'services/availabilityApi/models';
import { useQuery } from 'react-query';
import { authorizedAvailabilityApi } from 'services/apiService';

export const useQuerySectionCounter = (): {
  data: SectionCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ['sectionCounter'],
    async () => {
      const api = await authorizedAvailabilityApi();
      return api.getSectionCounter();
    }
  );

  return { data, refetch, isLoading };
};

export const useReportHook = (
  year,
  month
): {
  data: ReportObject[] | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ['availabilityReport', year, month],
    async () => {
      const api = await authorizedAvailabilityApi();
      return api.getMontlyReport({ yearMonth: year + '-' + month });
    }
  );

  return { data, refetch, isLoading };
};
