export const chunckArrayToPercentageArray: (
  chunckArray: number[]
) => string[] = (chunckArray) => {
  const chunckToPercentage = (numberOfChuncs: number, totChuncks: number) => {
    return (numberOfChuncs / totChuncks).toLocaleString('en', {
      style: 'percent',
    });
  };
  const totalChuncks = chunckArray.reduce((a, b) => a + b, 0);
  const percentageArray: string[] = [];
  chunckArray.forEach((element) =>
    percentageArray.push(chunckToPercentage(element, totalChuncks))
  );

  return percentageArray;
};
