import React, { useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Divider from '@mui/material/Divider';
import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import theme from 'config/theme';

import { GeneralContext } from 'utils/context/General';

import ametaLogo from 'assets/img/Ameta-logo-hvit-flat_1.svg';
import agderOpsLogo from 'assets/img/agder_ops_logo.svg';
import smartInspectionLogo from 'assets/img/LOGO-Smart-Inspection.png';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '10%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      backgroundColor: theme.colors.base.base1light,
    },
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    leftHeader: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    lineEnd: {
      width: '100%',
      height: '0.5vh',
      backgroundColor: theme.colors.base.OpsRed,
    },
    divider: {
      height: '60%',
      width: 2,
      borderRadius: 1,
      marginLeft: 25,
      marginRight: 25,
    },
    opsLogo: { height: '5vh' },
    ametaLogo: { height: '4vh' },
    miniMenu: {
      height: '100%',
      display: 'flex',
      marginRight: 25,
      padding: 20,
      border: 'solid',
      borderWidth: 3,
      borderColor: theme.colors.base.background,
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: '#122E4E',
      borderRadius: 20,
    },
    showMenusButton: { marginLeft: 50 },
    smartInspectionLogo: {
      height: '5vh',
      paddingRight: 40,
    },
  })
);

function TopView({ title, subtitle }: { title: string; subtitle?: string }) {
  const classes = useStyles();

  const { generalState, setGeneralState } = useContext(GeneralContext);

  return (
    <div className={classes.root}>
      <div
        className={classes.content}
        style={{ paddingLeft: generalState.hideMenus ? 0 : 95 }}
      >
        <div className={classes.leftHeader}>
          {generalState.hideMenus && (
            <div className={classes.miniMenu}>
              <img
                className={classes.ametaLogo}
                alt="Ameta logo"
                src={ametaLogo}
              />
              <CommonFab
                className={classes.showMenusButton}
                icon="maximize-2"
                tooltip={{ placement: 'right', text: 'Ekspander' }}
                onClick={() => {
                  setGeneralState((prevState) => {
                    return { ...prevState, hideMenus: false };
                  });
                }}
              />
            </div>
          )}
          <img
            className={classes.opsLogo}
            alt="Agder OPS logo"
            src={agderOpsLogo}
          />
          <Divider
            className={classes.divider}
            orientation="vertical"
            variant="middle"
          />
          <CommonText textVariant="20 medium">{title}</CommonText>
          {subtitle && (
            <React.Fragment>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
              />
              <CommonText textVariant="20 light">{subtitle}</CommonText>
            </React.Fragment>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CommonText style={{ paddingRight: 20 }} textVariant="14 light">
            {'Laget av'}
          </CommonText>

          <img
            className={classes.smartInspectionLogo}
            alt="Smart Inspection logo"
            src={smartInspectionLogo}
          />
        </div>
      </div>
      <div className={classes.lineEnd} />
    </div>
  );
}

export default TopView;
