/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewYmPointLocation
 */
export interface NewYmPointLocation {
    /**
     * 
     * @type {number}
     * @memberof NewYmPointLocation
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof NewYmPointLocation
     */
    latitude?: number;
}

export function NewYmPointLocationFromJSON(json: any): NewYmPointLocation {
    return NewYmPointLocationFromJSONTyped(json, false);
}

export function NewYmPointLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewYmPointLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
    };
}

export function NewYmPointLocationToJSON(value?: NewYmPointLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longitude': value.longitude,
        'latitude': value.latitude,
    };
}


