/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NHReport
 */
export interface NHReport {
    /**
     * 
     * @type {string}
     * @memberof NHReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof NHReport
     */
    nValue: number;
    /**
     * 
     * @type {number}
     * @memberof NHReport
     */
    hValue: number;
}

export function NHReportFromJSON(json: any): NHReport {
    return NHReportFromJSONTyped(json, false);
}

export function NHReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): NHReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'nValue': json['nValue'],
        'hValue': json['hValue'],
    };
}

export function NHReportToJSON(value?: NHReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'nValue': value.nValue,
        'hValue': value.hValue,
    };
}


