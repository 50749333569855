import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { objectList } from './nvdObjectsTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from 'config/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: 200,
    maxWidth: 200,
    padding: 5,
    display: 'flex',
    'flex-direction': 'row',
  },
  category: {
    userSelect: 'none',
    flex: 11,
    fontWeight: 600,
  },
}));

export default function FilterItem({
  category,
  handleCategorySelect,
  selectedCategories,
}: {
  category: string;
  handleCategorySelect: (category) => void;
  selectedCategories: string[];
}) {
  const [itemSelected, setitemSelected] = useState(false);

  const classes = useStyles();

  return (
    <Paper
      elevation={selectedCategories.includes(category) ? 1 : 4}
      onClick={() => {
        handleCategorySelect(category);
        setitemSelected(!itemSelected);
      }}
      className={classes.root}
      style={{
        backgroundColor: selectedCategories.includes(category)
          ? objectList[category].clusterColor
          : theme.palette.background.default,
      }}
    >
      <Container style={{ flex: 1, padding: 0, paddingLeft: 5 }}>
        <FontAwesomeIcon
          color={
            selectedCategories.includes(category)
              ? objectList[category].markerColor
              : theme.palette.error.main
          }
          icon={objectList[category].markerIcon}
        />
      </Container>
      <Typography
        align="center"
        style={{
          color: selectedCategories.includes(category) ? 'white' : 'black',
        }}
        className={classes.category}
        variant="subtitle2"
      >
        {category}
      </Typography>
    </Paper>
  );
}
