import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import withStyles from '@mui/styles/withStyles';

import { Map as LeafletMap, TileLayer, GeoJSON } from 'react-leaflet';
import * as L from 'leaflet';
import EnvironmentCardMapMarker from 'components/opsDash/mainView/environmentCard/EnvironmentCardMapMarker';
import EnvironmentCardMapMarkerPopup from 'components/opsDash/mainView/environmentCard//EnvironmentCardMapMarkerPopup';
import { YmMapContext } from 'utils/context/YmMap';

import { YmPoint } from 'services/ymApi';

// Overriding leaflet's CSS
const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.leaflet-container': {
      height: '100%',
      width: '100%',
    },
    '.leaflet-control-layers:hover': {
      'text-align': 'left',
      'box-shadow': '0 1px 5px rgba(0, 0, 0, 0.4)',
      'border-radius': '5px',
    },
    '.leaflet-control-attribution.leaflet-control': {
      'margin-right': '30px',
    },
  },
})(() => null);

function EnvironmentCardMap({ markers }: { markers?: YmPoint[] }) {
  const { setYmMapState } = useContext(YmMapContext);
  const history = useHistory();

  const filteredMarkers =
    markers &&
    markers.filter((marker) => {
      return marker.status === 'warning' || marker.status === 'alarm';
    });
  return (
    <React.Fragment>
      <GlobalCss />
      <LeafletMap
        style={{
          backgroundColor: '#f1f1f1',
          borderRadius: 15,
          zIndex: 0,
        }}
        ref={(ref) => {
          ref &&
            filteredMarkers &&
            filteredMarkers.length > 0 &&
            ref.leafletElement.fitBounds(
              filteredMarkers.map((marker) => {
                return [
                  marker.centerPoint.latitude,
                  marker.centerPoint.longitude,
                ];
              })
            );
        }}
        key="environmentcardmap"
        zoom={7}
        center={[58.3, 8]}
        maxZoom={20}
        minZoom={7}
        attributionControl={true}
        zoomControl={false}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart_graatone&zoom={z}&x={x}&y={y}"
          attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
        />
        {filteredMarkers &&
          filteredMarkers.map((marker) => {
            return (
              <GeoJSON
                key={marker.id}
                data={marker.geojson as GeoJSON.Feature}
                color="white"
                onclick={() => {
                  setYmMapState((prevState) => {
                    return {
                      ...prevState,
                      drawerOpen: true,
                      selectedYmPointId: marker.id,
                      centerPoint: new L.LatLng(
                        Number(marker.centerPoint.latitude) - 0.0002,
                        marker.centerPoint.longitude
                      ),
                      sideMenuOpen: false,
                      zoomLevel: 19,
                    };
                  });
                  history.push('/dashboard/ym-map');
                }}
                pointToLayer={(_, latlng) => {
                  const icon = L.marker(latlng, {
                    title: marker.comments,
                    icon: new L.DivIcon({
                      html: ReactDOMServer.renderToString(
                        <EnvironmentCardMapMarker ymPoint={marker} />
                      ),
                      className: 'custom-icon',
                    }),
                  });
                  return icon;
                }}
              >
                <EnvironmentCardMapMarkerPopup
                  title={marker.measurementPointId}
                  subtitle={marker.place ? marker.place : ''}
                />
              </GeoJSON>
            );
          })}
      </LeafletMap>
    </React.Fragment>
  );
}

export default EnvironmentCardMap;
