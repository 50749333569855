/* tslint:disable */
/* eslint-disable */
/**
 * Ameta nvdb API
 * A rest api for managing the ameta nvdb system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NvdbObjectCenterPoint,
    NvdbObjectCenterPointFromJSON,
    NvdbObjectCenterPointFromJSONTyped,
    NvdbObjectCenterPointToJSON,
    NvdbObjectGeojson,
    NvdbObjectGeojsonFromJSON,
    NvdbObjectGeojsonFromJSONTyped,
    NvdbObjectGeojsonToJSON,
    NvdbObjectNvdbObjectType,
    NvdbObjectNvdbObjectTypeFromJSON,
    NvdbObjectNvdbObjectTypeFromJSONTyped,
    NvdbObjectNvdbObjectTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface NvdbObject
 */
export interface NvdbObject {
    /**
     * 
     * @type {string}
     * @memberof NvdbObject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NvdbObject
     */
    sort: string;
    /**
     * 
     * @type {NvdbObjectGeojson}
     * @memberof NvdbObject
     */
    geojson: NvdbObjectGeojson;
    /**
     * 
     * @type {string}
     * @memberof NvdbObject
     */
    geohash: string;
    /**
     * 
     * @type {string}
     * @memberof NvdbObject
     */
    geotype: string;
    /**
     * 
     * @type {NvdbObjectCenterPoint}
     * @memberof NvdbObject
     */
    centerPoint: NvdbObjectCenterPoint;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof NvdbObject
     */
    properties: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {NvdbObjectNvdbObjectType}
     * @memberof NvdbObject
     */
    nvdbObjectType: NvdbObjectNvdbObjectType;
}

export function NvdbObjectFromJSON(json: any): NvdbObject {
    return NvdbObjectFromJSONTyped(json, false);
}

export function NvdbObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NvdbObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'geojson': NvdbObjectGeojsonFromJSON(json['geojson']),
        'geohash': json['geohash'],
        'geotype': json['geotype'],
        'centerPoint': NvdbObjectCenterPointFromJSON(json['centerPoint']),
        'properties': json['properties'],
        'nvdbObjectType': NvdbObjectNvdbObjectTypeFromJSON(json['nvdbObjectType']),
    };
}

export function NvdbObjectToJSON(value?: NvdbObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'geojson': NvdbObjectGeojsonToJSON(value.geojson),
        'geohash': value.geohash,
        'geotype': value.geotype,
        'centerPoint': NvdbObjectCenterPointToJSON(value.centerPoint),
        'properties': value.properties,
        'nvdbObjectType': NvdbObjectNvdbObjectTypeToJSON(value.nvdbObjectType),
    };
}


