import React, { useContext } from 'react';
import { GeoJSON } from 'react-leaflet';
import * as L from 'leaflet';
import { YmPoint } from 'services/ymApi/models';

import { YmMapContext } from 'utils/context/YmMap';

function YmMarkers({ ymData }: { ymData: YmPoint[] }) {
  const { setYmMapState } = useContext(YmMapContext);

  return (
    <React.Fragment>
      {Object.values(ymData).map((point) => {
        const markersData = point.geojson;
        const GeoJson: GeoJSON.Feature =
          markersData as unknown as GeoJSON.Feature;
        return (
          <GeoJSON
            key={point.id}
            data={GeoJson}
            color="white"
            onclick={() => {
              setYmMapState((prevState) => {
                return {
                  ...prevState,
                  drawerOpen: true,
                  sideMenuOpen: false,
                  selectedYmPointId: point.id,
                };
              });
            }}
            pointToLayer={(_, latlng) => {
              const marker = L.marker(latlng, {
                title: point.comments,
                icon: L.AwesomeMarkers.icon({
                  icon:
                    point.status === 'ok'
                      ? 'check'
                      : point.status === 'warning'
                      ? 'sort-up'
                      : point.status === 'alarm'
                      ? 'exclamation'
                      : 'question-circle',
                  markerColor:
                    point.status === 'ok'
                      ? 'green'
                      : point.status === 'warning'
                      ? 'orange'
                      : point.status === 'alarm'
                      ? 'red'
                      : 'cadetblue',
                  prefix: 'fa',
                }),
              });
              return marker;
            }}
          ></GeoJSON>
        );
      })}
    </React.Fragment>
  );
}

export default YmMarkers;
