/* tslint:disable */
/* eslint-disable */
/**
 * Ameta nvdb API
 * A rest api for managing the ameta nvdb system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NVDBObjectsResponse,
    NVDBObjectsResponseFromJSON,
    NVDBObjectsResponseToJSON,
} from '../models';

export interface GetNvdbObjectsRequest {
    hashKey: string;
    lastEvaluatedKey?: string;
}

/**
 * 
 */
export class NvdbApi extends runtime.BaseAPI {

    /**
     * get nvdb objects on coordinates
     */
    async getNvdbObjectsRaw(requestParameters: GetNvdbObjectsRequest): Promise<runtime.ApiResponse<NVDBObjectsResponse>> {
        if (requestParameters.hashKey === null || requestParameters.hashKey === undefined) {
            throw new runtime.RequiredError('hashKey','Required parameter requestParameters.hashKey was null or undefined when calling getNvdbObjects.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lastEvaluatedKey !== undefined) {
            queryParameters['lastEvaluatedKey'] = requestParameters.lastEvaluatedKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/objects/{hashKey}`.replace(`{${"hashKey"}}`, encodeURIComponent(String(requestParameters.hashKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NVDBObjectsResponseFromJSON(jsonValue));
    }

    /**
     * get nvdb objects on coordinates
     */
    async getNvdbObjects(requestParameters: GetNvdbObjectsRequest): Promise<NVDBObjectsResponse> {
        const response = await this.getNvdbObjectsRaw(requestParameters);
        return await response.value();
    }

}
