import React, { useState } from 'react';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { SectionCounter } from 'services/availabilityApi/models';

import CommonTable from 'components/common/CommonTable';
import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';

import theme from 'config/theme';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      border: 'solid',
      borderColor: 'white',
      backgroundColor: theme.colors.base.base1,
      borderWidth: 5,
    },
    paperHeader: {
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      flex: 4.4,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      backgroundColor: theme.colors.base.background,
    },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
  })
);

function SectionOverview({
  sectionCounterData,
}: {
  sectionCounterData: SectionCounter | undefined;
}) {
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, 'M')
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, 'yyyy')
  );

  const sectionsWithDeduction =
    sectionCounterData?.data?.deducted?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const sectionsWithoutDeduction =
    sectionCounterData?.data?.nonDeducted?.[selectedYear]?.months?.[
      selectedMonth
    ]?.sections;
  const sectionsTotal =
    sectionCounterData?.data?.totalTime?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const tableData: {
    sectionNr: string;
    totalMinutesWithDeduction?: string;
    totalMinutesWithoutDeduction?: string;
    totalMinutes?: string;
  }[] = [
    { sectionNr: '1' },
    { sectionNr: '2' },
    { sectionNr: '3' },
    { sectionNr: '4' },
    { sectionNr: '5' },
    { sectionNr: '6' },
  ];
  sectionsTotal &&
    Object.entries(sectionsTotal).forEach((element) => {
      tableData.forEach((tableElement, tableIndex) => {
        if (tableElement.sectionNr === element[0]) {
          tableData.splice(tableIndex, 1);
        }
      });

      tableData.push({
        sectionNr: element[0],
        totalMinutesWithDeduction:
          sectionsWithDeduction?.[element[0]].totalMinutes,
        totalMinutesWithoutDeduction:
          sectionsWithoutDeduction?.[element[0]].totalMinutes,
        totalMinutes: element[1].totalMinutes,
      });
    });

  tableData.sort((a, b) => {
    return Number(a.sectionNr) - Number(b.sectionNr);
  });

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderTop}>
          <CommonText textVariant="20 medium" colorOverride="white">
            Seksjonsoversikt
          </CommonText>
          <div className={classes.pickerContainer}>
            <CommonText
              textVariant="14 medium"
              colorOverride="white"
              className={classes.pickerLabel}
            >
              År:
            </CommonText>
            <OptionsPicker
              className={classes.picker}
              reverse
              optionsList={yearsListDropdown()}
              initialvalue={selectedYear}
              parentCallback={(value) => {
                setSelectedYear(value);
              }}
            />
          </div>
          <div className={classes.pickerContainer}>
            <CommonText
              textVariant="14 medium"
              colorOverride="white"
              className={classes.pickerLabel}
            >
              Periode:
            </CommonText>
            <OptionsPicker
              className={classes.picker}
              reverse
              optionsList={{
                '1': 'Januar',
                '2': 'Februar',
                '3': 'Mars',
                '4': 'April',
                '5': 'Mai',
                '6': 'Juni',
                '7': 'Juli',
                '8': 'August',
                '9': 'September',
                '10': 'Oktober',
                '11': 'November',
                '12': 'Desember',
              }}
              parentCallback={(value) => {
                setSelectedMonth(value);
              }}
              initialvalue={selectedMonth}
            />
          </div>
        </div>

        <Divider />
      </div>
      <div className={classes.paperBody}>
        <CommonTable
          alignCenter
          headers={[
            { id: 'sectionNr', label: 'Seksjon' },
            {
              id: 'totalMinutesWithDeduction',
              label: 'Stengeminutter med trekk',
            },
            {
              id: 'totalMinutesWithoutDeduction',
              label: 'Stengeminutter uten trekk',
            },
            { id: 'totalMinutes', label: 'Totalt antall stengeminutter' },
          ]}
          data={tableData as { [key: string]: string | number }[]}
        />
      </div>
    </Paper>
  );
}

export default SectionOverview;
