import React from 'react';

import { GeoJSON } from 'react-leaflet';

import * as L from 'leaflet';

import 'leaflet.awesome-markers';
import 'assets/css/leaflet.awesome-markers/leaflet.awesome-markers.css';

export default function LocationMarker({
  location,
}: {
  location: GeoJSON.Feature<GeoJSON.Point> | undefined;
}) {
  return location && location.geometry.coordinates.length > 0 ? (
    <GeoJSON
      key={Math.random()}
      data={location}
      color="white"
      pointToLayer={(feature, latlng) => {
        const marker = L.marker(latlng, {
          icon: L.AwesomeMarkers.icon({
            icon: 'dot-circle',
            markerColor: 'cadetblue',
            prefix: 'fa',
          }),
        });
        return marker;
      }}
    ></GeoJSON>
  ) : null;
}
