import React from 'react';

import TopView from 'components/opsDash/TopView';
import ObjectMap from 'components/opsDash/nvdb/ObjectMap';

export default function NvdbMap() {
  return (
    <React.Fragment>
      <TopView title="NVDB" subtitle="Kartvisning" />
      <ObjectMap />
    </React.Fragment>
  );
}
