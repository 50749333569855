import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TopView from 'components/common/TopView';

import ProjectOwnerCard from 'components/deliveryDash/project/ownerCard/ProjectOwnerCard';
import ProjectDescriptionCard from 'components/deliveryDash/project/descriptionCard/ProjectDescriptionCard';
import ProjectDocumentsCard from 'components/deliveryDash/project/documentsCard/ProjectDocumentsCard';
import ProjectLocationCard from 'components/deliveryDash/project/locationCard/ProjectLocationCard';
import ProjectPointCloudsCard from 'components/deliveryDash/project/pointcloudsCard/ProjectPointcloudsCard';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    primaryLayout: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: 60,
      paddingTop: 0,
    },
    secondaryLeftLayout: {
      height: '100%',
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
    },
    secondaryRightLayout: {
      height: '100%',
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
    },
    ownerCard: { padding: 20, width: '100%', flex: 1 },
    descriptionCard: { padding: 20, width: '100%', flex: 1 },
    locationCard: { padding: 20, width: '100%', flex: 1 },
    documentsCard: {
      padding: 20,
      width: '100%',
      flex: 9,
    },
    pointcloudsCard: {
      padding: 20,
      width: '100%',
      flex: 4,
    },
  })
);

export default function NewProject() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopView leftSideComponents={null} />
      <div className={classes.primaryLayout}>
        <div className={classes.secondaryLeftLayout}>
          <div className={classes.ownerCard}>
            <ProjectOwnerCard newProject project={undefined} />
          </div>
          <div className={classes.descriptionCard}>
            <ProjectDescriptionCard disabled project={undefined} />
          </div>
          <div className={classes.locationCard}>
            <ProjectLocationCard disabled project={undefined} />
          </div>
        </div>
        <div className={classes.secondaryRightLayout}>
          <div className={classes.documentsCard}>
            <ProjectDocumentsCard projectId="" disabled editable={false} />
          </div>
          <div className={classes.pointcloudsCard}>
            <ProjectPointCloudsCard projectId="" disabled />
          </div>
        </div>
      </div>
    </div>
  );
}
