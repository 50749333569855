import React from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CommonText from 'components/common/textOutput/CommonText';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 30,
    },
    rowElement: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    leftComponent: {
      flex: 1,
      paddingRight: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightComponent: {
      flex: 1,
      paddingleft: 5,
      paddingRight: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    legendCircle: {
      height: 15,
      width: 15,
      'border-style': 'solid',
      border: 1,
      borderRadius: '50%',
      paddigLeft: 5,
      paddingRight: 5,
    },
  })
);

function ChartLegends({
  legends,
}: {
  legends: { name: string; color?: string }[];
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {legends.map((legend, index) => {
        return (
          <div key={index} className={classes.rowElement}>
            <div className={classes.leftComponent}>
              <CommonText noWrap textVariant="12 light">
                {legend.name}
              </CommonText>
            </div>
            <div className={classes.rightComponent}>
              <div
                className={classes.legendCircle}
                style={{
                  backgroundColor: legend.color,
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ChartLegends;
