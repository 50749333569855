import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Paper from '@mui/material/Paper';

import TextField from 'components/common/textInputs/TextField';

import { Box } from '@mui/system';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'width 350ms ease-out',
      borderRadius: 25,
      height: '75%',
    },
    menuButton: { padding: 10 },
    menuList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 25,
    },
  })
);

export default function SearchMenu({
  open,
  onChange,
  setValue,
  value,
}: {
  open: boolean;
  onChange:
    | ((
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void)
    | undefined;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}) {
  const classes = useStyles();

  return (
    <Box sx={{ width: '50%', pr: 3 }}>
      <Paper
        className={classes.root}
        sx={{
          width: open ? '100%' : 0,
          p: open ? 1.8 : 0,
          justifyContent: open ? 'flex-start' : 'center',
        }}
      >
        {open && (
          <Box className={classes.menuList}>
            <TextField
              editable
              placeholder="Søk etter dokument"
              keepActive
              value={value}
              onChange={(e) => {
                onChange && onChange(e);
                setValue(e.target.value);
              }}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
