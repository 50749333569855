import React, { createContext, useState } from 'react';

type DashboardState = {
  currentDashboard: string | undefined;
  setCurrentDashboard: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const DashboardContext = createContext<DashboardState>({
  currentDashboard: undefined,
  setCurrentDashboard: () => {
    // do nothing
  },
});

const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentDashboard, setCurrentDashboard] = useState<string | undefined>(
    undefined
  );

  return (
    <DashboardContext.Provider
      value={{ currentDashboard, setCurrentDashboard }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };
