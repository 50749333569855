import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import * as materialColors from '@mui/material/colors';
import { CompanyProjectRelation } from 'services/deliveryApi/models/CompanyProjectRelation';
import MarkerCluster from './MarkerCluster';

// Overriding leaflet's CSS
const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.leaflet-container': {
      height: '100%',
      width: '100%',
      minHeight: 800,
    },
    '.leaflet-control-layers:hover': {
      'text-align': 'left',
      'box-shadow': '0 1px 5px rgba(0, 0, 0, 0.4)',
      'border-radius': '5px',
    },
    '.leaflet-control-attribution.leaflet-control': {
      'margin-right': '30px',
    },
  },
})(() => null);

export default function Map({
  projects,
}: {
  projects: CompanyProjectRelation[] | undefined;
}) {
  return (
    <React.Fragment>
      <GlobalCss />
      <LeafletMap
        style={{
          backgroundColor: '#e1f4ff',
        }}
        key="mainmap"
        center={[59, 8.0]}
        zoom={8}
        maxZoom={20}
        attributionControl={true}
        zoomControl={false}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}"
          attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
        />
        <MarkerCluster
          projects={projects}
          clusterColor={materialColors.lightBlue[700]}
        />
      </LeafletMap>
    </React.Fragment>
  );
}
