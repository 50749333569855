/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NHReport,
    NHReportFromJSON,
    NHReportFromJSONTyped,
    NHReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface NhYearDataObject
 */
export interface NhYearDataObject {
    /**
     * 
     * @type {string}
     * @memberof NhYearDataObject
     */
    avgN: string;
    /**
     * 
     * @type {string}
     * @memberof NhYearDataObject
     */
    avgH: string;
    /**
     * 
     * @type {{ [key: string]: NHReport; }}
     * @memberof NhYearDataObject
     */
    months: { [key: string]: NHReport; };
}

export function NhYearDataObjectFromJSON(json: any): NhYearDataObject {
    return NhYearDataObjectFromJSONTyped(json, false);
}

export function NhYearDataObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NhYearDataObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avgN': json['avgN'],
        'avgH': json['avgH'],
        'months': (mapValues(json['months'], NHReportFromJSON)),
    };
}

export function NhYearDataObjectToJSON(value?: NhYearDataObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avgN': value.avgN,
        'avgH': value.avgH,
        'months': (mapValues(value.months, NHReportToJSON)),
    };
}


