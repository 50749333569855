import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';

import CommonTextField from 'components/common/textInputs/CommonTextField';

import isEmpty from 'utils/validation/isEmpty';
import isEmail from 'utils/validation/isEmail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import theme from 'config/theme';
library.add(faEye, faEyeSlash);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    formInput: { width: '100%' },
    completeButton: {
      backgroundImage: 'linear-gradient(180deg, #11E5B3 0%, #20CFAC 100%)',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: 'white',
      width: 100,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 15,
    },
    serverError: {
      padding: 20,
    },
    textButton: {
      textTransform: 'none',
    },
    textButtonClicked: {
      color: theme.colors.messageAndAlerts.success,
    },
    emailText: {
      paddingTop: 10,
      paddingBottom: 20,
    },
    informationText: {
      paddingBottom: 20,
    },
    buttonIcon: { marginRight: 10 },
  })
);

export default function ForgotPassword({
  onCancel,
  onSuccess,
}: {
  onCancel?: () => void;
  onSuccess?: () => void;
}) {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [emailRegistered, setEmailRegistered] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] =
    useState<string | null>(null);
  const [resendVerificationCode, setResendVerificationcode] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password1Error, setPassword1Error] = useState<string | null>(null);
  const [password2Error, setPassword2Error] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);

  const vaildateInputs = () => {
    if (isEmpty(verificationCode)) {
      setVerificationCodeError('Verifikasjonskode mangler');
    }
    if (isEmpty(password1)) {
      setPassword1Error('Passord mangler');
    }
    if (isEmpty(password2)) {
      setPassword2Error('Passord mangler');
    }
    if (!isEmpty(password1) && !isEmpty(password2) && password1 !== password2) {
      setPassword1Error('Passord ikke identisk');
      setPassword2Error('Passord ikke identisk');
    }
  };

  return (
    <div className={classes.root}>
      {emailRegistered ? (
        <React.Fragment>
          <CommonText
            textVariant="14 medium"
            className={classes.informationText}
          >
            En epost med verifiseringskode er sendt til følgende adresse:
          </CommonText>
          <CommonText textVariant="20 medium" className={classes.emailText}>
            {email}
          </CommonText>
          <CommonText
            textVariant="14 medium"
            className={classes.informationText}
          >
            Skriv inn verifiseringskoden og nytt passord under.
          </CommonText>
          <form className={classes.formInput} noValidate autoComplete="off">
            <CommonTextField
              id="resetverificationcode"
              label={
                verificationCodeError
                  ? 'Verifikasjonskode mangler'
                  : 'Verifikasjonskode'
              }
              type="tel"
              error={Boolean(verificationCodeError)}
              onFocus={() => {
                setVerificationCodeError(null);
              }}
              onChange={(event) => {
                setVerificationCode(event.target.value);
              }}
            />
            <div style={{ height: 26 }} />
            <CommonTextField
              id="resetpassword1"
              label={password1Error ? password1Error : 'Passord'}
              type={showPassword ? 'text' : 'password'}
              error={Boolean(password1Error)}
              onFocus={() => {
                setPassword1Error(null);
              }}
              onChange={(event) => {
                setPassword1(event.target.value);
              }}
            />
            <div style={{ height: 26 }} />
            <CommonTextField
              id="resetpassword2"
              label={password2Error ? password2Error : 'Gjenta passord'}
              type={showPassword ? 'text' : 'password'}
              error={Boolean(password2Error)}
              onFocus={() => {
                setPassword2Error(null);
              }}
              onChange={(event) => {
                setPassword2(event.target.value);
              }}
            />
            <div style={{ height: 10 }} />
            <div
              className={classes.buttonContainer}
              style={{ justifyContent: 'flex-end' }}
            >
              <Button
                variant="text"
                className={classes.textButton}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                <FontAwesomeIcon
                  className={classes.buttonIcon}
                  color={theme.colors.icons.noState.light}
                  icon={!showPassword ? faEye : faEyeSlash}
                />
                <CommonText textVariant="12 light">
                  {showPassword ? 'Skjul passord' : 'Vis passord'}
                </CommonText>
              </Button>
            </div>
            <div style={{ height: 10 }} />
            <div className={classes.buttonContainer}>
              <Fab
                variant="extended"
                className={classes.completeButton}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  setServerError(null);
                  vaildateInputs();
                  if (
                    !isEmpty(verificationCode) &&
                    !isEmpty(password1) &&
                    !isEmpty(password2) &&
                    password1 === password2
                  ) {
                    Auth.forgotPasswordSubmit(
                      email,
                      verificationCode,
                      password1
                    )
                      .then(() => {
                        setEmailRegistered(false);
                        onSuccess && onSuccess();
                      })
                      .catch((err) => {
                        switch (err.message) {
                          case 'Invalid verification code provided, please try again.':
                            setServerError(
                              'Feil verifiseringskode. Prøv igjen.'
                            );
                            break;
                          case 'An account with the given email already exists.':
                            setServerError(
                              'En bruker med denne epostadressen finnes allerede'
                            );
                            break;
                          case 'Password did not conform with policy: Password not long enough':
                            setServerError(
                              'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                            );
                            break;
                          case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
                            setServerError(
                              'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                            );
                            break;
                          case 'Password did not conform with policy: Password must have uppercase characters':
                            setServerError(
                              'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                            );
                            break;
                          case 'Password did not conform with policy: Password must have numeric characters':
                            setServerError(
                              'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                            );
                            break;

                          default:
                            setServerError(
                              'Ukjent systemfeil ved registrering'
                            );
                        }
                      });
                  }
                }}
              >
                <CommonText textVariant="20 medium">Fullfør</CommonText>
              </Fab>
              <Button
                className={clsx(classes.textButton, {
                  [classes.textButtonClicked]: resendVerificationCode,
                })}
                onClick={() => {
                  if (!resendVerificationCode) {
                    Auth.forgotPassword(email)
                      .then(() => {
                        setResendVerificationcode(true);
                      })
                      .catch((err) => {
                        switch (err.message) {
                          case 'Attempt limit exceeded, please try after some time.':
                            setServerError(
                              'Maksgrense er nådd. Venligst vent en stund og prøv igjen.'
                            );
                            break;
                          default:
                            setServerError(
                              'Kunne ikke sende verifikasjonskode'
                            );
                            break;
                        }
                      });
                  }
                }}
              >
                <CommonText
                  textVariant={
                    resendVerificationCode ? '14 medium' : '14 light'
                  }
                >
                  {resendVerificationCode
                    ? 'Ny verifikasjonskode er sendt'
                    : 'Send verifikasjonskode på nytt'}
                </CommonText>
              </Button>
              <Button
                onClick={() => {
                  onCancel && onCancel();
                }}
              >
                <CommonText textVariant="20 medium">Avbryt</CommonText>
              </Button>
            </div>
            <CommonText textVariant="12 light" className={classes.serverError}>
              {serverError}
            </CommonText>
          </form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CommonText
            className={classes.informationText}
            textVariant="14 medium"
          >
            Skriv in epostadressen din i feltet under.
          </CommonText>

          <form className={classes.formInput} noValidate autoComplete="off">
            <CommonTextField
              id="email"
              label={
                emailError ? 'Epost mangler eller ugylding' : 'Epostadresse'
              }
              type="email"
              error={Boolean(emailError)}
              onFocus={() => {
                setEmailError(null);
              }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <div style={{ height: 20 }} />
            <div className={classes.buttonContainer}>
              <Fab
                variant="extended"
                className={classes.completeButton}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  setServerError(null);
                  if (isEmpty(email) || !isEmail(email)) {
                    setEmailError('Epost mangler eller ugyldig');
                  }
                  if (isEmail(email)) {
                    Auth.forgotPassword(email)
                      .then(() => {
                        setEmailRegistered(true);
                      })
                      .catch((err) => {
                        switch (err.message) {
                          case 'Attempt limit exceeded, please try after some time.':
                            setServerError(
                              'Maksgrense er nådd. Venligst vent en stund og prøv igjen.'
                            );
                            break;
                          default:
                            setServerError(
                              'Kunne ikke sende verifikasjonskode'
                            );
                            break;
                        }
                      });
                  }
                }}
              >
                <CommonText style={{ color: 'white' }} textVariant="20 medium">
                  Neste
                </CommonText>
              </Fab>
              <Button
                onClick={() => {
                  onCancel && onCancel();
                }}
              >
                <CommonText textVariant="20 medium">Avbryt</CommonText>
              </Button>
            </div>
            <CommonText colorOverride="error" textVariant="12 light">
              {serverError}
            </CommonText>
          </form>
        </React.Fragment>
      )}
    </div>
  );
}
