import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

import CommonText from 'components/common/textOutput/CommonText';
import theme from 'config/theme';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.base.white,
    borderRadius: 15,
    padding: 15,
    margin: 0,
    boxShadow: '0px 4px 10px rgba(212, 220, 224, 0.78)',
  },
}))(Tooltip);

function CommonTooltip({
  children,
  message,
  placement,
}: {
  children: React.ReactElement;
  message: string;
  placement: 'left' | 'right' | 'top' | 'bottom';
}) {
  return (
    <LightTooltip
      placement={placement}
      title={<CommonText textVariant="12 light">{message}</CommonText>}
    >
      <div>{children}</div>
    </LightTooltip>
  );
}

export default CommonTooltip;
