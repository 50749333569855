import React, { useState, useEffect, useContext } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DesktopNavBar, {
  MainMenuItem,
} from 'components/common/navBar/DesktopNavBar';
import isUserLoggedIn from 'utils/isUserLoggedIn';
import YmMap from 'components/opsDash/pages/YmMap';
import TrafficView from 'components/opsDash/pages/TrafficView';
import MainDash from 'components/opsDash/pages/MainDash';
import NvdbMap from 'components/opsDash/pages/NvdbMap';
import TestPage from 'components/opsDash/pages/TestPage';

import Availability from 'components/opsDash/pages/Availability';

import Security from 'components/opsDash/pages/Security';

import HMS from 'components/opsDash/pages/HMS';

import { YmMapProvider } from 'utils/context/YmMap';
import { GeneralContext } from 'utils/context/General';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    mainContent: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.base.background,
    },
  })
);

const MainMenuItems: MainMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'circle',
    path: '/dashboard',
  },
  {
    title: 'Tilgjengelighet',
    icon: 'circle',
    path: '/dashboard/availability',
  },
  {
    title: 'Sikkerhet',
    icon: 'circle',
    path: '/dashboard/security',
  },
  {
    title: 'HMS',
    icon: 'circle',
    path: '/dashboard/hms',
  },
  {
    title: 'Miljø',
    icon: 'circle',
    path: '/dashboard/ym-map',
  },
  {
    title: 'Trafikkvisning',
    icon: 'circle',
    path: '/dashboard/traffic',
  },
  // {
  //   title: 'NVDB',
  //   icon: 'circle',
  //   path: '/dashboard/object-map',
  // },
];

export default function OpsDashboard() {
  const classes = useStyles();
  const history = useHistory();

  const userStatus = isUserLoggedIn();

  const { search } = useLocation();
  const values = queryString.parse(search);
  const { setGeneralState } = useContext(GeneralContext);
  const [videoWall, setVideoWall] = useState(false);

  useEffect(() => {
    if (userStatus === 'nouser') {
      history.push('/');
    }
  });

  if (values && values.videowall && !videoWall) {
    setGeneralState((prevState) => {
      return { ...prevState, hideMenus: true };
    });
    setVideoWall(true);
  }

  return (
    <YmMapProvider>
      <div className={classes.mainContent}>
        <DesktopNavBar hideableMenu MainMenuItems={MainMenuItems} />
        <div className={classes.content}>
          <Route path="/dashboard" exact>
            <MainDash />
          </Route>
          <Route path="/dashboard/traffic">
            <TrafficView />
          </Route>
          <Route path="/dashboard/ym-map">
            <YmMap />
          </Route>
          {process.env.NODE_ENV === 'development' ||
            (process.env.BUILD_ENVIRONMENT === 'development' && (
              <Route path="/dashboard/object-map">
                <NvdbMap />
              </Route>
            ))}
          <Route path="/dashboard/Availability">
            <Availability />
          </Route>
          <Route path="/dashboard/security">
            <Security />
          </Route>
          <Route path="/dashboard/hms">
            <HMS />
          </Route>
          {process.env.NODE_ENV === 'development' ||
            (process.env.BUILD_ENVIRONMENT === 'development' && (
              <Route path="/dashboard/test">
                <TestPage />
              </Route>
            ))}
        </div>
      </div>
    </YmMapProvider>
  );
}
