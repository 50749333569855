import React from 'react';

import InitialLoadingContent from 'components/deliveryDash/project/pointcloudsCard/InitialLoadingContent';
import { ProjectPointcloudRelation } from 'services/deliveryApi/models/ProjectPointcloudRelation';
import PointcloudTable from 'components/deliveryDash/project/pointcloudsCard/PointcloudTable';

function CardContent({
  pointclouds,
}: {
  pointclouds: ProjectPointcloudRelation[] | undefined;
}) {
  return !pointclouds ? (
    //When the project has no documents and no files currently uploading
    <InitialLoadingContent loading={false} />
  ) : (
    //When the project already has documents
    <PointcloudTable pointclouds={pointclouds} />
  );
}

export default CardContent;
