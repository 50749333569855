import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';

import CommonTextField from 'components/common/textInputs/CommonTextField';
import isEmail from 'utils/validation/isEmail';
import isEmpty from 'utils/validation/isEmpty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import theme from 'config/theme';

library.add(faEye, faEyeSlash);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    labelText: {
      paddingBottom: 30,
    },
    formInput: { width: '100%' },
    button: {
      backgroundImage:
        'linear-gradient(90.34deg, #11E5B3 15.78%, #20CFAC 87.04%);',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: 'white',
      width: '35%',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 15,
    },
    focusVisble: {
      color: 'rgba(113, 146, 176, 0.22)',
    },
    serverError: {
      padding: 20,
    },
    buttonIcon: { marginRight: 10 },
  })
);

export default function CognitoSignIn({
  onSuccess,
  onForgotPassword,
}: {
  onSuccess?: () => void;
  onForgotPassword?: () => void;
}) {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [serverError, setServerError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classes.root}>
      <CommonText textVariant="12 light" className={classes.labelText}>
        Logg inn med epostadresse.
      </CommonText>
      <form className={classes.formInput} noValidate autoComplete="off">
        <CommonTextField
          id="signinemail"
          label={emailError ? 'Epost mangler eller ugyldig' : 'Epostadresse'}
          type="email"
          error={emailError}
          onFocus={() => {
            setEmailError(false);
          }}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <div style={{ height: 26 }} />
        <CommonTextField
          id="signinpassword"
          label={passwordError ? 'Passord mangler' : 'Passord'}
          type={showPassword ? 'text' : 'password'}
          error={passwordError}
          onFocus={() => {
            setPasswordError(false);
          }}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <div style={{ height: 10 }} />
        <div
          className={classes.buttonContainer}
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="text"
            TouchRippleProps={{
              classes: { ripple: classes.focusVisble },
            }}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <FontAwesomeIcon
              className={classes.buttonIcon}
              color={theme.colors.icons.noState.light}
              icon={!showPassword ? faEye : faEyeSlash}
            />
            <CommonText textVariant="12 light">
              {showPassword ? 'Skjul passord' : 'Vis passord'}
            </CommonText>
          </Button>
        </div>
        <div style={{ height: 10 }} />
        <div className={classes.buttonContainer}>
          <Fab
            variant="extended"
            className={classes.button}
            type="submit"
            TouchRippleProps={{
              classes: { ripple: classes.focusVisble },
            }}
            onClick={(event) => {
              event.preventDefault();
              setServerError('');
              setEmailError(isEmpty(email) || !isEmail(email));
              setPasswordError(isEmpty(password));

              if (!isEmpty(email) && isEmail(email) && !isEmpty(password)) {
                Auth.signIn({ username: email, password })
                  .then(() => {
                    onSuccess && onSuccess();
                  })
                  .catch((err) => {
                    switch (err.message) {
                      case 'Incorrect username or password.':
                        setServerError('Feil epostadresse eller passord');
                        break;
                      case 'User is not confirmed.':
                        history.push(`/sign-up?email=${email}&verify=true`);
                        break;
                      default:
                        setServerError('Feil ved innlogging');
                    }
                  });
              }
            }}
          >
            <CommonText style={{ color: 'white' }} textVariant="20 medium">
              Logg inn
            </CommonText>
          </Fab>
          <Button
            onClick={() => {
              onForgotPassword && onForgotPassword();
            }}
            TouchRippleProps={{
              classes: { ripple: classes.focusVisble },
            }}
          >
            <CommonText textVariant="12 light">Glemt passord?</CommonText>
          </Button>
        </div>
        <CommonText
          colorOverride="error"
          textVariant="12 light"
          className={classes.serverError}
        >
          {serverError}
        </CommonText>
      </form>
    </div>
  );
}
