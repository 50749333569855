import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { YmMapContext } from 'utils/context/YmMap';

import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';

import YmPointDialog from 'components/opsDash/ym/dialogs/YmPointDialog';
import DeleteYmPointDialog from 'components/opsDash/ym/dialogs/DeleteYmPointDialog';

import CreateYmMeasurementDialog from 'components/opsDash/ym/dialogs/CreateYmMeasurmentDialog';
import MeasurementCard from 'components/opsDash/ym/bottomDrawer/MeasurementCard';
import HistoricalDataDialog from 'components/opsDash/ym/dialogs/HistoricalDataDialog';

import { useYmPointQuery } from 'services/ymQueryHooks';

import theme from 'config/theme';
import YmHistoricalListDialog from '../dialogs/YmHistoricalListDialog';
import YmHistoricalListDialogAll from '../dialogs/YmHistoricalListDialogAll';

import { useYmPointQueries } from 'services/ymQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.colors.base.white,
    },
    drawerContent: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    leftContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    menuList: {
      padding: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },

    pointInfoList: {
      padding: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    pointInfo: {
      padding: 5,
    },
    rightContent: {
      flex: 8,
      width: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
      backgroundColor: theme.colors.base.background,
      boxShadow: 'inset 0px 3px 10px rgba(209, 214, 228, 0.88)',
      borderRadius: 8,
      display: 'flex',
      flexWrap: 'wrap',
    },
  })
);
export default function BottomDrawer({
  ymPointQueries,
}: {
  ymPointQueries: ReturnType<typeof useYmPointQueries>;
}) {
  const classes = useStyles();

  const { ymMapState, setYmMapState } = useContext(YmMapContext);
  const [deleteDialogOpen, setDeleteDialigOpen] = useState(false);
  const [openYmPointDialog, setOpenYmPointDialog] = useState(false);
  const [openCreateYmMeasurementDialog, setOpenCreateYmMeasurementDialog] =
    useState(false);

  const [historicalDataDialogOpen, setHistoricalDataDialogOpen] =
    useState(false);

  const [historicalListDialogOpen, setHistoricalListDialogOpen] =
    useState(false);
  const [historicalListDialogAllOpen, setHistoricalListDialogAllOpen] =
    useState(false);
  const [historicaDataMeasurementType, setHistoricaDataMeasurementType] =
    useState('');

  const ymPointQuery = useYmPointQuery(ymMapState.selectedYmPointId);

  const ymPoint = ymPointQuery?.data;
  const ymPointData = ymPoint?.data;

  return (
    <div
      className={classes.root}
      style={{
        transition: 'height 250ms ease',
        height: ymMapState.drawerOpen ? '50%' : '0%',
      }}
    >
      {ymMapState.drawerOpen && (
        <div
          className={classes.drawerContent}
          style={{
            transition: 'opacity 250ms ease-out',
            opacity: ymMapState.drawerOpen ? '1' : '0',
            paddingTop: 10,
            paddingRight: 10,
            paddingBottom: 10,
          }}
        >
          <div className={classes.leftContent}>
            <div className={classes.menuList}>
              <CommonFab
                noShadow
                tooltip={{ placement: 'bottom', text: 'Rediger punkt' }}
                icon="edit"
                onClick={() => {
                  setOpenYmPointDialog(true);
                }}
              />
              <CommonFab
                noShadow
                tooltip={{ placement: 'bottom', text: 'Legg til ny måledata' }}
                icon="plus-circle"
                onClick={() => {
                  setOpenCreateYmMeasurementDialog(true);
                }}
              />
              <CommonFab
                noShadow
                tooltip={{ placement: 'bottom', text: 'Slett punkt' }}
                icon="trash-2"
                onClick={() => {
                  setDeleteDialigOpen(true);
                }}
              />
              <CommonFab
                noShadow
                tooltip={{
                  placement: 'bottom',
                  text: 'Vis all historikk i listeform',
                }}
                icon="list"
                onClick={() => {
                  setHistoricalListDialogAllOpen(true);
                }}
              />
            </div>

            <div className={classes.pointInfoList}>
              <div className={classes.pointInfo}>
                <CommonText textVariant="14 medium">Målepunkt Id:</CommonText>
                <CommonText textVariant="14 light">
                  {ymPoint && ymPoint.measurementPointId}
                </CommonText>
              </div>
              <div className={classes.pointInfo}>
                <CommonText textVariant="14 medium">Vannkilde Id:</CommonText>
                <CommonText textVariant="14 light">
                  {ymPoint && ymPoint.waterSourceId}
                </CommonText>
              </div>
              <div className={classes.pointInfo}>
                <CommonText textVariant="14 medium">Sted:</CommonText>
                <CommonText textVariant="14 light">
                  {ymPoint && ymPoint.place}
                </CommonText>
              </div>
              <div className={classes.pointInfo}>
                <CommonText textVariant="14 medium">Kommentar:</CommonText>
                <CommonText textVariant="14 light">
                  {ymPoint && ymPoint.comments}
                </CommonText>
              </div>
              <div className={classes.pointInfo}>
                <CommonText textVariant="14 medium">Status:</CommonText>
                {ymPoint && (
                  <CommonText
                    colorOverride={
                      ymPoint.status === 'alarm'
                        ? 'error'
                        : ymPoint.status === 'warning'
                        ? 'warning'
                        : ymPoint.status === 'unknown'
                        ? 'unknown'
                        : 'success'
                    }
                    textVariant="14 medium"
                  >
                    {ymPoint.status === 'alarm'
                      ? 'Alarmerende'
                      : ymPoint.status === 'warning'
                      ? 'Varslende'
                      : ymPoint.status === 'unknown'
                      ? 'Ukjent'
                      : 'God'}
                  </CommonText>
                )}
              </div>
            </div>
          </div>
          <div className={classes.rightContent}>
            {ymPointData &&
              Object.entries(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ymPointData.latestMeasurements as any
              ).map((mapEntry, index) => {
                return (
                  <div
                    key={index}
                    style={{ height: '50%', width: 'calc(100% / 8.5)' }}
                  >
                    <MeasurementCard
                      measurementType={mapEntry[0]}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      measurementValue={(mapEntry[1] as any).value}
                      measurementThreshold={
                        ymPoint?.thresholdValues &&
                        Number(ymPoint.thresholdValues[mapEntry[0]])
                      }
                      onHistoryButtonClicked={(
                        selectedMeasurementType: string
                      ) => {
                        setHistoricaDataMeasurementType(
                          selectedMeasurementType
                        );
                        setHistoricalDataDialogOpen(true);
                      }}
                      onClickEdit={(selectedMeasurementType: string) => {
                        setHistoricaDataMeasurementType(
                          selectedMeasurementType
                        );
                        setHistoricalListDialogOpen(true);
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      date={(mapEntry[1] as any).date}
                    />
                  </div>
                );
              })}
          </div>
          {ymPoint && (
            <CreateYmMeasurementDialog
              ymPointId={ymPoint.id}
              ymPointMeasurementId={ymPoint.measurementPointId}
              isOpened={openCreateYmMeasurementDialog}
              onClose={() => {
                setOpenCreateYmMeasurementDialog(false);
                setTimeout(() => {
                  ymPointQueries.refetch();
                  ymPointQuery.refetch();
                }, 2000);
              }}
            />
          )}
          {ymPoint && (
            <YmPointDialog
              ymPoint={ymPoint}
              isOpened={openYmPointDialog}
              onClose={() => {
                setOpenYmPointDialog(false);
                setTimeout(() => {
                  ymPointQueries.refetch();
                  ymPointQuery.refetch();
                }, 2000);
              }}
              onSave={() => {
                setOpenYmPointDialog(false);
              }}
            />
          )}
          {ymPoint && (
            <DeleteYmPointDialog
              ymPointToDelete={ymPoint}
              isOpened={deleteDialogOpen}
              onClose={() => {
                setDeleteDialigOpen(false);
                setYmMapState((prevState) => {
                  return {
                    ...prevState,
                    drawerOpen: false,
                    sideMenuOpen: false,
                  };
                });
                setTimeout(() => {
                  ymPointQueries.refetch();
                  ymPointQuery.refetch();
                }, 2000);
              }}
            />
          )}
          {ymPoint && (
            <HistoricalDataDialog
              open={historicalDataDialogOpen}
              onClose={() => {
                setHistoricalDataDialogOpen(false);
              }}
              measurementType={historicaDataMeasurementType}
              ymPoint={ymPoint}
            />
          )}
          {ymPoint && (
            <YmHistoricalListDialog
              open={historicalListDialogOpen}
              refetch={() => {
                ymPointQueries.refetch();
                ymPointQuery.refetch();
              }}
              onClose={() => {
                setHistoricalListDialogOpen(false);
                ymPointQueries.refetch();
                ymPointQuery.refetch();
              }}
              measurementType={historicaDataMeasurementType}
              ymPoint={ymPoint}
            />
          )}
          {ymPoint && (
            <YmHistoricalListDialogAll
              open={historicalListDialogAllOpen}
              refetch={() => {
                ymPointQueries.refetch();
                ymPointQuery.refetch();
              }}
              onClose={() => {
                setHistoricalListDialogAllOpen(false);
                ymPointQueries.refetch();
                ymPointQuery.refetch();
              }}
              ymPoint={ymPoint}
            />
          )}
        </div>
      )}
    </div>
  );
}
