import React, { useState } from 'react';
import clsx from 'clsx';

import CustomScroll from 'react-custom-scroll';
import 'assets/css/commonScroll.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonText from 'components/common/textOutput/CommonText';
import { chunckArrayToPercentageArray } from 'utils/chunckArrayToPercentageArray';
import useComponentSize from 'utils/hooks/useComponentSize';
import { getComparator, stableSort } from 'utils/sorting';
import theme from 'config/theme';
import CommonFab from 'components/common/CommonFab';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.base.white,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    table: { backgroundColor: theme.colors.base.white },
    headerCell: {
      padding: 10,
      borderColor: 'rgba(230, 237, 241, 0.7)',
      backgroundColor: theme.colors.base.white,
    },
    headerCellContent: {},
    headerCellBorder: {
      borderLeft: '2px solid',
      borderColor: 'rgba(230, 237, 241, 0.7)',
    },
    tableItemCell: {
      borderLeft: '2px solid',
      borderColor: 'rgba(230, 237, 241, 0.7)',
    },
    clickableTableItem: {
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px 10px rgba(212, 220, 224, 0.36)',
      },
      width: '100%',
    },
    firstTableItem: { borderLeft: 'none' },
    lastTableItem: {
      borderBottom: 'none',
      borderImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(166, 190, 203, 0.25) 100%)`,
      borderImageSlice: 1,
    },
  })
);
export default function CommonTable({
  headers,
  flexArray,
  data,
  sortable,
  alignCenter,
  className,
  onRowClick,
  onDeleteClick,
  onEditClick,
}: {
  headers: { id: string; label: string; thresholdNumber?: number }[];
  flexArray?: number[];
  data?: { [key: string]: string | number }[];
  sortable?: boolean;
  alignCenter?: boolean;
  className?: string;
  onRowClick?: (rowElement) => void;
  onDeleteClick?: (rowElement) => void;
  onEditClick?: (rowElement) => void;
}) {
  const classes = useStyles();

  const flexPercentageArray = flexArray
    ? chunckArrayToPercentageArray(flexArray)
    : undefined;

  const [rootRef, setRootRef] = useState<HTMLElement>();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState(headers[0].id);

  const TableItem = function TableItem({
    data,
    lastItem,
  }: {
    data: { [key: string]: number | string };
    lastItem: boolean;
  }) {
    return (
      <TableRow
        className={clsx({
          [classes.clickableTableItem]: onRowClick !== undefined,
        })}
        onClick={() => {
          onRowClick && onRowClick(data);
        }}
      >
        {headers.map((header, index) => {
          return (
            <TableCell
              key={index}
              className={clsx(classes.tableItemCell, {
                [classes.lastTableItem]: lastItem,
                [classes.firstTableItem]: index === 0,
              })}
              width={
                flexPercentageArray
                  ? flexPercentageArray[index]
                  : 100 / headers.length
              }
            >
              <CommonText
                colorOverride={
                  data[header.id] && header.thresholdNumber
                    ? data[header.id] > header.thresholdNumber
                      ? 'success'
                      : 'error'
                    : 'secondary'
                }
                alignCenter={alignCenter}
                textVariant={
                  data[header.id] && header.thresholdNumber
                    ? '12 medium'
                    : '12 light'
                }
              >
                {data[header.id] ? (
                  data[header.id]
                ) : header.id === 'delete' && onDeleteClick ? (
                  <CommonFab
                    noShadow
                    tooltip={{ placement: 'bottom', text: 'Slett' }}
                    icon="trash-2"
                    onClick={() => {
                      onDeleteClick(data);
                    }}
                  />
                ) : header.id === 'edit' && onEditClick ? (
                  <CommonFab
                    noShadow
                    tooltip={{ placement: 'bottom', text: 'Rediger' }}
                    icon="edit"
                    onClick={() => {
                      onEditClick(data);
                    }}
                  />
                ) : (
                  '-'
                )}
              </CommonText>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <div
      ref={(ref) => {
        setRootRef(ref as HTMLElement);
      }}
      className={clsx(classes.root, {
        [className as string]: className,
      })}
    >
      <TableContainer
        className={'common-scroll'}
        style={{
          height: useComponentSize(rootRef as HTMLElement, 0).height,
          maxWidth: useComponentSize(rootRef as HTMLElement, 0).width,
        }}
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={100}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    className={clsx(classes.headerCell, {
                      [classes.headerCellBorder]: index > 0,
                    })}
                    sortDirection={orderBy === header.id ? order : false}
                    width={
                      flexPercentageArray
                        ? flexPercentageArray[index]
                        : 100 / headers.length
                    }
                  >
                    <div className={classes.headerCellContent}>
                      {sortable ? (
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : 'asc'}
                          onClick={() => {
                            setOrder(
                              orderBy === header.id && order === 'asc'
                                ? 'desc'
                                : 'asc'
                            );
                            setOrderBy(header.id);
                          }}
                        >
                          <CommonText
                            alignCenter={alignCenter}
                            textVariant="12 medium"
                          >
                            {header.label}
                          </CommonText>
                        </TableSortLabel>
                      ) : (
                        <CommonText
                          alignCenter={alignCenter}
                          textVariant="12 medium"
                        >
                          {header.label}
                        </CommonText>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                (sortable
                  ? stableSort(data, getComparator(order, orderBy)).map(
                      (dataElement, index) => {
                        return (
                          <TableItem
                            key={index}
                            data={dataElement}
                            lastItem={index === data.length - 1}
                          ></TableItem>
                        );
                      }
                    )
                  : data.map((dataElement, index) => {
                      return (
                        <TableItem
                          key={index}
                          data={dataElement}
                          lastItem={index === data.length - 1}
                        ></TableItem>
                      );
                    }))}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
    </div>
  );
}
