import React, { useCallback, useEffect, useRef, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { useNvdbObjectsQueries } from 'services/nvdbQueryHooks';
import NvdbMarkerCluster from 'components/opsDash/nvdb/NvdbMarkerCluster';
import geohash from 'ngeohash';
import Lodash from 'lodash';
import { objectList } from 'components/opsDash/nvdb/nvdObjectsTypes';
import NvdbFilterItemlist from 'components/opsDash/nvdb/NvdbFilterItemlist';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { LeafletEvent } from 'leaflet';

// Overriding leaflet's CSS
const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.leaflet-container': {
      height: '100%',
      width: '100%',
      minHeight: 800,
    },
    '.leaflet-control-layers:hover': {
      'text-align': 'left',
      'box-shadow': '0 1px 5px rgba(0, 0, 0, 0.4)',
      'border-radius': '5px',
    },
    '.leaflet-control-attribution.leaflet-control': {
      'margin-right': '30px',
    },
  },
})(() => null);

export default function Map() {
  const [hashArray, setHashArray] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categorySelectOpen, setCategorySelectOpen] = React.useState(true);
  const [noCategorySnackOpen, setNoCategorySnackOpen] = useState(false);
  const [noDataSnackOpen, setnoDataSnackOpen] = useState(false);

  const handlecords = useCallback(
    (mapRef) => {
      const southWest = mapRef.getBounds()._southWest;
      const northEast = mapRef.getBounds()._northEast;
      let precision = mapRef.getZoom() < 8 ? 2 : 5;
      let calculatedBBXHashArray = geohash.bboxes(
        southWest.lat,
        southWest.lng,
        northEast.lat,
        northEast.lng,
        precision
      );
      while (calculatedBBXHashArray.length > 5) {
        precision -= 1;
        calculatedBBXHashArray = geohash.bboxes(
          southWest.lat,
          southWest.lng,
          northEast.lat,
          northEast.lng,
          precision
        );
      }
      const updatedHashArray = [...hashArray];
      calculatedBBXHashArray.forEach((element) => {
        if (updatedHashArray.length === 0) {
          updatedHashArray.push(element);
        }

        if (!updatedHashArray.includes(element)) {
          updatedHashArray.push(element);
        }
      });
      setHashArray(updatedHashArray);
    },
    [hashArray]
  );

  const leafletMapRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [initMap, setInitMap] = useState(false);

  useEffect(() => {
    if (!initMap) {
      handlecords(leafletMapRef.current.leafletElement);
      setInitMap(true);
    }
  }, [
    handlecords,
    initMap,
    setInitMap,
    selectedCategories,
    categorySelectOpen,
  ]);

  const nvdbObjectQueries = useNvdbObjectsQueries(hashArray);

  const markerData = nvdbObjectQueries.data;

  const markerDataByType = Lodash.groupBy(
    Object.values(markerData),
    'nvdbObjectType.name'
  );

  function handleCategorySelection(category: string): void {
    if (selectedCategories.includes(category)) {
      setSelectedCategories((prev) => {
        prev.splice(prev.indexOf(category), 1);

        return prev;
      });
    } else {
      setSelectedCategories((prev) => {
        prev.push(category);
        return prev;
      });
    }
  }

  function handleMapMoveAndZoom(e: LeafletEvent): void {
    handlecords(e.target);

    if (selectedCategories.length === 0) {
      setNoCategorySnackOpen(true);
    } else if (Object.keys(markerData).length === 0) {
      setnoDataSnackOpen(true);
    }
  }

  return (
    <>
      <GlobalCss />

      <NvdbFilterItemlist
        selectedCategories={selectedCategories}
        handleCategorySelect={handleCategorySelection}
        open={categorySelectOpen}
        setOpen={setCategorySelectOpen}
      />

      <Snackbar
        open={noCategorySnackOpen}
        onClose={() => {
          setNoCategorySnackOpen(false);
        }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" elevation={6} variant="filled">
          Velg minst en kategori
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={noDataSnackOpen}
        onClose={() => {
          setnoDataSnackOpen(false);
        }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert severity="error" elevation={6} variant="filled">
          Zoom inn eller flytt kartet for å se data
        </MuiAlert>
      </Snackbar>

      <LeafletMap
        ref={leafletMapRef}
        style={{
          backgroundColor: '#e1f4ff',
          zIndex: 0,
        }}
        key="mainmap"
        center={[58.53616869687896, 8.854917283457583]}
        zoom={9}
        maxZoom={20}
        minZoom={7}
        attributionControl={true}
        zoomControl={false}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        onzoomend={handleMapMoveAndZoom}
        ondragend={handleMapMoveAndZoom}
      >
        {selectedCategories.length !== 0 &&
          selectedCategories.map((type, index) => {
            return (
              <NvdbMarkerCluster
                markerIcon={objectList[type].markerIcon}
                key={index}
                markerColor={objectList[type].markerColor}
                clusterColor={objectList[type].clusterColor}
                nvdbObjects={markerDataByType[type]}
              />
            );
          })}

        <TileLayer
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}"
          attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
        />
      </LeafletMap>
    </>
  );
}
