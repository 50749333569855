import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import * as L from 'leaflet';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import NvdbMarkers from 'components/opsDash/nvdb/NvdbMarkers';
import { NvdbObject } from 'services/nvdbApi';

const useStyles = makeStyles(() =>
  createStyles({
    markerText: {
      borderRadius: '20px',
      fontSize: '1vw',
      color: '#fff',
      fontWeight: 900,
      fontFamily: 'Open Sans',
      'text-tansform': 'uppercase',
    },
    markerClusterAreaSmall: {
      width: '1.8vw',
      height: '1.8vw',
      position: 'absolute' as 'absolute',
      left: '-0.9vw',
      top: '-0.9vw',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerClusterAreaMedium: {
      width: '2.0vw',
      height: '2.0vw',
      position: 'absolute' as 'absolute',
      left: '-1.0vw',
      top: '-1.0vw',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerClusterAreaLarge: {
      width: '2.5vw',
      height: '2.5vw',
      position: 'absolute' as 'absolute',
      left: '-1.25vw',
      top: '-1.25vw',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerClusterSmall: {
      borderRadius: '20px',
      fontSize: '1vw',
      color: '#fff',
      fontWeight: 900,
      fontFamily: 'Open Sans',
      textTransform: 'uppercase',
    },
    markerClusterLarge: {
      borderRadius: '40px',
      fontSize: '1.5vw',
      color: '#fff',
      fontWeight: 900,
      fontFamily: 'Open Sans',
      textTransform: 'uppercase',
    },
  })
);

function NvdbMarkerCluster({
  nvdbObjects,
  markerColor,
  clusterColor,
  markerIcon,
}: {
  nvdbObjects: NvdbObject[];
  clusterColor: string;
  markerColor:
    | 'red'
    | 'darkred'
    | 'orange'
    | 'green'
    | 'darkgreen'
    | 'blue'
    | 'purple'
    | 'darkpurple'
    | 'cadetblue'
    | undefined;
  markerIcon: string;
}) {
  const classes = useStyles();

  if (!nvdbObjects) {
    return null;
  }

  const createClusterCustomIcon = (cluster) => {
    const count = cluster.getChildCount();

    const options = {
      cluster: `markerClusterSmall`,
      clusterArea: `markerClusterAreaSmall`,
    };

    return L.divIcon({
      html: `<div class=${
        classes[options.clusterArea]
      } style=background-color:${clusterColor}>
              <div>${count}</div>
            </div>`,
      className: `${classes.markerText}`,
    });
  };

  return (
    <>
      <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
        <NvdbMarkers
          markerIcon={markerIcon}
          color={markerColor}
          nvdbData={nvdbObjects}
        />
      </MarkerClusterGroup>
    </>
  );
}

export default NvdbMarkerCluster;
