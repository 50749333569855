import React, { useState } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import TablePlaceholder from 'components/deliveryDash/project/documentsCard/TablePlaceholder';
import useComponentSize from 'utils/hooks/useComponentSize';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    table: {
      height: '100%',
      width: '100%',
    },
    spinnerContainer: {
      position: 'absolute',
      zIndex: 1,
      height: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinner: { color: theme.colors.icons.active.dark },
  })
);

function InitialLoadingContent({ loading }: { loading: boolean }) {
  const classes = useStyles();

  const [rootRef, setRootRef] = useState<HTMLElement>();

  return (
    <div
      ref={(ref) => {
        setRootRef(ref as HTMLElement);
      }}
      className={classes.root}
    >
      {loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress size={150} classes={{ circle: classes.spinner }} />
        </div>
      )}
      <div className={classes.table}>
        <TablePlaceholder
          loading={loading}
          tableHeight={useComponentSize(rootRef, 300).height}
        />
      </div>
    </div>
  );
}

export default InitialLoadingContent;
