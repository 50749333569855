import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import CommonText from 'components/common/textOutput/CommonText';
import CommonCard from 'components/common/cards/CommonCard';
import CommonFab from 'components/common/CommonFab';
import TextField from 'components/common/textInputs/TextField';
import {
  Project,
  NewProject,
  CompanyProjectRelation,
} from 'services/deliveryApi/models/';
import { useQueryProjectCompaniesList } from 'services/deliveryQueryHooks';
import { SelectedProjectContext } from 'utils/context/SelectedProject';
import { authorizedProjectApi } from 'services/apiService';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardBody: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
      padding: 20,
    },
    rowElement: {
      flex: 1,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    label: {
      padding: 10,
    },
    saveButton: {
      marginRight: 10,
    },
    buttonContainer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
      paddingRight: 20,
      paddingTop: 20,
    },
  })
);

interface InputForm {
  projectName?: string;
  projectNumber?: string;
  customerProjectNumber?: string;
}

export default function ProjectOwnerCard({
  editable,
  newProject,
  project,
  refetch,
}: {
  editable?: boolean;
  newProject?: boolean;
  project: Project | undefined;
  refetch?: () => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { setSelectedProjectId } = useContext(SelectedProjectContext);

  const [edit, setEdit] = useState(newProject);
  const [projectNameEdit, setProjectNameEdit] = useState(false);
  const [projectNumberEdit, setProjectNumberEdit] = useState(false);
  const [projectCustomerNumberEdit, setProjectCustomerNumberEdit] =
    useState(false);

  const [saved, setSaved] = useState(false);
  const [formData, setFormData] = useState<InputForm>({});
  const [vaildations, setValidations] = useState({
    projectName: true,
    projectNumber: true,
    customerProjectNumber: true,
  });

  const anyFieldsInEditMode =
    projectNameEdit || projectNumberEdit || projectCustomerNumberEdit || edit;

  const companiesListQuery = useQueryProjectCompaniesList(
    project?.id as string
  );

  const projectCompanies = companiesListQuery.data;
  let projectOwner: CompanyProjectRelation | undefined;
  projectCompanies?.forEach((company) => {
    if (company.accessType === 'owner') {
      projectOwner = company;
    }
  });

  const validateForm = (form) => {
    const currentValidation = { ...vaildations };

    if (!form.projectName) {
      currentValidation.projectName = false;
    }
    if (!form.projectNumber) {
      currentValidation.projectNumber = false;
    }
    if (!form.customerProjectNumber) {
      currentValidation.customerProjectNumber = false;
    }

    setValidations(currentValidation);
    return (
      currentValidation.projectName &&
      currentValidation.projectNumber &&
      currentValidation.customerProjectNumber
    );
  };

  const emptyFieldCheck = () => {
    return (
      !formData.projectName &&
      !formData.projectNumber &&
      !formData.customerProjectNumber
    );
  };

  const fieldsAreDirty = () => {
    return (
      project?.projectName !== formData.projectName ||
      project?.projectNumber !== formData.projectNumber ||
      project?.customerProjectNumber !== formData.customerProjectNumber
    );
  };

  const setEditFieldsFlase = () => {
    setProjectNameEdit(false);
    setProjectNumberEdit(false);
    setProjectCustomerNumberEdit(false);
  };

  function onSaveClick() {
    if (!emptyFieldCheck() && fieldsAreDirty()) {
      authorizedProjectApi().then((api) => {
        api
          .updateProject({
            projectId: String(project?.id),
            updateProject: {
              ...formData,
            },
          })
          .then(refetch);
      });
    }
  }

  const createProjectFab = () => {
    return (
      <CommonFab
        icon="plus"
        iconColor={theme.colors.messageAndAlerts.notificationInfo}
        onClick={() => {
          if (validateForm(formData)) {
            authorizedProjectApi().then((api) => {
              api
                .createProject({ newProject: formData as NewProject })
                .then((response) => {
                  setSelectedProjectId(JSON.parse(response).projectId);
                  history.push('/dashboard/project');
                });
            });
          }
        }}
        text="Opprett prosjekt"
        buttonSize="medium"
      />
    );
  };

  const saveProjectFab = () => {
    return (
      <CommonFab
        className={classes.saveButton}
        textColor="success"
        onClick={() => {
          if (!saved) {
            setEdit(false);
            setEditFieldsFlase();
            setSaved(true);
            onSaveClick();
          }
        }}
        staticComponent={saved}
        selected={saved}
        text={saved ? 'Lagret' : 'Lagre'}
        buttonSize="medium"
      />
    );
  };

  return (
    <div className={classes.root}>
      {editable || newProject ? (
        <div className={classes.buttonContainer}>
          {newProject
            ? createProjectFab()
            : (anyFieldsInEditMode || saved) && saveProjectFab()}

          {!newProject && (
            <CommonFab
              icon="edit-2"
              onClick={() => {
                if (anyFieldsInEditMode) {
                  setEdit(false);
                  setEditFieldsFlase();
                } else {
                  setEdit(true);
                }
                setSaved(false);
              }}
              selected={anyFieldsInEditMode}
              buttonSize="medium"
            />
          )}
        </div>
      ) : null}
      <CommonCard
        bodyComponent={
          <div className={classes.cardBody}>
            {/* Using mockadata for Prosjekteier */}
            <div className={classes.rowElement}>
              <CommonText textVariant="12 light" className={classes.label}>
                Prosjekteier
              </CommonText>
              <TextField
                editable={false}
                value={projectOwner ? projectOwner.companyName : ''}
              />
            </div>
            <Divider orientation="vertical" light />

            <FormControl className={classes.rowElement}>
              <CommonText textVariant="12 light" className={classes.label}>
                Prosjektnavn
              </CommonText>
              <TextField
                keepActive
                editable={edit || projectNameEdit}
                placeholder="Prosjektnavn..."
                onChange={(value) => {
                  setValidations({ ...vaildations, projectName: true });
                  setFormData({
                    ...formData,
                    projectName: value.target.value,
                  });
                  setSaved(false);
                }}
                onClick={() => {
                  editable && setProjectNameEdit(true);
                }}
                error={!vaildations.projectName}
                defaultValue={project && project.projectName}
              />
            </FormControl>
            <Divider orientation="vertical" light />
            <div className={classes.rowElement}>
              <CommonText textVariant="12 light" className={classes.label}>
                Prosjektnr
              </CommonText>
              <TextField
                keepActive
                editable={edit || projectNumberEdit}
                placeholder="Prosjektnummer..."
                onChange={(value) => {
                  setValidations({ ...vaildations, projectNumber: true });
                  setFormData({
                    ...formData,
                    projectNumber: value.target.value,
                  });
                  setSaved(false);
                }}
                onClick={() => {
                  editable && setProjectNumberEdit(true);
                }}
                error={!vaildations.projectNumber}
                defaultValue={project && project.projectNumber}
              />
            </div>
            <Divider orientation="vertical" light />
            <div className={classes.rowElement}>
              <CommonText textVariant="12 light" className={classes.label}>
                Prosjektnr, kunde
              </CommonText>
              <TextField
                keepActive
                editable={edit || projectCustomerNumberEdit}
                placeholder="Prosjektnummer..."
                onChange={(value) => {
                  setValidations({
                    ...vaildations,
                    customerProjectNumber: true,
                  });
                  setFormData({
                    ...formData,
                    customerProjectNumber: value.target.value,
                  });
                  setSaved(false);
                }}
                onClick={() => {
                  editable && setProjectCustomerNumberEdit(true);
                }}
                error={!vaildations.customerProjectNumber}
                defaultValue={project && project.customerProjectNumber}
              />
            </div>
          </div>
        }
      ></CommonCard>
    </div>
  );
}
