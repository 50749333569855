import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const CommonAutocomplete = forwardRef(
  (
    {
      id,
      label,
      options,
      loading,
      onChange,
    }: {
      id: string;
      label: string;
      options: { name: string; value: string }[];
      loading?: boolean;
      onChange: (value) => void;
    },
    forwardRef
  ) => {
    const [currentSelectedCompany, setCurrentSelectedCompany] = useState<{
      name: string;
      value: string;
    } | null>(null);

    const clearSelected = () => {
      setCurrentSelectedCompany(null);
    };

    useImperativeHandle(forwardRef, () => {
      return { clearSelected };
    });

    return (
      <Autocomplete
        options={options}
        value={currentSelectedCompany}
        getOptionLabel={(option) => option.name}
        loading={loading}
        onChange={(_, value) => {
          onChange(value);
          setCurrentSelectedCompany(value);
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        renderInput={(params) => {
          return <TextField {...params} id={id} label={label} />;
        }}
        sx={{ width: '100%' }}
      />
    );
  }
);

export default CommonAutocomplete;
