import { useTheme } from '@mui/material/styles';
import {
  DataProp,
  calculateGradientOffset,
  toPercent,
} from 'components/common/charts/graphUtils';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomizedAxisTick from '../CustomizedAxisTick';

export default function TrafficAreaGraph({
  data,
  dataProps,
  xKey,
  aspect,
  width,
  height,
  stackGraphs,
  showAsPercentage,
}: {
  data: object[];
  dataProps: DataProp[];
  xKey: string;
  aspect?: number;
  width: string | number;
  height: string | number;
  stackGraphs?: boolean;
  showAsPercentage?: boolean;
}) {
  const theme = useTheme();

  return (
    <ResponsiveContainer
      height={height === '100%' ? '99%' : height}
      width={width === '100%' ? '99%' : width}
      aspect={aspect}
    >
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 40,
        }}
        stackOffset={showAsPercentage ? 'expand' : 'none'}
      >
        <CartesianGrid strokeDasharray="9 9" />
        <XAxis tick={<CustomizedAxisTick />} dataKey={xKey} />
        {showAsPercentage ? <YAxis tickFormatter={toPercent} /> : <YAxis />}
        <Tooltip
          labelStyle={{
            color: 'black',
          }}
          itemStyle={{
            color: 'black',
          }}
          wrapperStyle={{
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
        />
        {dataProps.map((areaProp: DataProp, index) => {
          if (areaProp.gradientThreshold) {
            const offset = calculateGradientOffset(
              data,
              areaProp.key,
              areaProp.gradientThreshold
            );

            return (
              <defs key={index}>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset={offset}
                    stopColor={theme.palette.primary.main}
                    stopOpacity={1}
                  />
                  <stop
                    offset={offset}
                    stopColor={areaProp.color}
                    stopOpacity={1}
                  />
                </linearGradient>
              </defs>
            );
          } else {
            return null;
          }
        })}
        {dataProps.map((areaProp: DataProp, index) => {
          return (
            <Area
              stackId={showAsPercentage || stackGraphs ? 1 : index}
              type="monotone"
              key={index}
              dataKey={areaProp.key}
              stroke={areaProp.strokeColor ? areaProp.strokeColor : '#000'}
              fill={
                areaProp.gradientThreshold ? 'url(#splitColor)' : areaProp.color
              }
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}
