import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import CommonDialog from 'components/common/CommonDialog';
import { YmPoint } from 'services/ymApi/models';

import ThresholdAreaChart from 'components/common/charts/areaChart/ThresholdAreaGraph';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '50%', width: '50%' },
    chartContainer: {
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface GraphData {
  value: string;
  date: string;
}

export default function HistoricalDataDialog({
  measurementType,
  ymPoint,
  open,
  onClose,
}: {
  measurementType: string;
  ymPoint: YmPoint;
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  const dataArray: GraphData[] = [];

  let currentYear = '';
  let currentMonth = '';
  let currentDay = '';

  const measurementThresholdValue: number | undefined = ymPoint.thresholdValues
    ? Number(ymPoint.thresholdValues[measurementType])
    : undefined;

  ymPoint.data &&
    Object.entries(ymPoint.data.historicalDataRecord).forEach((year) => {
      if (typeof year[1] === 'object') {
        currentYear = year[0];
        Object.entries(year[1] as object).forEach((month) => {
          if (typeof month[1] === 'object') {
            currentMonth = month[0];
            Object.entries(month[1] as object).forEach((day) => {
              currentDay = day[0];
              if (day[1][measurementType]) {
                const dataElement: GraphData = {
                  value: day[1][measurementType].value,
                  date:
                    currentYear.replace('y', '20') +
                    '-' +
                    currentMonth.replace('m', '') +
                    '-' +
                    currentDay.replace('d', ''),
                };

                dataArray.push(dataElement);
              }
            });
          }
        });
      }
    });
  const sortedDataByDate = dataArray.sort(
    (a: GraphData, b: GraphData) =>
      new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={measurementType}
      className={classes.root}
      dialogContent={
        <div className={classes.chartContainer}>
          <ThresholdAreaChart
            data={sortedDataByDate}
            xKey="date"
            yKey="value"
            gradientThreshold={measurementThresholdValue}
          />
        </div>
      }
    />
  );
}
