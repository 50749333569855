import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonFab from 'components/common/CommonFab';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginLeft: 10,
      marginRight: 10,
    },
  })
);
export default function FileExplorerButton({
  onFilesSelected,
}: {
  onFilesSelected: (files: File[]) => void;
}) {
  const classes = useStyles();
  return (
    <label htmlFor="upload-file">
      <input
        onClick={(event) => {
          event.currentTarget.value = ''; //making sure the input is clear so that onChange is called even if the same file is picked twice
        }}
        id="upload-file"
        name="upload-file"
        style={{ display: 'none' }}
        multiple
        onChange={(event) => {
          const fileList = (event.currentTarget as HTMLInputElement).files;
          const files = fileList && Array.from(fileList);

          files && onFilesSelected(files);
          event.currentTarget.files = null;
        }}
        type="file"
      />
      <CommonFab
        className={classes.button}
        componentType="span"
        text="Last opp"
        icon="upload-cloud"
        divider
        onClick={() => {
          // Do nothing
        }}
      />
    </label>
  );
}
