import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { useState, useEffect, useRef } from 'react';

export default function useUserStatus(): string {
  const [user, setUser] = useState('null');

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    const updateUser: () => Promise<void> = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser && isMountedRef.current) setUser('user');
      } catch {
        setUser('nouser');
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events

    // we are not using async to wait for updateUser, so there will be a flash of page where the user is assumed not to be logged in. If we use a flag
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => {
      isMountedRef.current = false;
      Hub.remove('auth', updateUser);
    }; // cleanup
  }, []);

  return user;
}
