import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CommonText from 'components/common/textOutput/CommonText';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      width: '100%',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      paddingTop: 15,
      paddingBottom: 15,
      width: '100%',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor: theme.colors.base.base1,
      display: 'flex',
      flexDirection: 'row',
    },
    headerWithoutShadow: {
      paddingTop: 15,
      paddingBottom: 15,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    headerLeftComponent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 3,
      maxWidth: 'max-content',
    },
    headerRightComponent: {
      flex: 2,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      paddingLeft: 20,
    },
    body: {
      flexGrow: 1,
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
    },
    footer: {
      height: 70,
      display: 'flex',
      alignItems: 'center',
    },
    disabled: {
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      height: '100%',
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.70)',
    },
  })
);

export default function CommonCard({
  className,
  title,
  headerRightComponent,
  bodyComponent,
  footerComponent,
  disabled,
  disableHeaderShadow,
  setPaperRef,
  setBodyRef,
}: {
  className?: string;
  title?: string;
  headerRightComponent?: React.ReactNode;
  bodyComponent: React.ReactNode;
  footerComponent?: React.ReactNode;
  disabled?: boolean;
  disableHeaderShadow?: boolean;
  setPaperRef?: (ref: HTMLElement) => void;
  setBodyRef?: (ref: HTMLElement) => void;
}) {
  const classes = useStyles();

  const noHeader = title === undefined && headerRightComponent === undefined;

  return (
    <Paper
      ref={(ref) => ref && setPaperRef && setPaperRef(ref as HTMLElement)}
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
    >
      {disabled && <div className={classes.disabled} />}
      {noHeader ? null : (
        <div
          className={
            disableHeaderShadow ? classes.headerWithoutShadow : classes.header
          }
        >
          {
            <div className={classes.headerLeftComponent}>
              {title && (
                <CommonText
                  textVariant="20 medium"
                  colorOverride="white"
                  className={classes.title}
                >
                  {title}
                </CommonText>
              )}
            </div>
          }
          {headerRightComponent && (
            <div className={classes.headerRightComponent}>
              {headerRightComponent}
            </div>
          )}
        </div>
      )}
      <div
        ref={(ref) => ref && setBodyRef && setBodyRef(ref as HTMLElement)}
        className={classes.body}
      >
        {bodyComponent}
      </div>
      {footerComponent && <Divider light />}
      {footerComponent && (
        <div className={classes.footer}>{footerComponent}</div>
      )}
    </Paper>
  );
}
