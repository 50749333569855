import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';

import FilesLogo from 'assets/img/files.svg';
import FeatherIcon from 'feather-icons-react';

import 'font-awesome/css/font-awesome.min.css';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: 50,
    },
    dragAndDropText: {
      padding: 10,
    },
    dragAndDropContainer: {
      height: '100%',
      width: '100%',
      borderStyle: 'dotted',
      borderColor: theme.colors.icons.noState.light,
      display: 'flex',
      borderRadius: '75px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default function DragAndDropIndicator({ text }: { text: string }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.dragAndDropContainer}>
        <img
          src={FilesLogo}
          style={{ height: 'auto', width: '240px' }}
          alt="filesLogo"
        />

        <div>
          <FeatherIcon color={'#E6E6E6'} icon="upload-cloud" size="60" />
        </div>

        <CommonText textVariant="20 light" className={classes.dragAndDropText}>
          Drag & Drop
        </CommonText>
        <CommonText textVariant="12 light">{text}</CommonText>
      </div>
    </div>
  );
}
