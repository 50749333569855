import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';

import CommonTextField from 'components/common/textInputs/CommonTextField';

import isEmpty from 'utils/validation/isEmpty';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    formInput: { width: '100%' },
    completeButton: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: 'white',
      width: 250,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 15,
      paddingRight: 15,
    },
    serverError: {
      padding: 20,
    },

    confirmationText: {
      paddingBottom: 20,
    },
    confirmationEmailText: {
      color: theme.colors.text.main,
    },
    textButton: {
      textTransform: 'none',
      fontSize: 16,
      color: '#C7D3E0',
    },
    textButtonClicked: {
      color: theme.colors.messageAndAlerts.success,
    },
  })
);

export default function CognitoSignIn({
  confirmationEmail,
  onSuccess,
}: {
  confirmationEmail: string;
  onSuccess?: () => void;
}) {
  const classes = useStyles();

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState<
    string | null
  >(null);
  const [serverError, setServerError] = useState<string | null>(null);

  const [resendVerificationCode, setResendVerificationcode] = useState(false);

  const vaildateInput = () => {
    if (isEmpty(verificationCode)) {
      setVerificationCode('Verifikasjonskode mangler');
    }
  };

  return (
    <div className={classes.root}>
      <CommonText textVariant="12 light" className={classes.confirmationText}>
        En epost med verifiseringskode er sendt til følgende adresse:
      </CommonText>
      <CommonText
        textVariant="20 medium"
        className={classes.confirmationEmailText}
      >
        {confirmationEmail}
      </CommonText>
      <CommonText textVariant="12 light" className={classes.confirmationText}>
        Skriv inn verifiseringskoden under for å fullføre registreringen.
      </CommonText>
      <form className={classes.formInput} noValidate autoComplete="off">
        <CommonTextField
          id="signupverificationcode"
          label={
            verificationCodeError
              ? 'Verifikasjonskode mangler'
              : 'Verifikasjonskode'
          }
          type="tel"
          error={Boolean(verificationCodeError)}
          onFocus={() => {
            setVerificationCodeError(null);
          }}
          onChange={(event) => {
            setVerificationCode(event.target.value);
          }}
        />
        <div style={{ height: 30 }} />
        <div className={classes.buttonContainer}>
          <Fab
            variant="extended"
            className={classes.completeButton}
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              setServerError(null);
              vaildateInput();
              if (!isEmpty(verificationCode)) {
                Auth.confirmSignUp(confirmationEmail, verificationCode)
                  .then(() => {
                    onSuccess && onSuccess();
                  })
                  .catch((err) => {
                    switch (err.message) {
                      case 'Invalid verification code provided, please try again.':
                        setServerError('Feil verifiseringskode. Prøv igjen.');
                        break;
                      default:
                        setServerError('Ukjent systemfeil ved registrering');
                        break;
                    }
                  });
              }
            }}
          >
            <CommonText textVariant="20 medium">
              Fullfør registrering
            </CommonText>
          </Fab>
          <Button
            className={clsx(classes.textButton, {
              [classes.textButtonClicked]: resendVerificationCode,
            })}
            onClick={() => {
              if (!resendVerificationCode) {
                Auth.resendSignUp(confirmationEmail)
                  .then(() => {
                    setResendVerificationcode(true);
                  })
                  .catch((err) => {
                    switch (err.message) {
                      case 'Attempt limit exceeded, please try after some time.':
                        setServerError(
                          'Maksgrense er nådd. Venligst vent en stund og prøv igjen.'
                        );
                        break;
                      default:
                        setServerError('Kunne ikke sende verifikasjonskode');
                        break;
                    }
                  });
              }
            }}
          >
            <CommonText
              textVariant={resendVerificationCode ? '14 medium' : '14 light'}
            >
              {resendVerificationCode
                ? 'Ny verifikasjonskode er sendt'
                : 'Send verifikasjonskode på nytt'}
            </CommonText>
          </Button>
        </div>
        <CommonText
          colorOverride="error"
          textVariant="12 light"
          className={classes.serverError}
        >
          {serverError}
        </CommonText>
      </form>
    </div>
  );
}
