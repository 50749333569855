import React from 'react';
import clsx from 'clsx';

import CustomScroll from 'react-custom-scroll';
import 'assets/css/commonScroll.css';

import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import FeatherIcon from 'feather-icons-react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      borderRadius: 30,
    },
    header: {
      height: 80,
      minHeight: 50,
      display: 'flex',
      flexDirection: 'row',
    },
    headerLeft: {
      height: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 45,
      paddingTop: 45,
    },
    headerRight: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 50,
      paddingTop: 30,
    },
    titleIcon: { marginRight: 20 },
    dialogContent: {
      zIndex: 1,
      padding: 5,
      minWidth: 400,
      minHeight: 100,
    },
    dialogActions: { width: '100%', padding: 30 },
    divider: {
      paddingTop: 45,
      paddingLeft: 40,
      paddingBottom: 10,
      paddingRight: 50,
    },
  })
);

function CommonDialog({
  title,
  titleIcon,
  open,
  onClose,
  dialogContent,
  dialogActions,
  className,
  rightHeaderComponent,
}: {
  title?: string;
  titleIcon?: string;
  open: boolean;
  onClose: () => void;
  dialogContent: React.ReactNode;
  dialogActions?: React.ReactNode;
  className?: string;
  rightHeaderComponent?: React.ReactNode;
}) {
  const classes = useStyles();

  let iconSize = 20;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 30;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 40;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 50;
  }

  return (
    <Dialog
      onClose={onClose}
      classes={{
        paper: className
          ? clsx(classes.paper, { [className]: true })
          : classes.paper,
      }}
      open={open}
    >
      {
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            {titleIcon && (
              <FeatherIcon
                className={classes.titleIcon}
                color={theme.colors.icons.noState.light}
                icon={titleIcon}
                size={iconSize}
              />
            )}
            {title && <CommonText textVariant="20 medium">{title}</CommonText>}
          </div>
          <div className={classes.headerRight}>
            {rightHeaderComponent ? (
              rightHeaderComponent
            ) : (
              <CommonFab onClick={onClose} noShadow icon="x" />
            )}
          </div>
        </div>
      }
      {title ? (
        <div className={classes.divider}>{<Divider light />}</div>
      ) : null}
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          className={'common-scroll'}
        >
          <CustomScroll
            heightRelativeToParent="100%"
            minScrollHandleHeight={100}
          >
            {dialogContent}
          </CustomScroll>
        </div>
      </DialogContent>
      {dialogActions ? (
        <DialogActions className={classes.dialogActions}>
          {dialogActions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default CommonDialog;
