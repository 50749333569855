import React, { useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import FeatherIcon from 'feather-icons-react';

import InitialLoadingContent from 'components/deliveryDash/project/documentsCard/InitialLoadingContent';
import CardContent from 'components/deliveryDash/project/documentsCard/CardContent';
import CommonCard from 'components/common/cards/CommonCard';
import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';
import FileExplorerButton from 'components/deliveryDash/project/documentsCard/FileExplorerButton';

import { useQueryPointclouds } from 'services/deliveryQueryHooks';
import theme from 'config/theme';
import { ProjectDocumentRelation } from 'services/deliveryApi';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100%',
      maxHeight: '100%',
      width: '100%',
    },
    headerContent: {
      width: '100%',
      paddingRight: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    button: {
      marginLeft: 10,
      marginRight: 10,
    },
    disabled: {
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      height: '100%',
      borderRadius: 30,
      backgroundColor: 'rgba(255, 255, 255, 0.70)',
    },
    noDocumentsText: {
      paddingLeft: 10,
      paddingRight: 30,
    },
    searchField: {
      backgroundColor: 'white',
      width: '25%',
    },
  })
);

export default function ProjectDocumentsCard({
  projectId,
  disabled,
  editable,
  searchDialogOpen,
  documents,
  isFetching,
  refetch,
  onSearchClick,
}: {
  projectId: string;
  disabled?: boolean;
  editable: boolean;
  searchDialogOpen?: boolean;
  documents?: ProjectDocumentRelation[] | undefined;
  isFetching?: boolean;
  refetch?: () => void;
  onSearchClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}) {
  const pointcloudQuery = useQueryPointclouds(projectId);

  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const filesToUploadRef = useRef(filesToUpload); // needed to get updated state for clear file function, since its called from a setTimeout
  filesToUploadRef.current = filesToUpload;

  const classes = useStyles();

  const clearFile = (fileToClear: File) => {
    const newFileArray = filesToUploadRef.current.filter(function (file) {
      return file.name !== fileToClear.name;
    });
    setFilesToUpload(newFileArray);
  };

  const headerContent = (
    <div className={classes.headerContent}>
      {!editable && !isFetching && !documents && (
        // show message for read only access users when there are no documents available
        <React.Fragment>
          <FeatherIcon
            icon="info"
            color={theme.colors.icons.noState.light}
            size={25}
          />
          <CommonText
            className={classes.noDocumentsText}
            textVariant="14 medium"
            colorOverride="white"
          >
            Det finnes ingen dokumenter enda
          </CommonText>
        </React.Fragment>
      )}
      {documents && (
        <CommonFab
          className={classes.button}
          componentType="span"
          text={searchDialogOpen ? 'Reset søk' : 'Søk'}
          icon="search"
          divider
          onClick={(e) => {
            onSearchClick && onSearchClick(e);
          }}
          selected={searchDialogOpen}
        />
      )}
      {editable && (
        <FileExplorerButton
          onFilesSelected={(files) => {
            setFilesToUpload(files);
          }}
        />
      )}
      {documents && (
        <CommonFab
          className={classes.button}
          text="Sorter"
          icon={sortDirection === 'asc' ? 'chevron-up' : 'chevron-down'}
          divider
          onClick={() => {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
          }}
        />
      )}
      <CommonFab
        className={classes.button}
        text="Oppdater"
        icon="refresh-cw"
        divider
        spinnerAsIcon={isFetching}
        onClick={() => refetch && refetch()}
      />
    </div>
  );

  const bodyContent =
    isFetching && !documents ? (
      // displaying placeholder content and loading indicator while fetching first documents
      <InitialLoadingContent loading={true} />
    ) : (
      // no longer fetching initial documents, showing documents table or initial drop zone
      <CardContent
        editable={editable}
        disabled={disabled}
        documents={documents}
        filesToUpload={filesToUpload}
        setFilesToUpload={(files) => {
          setFilesToUpload(files);
        }}
        clearFile={clearFile}
        sortDirection={sortDirection}
        refetchDocuments={() => {
          refetch && refetch();
          pointcloudQuery.refetch();
        }}
      />
    );

  return (
    <CommonCard
      disabled={disabled}
      title="Dokumenter"
      headerRightComponent={headerContent}
      bodyComponent={bodyContent}
    />
  );
}
