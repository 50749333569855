import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';

import CommonText from 'components/common/textOutput/CommonText';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8.5,
      boxShadow:
        '0px 2px 10px rgba(183, 207, 220, 0.38), 0px 1px 3px rgba(60, 89, 116, 0.08)',
      '&:hover': {
        background: 'white',
        boxShadow:
          '0px 4px 11px rgba(86, 101, 130, 0.17), 0px 1px 3px rgba(60, 89, 116, 0.3)',
      },
      minWidth: 75,

      maxHeight: 25,
      minHeight: 25,
      [theme.breakpoints.up('fullhd')]: {
        minWidth: 100,

        maxHeight: 35,
        minHeight: 35,
      },
      [theme.breakpoints.up('wqhd')]: {
        minWidth: 250,

        maxHeight: 75,
        minHeight: 75,
      },
      [theme.breakpoints.up('uhd')]: {
        minWidth: 300,

        maxHeight: 100,
        minHeight: 100,
      },
    },
    icon: { color: theme.colors.icons.noState.light },
    iconHover: { color: theme.colors.icons.active.dark },
    withIcon: { paddingRight: 5, paddingLeft: 10 },
    disabled: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
      border: '1px solid #BBC9D8',
    },
    noShadow: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    },
    touchRipple: {
      color: 'rgba(113, 146, 176, 0.22)',
    },
  })
);
export default function CommonButton({
  className,
  text,
  disabled,
  onClick,
  noShadow,
  noTextWrap,
}: {
  className?: string;
  text: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  noShadow?: boolean;
  noTextWrap?: boolean;
}) {
  const classes = useStyles();

  const [active, setActive] = useState(false);

  return (
    <Button
      disabled={disabled}
      className={clsx(classes.root, {
        [classes.noShadow]: noShadow,
        [classes.disabled]: disabled,
        [className as string]: className,
      })}
      TouchRippleProps={{ classes: { ripple: classes.touchRipple } }}
      onClick={onClick}
      onMouseOver={() => {
        if (!disabled) {
          setActive(true);
        }
      }}
      onMouseOut={() => {
        if (!disabled) {
          setActive(false);
        }
      }}
    >
      {text && (
        <CommonText
          alignCenter
          style={{ textTransform: 'none' }}
          noWrap={noTextWrap}
          textVariant={active ? '14 medium' : '14 light'}
          colorOverride={disabled ? 'light' : active ? 'secondary' : undefined}
        >
          {text}
        </CommonText>
      )}
    </Button>
  );
}
