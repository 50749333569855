import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import OptionsPicker from 'components/common/pickers/OptionsPicker';

import FeatherIcon from 'feather-icons-react';
import CustomScroll from 'react-custom-scroll';
import 'assets/css/commonScroll.css';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 50,
      paddingRight: 50,
      borderTop: '20px solid',
      borderImage:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.0) 0%, rgba(166, 190, 203, 0.1) 100%)',
      borderImageSlice: 320,
    },
    listElement: { paddingTop: 15, paddingBottom: 15 },
    listElementContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 15,
    },
    leftSide: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rightSide: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 20,
    },
    avatar: { backgroundColor: theme.colors.base.background },
    elementLabel: { paddingLeft: 20 },

    icon: { color: theme.colors.icons.noState.light },
  })
);

function ProjectSharingList({
  listElements,
  onRemove,
}: {
  listElements: {
    type: 'user' | 'company';
    name: string;
    value: string;
    access: 'reader' | 'contributor';
  }[];
  onRemove: (element) => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={'common-scroll'} style={{ width: '100%', height: 200 }}>
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={100}>
          {listElements.map((element, index) => {
            return (
              <React.Fragment key={index}>
                <div className={classes.listElement}>
                  <div className={classes.listElementContent}>
                    <div className={classes.leftSide}>
                      <Avatar className={classes.avatar}>
                        <FeatherIcon
                          className={classes.icon}
                          size="20"
                          icon={
                            element.type === 'company' ? 'briefcase' : 'user'
                          }
                        />
                      </Avatar>
                      <CommonText
                        className={classes.elementLabel}
                        textVariant="20 medium"
                      >
                        {element.name}
                      </CommonText>
                    </div>
                    <div className={classes.rightSide}>
                      <OptionsPicker
                        parentCallback={(value) => {
                          element.access = value as 'reader' | 'contributor';
                        }}
                        optionsList={{
                          reader: 'Leser',
                          contributor: 'Tilbyder',
                        }}
                      />
                      <CommonFab
                        noShadow
                        buttonSize="medium"
                        onClick={() => {
                          onRemove(element);
                        }}
                        icon="x"
                        iconColor={theme.colors.messageAndAlerts.error}
                      />
                    </div>
                  </div>
                </div>
                {index !== listElements.length - 1 && <Divider light />}
              </React.Fragment>
            );
          })}
        </CustomScroll>
      </div>
    </div>
  );
}

export default ProjectSharingList;
