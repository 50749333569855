import React from 'react';

export interface DataProp {
  key: string;
  strokeColor?: string;
  color?: string;
  gradientThreshold?: number;
}

export const calculateGradientOffset = (
  data: object[],
  dataKey: string,
  threshold: number
) => {
  const dataMax = Math.max(...data.map((i) => i[dataKey]));
  let dataMin = Math.min(...data.map((i) => i[dataKey]));

  if (dataMin >= 0) {
    dataMin = 0;
  }

  const offset = 1 - (threshold - dataMin) / (dataMax - dataMin);

  if (offset === -Infinity || offset === Infinity) {
    return undefined;
  }

  return 1 - (threshold - dataMin) / (dataMax - dataMin);
};
export const toPercent = (decimal: number) => `${Math.round(decimal * 100)}%`;

export const getPercent = (value: number, total: number) => {
  const ratio = total > 0 ? value / total : 0;
  return toPercent(ratio);
};

export const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => result + entry.value, 0);
  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value} (${getPercent(
              entry.value,
              total
            )})`}
          </li>
        ))}
      </ul>
    </div>
  );
};
