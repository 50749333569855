import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonDialog from 'components/common/CommonDialog';
import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';

import { authorizedYmApi } from 'services/apiService';
import { YmPoint } from 'services/ymApi/models/YmPoint';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      maxHeight: '4vh',
      minHeight: '4vh',
      width: 300,
      padding: 10,
    },
    actions: {
      width: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  })
);

function DeleteYmPointDialog({
  isOpened,
  onClose,
  ymPointToDelete,
}: {
  isOpened: boolean;
  onClose: () => void;
  ymPointToDelete: YmPoint;
}) {
  const classes = useStyles();
  return (
    <CommonDialog
      open={isOpened}
      dialogContent={
        <div className={classes.dialogContent}>
          <CommonText textVariant="20 medium">
            Er du sikker på du vil slette målepunktet?
          </CommonText>
        </div>
      }
      dialogActions={
        <div className={classes.actions}>
          <CommonFab
            className={classes.button}
            text="Avbryt"
            onClick={onClose}
          />
          <div style={{ width: 50 }} />
          <CommonFab
            className={classes.button}
            icon="trash-2"
            iconColor={theme.colors.messageAndAlerts.error}
            text="Ja, slett målepunktet"
            onClick={() => {
              authorizedYmApi().then((api) => {
                api.deleteYmPoint({ pointId: ymPointToDelete.id });
              });
              onClose();
            }}
          />
        </div>
      }
      onClose={onClose}
    />
  );
}

export default DeleteYmPointDialog;
