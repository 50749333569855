import React, { useState } from 'react';

import { format } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

import CommonDialog from 'components/common/CommonDialog';
import CommonTable from 'components/common/CommonTable';
import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import { useReportHook } from 'services/availabilityQueryHooks';

import theme from 'config/theme';
import CommonDeleteDialog from 'components/common/CommonDeleteDialog';
import { authorizedAvailabilityApi } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '80%', width: '80%' },
    pickers: { display: 'flex', alignItems: 'center', paddingRight: 20 },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);
export default function ReportDialog({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const [selectedMonth, setSelectedMonth] = useState(format(Date.now(), 'M'));
  const [selectedYear, setSelectedYear] = useState(format(Date.now(), 'yyyy'));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteEventId, setDeleteEventId] = useState('');
  const reportQuery = useReportHook(selectedYear, selectedMonth);
  const reportData = reportQuery.data && reportQuery.data;

  return (
    <React.Fragment>
      <CommonFab
        noShadow
        tooltip={{
          placement: 'right',
          text: 'Se rapportoversikt',
        }}
        buttonSize="large"
        icon="list"
        onClick={() => {
          setOpen(true);
          reportQuery.refetch();
        }}
      />
      <CommonDialog
        open={open}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
        title="Månedsrapport"
        className={classes.root}
        rightHeaderComponent={
          <div className={classes.pickers}>
            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="12 light"
                className={classes.pickerLabel}
              >
                År:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                optionsList={{
                  '2018': '2018',
                  '2019': '2019',
                  '2020': '2020',
                  '2021': '2021',
                }}
                initialvalue={selectedYear}
                parentCallback={(value) => {
                  setSelectedYear(value);
                }}
              />
            </div>
            <div style={{ width: 50 }} />
            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="12 light"
                className={classes.pickerLabel}
              >
                Periode:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                optionsList={{
                  '1': 'Januar',
                  '2': 'Februar',
                  '3': 'Mars',
                  '4': 'April',
                  '5': 'Mai',
                  '6': 'Juni',
                  '7': 'Juli',
                  '8': 'August',
                  '9': 'September',
                  '10': 'Oktober',
                  '11': 'November',
                  '12': 'Desember',
                }}
                parentCallback={(value) => {
                  setSelectedMonth(value);
                }}
                initialvalue={selectedMonth}
              />
            </div>
            <div style={{ width: 50 }} />
            <CommonFab
              onClick={() => {
                onClose();
                setOpen(false);
              }}
              noShadow
              icon="x"
            />
          </div>
        }
        dialogContent={
          <div style={{ height: '100%', width: '100%' }}>
            {reportQuery.isLoading ? (
              <div className={classes.centeringContainer}>
                <CircularProgress
                  size={150}
                  classes={{ circle: classes.spinnerCircle }}
                />
              </div>
            ) : reportData && reportData.length > 0 ? (
              <CommonTable
                headers={[
                  { id: 'closedDate', label: 'Stengt' },
                  { id: 'openedDate', label: 'Åpnet' },
                  { id: 'workType', label: 'Arbeid' },
                  { id: 'totalTime', label: 'Total tid' },
                  { id: 'totalMinutes', label: 'Antall minutter' },
                  { id: 'section', label: 'Seksjon' },
                  { id: 'direction', label: 'Retning' },
                  { id: 'isDeducted', label: 'Trekk' },
                  { id: 'netMinutes', label: 'Netto minutter' },
                  { id: 'delete', label: '' },
                ]}
                data={
                  reportData as unknown as {
                    [key: string]: string | number;
                  }[]
                }
                onDeleteClick={(data) => {
                  setDeleteDialogOpen(true);
                  setDeleteEventId(data.sort);
                }}
              />
            ) : (
              <div className={classes.centeringContainer}>
                <CommonText
                  textVariant="20 medium"
                  className={classes.pickerLabel}
                >
                  Ingen data denne perioden
                </CommonText>
              </div>
            )}
          </div>
        }
      />
      {deleteDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteDialogOpen}
          onClose={() => {
            setDeleteEventId('');
            setDeleteDialogOpen(false);
            setTimeout(() => {
              reportQuery.refetch();
            }, 2000);
          }}
          onClick={() => {
            authorizedAvailabilityApi().then((api) => {
              api.deleteEventId({ eventId: deleteEventId });
            });
          }}
          messageText="Er du sikker på at du vil slette denne raden?"
          buttonText="Ja, slett denne raden"
        />
      )}
    </React.Fragment>
  );
}
