import React, { useState } from 'react';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonDialog from 'components/common/CommonDialog';
import CommonButton from 'components/common/CommonButton';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import DateTimePicker from 'components/common/pickers/DateTimePicker';
import Divider from '@mui/material/Divider';

import { authorizedAvailabilityApi } from 'services/apiService';

import theme from 'config/theme';
import CommonFab from 'components/common/CommonFab';

const useStyles = makeStyles(() =>
  createStyles({
    root: { width: '60%', height: '80%' },
    dialogContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'space-between',
      flexWrap: 'wrap',
      padding: 50,
    },
    inputElement: { width: '50%', padding: 20 },
    mediumInputElement: { width: '25%', padding: 20 },
    smallInputElement: { width: '20%', padding: 20 },
    inputLabel: {
      padding: 10,
    },
    inputFields: {
      borderRadius: 15,
      borderColor: theme.colors.base.background,
      border: 'solid',
    },
    addButton: { marginTop: 50 },
  })
);

export default function AddEventDialog({ refetch }: { refetch: () => void }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [closedDate, setClosedDate] = useState(new Date(Date.now()));
  const [openedDate, setOpenedDate] = useState(new Date(Date.now()));

  const timeDiffMs = openedDate.getTime() - closedDate.getTime();

  const hours = Math.floor(timeDiffMs / (1000 * 3600));
  const remainingMinutes = Math.round(
    (timeDiffMs - hours * 1000 * 3600) / (1000 * 60)
  );

  const totalTime =
    hours === 0
      ? Math.floor(timeDiffMs / (1000 * 60))
        ? Math.floor(timeDiffMs / (1000 * 60)) + ' minutter'
        : ''
      : remainingMinutes
      ? hours + ' timer og ' + remainingMinutes + ' minutter'
      : hours + ' timer';

  const totalMinutes = Math.floor(timeDiffMs / (1000 * 60));
  const netMinutes = Math.round(totalMinutes * 0.75);

  // this calcualtion is wrong and need to be replaced
  const dayMinutes = Math.round(totalMinutes * 0.66);
  const nightMinutes = Math.round(totalMinutes * 0.33);

  const [workType, setWorkType] = useState('Tunnelvask');
  const [direction, setDirection] = useState('1 & 3 V');
  const [section, setSection] = useState('1');
  const [isDeducted, setIsDeducted] = useState('ja');

  return (
    <React.Fragment>
      <CommonFab
        noShadow
        tooltip={{
          placement: 'right',
          text: 'Legg til ny hendelse',
        }}
        buttonSize="large"
        icon="plus"
        onClick={() => {
          setOpen(true);
        }}
      />
      <CommonDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Legg til ny hendelse"
        className={classes.root}
        dialogContent={
          <div className={classes.dialogContent}>
            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Stengingstidspunkt:
              </CommonText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CommonText textVariant="14 light">Dato:</CommonText>
                <DateTimePicker
                  date={closedDate}
                  pickerType="date"
                  parentCallback={(date) => {
                    setClosedDate(
                      new Date(
                        closedDate.setFullYear(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate()
                        )
                      )
                    );
                  }}
                />
                <CommonText textVariant="14 light">Tidspunkt:</CommonText>
                <DateTimePicker
                  date={closedDate}
                  pickerType="time"
                  parentCallback={(date) => {
                    setClosedDate(new Date(closedDate.setTime(date.getTime())));
                  }}
                />
              </div>
            </div>
            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Åpningstidspunkt:
              </CommonText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CommonText textVariant="14 light">Dato:</CommonText>
                <DateTimePicker
                  date={openedDate}
                  pickerType="date"
                  parentCallback={(date) => {
                    setOpenedDate(
                      new Date(
                        openedDate.setFullYear(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate()
                        )
                      )
                    );
                  }}
                />
                <CommonText textVariant="14 light">Tidspunkt:</CommonText>
                <DateTimePicker
                  date={openedDate}
                  pickerType="time"
                  parentCallback={(date) => {
                    setOpenedDate(new Date(openedDate.setTime(date.getTime())));
                  }}
                />
              </div>
            </div>
            <Divider style={{ width: '100%' }} orientation="horizontal" light />
            <div className={classes.smallInputElement}>
              <CommonText textVariant="14 light" className={classes.inputLabel}>
                Total tid:
              </CommonText>
              <TextField
                disabled
                className={classes.inputFields}
                value={totalTime}
              />
            </div>
            <div className={classes.smallInputElement}>
              <CommonText textVariant="14 light" className={classes.inputLabel}>
                Antall minutter:
              </CommonText>
              <TextField
                disabled
                value={totalMinutes ? String(totalMinutes) : ''}
                className={classes.inputFields}
              />
            </div>
            <div className={classes.smallInputElement}>
              <CommonText textVariant="14 light" className={classes.inputLabel}>
                Dag min. (hvordan?):
              </CommonText>
              <TextField
                disabled
                value={
                  dayMinutes ? String(dayMinutes + ' (dette er galt)') : ''
                }
                className={classes.inputFields}
              />
            </div>
            <div className={classes.smallInputElement}>
              <CommonText textVariant="14 light" className={classes.inputLabel}>
                Natt min. (hvordan?)
              </CommonText>
              <TextField
                disabled
                value={
                  nightMinutes ? String(nightMinutes + ' (dette er galt)') : ''
                }
                className={classes.inputFields}
              />
            </div>
            <div className={classes.smallInputElement}>
              <CommonText textVariant="14 light" className={classes.inputLabel}>
                Netto min.
              </CommonText>
              <TextField
                disabled
                value={netMinutes ? String(netMinutes) : ''}
                className={classes.inputFields}
              />
            </div>
            <Divider style={{ width: '100%' }} orientation="horizontal" light />
            <div className={classes.mediumInputElement}>
              <CommonText
                alignCenter
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Velg type arbeid:
              </CommonText>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <OptionsPicker
                  optionsList={{
                    Tunnelvask: 'Tunnelvask',
                    'Stegning asfalt': 'Stegning asfalt',
                    Vedlikehold: 'Vedlikehold',
                    Annet: 'Annet',
                  }}
                  parentCallback={(value) => {
                    setWorkType(value);
                  }}
                />
              </div>
            </div>
            <div className={classes.mediumInputElement}>
              <CommonText
                alignCenter
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Velg retning:
              </CommonText>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <OptionsPicker
                  optionsList={{
                    '1 & 3 V': '1 & 3 V',
                    '2 & 4 Ø': '2 & 4 Ø',
                  }}
                  initialvalue={direction}
                  parentCallback={(value) => {
                    setDirection(value);
                  }}
                />
              </div>
            </div>
            <div className={classes.mediumInputElement}>
              <CommonText
                alignCenter
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Velg seksjon:
              </CommonText>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <OptionsPicker
                  optionsList={{
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    '6': '6',
                  }}
                  initialvalue={section}
                  parentCallback={(value) => {
                    setSection(value);
                  }}
                />
              </div>
            </div>
            <div className={classes.mediumInputElement}>
              <CommonText
                alignCenter
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Trekk:
              </CommonText>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <OptionsPicker
                  optionsList={{
                    ja: 'ja',
                    nei: 'nei',
                  }}
                  initialvalue={isDeducted}
                  parentCallback={(value) => {
                    setIsDeducted(value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CommonButton
                className={classes.addButton}
                onClick={() => {
                  authorizedAvailabilityApi().then((api) => {
                    api
                      .createEvent({
                        event: {
                          closedDate: format(closedDate, 'yyyy.MM.dd HH:mm'),
                          openedDate: format(openedDate, 'yyyy.MM.dd HH:mm'),
                          workType: workType,
                          direction: direction,
                          section: section,
                          isDeducted: isDeducted,
                        },
                      })
                      .then(() => {
                        refetch();
                      });
                  });
                  setOpen(false);
                }}
                text="Opprett hendelse"
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
