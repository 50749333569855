import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';

import CommonText from 'components/common/textOutput/CommonText';
import CommonButton from 'components/common/CommonButton';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textContainer: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonContainer: {
      flex: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 50,
    },
    buttonBox: { padding: 25, width: '50%', height: '35%' },
    buttonPaper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderRadius: 25,
    },
    text: { padding: 10 },
  })
);
export default function TestPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <CommonText className={classes.text} textVariant="8 light">
          8 light
        </CommonText>
        <CommonText className={classes.text} textVariant="8 medium">
          8 medium
        </CommonText>
        <CommonText className={classes.text} textVariant="10 light">
          10 light
        </CommonText>
        <CommonText className={classes.text} textVariant="10 medium">
          10 medium
        </CommonText>
        <CommonText className={classes.text} textVariant="12 light">
          12 light
        </CommonText>
        <CommonText className={classes.text} textVariant="12 medium">
          12 medium
        </CommonText>
        <CommonText className={classes.text} textVariant="14 light">
          14 light
        </CommonText>
        <CommonText className={classes.text} textVariant="14 medium">
          14 medium
        </CommonText>
        <CommonText className={classes.text} textVariant="20 light">
          20 light
        </CommonText>
        <CommonText className={classes.text} textVariant="20 medium">
          20 medium
        </CommonText>
        <CommonText className={classes.text} textVariant="90 medium">
          100 medium
        </CommonText>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonBox}>
          <Paper
            className={classes.buttonPaper}
            style={{ backgroundColor: theme.colors.base.white }}
          >
            <CommonButton
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
            <CommonButton
              disabled
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
          </Paper>
        </div>
        <div className={classes.buttonBox}>
          <Paper
            className={classes.buttonPaper}
            style={{ backgroundColor: theme.colors.base.lightGrey }}
          >
            <CommonButton
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
            <CommonButton
              disabled
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
          </Paper>
        </div>
        <div className={classes.buttonBox}>
          <Paper
            className={classes.buttonPaper}
            style={{ backgroundColor: theme.colors.base.background }}
          >
            <CommonButton
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
            <CommonButton
              disabled
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
          </Paper>
        </div>
        <div className={classes.buttonBox}>
          <Paper
            className={classes.buttonPaper}
            style={{ backgroundColor: theme.colors.base.lightGrey }}
          >
            <CommonButton
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
            <CommonButton
              disabled
              text="Se detajer"
              onClick={() => {
                // Do nothing
              }}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
