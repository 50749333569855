import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CommonText from 'components/common/textOutput/CommonText';

import { SelectedProjectContext } from 'utils/context/SelectedProject';
import { formatDateToLocalDate } from 'utils/dateTimeHandler';
import { CompanyProjectRelation } from 'services/deliveryApi/models';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px 10px rgba(212, 220, 224, 0.36)',
      },
      width: '100%',
      height: 70,
    },
    tableCell: {
      borderLeft: '2px solid rgba(230, 237, 241, 0.7)',
    },
    tableCellBottomBorder: {
      borderBottom: '2px solid',
      borderImage:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.0) 0%, rgba(166, 190, 203, 0.25) 100%)',
      borderImageSlice: 1,
    },
    lastTableCell: {
      borderBottom: 'none',
      borderLeft: '2px solid',
      borderImage:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(166, 190, 203, 0.25) 100%)',
      borderImageSlice: 1,
    },
  })
);

export default function ProjectTableItem({
  project,
  lastElement,
  percentageArray,
}: {
  project: CompanyProjectRelation;
  lastElement: boolean;
  percentageArray?: string[];
}) {
  const classes = useStyles();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const { setSelectedProjectId } = useContext(SelectedProjectContext);

  return (
    <TableRow
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        setSelectedProjectId(project.sort);
        history.push('/dashboard/project');
      }}
      className={classes.root}
    >
      <TableCell
        className={clsx('', {
          [classes.lastTableCell]: lastElement,
        })}
        width={percentageArray && percentageArray[0]}
        style={{ borderLeft: 'none' }}
      >
        <CommonText textVariant={hover ? '12 medium' : '12 light'}>
          {project.companyName ? project.companyName : 'mangler'}
        </CommonText>
      </TableCell>
      <TableCell
        className={clsx(classes.tableCell, {
          [classes.lastTableCell]: lastElement,
        })}
        width={percentageArray && percentageArray[1]}
      >
        <CommonText textVariant={hover ? '12 medium' : '12 light'}>
          {project.projectName ? project.projectName : 'mangler'}
        </CommonText>
      </TableCell>
      <TableCell
        className={clsx(classes.tableCell, {
          [classes.lastTableCell]: lastElement,
        })}
        width={percentageArray && percentageArray[2]}
      >
        <CommonText textVariant={hover ? '12 medium' : '12 light'}>
          {project.projectNumber ? project.projectNumber : 'mangler'}
        </CommonText>
      </TableCell>
      <TableCell
        className={clsx(classes.tableCell, {
          [classes.lastTableCell]: lastElement,
        })}
        width={percentageArray && percentageArray[3]}
        align="left"
      >
        <CommonText textVariant={hover ? '12 medium' : '12 light'}>
          {project.projectDescription
            ? project.projectDescription.substr(0, 120) + '...'
            : 'ingen beskrivelse'}
        </CommonText>
      </TableCell>

      <TableCell
        className={clsx(classes.tableCell, {
          [classes.lastTableCell]: lastElement,
        })}
        align="left"
        width={percentageArray && percentageArray[4]}
      >
        <CommonText textVariant={hover ? '12 medium' : '12 light'}>
          {project.startDate
            ? formatDateToLocalDate(project.startDate) +
              ' - ' +
              (project.endDate ? formatDateToLocalDate(project.endDate) : 'Nå')
            : 'Ikke Startet'}
        </CommonText>
      </TableCell>
    </TableRow>
  );
}
