import React, { useState, useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonDialog from 'components/common/CommonDialog';
import SharingForm from 'components/deliveryDash/project/projectSharing/SharingForm';
import SharingComplete from 'components/deliveryDash/project/projectSharing/SharingComplete';
import ConfirmSharing from 'components/deliveryDash/project/projectSharing/ConfirmSharing';
import { SelectedProjectContext } from 'utils/context/SelectedProject';
import CircularProgress from '@mui/material/CircularProgress';
import { authorizedProjectApi } from 'services/apiService';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      maxHeight: 600,
      width: 600,
      [theme.breakpoints.up('fullhd')]: {
        maxHeight: 800,
        width: 800,
      },
      [theme.breakpoints.up('wqhd')]: {
        maxHeight: 1000,
        width: 1000,
      },
      [theme.breakpoints.up('uhd')]: {
        maxHeight: 1200,
        width: 1200,
      },
    },
    spinner: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 200,
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

function ProjectSharing({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  const [sharingList, setSharingList] = useState<
    {
      type: 'user' | 'company';
      name: string;
      value: string;
      access: 'reader' | 'contributor';
    }[]
  >([]);

  const [completedSharingList, setCompletedSharingList] = useState<
    {
      type: 'user' | 'company';
      name: string;
      value: string;
      access: 'reader' | 'contributor';
    }[]
  >([]);

  const { selectedProjectId } = useContext(SelectedProjectContext);

  const [requestLoading, setRequestLoading] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);
  const [requestError, setRequestError] = useState(false);

  return (
    <CommonDialog
      title="Del prosjekt"
      titleIcon="share-2"
      open={open}
      onClose={onClose}
      dialogContent={
        <div className={classes.dialogContent}>
          {completedSharingList.length <= 0 ? (
            <SharingForm
              sharingList={sharingList}
              onNext={() => {
                setCompletedSharingList(sharingList);
              }}
              onShareAdd={(element) => {
                setSharingList([...sharingList, element]);
              }}
              onShareRemove={(element) => {
                setSharingList(
                  sharingList.filter((option) => {
                    return option !== element;
                  })
                );
              }}
            />
          ) : requestLoading && !requestComplete ? (
            <div className={classes.spinner}>
              <CircularProgress
                size={100}
                classes={{ circle: classes.spinnerCircle }}
              />
            </div>
          ) : requestComplete && !requestLoading ? (
            <SharingComplete
              message="Prosjektdelingen er fullført"
              onClose={() => {
                setRequestError(false);
                setRequestComplete(false);
                setSharingList([]);
                setCompletedSharingList([]);
                onClose();
              }}
            />
          ) : requestError && !requestLoading ? (
            <SharingComplete
              message="Kunne ikke dele prosjektet"
              onClose={() => {
                setRequestError(false);
                setRequestComplete(false);
                setSharingList([]);
                setCompletedSharingList([]);
                onClose();
              }}
            />
          ) : (
            <ConfirmSharing
              onCancel={() => {
                setCompletedSharingList([]);
              }}
              onAccept={(completedSharingList) => {
                authorizedProjectApi().then((api) => {
                  const requestInput = {};

                  completedSharingList.forEach((element) => {
                    if (element.type === 'user') {
                      if (!('userAccess' in requestInput)) {
                        requestInput['userAccess'] = [];
                      }
                      requestInput['userAccess'].push({
                        userEmail: element.name,
                        accessType: element.access,
                      });
                    }
                    if (element.type === 'company') {
                      if (!('companyAccess' in requestInput)) {
                        requestInput['companyAccess'] = [];
                      }
                      requestInput['companyAccess'].push({
                        companyId: element.value,
                        accessType: element.access,
                      });
                    }
                  });

                  api
                    .shareProject({
                      projectId: selectedProjectId,
                      shareProject: requestInput,
                    })
                    .then(() => {
                      setRequestLoading(false);
                      setRequestComplete(true);
                    })
                    .catch(() => {
                      setRequestLoading(false);
                      setRequestError(true);
                    });
                });
              }}
              completedSharingList={completedSharingList}
            />
          )}
        </div>
      }
    />
  );
}

export default ProjectSharing;
