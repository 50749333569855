import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import CommonText from 'components/common/textOutput/CommonText';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 20,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
    },
    comparison: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 20,
    },

    iconContainer: {
      position: 'absolute',
      alignSelf: 'flex-start',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 5,
      borderRadius: '50%',
      padding: 5,
      height: 40,
      width: 40,
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      backgroundColor: theme.colors.text.main,
      border: 'solid',
      borderWidth: 4,
      borderColor: theme.colors.base.white,
    },
  })
);
export default function ValueIndicator({
  label,
  value,
  icon,
  previous,
}: {
  label: string;
  value: number | undefined;
  icon?: IconDefinition;
  previous?: {
    value: number | undefined;
    endingText?: string;
    comparison: 'compare' | 'percentage';
    isIncreaseGood: 'yes' | 'no';
  };
}) {
  const classes = useStyles();

  const comparisonText =
    previous?.value && value
      ? previous.value > value
        ? previous.comparison === 'compare'
          ? Math.round(previous.value - value)
          : String((100 - (value / previous.value) * 100).toFixed(1) + '%')
        : previous.comparison === 'compare'
        ? Math.round(value - previous.value)
        : String(((value / previous.value) * 100 - 100).toFixed(1) + '%')
      : '';

  return (
    <Paper className={classes.root}>
      {icon && (
        <div className={classes.iconContainer}>
          <FontAwesomeIcon
            icon={icon}
            color={theme.colors.base.white}
            size="lg"
          />
        </div>
      )}

      <CommonText textVariant="14 medium">{label}</CommonText>
      <CommonText textVariant="32 medium">{value ? value : '-'}</CommonText>

      <div style={{ minHeight: 20 }}>
        {value && previous?.value ? (
          <div className={classes.comparison}>
            <FeatherIcon
              color={
                value !== previous.value
                  ? value > previous.value
                    ? previous.isIncreaseGood === 'yes'
                      ? theme.colors.messageAndAlerts.success
                      : theme.colors.messageAndAlerts.error
                    : previous.isIncreaseGood === 'yes'
                    ? theme.colors.messageAndAlerts.error
                    : theme.colors.messageAndAlerts.success
                  : theme.colors.messageAndAlerts.notificationInfo
              }
              icon={
                value !== previous.value
                  ? value > previous.value
                    ? 'arrow-up'
                    : 'arrow-down'
                  : 'minus'
              }
              size="20"
            />

            <CommonText
              colorOverride={
                value && previous.value && value !== previous.value
                  ? value > previous.value
                    ? previous.isIncreaseGood === 'yes'
                      ? 'success'
                      : 'error'
                    : previous.isIncreaseGood === 'yes'
                    ? 'error'
                    : 'success'
                  : 'info'
              }
              textVariant="12 light"
            >
              {comparisonText}
            </CommonText>
            {previous?.endingText && (
              <CommonText style={{ paddingLeft: 2 }} textVariant="12 light">
                {previous.endingText}
              </CommonText>
            )}
          </div>
        ) : null}
      </div>
    </Paper>
  );
}
