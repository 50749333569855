import React from 'react';

import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';

import CommonText from 'components/common/textOutput/CommonText';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
    },
    paperHeader: {
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      flex: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
    },

    sectionsContainer: { flex: 5 },
    textContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

function QuarterlyOverviewSection({
  sectionNumber,
  lastQuarter,
  soFarThisYear,
}: {
  sectionNumber: number;
  lastQuarter: number | undefined;
  soFarThisYear: number | undefined;
}) {
  const classes = useStyles();

  const thresholdNumber = 98.0;

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderTop}>
          <CommonText textVariant="20 medium">
            {'Seksjon ' + sectionNumber}
          </CommonText>
        </div>

        <Divider />
      </div>
      <div className={classes.paperBody}>
        <div className={classes.textContainer}>
          <CommonText textVariant="14 medium">Denne perioden:</CommonText>
          <CommonText
            colorOverride={
              lastQuarter
                ? lastQuarter < thresholdNumber
                  ? 'error'
                  : 'success'
                : undefined
            }
            textVariant="32 medium"
          >
            {lastQuarter ? lastQuarter + '%' : '-'}
          </CommonText>
        </div>

        <Divider
          orientation="vertical"
          style={{
            backgroundColor: 'white',

            height: '80%',
          }}
        />

        <div className={classes.textContainer}>
          <CommonText textVariant="14 medium">Hittil dette året:</CommonText>
          <CommonText
            colorOverride={
              soFarThisYear
                ? soFarThisYear < thresholdNumber
                  ? 'error'
                  : 'success'
                : undefined
            }
            textVariant="32 medium"
          >
            {soFarThisYear ? soFarThisYear + '%' : '-'}
          </CommonText>
        </div>
      </div>
    </Paper>
  );
}

export default QuarterlyOverviewSection;
