import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import clsx from 'clsx';

import CommonCard from 'components/common/cards/CommonCard';
import LocationPicker from 'components/common/map/locationPicker/LocationPicker';
import CommonFab from 'components/common/CommonFab';

import { Project } from 'services/deliveryApi/models/Project';
import { authorizedProjectApi } from 'services/apiService';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    cardBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      // minHeight: 250,
    },
    locationPicker: {
      borderRadius: 25,
    },
    editLocationButton: {
      zIndex: 1,
      position: 'absolute',
      padding: 10,
      right: 10,
    },

    saveLocationButton: {
      zIndex: 1,
      position: 'absolute',
      padding: 10,
      right: '21%',
    },
    saveDescriptonButton: {
      marginRight: 10,
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      paddingRight: 20,
    },
  })
);

export default function ProjectDescriptionLocationCard({
  editable,
  className,
  project,
  disabled,
  refetch,
}: {
  editable?: boolean;
  project?: Project;
  className?: string;
  disabled?: boolean;
  refetch?: () => void;
}) {
  const classes = useStyles();

  const [location, setLocation] = useState<string[]>(
    project?.projectLocation ? project.projectLocation.geometry.coordinates : []
  );

  const [editLocationMode, setEditLocationMode] = useState(false);

  const [savedLocation, setSavedLocation] = useState(false);

  function onSaveClick() {
    authorizedProjectApi().then((api) => {
      api
        .updateProject({
          projectId: String(project?.id),
          updateProject: {
            projectLocation: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: location,
              },
              properties: {},
            },
          },
        })
        .then(refetch);
    });
  }

  return (
    <div
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
    >
      <CommonCard
        disabled={disabled}
        bodyComponent={
          <div className={classes.cardBody}>
            {editable ? (
              <>
                {savedLocation || editLocationMode ? (
                  <>
                    <div className={classes.saveLocationButton}>
                      <CommonFab
                        text={savedLocation ? 'Lagret' : 'Lagre'}
                        staticComponent={savedLocation}
                        textColor="success"
                        onClick={() => {
                          onSaveClick();
                          setSavedLocation(true);
                          setEditLocationMode(false);
                        }}
                        selected={savedLocation}
                        buttonSize="medium"
                      />
                    </div>
                  </>
                ) : null}
                <div className={classes.editLocationButton}>
                  <CommonFab
                    text={
                      location === undefined || location.length === 0
                        ? 'Legg til lokasjon'
                        : 'Endre lokasjon'
                    }
                    icon="map-pin"
                    textColor="success"
                    iconColor={theme.colors.messageAndAlerts.success}
                    onClick={() => {
                      setEditLocationMode(!editLocationMode);
                      setSavedLocation(false);
                    }}
                    selected={editLocationMode}
                    buttonSize="medium"
                  />
                </div>
              </>
            ) : null}
            <LocationPicker
              setLocation={setLocation}
              className={classes.locationPicker}
              location={
                (project &&
                  (project.projectLocation as unknown)) as GeoJSON.Feature<GeoJSON.Point>
              }
              editLocation={editLocationMode}
              parentCallback={() => {
                setSavedLocation(false);
              }}
            />
          </div>
        }
      />
    </div>
  );
}
