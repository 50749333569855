import React, { useState } from 'react';
import { format } from 'date-fns';
import CommonCard from 'components/common/cards/CommonCard';
import CommonText from 'components/common/textOutput/CommonText';
import DateTimePicker from 'components/common/pickers/DateTimePicker';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import { sensorIdNameMap } from 'config/opsDash/sensorIdNameMap';
import TrafficAreaGraph from 'components/common/charts/areaChart/TrafficAreaGraph';
import ChartLegends from 'components/common/charts/ChartLegends';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryTrafficDataBySensorIdAndYearMonth } from 'services/trafficQueryHooks';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    commonCardHeaderRight: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 50,
      paddingLeft: 50,
    },
    sensorPickerLabel: { paddingRight: 10 },
    directionPickerLabel: { paddingRight: 10, paddingLeft: 20 },
    spinner: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    noData: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sensorName: { paddingLeft: 50 },
  })
);

function PassingsVehicleSize() {
  const classes = useStyles();

  const [sensorId, setSensorId] = useState('54653V1751052');
  const [date, setDate] = useState(new Date(Date.now() - 86400000)); // yesterday
  const [direction, setDirection] = useState('with');

  const trafficQuery = useQueryTrafficDataBySensorIdAndYearMonth(
    sensorId,
    format(date, 'yyyy-MM')
  );
  const trafficData = trafficQuery.data;

  const passingsData = trafficData
    ? trafficData.find((dayWithData) => {
        return dayWithData.sort === format(date, 'yyyy-MM-dd');
      })
    : undefined;

  const passinsDataByTime = { lane1: {}, lane2: {}, lane3: {}, lane4: {} };
  passingsData &&
    passingsData.data.forEach((data) => {
      passinsDataByTime[String(data.felt).replace('_', '')][
        format(new Date(data.fra), 'HH:mm')
      ] = { largeSize: data.largeSize, smallSize: data.smallSize };
    });

  const chartDataArray = Object.keys(passinsDataByTime.lane1).map((hourKey) => {
    const hourDataObject = {
      time: hourKey,
      Tungtransport:
        direction === 'with'
          ? passinsDataByTime.lane1[hourKey].largeSize +
            passinsDataByTime.lane3[hourKey].largeSize
          : passinsDataByTime.lane2[hourKey].largeSize +
            passinsDataByTime.lane4[hourKey].largeSize,
      Personbil:
        direction === 'with'
          ? passinsDataByTime.lane1[hourKey].smallSize +
            passinsDataByTime.lane3[hourKey].smallSize
          : passinsDataByTime.lane2[hourKey].smallSize +
            passinsDataByTime.lane4[hourKey].smallSize,
    };

    return hourDataObject;
  });

  return (
    <CommonCard
      title="Andel tungtransport"
      headerRightComponent={
        <div className={classes.commonCardHeaderRight}>
          <OptionsPicker
            label="Sensor"
            optionsList={sensorIdNameMap}
            parentCallback={(sensorId) => {
              setSensorId(sensorId);
            }}
          />

          <OptionsPicker
            label="Retning"
            optionsList={{
              with: 'I metreringsretning',
              against: 'Mot metreringsretning',
            }}
            parentCallback={(direction) => {
              setDirection(direction);
            }}
          />
          <div style={{ width: '30%' }}>
            <DateTimePicker
              date={date}
              parentCallback={(date) => {
                setDate(date);
              }}
              hideUnderline
            />
          </div>
        </div>
      }
      bodyComponent={
        trafficQuery.isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress
              size={150}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        ) : !trafficQuery.isLoading && !passingsData ? (
          <CommonText textVariant="20 medium" className={classes.noData}>
            {'Ingen data for ' +
              sensorIdNameMap[sensorId] +
              ' ' +
              format(date, 'dd.MM.yyyy')}
          </CommonText>
        ) : (
          trafficData && (
            <div
              style={{
                height: '100%',
                padding: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CommonText
                  className={classes.sensorName}
                  textVariant="20 medium"
                >
                  {sensorIdNameMap[sensorId] +
                    ', ' +
                    format(date, 'dd.MM.yyyy')}
                </CommonText>
                <ChartLegends
                  legends={[
                    {
                      name: 'Tungtransport',
                      color: theme.colors.messageAndAlerts.success,
                    },
                    {
                      name: 'Personbil',
                      color: theme.colors.messageAndAlerts.notificationInfo,
                    },
                  ]}
                />
              </div>

              <TrafficAreaGraph
                height="100%"
                width="100%"
                showAsPercentage={true}
                data={chartDataArray}
                xKey="time"
                stackGraphs
                dataProps={[
                  {
                    key: 'Tungtransport',
                    color: theme.colors.messageAndAlerts.success,
                  },
                  {
                    key: 'Personbil',
                    color: theme.colors.messageAndAlerts.notificationInfo,
                  },
                ]}
              />
            </div>
          )
        )
      }
    />
  );
}

export default PassingsVehicleSize;
