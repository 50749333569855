/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Event,
    EventFromJSON,
    EventToJSON,
    ReportObject,
    ReportObjectFromJSON,
    ReportObjectToJSON,
    SectionCounter,
    SectionCounterFromJSON,
    SectionCounterToJSON,
} from '../models';

export interface CreateEventRequest {
    event: Event;
}

export interface DeleteEventIdRequest {
    eventId: string;
}

export interface GetMontlyReportRequest {
    yearMonth: string;
}

/**
 * 
 */
export class AvailabilityApi extends runtime.BaseAPI {

    /**
     * create a new event
     */
    async createEventRaw(requestParameters: CreateEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling createEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/event/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventToJSON(requestParameters.event),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a new event
     */
    async createEvent(requestParameters: CreateEventRequest): Promise<void> {
        await this.createEventRaw(requestParameters);
    }

    /**
     * delete availability event
     */
    async deleteEventIdRaw(requestParameters: DeleteEventIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling deleteEventId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/event/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete availability event
     */
    async deleteEventId(requestParameters: DeleteEventIdRequest): Promise<void> {
        await this.deleteEventIdRaw(requestParameters);
    }

    /**
     * get the monthly report
     */
    async getMontlyReportRaw(requestParameters: GetMontlyReportRequest): Promise<runtime.ApiResponse<Array<ReportObject>>> {
        if (requestParameters.yearMonth === null || requestParameters.yearMonth === undefined) {
            throw new runtime.RequiredError('yearMonth','Required parameter requestParameters.yearMonth was null or undefined when calling getMontlyReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/report/{year-month}`.replace(`{${"year-month"}}`, encodeURIComponent(String(requestParameters.yearMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportObjectFromJSON));
    }

    /**
     * get the monthly report
     */
    async getMontlyReport(requestParameters: GetMontlyReportRequest): Promise<Array<ReportObject>> {
        const response = await this.getMontlyReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * get the section counter
     */
    async getSectionCounterRaw(): Promise<runtime.ApiResponse<SectionCounter>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/section-counter/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionCounterFromJSON(jsonValue));
    }

    /**
     * get the section counter
     */
    async getSectionCounter(): Promise<SectionCounter> {
        const response = await this.getSectionCounterRaw();
        return await response.value();
    }

}
