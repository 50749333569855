import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

import TopView from 'components/common/TopView';
import ProjectMenu from 'components/deliveryDash/project/ProjectMenu';

import ProjectOwnerCard from 'components/deliveryDash/project/ownerCard/ProjectOwnerCard';
import ProjectDescriptionCard from 'components/deliveryDash/project/descriptionCard/ProjectDescriptionCard';
import ProjectLocationCard from 'components/deliveryDash/project/locationCard/ProjectLocationCard';
import ProjectDocumentsCard from 'components/deliveryDash/project/documentsCard/ProjectDocumentsCard';
import ProjectPointCloudsCard from 'components/deliveryDash/project/pointcloudsCard/ProjectPointcloudsCard';
import { authorizedProjectApi } from 'services/apiService';

import ShareIcons from 'components/deliveryDash/project/projectSharing/ShareIcons';

import { SelectedProjectContext } from 'utils/context/SelectedProject';

import {
  useQueryProjects,
  useQueryProjectById,
  useQueryDocuments,
} from 'services/deliveryQueryHooks';

import theme from 'config/theme';
import SearchMenu from '../project/SearchMenu';
import { ProjectDocumentRelation } from 'services/deliveryApi';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    primaryLayout: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: 60,
      paddingTop: 0,
    },
    secondaryLeftLayout: {
      height: '100%',
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
    },
    secondaryRightLayout: {
      height: '100%',
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    spinner: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topViewRight: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',

      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingLeft: 20,
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    ownerCard: { padding: 20, width: '100%', flex: 1 },
    descriptionCard: { padding: 20, width: '100%', flex: 1 },
    locationCard: { padding: 20, width: '100%', flex: 1 },
    documentsCard: {
      padding: 20,
      width: '100%',
      flex: 9,
    },
    pointcloudsCard: {
      padding: 20,
      width: '100%',
      flex: 4,
    },
  })
);

export default function Project() {
  const classes = useStyles();

  const { selectedProjectId, setSelectedProjectId } = useContext(
    SelectedProjectContext
  );
  const projectQuery = useQueryProjectById(selectedProjectId);

  const project = projectQuery.data;

  const documentsQuery = useQueryDocuments(selectedProjectId);
  const documents = documentsQuery.data;

  const history = useHistory();

  useEffect(() => {
    // returning user to the list view on page reload

    if (selectedProjectId === '') {
      history.push('/dashboard/list-view');
    }
  }, [history, selectedProjectId]);

  const projectsQuery = useQueryProjects();
  const projects = projectsQuery.data;

  const [searchedDocuments, setSearchedDocuments] = useState<
    ProjectDocumentRelation[] | undefined
  >();

  const [searchOpen, setSearchOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  let isSelectedProjectEditable = false;
  let isSelectedProjectDocumentEditable = false;
  projects &&
    projects.forEach((projectElement) => {
      if (selectedProjectId === projectElement.sort) {
        if (projectElement.accessType === 'owner') {
          isSelectedProjectEditable = true;
          isSelectedProjectDocumentEditable = true;
        }
        if (projectElement.accessType === 'contributor') {
          isSelectedProjectDocumentEditable = true;
        }
      }
    });

  const onSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (documents) {
      setSearchedDocuments(
        documents.filter((doc) => {
          const combinedString = [doc.documentName, doc.documentTimeCreated]
            .filter(Boolean)
            .join(' '); // https://stackoverflow.com/questions/19902860/join-strings-with-a-delimiter-only-if-strings-are-not-null-or-empty

          return combinedString
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      );
    }
  };

  let shownDocuments = searchedDocuments ? searchedDocuments : documents;

  return (
    <div className={classes.root}>
      <TopView
        leftSideComponents={<ShareIcons />}
        rightSideComponents={
          <div className={classes.topViewRight}>
            {isSelectedProjectEditable ? (
              <SearchMenu
                open={searchOpen}
                onChange={onSearch}
                value={searchString}
                setValue={setSearchString}
              />
            ) : null}

            {isSelectedProjectEditable ? (
              <ProjectMenu
                isDeleted={() => {
                  authorizedProjectApi()
                    .then((api) => {
                      api.deleteProject({ projectId: selectedProjectId });
                    })
                    .then(() => {
                      setSelectedProjectId('');
                    });
                }}
              />
            ) : null}
          </div>
        }
      />
      {project && project.id === selectedProjectId ? (
        <div className={classes.primaryLayout}>
          <div className={classes.secondaryLeftLayout}>
            <div className={classes.ownerCard}>
              <ProjectOwnerCard
                editable={isSelectedProjectEditable}
                project={project}
                refetch={projectQuery.refetch}
              />
            </div>
            <div className={classes.descriptionCard}>
              <ProjectDescriptionCard
                editable={isSelectedProjectEditable}
                project={project}
                refetch={projectQuery.refetch}
              />
            </div>

            <div className={classes.locationCard}>
              <ProjectLocationCard
                editable={isSelectedProjectEditable}
                project={project}
                refetch={projectQuery.refetch}
              />
            </div>
          </div>
          <div className={classes.secondaryRightLayout}>
            <div className={classes.documentsCard}>
              <ProjectDocumentsCard
                projectId={selectedProjectId}
                editable={isSelectedProjectDocumentEditable}
                searchDialogOpen={searchOpen}
                onSearchClick={(e) => {
                  setSearchOpen(!searchOpen);
                  setSearchString('');
                  setSearchedDocuments(documents);
                }}
                documents={shownDocuments}
                isFetching={documentsQuery.isFetching}
                refetch={documentsQuery.refetch}
              />
            </div>
            <div className={classes.pointcloudsCard}>
              <ProjectPointCloudsCard projectId={selectedProjectId} />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress
            size={150}
            classes={{ circle: classes.spinnerCircle }}
          />
        </div>
      )}
    </div>
  );
}
