import React, { useState } from 'react';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';

import CommonDialog from 'components/common/CommonDialog';
import CommonButton from 'components/common/CommonButton';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import Description from 'components/common/textInputs/Description';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import DateTimePicker from 'components/common/pickers/DateTimePicker';
import { authorizedSecurityEventApi } from 'services/apiService';
import {
  SecurityEvent,
  SecurityEventAccidentTypeEnum,
} from 'services/securityApi/models';

import theme from 'config/theme';
import CommonFab from 'components/common/CommonFab';

const useStyles = makeStyles(() =>
  createStyles({
    root: { width: '60%', height: '80%' },
    dialogContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'space-between',
      flexWrap: 'wrap',
      padding: 50,
    },
    inputElement: { width: '50%', padding: 20 },
    smallInputElement: { width: '20%', padding: 20 },
    inputLabel: {
      padding: 10,
    },
    inputFields: {
      borderRadius: 15,
      borderColor: theme.colors.base.background,
      border: 'solid',
    },
    addButton: { marginTop: 50 },
    dateTimePicker: { paddingLeft: 0, paddingRight: 0 },
    commentBox: { paddingTop: 5, paddingLeft: 10 },
  })
);

export default function AddSecurityEventDialog({
  refetch,
}: {
  refetch: () => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  const initialForm: SecurityEvent = {
    date: format(initialDate, 'dd.MM.yyyy HH:mm'),
    place: '',
    accidentType: SecurityEventAccidentTypeEnum.AU,
    involvedCount: -1,
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [formData, setFormData] = useState<SecurityEvent>(initialForm);
  const [vaildations, setValidations] = useState(initialValidation);

  const validateForm = (form: SecurityEvent) => {
    const currentValidation = { ...vaildations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };

  const closeAndResetStates = () => {
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (validateForm(formData)) {
      authorizedSecurityEventApi().then((api) => {
        api
          .createSecurityEvent({
            securityEvent: {
              ...formData,
              date: format(selectedDate, 'dd.MM.yyyy HH:mm'),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <CommonFab
        noShadow
        tooltip={{ placement: 'right', text: 'Legg til ny sikkerhetshendelse' }}
        buttonSize="large"
        icon="plus"
        onClick={() => {
          setOpen(true);
        }}
      />
      <CommonDialog
        open={open}
        onClose={closeAndResetStates}
        title="Legg til ny sikkerhetshendelse"
        className={classes.root}
        dialogContent={
          <div className={classes.dialogContent}>
            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Dato og tidspunkt:
              </CommonText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DateTimePicker
                  className={classes.dateTimePicker}
                  date={selectedDate}
                  pickerType="date"
                  parentCallback={(date) => {
                    setSelectedDate(
                      new Date(
                        selectedDate.setFullYear(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate()
                        )
                      )
                    );
                  }}
                />
                <DateTimePicker
                  className={classes.dateTimePicker}
                  date={selectedDate}
                  pickerType="time"
                  parentCallback={(date) => {
                    setSelectedDate(
                      new Date(selectedDate.setTime(date.getTime()))
                    );
                  }}
                />
              </div>
            </div>

            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Ulykkestype:
              </CommonText>
              <div
                style={{
                  paddingLeft: 20,
                }}
              >
                <OptionsPicker
                  optionsList={{
                    [SecurityEventAccidentTypeEnum.DR]: 'Drepte',
                    [SecurityEventAccidentTypeEnum.MAS]:
                      'Meget alvorlig skadet',
                    [SecurityEventAccidentTypeEnum.AS]: 'Alvorlige skadet',
                    [SecurityEventAccidentTypeEnum.LS]: 'Lettere skadet',
                    [SecurityEventAccidentTypeEnum.PR]: 'Påkjørt rekkverk',
                    [SecurityEventAccidentTypeEnum.AU]:
                      'Andre ulykker uten personskade',
                  }}
                  initialvalue={formData.accidentType}
                  parentCallback={(value) => {
                    setFormData({
                      ...formData,
                      accidentType: SecurityEventAccidentTypeEnum[value],
                    });
                  }}
                />
              </div>
            </div>
            <Divider style={{ width: '100%' }} orientation="horizontal" light />

            <div className={classes.inputElement} style={{ paddingRight: 300 }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Sted:
              </CommonText>
              <TextField
                keepActive
                editable
                onChange={(value) => {
                  setValidations({ ...vaildations, place: true });
                  setFormData({
                    ...formData,
                    place: value.target.value,
                  });
                }}
                error={!vaildations.place}
              />
            </div>
            <div className={classes.inputElement} style={{ paddingRight: 350 }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Antall involverte:
              </CommonText>
              <TextField
                keepActive
                editable
                onChange={(value) => {
                  let newValue;
                  if (value.target.value === '') {
                    newValue = '';
                  } else {
                    newValue = +value.target.value;
                  }
                  setValidations({ ...vaildations, involvedCount: true });
                  setFormData({
                    ...formData,
                    involvedCount: newValue,
                  });
                }}
                error={!vaildations.involvedCount}
                numericInput
              />
            </div>

            <Divider style={{ width: '100%' }} orientation="horizontal" light />

            <div className={classes.inputElement} style={{ width: '100%' }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Kommentar:
              </CommonText>
              <Description
                className={classes.commentBox}
                editable
                onChange={(value) => {
                  setValidations({ ...vaildations, comment: true });
                  setFormData({
                    ...formData,
                    comment: value.target.value,
                  });
                }}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CommonButton
                className={classes.addButton}
                onClick={onSaveClick}
                text="Opprett sikkerhetshendelse"
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
