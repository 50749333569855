import React from 'react';
import Amplify from 'aws-amplify';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';

import SignIn from 'components/deliveryDash/pages/SignIn';
import SignUp from 'components/deliveryDash/pages/SignUp';
import MainDashboard from 'components/MainDashboard';

import theme from 'config/theme';

import { ReactQueryDevtools } from 'react-query-devtools';
import { queryClient } from 'services/apiService';
import config from './config/auth-config';

import SessionIdCookie from 'services/SessionIdCookie';

import { DashboardProvider } from 'utils/context/Dashboard';
import { GeneralProvider } from 'utils/context/General';

Amplify.configure(config);

function App() {
  return (
    <div style={{ height: '100vh' }}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <SessionIdCookie />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Route exact path="/">
                <SignIn />
              </Route>
              <Route path="/sign-up">
                <SignUp />
              </Route>
              <Route path="/dashboard">
                <GeneralProvider>
                  <DashboardProvider>
                    <MainDashboard />
                  </DashboardProvider>
                </GeneralProvider>
              </Route>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
