import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TopView from 'components/opsDash/TopView';
import MonthlyOverview from 'components/opsDash/security/MonthlyOverview';
import SecurityBarChart from 'components/opsDash/security/SecurityBarChart';
import SecurityReportDialog from 'components/opsDash/security/dialog/SecurityReportDialog';
import AddSecurityEventDialog from 'components/opsDash/security/dialog/AddSecurityEventDialog';
import { useQuerySecurityCounter } from 'services/securityQueryHooks';
import PageMenu from 'components/common/pageMenu/PageMenu';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    pageContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    element: { flex: 1, height: '100%', padding: 20 },
    cards: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
export default function Security() {
  const classes = useStyles();

  const securityCounterQuery = useQuerySecurityCounter();

  const sectionCounterData = securityCounterQuery.data;

  return (
    <div className={classes.root}>
      <TopView title="Sikkerhet" />
      <div className={classes.pageContent}>
        <PageMenu
          menuItems={[
            <AddSecurityEventDialog
              refetch={securityCounterQuery.refetch}
              key="AddSecurityEventDialog"
            />,
            <SecurityReportDialog
              securityCounterData={sectionCounterData}
              isLoading={securityCounterQuery.isLoading}
              key="SecurityReportDialog"
              refetch={securityCounterQuery.refetch}
            />,
          ]}
        />
        <div className={classes.cards}>
          <div className={classes.element}>
            <MonthlyOverview
              securityCounterData={sectionCounterData}
              isLoading={securityCounterQuery.isLoading}
            />
          </div>
          <div className={classes.element}>
            <SecurityBarChart
              securityCounterData={sectionCounterData}
              isLoading={securityCounterQuery.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
