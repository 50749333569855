import { zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';
export function formatDateToUtcISO(date: Date): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Gets timezone from browser, as of March 2019 does not work in internet explorer
  return zonedTimeToUtc(date, timeZone).toISOString();
}

export function formatDateToLocalDate(date: string): string {
  const gmtDateTime = moment.utc(date);
  const localDate = gmtDateTime.local().format('DD.MM.YYYY');
  return localDate;
}
