import React from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import FeatherIcon from 'feather-icons-react';
import DashCard from 'components/common/cards/DashCard';
import CommonText from 'components/common/textOutput/CommonText';
import theme from 'config/theme';

import { useQuerySectionCounter } from 'services/availabilityQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.base.white,
    },
    availabilityText: {
      flex: 3,
      display: 'flex',
      alignItems: 'center',
    },
    footer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: 'center',
      backgroundColor: theme.colors.base.base1light,
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
    dividerContainer: {
      flex: 1,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divider: {
      height: '60%',
      width: 2,
      borderRadius: 1,
      opacity: 0.2,
    },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: 'white' },
  })
);

function AvailabilityCard() {
  const classes = useStyles();

  const history = useHistory();

  let iconSize = 24;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 32;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 48;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 56;
  }

  const sectionCounterQuery = useQuerySectionCounter();
  const sectionCounterData = sectionCounterQuery.data;

  const currentMonthDate = new Date();
  const currentMonth = String(format(currentMonthDate, 'M'));
  const currentYear = String(format(currentMonthDate, 'Y'));
  const currentQuater = Math.ceil(Number(currentMonth) / 3);
  let selectedYear;
  let selectedQuarter;
  if (currentQuater === 1) {
    selectedYear = String(Number(currentYear) - 1);
    selectedQuarter = 4;
  } else {
    selectedYear = currentYear;
    selectedQuarter = String(currentQuater - 1);
  }

  const quarterAvailability: number | undefined = sectionCounterData?.data
    ?.deducted?.[selectedYear]?.quarters?.[selectedQuarter]
    ? Number(
        sectionCounterData.data.deducted[selectedYear]?.quarters[
          selectedQuarter
        ].availability
      )
    : undefined;

  const soFarThisYear: number | undefined = sectionCounterData?.data
    ?.deducted?.[currentYear]?.availability
    ? Number(sectionCounterData.data.deducted[currentYear].availability)
    : undefined;

  return (
    <DashCard
      title="Tilgjengelighet • Oppetid"
      subtitle={currentQuater - 1 + '. kvartal, ' + selectedYear}
      onDetailsClick={() => {
        history.push('/dashboard/availability');
      }}
      bodyComponent={
        <div className={classes.root}>
          {sectionCounterQuery.isLoading ? (
            <div className={classes.centeringContainer}>
              <CircularProgress
                size={150}
                classes={{ circle: classes.spinnerCircle }}
              />
            </div>
          ) : (
            <React.Fragment>
              <CommonText
                className={classes.availabilityText}
                colorOverride={
                  quarterAvailability
                    ? quarterAvailability > 98.0
                      ? 'success'
                      : 'error'
                    : 'secondary'
                }
                textVariant="90 medium"
              >
                {quarterAvailability
                  ? quarterAvailability.toFixed(2) + '%'
                  : '-'}
              </CommonText>

              <div className={classes.footer}>
                <FeatherIcon
                  size={iconSize}
                  icon="info"
                  color={theme.colors.icons.active.dark}
                />
                <CommonText alignCenter textVariant="14 medium">
                  Hittil i år
                </CommonText>
                <CommonText
                  textVariant="20 medium"
                  colorOverride={
                    soFarThisYear
                      ? soFarThisYear > 98.0
                        ? 'success'
                        : 'error'
                      : 'secondary'
                  }
                >
                  {soFarThisYear ? soFarThisYear.toFixed(2) + '%' : '-'}
                </CommonText>
              </div>
            </React.Fragment>
          )}
        </div>
      }
    />
  );
}

export default AvailabilityCard;
