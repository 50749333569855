import React, { useState } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import CustomScroll from 'react-custom-scroll';
import FileUploadItem from 'components/deliveryDash/project/documentsCard/FileUploadItem';
import DocumentTableItem from 'components/deliveryDash/project/documentsCard/DocumentTableItem';
import { ProjectDocumentRelation } from 'services/deliveryApi/models';
import useComponentSize from 'utils/hooks/useComponentSize';
import { getComparator, stableSort } from 'utils/sorting';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  })
);

function DocumentTable({
  editable,
  documents,
  filesToUpload,
  sortDirection,
  onFileUploadCompleted,
  onFileUploadCanceled,
  refetchDocuments,
}: {
  editable: boolean;
  documents?: ProjectDocumentRelation[];
  filesToUpload: File[];
  sortDirection: 'asc' | 'desc';
  onFileUploadCompleted: (completedFile: File) => void;
  onFileUploadCanceled: (canceledFile: File) => void;
  refetchDocuments: () => void;
}) {
  const classes = useStyles();
  const [rootRef, setRootRef] = useState<HTMLElement>();

  return (
    <div
      ref={(ref) => {
        setRootRef(ref as HTMLElement);
      }}
      className={classes.root}
    >
      <TableContainer
        style={{ height: useComponentSize(rootRef, 100).height - 25 }} // minus 25 to account for paper header shadow
        className={'common-scroll'}
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={80}>
          <Table size="small">
            <TableBody>
              {filesToUpload.map((file) => {
                return (
                  <FileUploadItem
                    key={file.name}
                    file={file}
                    onUploadSuccess={(completedFile) => {
                      onFileUploadCompleted(completedFile);
                    }}
                    onCancel={(canceledFile) => {
                      onFileUploadCanceled(canceledFile);
                    }}
                  />
                );
              })}
              {documents &&
                stableSort(
                  documents,
                  getComparator(sortDirection, 'documentTimeCreated')
                ).map((document) => {
                  return (
                    <DocumentTableItem
                      hideDelete={!editable}
                      key={document.sort}
                      document={document}
                      refetchDocuments={refetchDocuments}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
    </div>
  );
}

export default DocumentTable;
