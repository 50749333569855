import React, { useState } from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import CustomScroll from 'react-custom-scroll';
import PointcloudTableItem from 'components/deliveryDash/project/pointcloudsCard/PointcloudTableItem';
import { ProjectPointcloudRelation } from 'services/deliveryApi/models';
import useComponentSize from 'utils/hooks/useComponentSize';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  })
);

function PointcloudTable({
  pointclouds,
}: {
  pointclouds?: ProjectPointcloudRelation[];
}) {
  const classes = useStyles();
  const [rootRef, setRootRef] = useState<HTMLElement>();

  return (
    <div
      ref={(ref) => {
        setRootRef(ref as HTMLElement);
      }}
      className={classes.root}
    >
      <TableContainer
        style={{ height: useComponentSize(rootRef, 50).height - 25 }} // minus 25 to account for paper header shadow
        className="common-scroll"
      >
        <CustomScroll heightRelativeToParent="100%" minScrollHandleHeight={80}>
          <Table size="small">
            <TableBody>
              {pointclouds &&
                pointclouds.map((document) => {
                  return (
                    <PointcloudTableItem
                      key={document.sort}
                      pointcloud={document}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </CustomScroll>
      </TableContainer>
    </div>
  );
}

export default PointcloudTable;
