import React, { useState } from 'react';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';

import CommonDialog from 'components/common/CommonDialog';
import CommonButton from 'components/common/CommonButton';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import Description from 'components/common/textInputs/Description';
import DateTimePicker from 'components/common/pickers/DateTimePicker';
import { authorizedRuhReportApi } from 'services/apiService';

import theme from 'config/theme';
import CommonFab from 'components/common/CommonFab';
import { RUHReport } from 'services/hmsApi';

const useStyles = makeStyles(() =>
  createStyles({
    root: { width: '60%', height: '80%' },
    dialogContent: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'space-between',
      flexWrap: 'wrap',
      padding: 50,
    },
    inputElement: { width: '50%', padding: 20 },
    smallInputElement: { width: '20%', padding: 20 },
    inputLabel: {
      padding: 10,
    },
    inputFields: {
      borderRadius: 15,
      borderColor: theme.colors.base.background,
      border: 'solid',
    },
    addButton: { marginTop: 50 },
    dateTimePicker: { paddingLeft: 0, paddingRight: 0 },
    commentBox: { paddingTop: 5, paddingLeft: 10 },
  })
);

export default function AddRUHDialog({ refetch }: { refetch: () => void }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  const initialForm: RUHReport = {
    date: format(initialDate, 'dd.MM.yyyy'),
    ruhType: '',
    ruhNr: -1,
    description: '',
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedClosingDate, setSelectedClosingDate] = useState<Date | null>(
    null
  );
  const [formData, setFormData] = useState<RUHReport>(initialForm);
  const [vaildations, setValidations] = useState(initialValidation);

  const validateForm = (form: RUHReport) => {
    const currentValidation = { ...vaildations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };

  const closeAndResetStates = () => {
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (validateForm(formData)) {
      authorizedRuhReportApi().then((api) => {
        api
          .createRuhReport({
            rUHReport: {
              ...formData,
              date: format(selectedDate, 'dd.MM.yyyy'),
              ...(selectedClosingDate && {
                closed: format(selectedClosingDate, 'dd.MM.yyyy'),
              }),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <CommonFab
        noShadow
        tooltip={{ placement: 'right', text: 'Legg til RUH-rapport' }}
        buttonSize="large"
        icon="plus"
        onClick={() => {
          setOpen(true);
        }}
      />
      <CommonDialog
        open={open}
        onClose={closeAndResetStates}
        title="Legg til RUH-rapport"
        className={classes.root}
        dialogContent={
          <div className={classes.dialogContent}>
            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Dato:
              </CommonText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DateTimePicker
                  className={classes.dateTimePicker}
                  date={selectedDate}
                  pickerType="date"
                  parentCallback={setSelectedDate}
                />
              </div>
            </div>

            <div className={classes.inputElement}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Sluttdato:
              </CommonText>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DateTimePicker
                  className={classes.dateTimePicker}
                  date={selectedClosingDate}
                  pickerType="date"
                  parentCallback={setSelectedClosingDate}
                />
                <CommonFab
                  onClick={() => {
                    setSelectedClosingDate(null);
                  }}
                  text="Reset sluttdato"
                />
              </div>
            </div>

            <Divider style={{ width: '100%' }} orientation="horizontal" light />
            <div className={classes.inputElement} style={{ paddingRight: 350 }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                RUH type:
              </CommonText>
              <div>
                <TextField
                  keepActive
                  editable
                  onChange={(value) => {
                    setValidations({ ...vaildations, ruhType: true });
                    setFormData({
                      ...formData,
                      ruhType: value.target.value,
                    });
                  }}
                  error={!vaildations.ruhType}
                />
              </div>
            </div>
            <div className={classes.inputElement} style={{ paddingRight: 350 }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                RUH nr.:
              </CommonText>
              <TextField
                keepActive
                editable
                onChange={(value) => {
                  let newValue;
                  if (value.target.value === '') {
                    newValue = '';
                  } else {
                    newValue = +value.target.value;
                  }
                  setValidations({ ...vaildations, ruhNr: true });
                  setFormData({
                    ...formData,
                    ruhNr: newValue,
                  });
                }}
                error={!vaildations.ruhNr}
                numericInput
              />
            </div>

            <Divider style={{ width: '100%' }} orientation="horizontal" light />

            <div className={classes.inputElement} style={{ width: '100%' }}>
              <CommonText
                textVariant="14 medium"
                className={classes.inputLabel}
              >
                Beskrivelse:
              </CommonText>
              <Description
                className={classes.commentBox}
                editable
                onChange={(value) => {
                  setValidations({ ...vaildations, description: true });
                  setFormData({
                    ...formData,
                    description: value.target.value,
                  });
                }}
                error={!vaildations.description}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CommonButton
                className={classes.addButton}
                onClick={onSaveClick}
                text="Opprett sikkerhetshendelse"
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
