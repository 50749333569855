import React from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DashCard from 'components/common/cards/DashCard';
import Divider from '@mui/material/Divider';

import theme from 'config/theme';
import CommonText from 'components/common/textOutput/CommonText';

import { format } from 'date-fns';
import { useQuerySecurityCounter } from 'services/securityQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    collective: {
      flex: 2,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: 24,
    },
    leftRightView: {
      flex: 1,
      height: '100%',
      widht: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: 16,
    },
    divider: {
      height: '100%',
      backgroundColor: theme.colors.base.lightGrey,
    },
    horizontalDivider: {
      width: '100%',

      backgroundColor: theme.colors.base.lightGrey,
    },
  })
);

function SecurityCard() {
  const classes = useStyles();
  const history = useHistory();
  const securityCounterQuery = useQuerySecurityCounter();

  const securityCounterData = securityCounterQuery.data;

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const currentDate = new Date();
  const reportYear =
    securityCounterData?.data?.years?.[
      yearStringConverter(format(currentDate, 'yyyy'))
    ];

  const fatal = reportYear?.fatalAccidents ? reportYear?.fatalAccidents : 0;
  let dateArray: Date[] = [];
  reportYear &&
    Object.entries(reportYear?.months).forEach((months) => {
      Object.entries(months[1]?.days).forEach((days) => {
        Object.entries(days[1]).forEach((day) => {
          day.forEach((item) => {
            if (
              (item['accidentType'] === 'MAS' ||
                item['accidentType'] === 'AS') &&
              item['date']
            ) {
              const dateComponents = item['date'].split(' ');
              const datePieces = dateComponents[0].split('.');
              const timePieces = dateComponents[1].split(':');
              const dateFormat = new Date(
                datePieces[2],
                datePieces[1] - 1,
                datePieces[0],
                timePieces[0],
                timePieces[1]
              );
              dateArray.push(dateFormat);
            }
          });
        });
      });
    });

  const sortedDataByDate = dateArray.sort((a, b) => b.getTime() - a.getTime());
  const daysBetweenDates: number = Math.floor(
    (new Date(currentDate).getTime() -
      new Date(sortedDataByDate[0]).getTime()) /
      (1000 * 60 * 60 * 24)
  );

  return (
    <DashCard
      title="Sikkerhet • Antall ulykker"
      subtitle={'Så langt i ' + format(currentDate, 'yyyy')}
      onDetailsClick={() => {
        history.push('/dashboard/security');
      }}
      bodyComponent={
        <div className={classes.body}>
          <div className={classes.collective}>
            <div className={classes.leftRightView}>
              <div>
                <CommonText alignCenter textVariant="20 light">
                  Drepte
                </CommonText>
                <CommonText alignCenter textVariant="14 light">
                  ( antall )
                </CommonText>
              </div>
              <div>
                <CommonText
                  colorOverride={fatal === 0 ? 'success' : 'error'}
                  textVariant="50 medium"
                >
                  {fatal}
                </CommonText>
              </div>
              <div>
                <CommonText textVariant="14 light">
                  {'i ' + format(currentDate, 'yyyy')}
                </CommonText>
              </div>
            </div>
            <div>
              <Divider className={classes.divider} orientation="vertical" />
            </div>
            <div className={classes.leftRightView}>
              <div>
                <CommonText alignCenter textVariant="20 light">
                  Hardt Skadde
                </CommonText>
                <CommonText alignCenter textVariant="14 light">
                  ( dager siden sist )
                </CommonText>
              </div>
              <div>
                <CommonText textVariant="50 medium">
                  {daysBetweenDates}
                </CommonText>
              </div>
              <div>
                <CommonText textVariant="14 light">
                  {'i ' + format(currentDate, 'yyyy')}
                </CommonText>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SecurityCard;
