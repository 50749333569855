import React from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DashCard from 'components/common/cards/DashCard';
import ValueIndicator from 'components/common/cards/ValueIndicator';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import {
  faHardHat,
  faFileInvoice,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import { HMSCounter } from 'services/hmsApi';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 16,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: '100%',
      width: '100%',
    },
    valueIndicatior: {
      height: '50%',
      minHeight: '50%',
      width: '50%',
      minWidth: '50%',
      padding: 5,
    },
  })
);

function RUHCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const classes = useStyles();
  const history = useHistory();

  const monthConverter = (date: Date) => {
    const month = format(date, 'M');
    const paddingString = 'm';
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, 'yyyy');
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const currentDate = new Date();
  const previousMonthDate = new Date(currentDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const twoMonthsAgoDate = new Date(currentDate);
  twoMonthsAgoDate.setMonth(previousMonthDate.getMonth() - 1);

  const sjaReportYear =
    hmsCounterData?.data?.sja?.years?.[yearConverter(currentDate)];

  const ruhReportYear =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(currentDate)];

  const ruhTwoMonthsAgo =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(twoMonthsAgoDate)]
      ?.months?.[monthConverter(twoMonthsAgoDate)];

  const sjaTwoMonthsAgo =
    hmsCounterData?.data?.sja?.years?.[yearConverter(twoMonthsAgoDate)]
      ?.months?.[monthConverter(twoMonthsAgoDate)];

  let currentMonth = format(previousMonthDate, 'LLLL', { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(previousMonthDate, 'yyyy');

  return (
    <DashCard
      title="HMS • RUH og SJA"
      subtitle={currentMonth + ' ' + currentYear}
      noBodyPaper
      onDetailsClick={() => {
        history.push('/dashboard/hms');
      }}
      bodyComponent={
        <div className={classes.content}>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="RUH"
              icon={faHardHat}
              value={
                ruhReportYear?.months?.[monthConverter(previousMonthDate)]
                  ?.sumRuh
                  ? ruhReportYear.months[monthConverter(previousMonthDate)]
                      .sumRuh
                  : 0
              }
              previous={{
                value: ruhTwoMonthsAgo ? ruhTwoMonthsAgo.sumRuh : 0,
                comparison: 'compare',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'no',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="RUH sum hittil i år"
              icon={faCalendarAlt}
              value={ruhReportYear?.sumRuh ? ruhReportYear.sumRuh : 0}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="SJA"
              icon={faFileInvoice}
              value={
                sjaReportYear?.months?.[monthConverter(previousMonthDate)]
                  ?.sumSja
                  ? sjaReportYear.months[monthConverter(previousMonthDate)]
                      .sumSja
                  : 0
              }
              previous={{
                value: sjaTwoMonthsAgo?.sumSja ? sjaTwoMonthsAgo.sumSja : 0,
                comparison: 'compare',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'yes',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="SJA sum hittil i år"
              icon={faCalendarAlt}
              value={sjaReportYear?.sumSja ? sjaReportYear.sumSja : 0}
            />
          </div>
        </div>
      }
    />
  );
}

export default RUHCard;
