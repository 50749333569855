import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonDialog from 'components/common/CommonDialog';
import CommonTable from 'components/common/CommonTable';

import { format } from 'date-fns';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import theme from 'config/theme';
import CommonDeleteDialog from 'components/common/CommonDeleteDialog';
import EditYmMeasurementDialog from 'components/opsDash/ym/dialogs/EditYmMeasurementDialog';

import { YmPoint } from 'services/ymApi';
import { authorizedYmApi } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '90%', width: '50%' },
    pickers: { display: 'flex', alignItems: 'center', paddingRight: 20 },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

export default function YmHistoricalListDialog({
  ymPoint,
  measurementType,
  refetch,
  open,
  onClose,
}: {
  refetch: () => void;
  ymPoint: YmPoint;
  measurementType: string;
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState(undefined);

  // str.substring(1), removes the first character of str

  const measurementHistoryDateConverter = (
    shortYear: string,
    shortMonth: string,
    shortDay: string
  ) => {
    const year = '20' + shortYear.substring(1);
    const monthZeroIndex = shortMonth.substring(1);
    const day = shortDay.substring(1);

    return new Date(+year, +monthZeroIndex - 1, +day);
  };

  interface historicalData {
    [key: string]: yearData;
  }
  interface yearData {
    [key: string]: monthData;
  }

  interface monthData {
    [key: string]: dayData;
  }
  interface dayData {
    [key: string]: measurement;
  }

  interface measurement {
    date?: string;
    measurementIndication: string;
    unit: string;
    value: string;
    referenceId: string;
  }

  const ymPointMeasurements: (
    historicalDataRecord: historicalData
  ) => object[] = (historicalDataRecord: historicalData) => {
    const ymMeasurements: object[] = [];
    Object.entries(historicalDataRecord).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([month, monthData]) => {
        Object.entries(monthData).forEach(([day, dayData]) => {
          const dayDate = measurementHistoryDateConverter(year, month, day);
          const obj = {
            dateString: format(dayDate, 'dd.MM.yyyy'),
            value: dayData?.[measurementType]?.value,
            dayData,
            date: dayDate,
          };

          dayData?.[measurementType] && ymMeasurements.push(obj);
        });
      });
    });

    return ymMeasurements;
  };

  const reportData =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData);

  const temp = selectedMeasurement as
    | {
        dateString: string;
        value: string;
        dayData: dayData;
        date: Date;
      }
    | undefined;
  const selectedDayData: measurement | undefined =
    temp?.dayData[measurementType];
  return (
    <React.Fragment>
      <CommonDialog
        open={open}
        onClose={onClose}
        title={'Historie liste for ' + measurementType}
        className={classes.root}
        rightHeaderComponent={
          <div className={classes.pickers}>
            <CommonFab
              onClick={() => setDeleteAllDialogOpen(true)}
              icon="trash-2"
            />

            <div style={{ width: 30 }} />
            <CommonFab onClick={onClose} noShadow icon="x" />
          </div>
        }
        dialogContent={
          <div style={{ height: '100%', width: '100%' }}>
            {reportData && reportData.length > 0 ? (
              <CommonTable
                headers={[
                  { id: 'dateString', label: 'Dato' },
                  { id: 'value', label: measurementType + ' verdi' },
                  { id: 'edit', label: 'Rediger' },
                  { id: 'delete', label: 'Slett' },
                ]}
                data={
                  reportData as unknown as {
                    [key: string]: string | number;
                  }[]
                }
                sortable
                onDeleteClick={(data) => {
                  setDeleteDialogOpen(true);
                  setSelectedMeasurement(data);
                }}
                onEditClick={(data) => {
                  setEditDialogOpen(true);
                  setSelectedMeasurement(data);
                }}
              />
            ) : (
              <div className={classes.centeringContainer}>
                <CommonText
                  textVariant="20 medium"
                  className={classes.pickerLabel}
                >
                  Ingen data denne perioden
                </CommonText>
              </div>
            )}
          </div>
        }
      />
      {deleteAllDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteAllDialogOpen}
          onClose={() => {
            setDeleteAllDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onClick={() => {
            authorizedYmApi().then((api) => {
              api
                .deleteYmMeasurementType({
                  pointId: ymPoint.id,
                  measurementType: measurementType,
                })
                .then(refetch);
            });
          }}
          messageText={
            'Er du sikker på at du vil slette all data på dette målepunktet for ' +
            measurementType +
            ' ?'
          }
          buttonText="Ja, slett all data"
        />
      )}
      {deleteDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onClick={() => {
            if (selectedDayData) {
              authorizedYmApi().then((api) => {
                api
                  .deleteYmMeasurement({
                    referenceId: selectedDayData.referenceId,
                    deleteYmMeasurement: {
                      data: { _delete: measurementType },
                    },
                  })
                  .then(refetch);
              });
            }
          }}
          messageText="Er du sikker på at du vil slette denne verdien?"
          buttonText="Ja, slett denne verdien"
        />
      )}
      {ymPoint && editDialogOpen && selectedDayData && (
        <EditYmMeasurementDialog
          isOpened={editDialogOpen}
          ymPointId={ymPoint.id}
          ymPointMeasurementId={ymPoint.measurementPointId}
          selectedMeasurement={selectedMeasurement}
          measurementType={measurementType}
          onClose={() => {
            setEditDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          referenceId={selectedDayData.referenceId}
        />
      )}
    </React.Fragment>
  );
}
