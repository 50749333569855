import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import ValueIndicator from 'components/common/cards/ValueIndicator';

import BarChart from 'components/common/charts/BarChart/BarChart';

import theme from 'config/theme';

import { faCalculator, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import { HMSCounter, NhYearDataObject } from 'services/hmsApi';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'row',

      padding: 10,
    },
    leftElement: {
      flex: 1,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: 50,
      backgroundColor: theme.colors.base.base1,
    },
    middleElement: {
      flex: 3,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    rightElement: {
      flex: 3,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    monthPicker: { paddingRight: 10, width: 130 },
    yearPicker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
    },
    barChart: { flex: 1 },
    valueIndicatior: {
      width: '50%',
      height: '100%',
      padding: 20,
    },
  })
);

interface GraphDataN {
  label: string;
  N?: number;
}

interface GraphDataH {
  label: string;
  H?: number;
}

export default function NAndH({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const classes = useStyles();

  const monthConverter = (date: Date) => {
    const month = format(date, 'M');
    const paddingString = 'm';
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, 'yyyy');
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const initialDate = new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const previousMonthDate = new Date(selectedDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const labelMonthConverter = {
    m1: 'Jan',
    m2: 'Feb',
    m3: 'Mar',
    m4: 'Apr',
    m5: 'Mai',
    m6: 'Jun',
    m7: 'Jul',
    m8: 'Aug',
    m9: 'Sep',
    m10: 'Okt',
    m11: 'Nov',
    m12: 'Des',
  };

  const yearMonthMapper: (year: NhYearDataObject | undefined) => {
    n: GraphDataN[];
    h: GraphDataH[];
  } = (year: NhYearDataObject | undefined) => {
    const nNumbers: GraphDataN[] = [];
    const hNumbers: GraphDataH[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      nNumbers.push({
        label: value,
        ...(year?.months?.[key]?.nValue && {
          'N-verdi': year.months[key].nValue,
        }),
      });
      hNumbers.push({
        label: value,
        ...(year?.months?.[key]?.hValue && {
          'H-verdi': year.months[key].hValue,
        }),
      });
    });
    return { n: nNumbers, h: hNumbers };
  };

  const nhReportYear =
    hmsCounterData?.data?.nh?.years?.[yearConverter(selectedDate)];

  const nhPreviousMonth =
    hmsCounterData?.data?.nh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <Paper className={classes.root}>
      <div className={classes.leftElement}>
        <CommonText textVariant="32 medium" colorOverride="white">
          N og H verdi
        </CommonText>
        <div className={classes.pickerContainer}>
          <CommonText
            textVariant="14 medium"
            colorOverride="white"
            className={classes.pickerLabel}
          >
            År:
          </CommonText>
          <OptionsPicker
            className={classes.yearPicker}
            reverse
            optionsList={yearsListDropdown()}
            initialvalue={previousMonthDate.getFullYear().toString()}
            parentCallback={(e) => {
              const newDate = new Date(selectedDate);
              newDate.setFullYear(+e);
              setSelectedDate(new Date(newDate));
            }}
          />
        </div>
        <div className={classes.pickerContainer}>
          <CommonText
            textVariant="14 medium"
            colorOverride="white"
            className={classes.pickerLabel}
          >
            Periode:
          </CommonText>
          <OptionsPicker
            className={classes.monthPicker}
            reverse
            initialvalue={previousMonthDate.getMonth().toString()}
            optionsList={{
              0: 'Januar',
              1: 'Februar',
              2: 'Mars',
              3: 'April',
              4: 'Mai',
              5: 'Juni',
              6: 'Juli',
              7: 'August',
              8: 'September',
              9: 'Oktober',
              10: 'November',
              11: 'Desember',
            }}
            parentCallback={(e) => {
              const newDate = new Date(selectedDate);
              newDate.setMonth(+e);
              setSelectedDate(new Date(newDate));
            }}
          />
        </div>
      </div>
      <div className={classes.middleElement}>
        <div style={{ width: '100%', display: 'flex', flex: 1 }}>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="N-verdi"
              icon={faCalculator}
              value={
                nhReportYear?.months?.[monthConverter(selectedDate)]?.nValue
              }
              previous={{
                value: nhPreviousMonth?.nValue,
                comparison: 'percentage',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'yes',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="N-verdi snitt hittil i år"
              icon={faCalendarAlt}
              value={
                nhReportYear?.avgN ? parseInt(nhReportYear.avgN) : undefined
              }
            />
          </div>
        </div>
        <CommonText textVariant="14 medium">Så langt i 2021:</CommonText>
        <BarChart
          margin={{ top: 0, bottom: 10, left: 0, right: 10 }}
          className={classes.barChart}
          dataKeys={['N-verdi']}
          colorArray={[theme.colors.indicators.serious as string]}
          data={
            yearMonthMapper(nhReportYear).n as unknown as {
              [key: string]: string | number;
            }[]
          }
        />
      </div>
      <Divider orientation="vertical" />
      <div className={classes.rightElement}>
        <div style={{ width: '100%', display: 'flex' }}>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="H-verdi"
              icon={faCalculator}
              value={
                nhReportYear?.months?.[monthConverter(selectedDate)]?.hValue
              }
              previous={{
                value: nhPreviousMonth?.hValue,
                comparison: 'compare',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'no',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="H-verdi snitt hittil i år"
              icon={faCalendarAlt}
              value={
                nhReportYear?.avgH ? parseInt(nhReportYear.avgH) : undefined
              }
            />
          </div>
        </div>
        <CommonText textVariant="14 medium">Så langt i 2021:</CommonText>
        <BarChart
          margin={{ top: 0, bottom: 10, left: 0, right: 10 }}
          className={classes.barChart}
          dataKeys={['H-verdi']}
          colorArray={[theme.colors.indicators.significant as string]}
          data={
            yearMonthMapper(nhReportYear).h as unknown as {
              [key: string]: string | number;
            }[]
          }
        />
      </div>
    </Paper>
  );
}
