import { useContext } from 'react';
import { setCookie } from 'services/cookieService';
import { SelectedProjectContext } from 'utils/context/SelectedProject';

export default function SessionIdCookie() {
  const { selectedProjectId } = useContext(SelectedProjectContext);

  selectedProjectId && setCookie('project-id', selectedProjectId);

  return null;
}
