import React, { useState } from 'react';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import theme from 'config/theme';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingLeft: 10,
      borderRadius: 20,
      boxShadow: '0px 2px 4px rgba(217, 219, 225, 0.6)',
      color: theme.colors.text.main,
      height: 35,

      backgroundColor: theme.colors.base.white,
      '&:focus': {
        borderRadius: 20,
        backgroundColor: theme.colors.base.background,
        boxShadow: '0px 4px 10px rgba(212, 220, 224, 0.78)',
      },
      fontSize: '10px',
      lineHeight: '12px',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
    },

    menu: {
      borderRadius: 10,
      padding: 10,
      color: theme.colors.text.main,
      fontSize: '10px',
      lineHeight: '12px',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
    },
    menuItem: {
      padding: 2,
      minHeight: 25,
      minWidth: 50,
      [theme.breakpoints.up('fullhd')]: {
        minHeight: 30,
      },
      [theme.breakpoints.up('wqhd')]: {
        minHeight: 40,
      },
      [theme.breakpoints.up('uhd')]: {
        minHeight: 45,
      },
    },
  })
);

function OptionsPicker({
  optionsList,
  parentCallback,
  className,
  reverse,
  initialvalue,
  label,
}: {
  optionsList: object;
  parentCallback: (value: string) => void;
  className?: string;
  reverse?: boolean;
  initialvalue?: string;
  label?: string;
}) {
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState(
    initialvalue
      ? initialvalue
      : reverse
      ? Object.keys(optionsList).reverse()[0]
      : Object.keys(optionsList)[0]
  );

  return (
    <FormControl className={clsx({ [className as string]: className })}>
      <Select
        value={selectedId}
        onChange={(event) => {
          if (event.target.value !== selectedId) {
            setSelectedId(event.target.value as string);
            parentCallback(event.target.value as string);
          }
        }}
        MenuProps={{ classes: { paper: classes.menu } }}
        disableUnderline
        classes={{
          root: classes.root,
        }}
      >
        {label && (
          <MenuItem disabled value="">
            <em>{label}</em>
          </MenuItem>
        )}
        {Object.keys(optionsList).map((optionId) => {
          return (
            <MenuItem
              className={classes.menuItem}
              key={optionId}
              value={optionId}
            >
              {optionsList[optionId]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default OptionsPicker;
