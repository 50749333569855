import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import CommonDialog from 'components/common/CommonDialog';
import CommonButton from 'components/common/CommonButton';
import YmMeasurementTextField from 'components/opsDash/ym/dialogs/YmMeasurementTextField';
import { MeasurementTypes } from 'components/opsDash/ym/MeasurementTypes';
import DateTimePicker from 'components/common/pickers/DateTimePicker';
import { format } from 'date-fns';
import { authorizedYmApi } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '50%',
      height: '90%',
      padding: 12,
    },
    label: {
      paddingLeft: 10,
      paddingTop: 10,
      width: '100%',
    },
    saveButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 50,
    },
    textFields: {
      float: 'left',
      width: '38%',
      display: 'flex',
      flexDirection: 'column',
    },
    measurementTypesDiv: {
      width: '100%',
      display: 'flex',
      height: '100%',
      flexWrap: 'wrap',
    },
    measurementTypesRow: {
      float: 'left',
      width: '22%',
      margin: 10,
    },
    measurementErrorMessage: {
      width: '100%',
      display: 'flex',
      paddingLeft: 10,
      paddingTop: 10,
    },
  })
);

export default function CreateYmMeasurementDialog({
  ymPointMeasurementId,
  ymPointId,
  isOpened,
  onClose,
}: {
  ymPointId: string;
  ymPointMeasurementId: string;
  isOpened: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    data: {},
    date: new Date(),
  });
  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(MeasurementTypes).map((value) => [value.label, true])
  );
  const [vaildations, setValidations] = useState({
    initialStatesThresholdValuesValidation,
  });
  const [measurementErrorMessage, setMeasurementErrorMessage] = useState('');
  const [saved, setSaved] = useState(false);
  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
        break;
      }
    }
    if (countFalse !== 0) {
      setMeasurementErrorMessage(
        'Ugyldige tegn er brukt. Gyldige formater:: <51.5, >51.5, 51.5, <51, >51, 51'
      );
    } else if (Object.keys(formData.data).length === 0) {
      setMeasurementErrorMessage('Du må minst legge til en måleverdi');
    } else if (countFalse === 0 && Object.keys(formData.data).length !== 0) {
      authorizedYmApi().then((api) => {
        api
          .createYmMeasurement({
            pointId: ymPointId,
            newYmMeasurement: {
              measurementPointId: ymPointMeasurementId,
              date: format(formData.date, 'yyyy-MM-dd'),
              data: formData.data,
            },
          })
          .then(() => {
            setSaved(false);
            setFormData({
              data: {},
              date: new Date(),
            });
            setMeasurementErrorMessage('');
            onClose();
          });
      });
    }
    setSaved(false);
  }
  const validThreshold = (value, label) => {
    const re = /^[<>]?[0-9\b]+(\.[0-9]+)?$/;
    if (value === '' || re.test(value)) {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: true,
        },
      });
    } else {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: false,
        },
      });
    }
  };
  return (
    <CommonDialog
      title="Legg til nytt mål"
      className={classes.root}
      dialogContent={
        <div>
          <div className={classes.textFields}>
            <CommonText textVariant="12 light" className={classes.label}>
              Målepunkt Id:
            </CommonText>
            <TextField
              placeholder="MålepunktId..."
              editable={false}
              value={ymPointMeasurementId}
            />
            <CommonText textVariant="12 light" className={classes.label}>
              Dato:
            </CommonText>
            <DateTimePicker
              date={formData.date}
              parentCallback={(date) => {
                setFormData({ ...formData, date });
                setSaved(false);
              }}
            />
            <CommonText textVariant="12 light" className={classes.label}>
              Måletype:
            </CommonText>
          </div>
          <div className={classes.measurementTypesDiv}>
            {MeasurementTypes.map((types, index) => {
              return (
                <div className={classes.measurementTypesRow} key={index}>
                  <YmMeasurementTextField
                    onChange={(value) => {
                      let measurementIndication = 'eq';
                      if (value[0] === '<') {
                        measurementIndication = 'lt';
                      } else if (value[0] === '>') {
                        measurementIndication = 'mt';
                      }
                      validThreshold(value, types.label);
                      if (value === '') {
                        const thresholdObject = Object.fromEntries(
                          Object.entries(formData.data).filter(
                            ([key]) => key !== types.label
                          )
                        );
                        setFormData({
                          ...formData,
                          data: thresholdObject,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          data: {
                            ...formData.data,
                            [types.label]: {
                              unit: types.unit,
                              value,
                              measurementIndication,
                            },
                          },
                        });
                        setMeasurementErrorMessage('');
                      }
                      setSaved(false);
                    }}
                    label={types.label}
                    unit={types.unit}
                    error={
                      !vaildations.initialStatesThresholdValuesValidation[
                        types.label
                      ]
                    }
                  />
                </div>
              );
            })}
          </div>
          {measurementErrorMessage !== '' ? (
            <div className={classes.measurementErrorMessage}>
              <CommonText textVariant="12 light">
                {measurementErrorMessage}
              </CommonText>
            </div>
          ) : (
            ''
          )}
          <div className={classes.saveButton}>
            <CommonButton
              onClick={() => {
                if (!saved) {
                  setSaved(true);
                  onSaveClick();
                }
              }}
              text="Legg til ny måledata"
            />
          </div>
        </div>
      }
      onClose={() => {
        setSaved(false);
        setValidations({
          initialStatesThresholdValuesValidation,
        });
        setFormData({
          data: {},
          date: new Date(),
        });
        setMeasurementErrorMessage('');
        onClose();
      }}
      open={isOpened}
    />
  );
}
