import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as materialColors from '@mui/material/colors';
import CustomizedAxisTick from 'components/common/charts/CustomizedAxisTick';
import { calculateGradientOffset } from 'components/common/charts/graphUtils';

import theme from 'config/theme';

export default function ThresholdAreaChart({
  data,
  xKey,
  yKey,

  gradientThreshold,
}: {
  data: object[];
  xKey: string;
  yKey: string;

  gradientThreshold: number | undefined;
}) {
  return (
    <ResponsiveContainer height="99%" width="99%">
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="9 9" />
        <XAxis tick={<CustomizedAxisTick />} dataKey={xKey} />
        <YAxis />
        <Tooltip
          labelStyle={{
            color: 'black',
          }}
          itemStyle={{
            color: 'black',
          }}
          wrapperStyle={{
            backgroundColor: 'white',
            borderRadius: '4px',
          }}
        />

        {gradientThreshold && (
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={calculateGradientOffset(data, yKey, gradientThreshold)}
                stopColor={theme.colors.indicators.serious}
                stopOpacity={1}
              />
              <stop
                offset={calculateGradientOffset(data, yKey, gradientThreshold)}
                stopColor={theme.colors.base.base1}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
        )}

        <Area
          stackId="1"
          type="monotone"
          dataKey={yKey}
          stroke={materialColors.grey[900]}
          fill={
            gradientThreshold
              ? 'url(#splitColor)'
              : theme.colors.indicators.neutral
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
