import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonDialog from 'components/common/CommonDialog';
import CommonTable from 'components/common/CommonTable';

import { format } from 'date-fns';

import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import CommonFab from 'components/common/CommonFab';
import { CircularProgress } from '@mui/material';
import theme from 'config/theme';
import CommonDeleteDialog from 'components/common/CommonDeleteDialog';
import { authorizedDeleteReportApi } from 'services/apiService';

import { HMSCounter, SjaMonthDataObject, SJAReport } from 'services/hmsApi';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '90%', width: '50%' },
    pickers: { display: 'flex', alignItems: 'center', paddingRight: 20 },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

export default function SjaReportDialog({
  hmsCounterData,
  isLoading,
  refetch,
}: {
  hmsCounterData: HMSCounter | undefined;
  isLoading: boolean;
  refetch: () => void;
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, 'M')
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, 'yyyy')
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSjaReportId, setDeleteSjaReportId] = useState('');

  const monthStringConverter = (month: string) => {
    const paddingString = 'm';
    return paddingString.concat(month);
  };

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const sjaEventsInMonth: (month: SjaMonthDataObject) => SJAReport[] = (
    month: SjaMonthDataObject
  ) => {
    // Can get the events in scrabled order. Solved by using sortable table.

    const sjaEvents: SJAReport[] = [];
    Object.values(month.days).forEach((events: SJAReport[]) => {
      events.forEach((e) => {
        sjaEvents.push(e);
      });
    });

    return sjaEvents;
  };

  const reportDataMonth =
    hmsCounterData?.data?.sja?.years?.[yearStringConverter(selectedYear)]
      ?.months?.[monthStringConverter(selectedMonth)];
  const reportData = reportDataMonth && sjaEventsInMonth(reportDataMonth);

  return (
    <React.Fragment>
      <CommonFab
        noShadow
        tooltip={{ placement: 'right', text: 'Månedsrapport SJA' }}
        buttonSize="large"
        icon="list"
        onClick={() => {
          setOpen(true);
        }}
      />
      <CommonDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Månedsrapport SJA"
        className={classes.root}
        rightHeaderComponent={
          <div className={classes.pickers}>
            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="12 light"
                className={classes.pickerLabel}
              >
                År:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                optionsList={yearsListDropdown()}
                initialvalue={selectedYear}
                parentCallback={(value) => {
                  setSelectedYear(value);
                }}
              />
            </div>
            <div style={{ width: 50 }} />
            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="12 light"
                className={classes.pickerLabel}
              >
                Periode:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                optionsList={{
                  '1': 'Januar',
                  '2': 'Februar',
                  '3': 'Mars',
                  '4': 'April',
                  '5': 'Mai',
                  '6': 'Juni',
                  '7': 'Juli',
                  '8': 'August',
                  '9': 'Septemper',
                  '10': 'Oktober',
                  '11': 'November',
                  '12': 'Desember',
                }}
                parentCallback={(value) => {
                  setSelectedMonth(value);
                }}
                initialvalue={selectedMonth}
              />
            </div>
            <div style={{ width: 50 }} />
            <CommonFab onClick={() => setOpen(false)} noShadow icon="x" />
          </div>
        }
        dialogContent={
          <div style={{ height: '100%', width: '100%' }}>
            {isLoading ? (
              <div className={classes.centeringContainer}>
                <CircularProgress
                  size={150}
                  classes={{ circle: classes.spinnerCircle }}
                />
              </div>
            ) : reportData && reportData.length > 0 ? (
              <CommonTable
                headers={[
                  { id: 'sjaNr', label: 'Nummer' },
                  { id: 'date', label: 'Dato' },
                  { id: 'sjaType', label: 'Type' },
                  { id: 'company', label: 'Firma' },
                  { id: 'delete', label: '' },
                ]}
                data={
                  reportData as unknown as {
                    [key: string]: string | number;
                  }[]
                }
                sortable
                onDeleteClick={(data) => {
                  setDeleteDialogOpen(true);
                  setDeleteSjaReportId(data.reportId);
                }}
              />
            ) : (
              <div className={classes.centeringContainer}>
                <CommonText
                  textVariant="20 medium"
                  className={classes.pickerLabel}
                >
                  Ingen data denne perioden
                </CommonText>
              </div>
            )}
          </div>
        }
      />
      {deleteDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteDialogOpen}
          onClose={() => {
            setDeleteSjaReportId('');
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onClick={() => {
            authorizedDeleteReportApi().then((api) => {
              api
                .deleteSjaReport({ sjaReportId: deleteSjaReportId })
                .then(refetch);
            });
          }}
          messageText="Er du sikker på at du vil slette denne raden?"
          buttonText="Ja, slett denne raden"
        />
      )}
    </React.Fragment>
  );
}
