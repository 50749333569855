import React, { useEffect, useState, useCallback, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import theme from 'config/theme';
import { ButtonBase } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import Avatar from 'components/common/Avatar';
import { useQueryCognitoUser } from 'services/deliveryQueryHooks';
import CustomerSupport from 'components/common/CustomerSupport';
import { useLocation } from 'react-router';
import { GeneralContext } from 'utils/context/General';

import ametaLogo from 'assets/img/Ameta-logo-hvit-flat_1.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '15%',
      position: 'relative',
      height: '100%',
    },
    mainMenu: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 5,
      position: 'relative',
      backgroundColor: theme.colors.base.base1dark,
    },
    subMenuRoot: {
      position: 'absolute',
      top: 0,
      left: '100%',
      height: '100%',
      width: '100%',
      background: theme.colors.base.base1,
      zIndex: 3,
    },
    subMenu: {
      height: '100%',
      width: '100%',
      background:
        'linear-gradient(180deg, #3C4F87 46.94%, rgba(60, 79, 135, 0) 151.57%)', // if this can be combined with background of subMenuRoot, they can be reduced to one div
      paddingTop: 140,
    },
    mainMenuButtonContainer: { width: '100%', paddingTop: 20 },
    mainMenuButton: {
      '&:hover': {
        backgroundColor: theme.colors.base.base1darker,
      },
    },
    toggledMainMenuButton: {
      backgroundColor: theme.colors.base.base1darker,
      color: theme.colors.base.base2,
    },
    subMenuButton: {
      '&:hover': {
        backgroundColor: 'rgba(14, 38, 67, 0.3)', // same as #0E2643 with 0.3 in opacity
      },
    },
    toggledSubMenuButton: {
      backgroundColor: 'rgba(14, 38, 67, 0.3)',
      color: theme.colors.base.base2,
    },
    logo: {
      width: '100%',
      padding: 30,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    showMenu: {
      position: 'absolute',
      top: 10,
      left: 10,
    },
  })
);

const StyledButton = withStyles({
  root: {
    border: 0,
    height: 41,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})(ButtonBase);

interface SubMenuItem {
  title: string;
  icon: string;
  path: string;
}
export interface MainMenuItem {
  title: string;
  icon: string;
  path?: string;
  submenu?: SubMenuItem[];
}

export default function DesktopNavBar({
  MainMenuItems,
  hideableMenu,
}: {
  MainMenuItems: MainMenuItem[];
  hideableMenu?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const cognitoUserQuery = useQueryCognitoUser();
  const cognitoUser = cognitoUserQuery.data;

  const { generalState, setGeneralState } = useContext(GeneralContext);

  const [selectedMainMenuIndex, setSelectedMainMenuIndex] =
    useState<number>(-1);
  const [selectedSubMenuIndex, setSelectedSubMenuIndex] = useState<number>(-1);
  const [openSubmenu, setOpenSubmenu] = useState<boolean>(false);

  const mainMenuOnClick = (mainMenuIndex: number, path?: string) => {
    setSelectedMainMenuIndex(mainMenuIndex);
    setOpenSubmenu(MainMenuItems[mainMenuIndex].submenu ? !openSubmenu : false);

    if (path) {
      history.push(path);
    }
  };

  const subMenuOnClick = (selectedSubMenuIndex: number, path: string) => {
    setSelectedSubMenuIndex(selectedSubMenuIndex);
    setOpenSubmenu(false);
    history.push(path);
  };

  const indexFinder = (menuArray, searchTerm) => {
    let mainMenuIndex = -1;

    mainMenuIndex = menuArray.findIndex(
      (menuItem) => menuItem.path === searchTerm
    );
    let subMenuIndex = -1;

    menuArray.forEach((menuItem, index) => {
      if (menuItem.submenu) {
        subMenuIndex = menuItem.submenu.findIndex(
          (subMenuItem) => subMenuItem.path === searchTerm
        );
        if (subMenuIndex !== -1) {
          mainMenuIndex = index;
        }
      }
    });

    return [mainMenuIndex, subMenuIndex];
  };

  const setMainMenuAndSubMenuOnPathName = useCallback(
    (pathName: string) => {
      const indecies = indexFinder(MainMenuItems, pathName);

      setSelectedMainMenuIndex(indecies[0]);
      setSelectedSubMenuIndex(indecies[1]);
    },
    [MainMenuItems]
  );

  let iconSize = 20;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 30;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 40;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 50;
  }

  const mainMenuButtonGenerator = (mainMenuArray: MainMenuItem[]) => {
    return mainMenuArray.map((element, index) => (
      <StyledButton
        className={
          index === selectedMainMenuIndex
            ? classes.toggledMainMenuButton
            : classes.mainMenuButton
        }
        onClick={() => {
          mainMenuOnClick(index, element.path);
        }}
        key={'mainMenu' + index}
      >
        <div
          style={{
            height: '100%',
            width: 4,
            backgroundColor:
              index === selectedMainMenuIndex
                ? theme.colors.base.base2
                : undefined,
          }}
        />
        <div // Padding hack, as padding on FeatherIcon does not work as expected
          style={{
            height: '100%',
            width: 15,
          }}
        />
        <FeatherIcon
          icon={element.icon}
          style={{
            iconSize: iconSize,
            color:
              index === selectedMainMenuIndex
                ? theme.colors.base.base2
                : theme.colors.base.grey,
          }}
        />

        <CommonText
          style={{ paddingLeft: 8 }}
          noWrap={true}
          colorOverride={index === selectedMainMenuIndex ? 'positive' : 'light'}
          textVariant={
            index === selectedMainMenuIndex ? '12 medium' : '12 light'
          }
        >
          {element.title}
        </CommonText>
      </StyledButton>
    ));
  };

  const subMenuButtonGenerator = (subMenuArray: SubMenuItem[]) => {
    return subMenuArray.map((element, index) => (
      <StyledButton
        className={
          index === selectedSubMenuIndex
            ? classes.toggledSubMenuButton
            : classes.subMenuButton
        }
        onClick={() => {
          subMenuOnClick(index, element.path);
        }}
        key={'subMenu' + index}
      >
        <div // Padding hack, as padding on FeatherIcon does not work as expected
          style={{
            height: '100%',
            width: 25,
          }}
        />
        <FeatherIcon
          icon={element.icon}
          style={{
            iconSize: iconSize,
            color:
              index === selectedSubMenuIndex
                ? theme.colors.base.base2
                : theme.colors.base.grey,
          }}
        />
        <CommonText
          style={{ paddingLeft: 8 }}
          noWrap={true}
          colorOverride={index === selectedSubMenuIndex ? 'positive' : 'light'}
          textVariant={
            index === selectedSubMenuIndex ? '12 medium' : '12 light'
          }
        >
          {element.title}
        </CommonText>
      </StyledButton>
    ));
  };

  useEffect(() => {
    setMainMenuAndSubMenuOnPathName(location.pathname);
  }, [location, setMainMenuAndSubMenuOnPathName]);

  const subMenuElements = MainMenuItems[selectedMainMenuIndex]
    ? MainMenuItems[selectedMainMenuIndex].submenu
    : undefined;

  return !generalState.hideMenus ? (
    <div className={classes.root}>
      <div className={classes.mainMenu}>
        <div style={{ width: '100%' }}>
          <img className={classes.logo} alt="Ameta logo" src={ametaLogo} />
          <div className={classes.mainMenuButtonContainer}>
            {mainMenuButtonGenerator(MainMenuItems)}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingBottom: 50, paddingTop: 50 }} />
          {hideableMenu && (
            <CommonFab
              icon="minimize-2"
              tooltip={{ placement: 'right', text: 'Minimer' }}
              onClick={() => {
                setGeneralState((prevState) => {
                  return { ...prevState, hideMenus: true };
                });
              }}
            />
          )}
          <div style={{ paddingBottom: 10, paddingTop: 10 }} />
          <Avatar imageURL={cognitoUser && cognitoUser.imageUrl} />
          <div style={{ paddingBottom: 50, paddingTop: 50 }}>
            <CustomerSupport />
          </div>
        </div>
      </div>
      <div
        className={classes.subMenuRoot}
        style={{
          transition: 'left 250ms ease-out',
          left: openSubmenu ? '100%' : '0%',
        }}
      >
        <div className={classes.subMenu}>
          {subMenuElements && subMenuButtonGenerator(subMenuElements)}
        </div>
      </div>
    </div>
  ) : null;
}
