import React, { useState } from 'react';

import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';

import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import CommonTable from 'components/common/CommonTable';

import theme from 'config/theme';
import { SecurityCounter, SecurityYearObject } from 'services/securityApi';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      border: 'solid',
      backgroundColor: theme.colors.base.base1,
      borderColor: 'white',
      borderWidth: 5,
    },
    paperHeader: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      flex: 10,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      backgroundColor: theme.colors.base.background,
    },
    bodyLeftElement: {
      flex: 1,
      padding: 20,
      paddingRight: 10,
      height: '100%',
    },
    bodyRightElement: {
      flex: 1,
      padding: 20,
      paddingLeft: 10,
      height: '100%',
    },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    overviewCard: {
      height: '100%',
      width: '100%',
      borderRadius: 10,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const labelMonthConverter = {
  m1: 'Januar',
  m2: 'Februar',
  m3: 'Mars',
  m4: 'April',
  m5: 'Mai',
  m6: 'Juni',
  m7: 'Juli',
  m8: 'August',
  m9: 'September',
  m10: 'Oktober',
  m11: 'November',
  m12: 'Desember',
};

interface MonthOverviewData {
  id: string;
  monthlyOverview: number;
  soFarSelectedYear: number;
}

function MonthlyOverview({
  securityCounterData,
  isLoading,
}: {
  securityCounterData: SecurityCounter | undefined;
  isLoading: boolean;
}) {
  const classes = useStyles();

  const monthStringConverter = (month: string) => {
    const paddingString = 'm';
    return paddingString.concat(month);
  };
  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const initialMonth = monthStringConverter(format(previousMonthDate, 'M'));
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, 'yyyy')
  );

  const accidentsWithInjuriesMapping = {
    accidentsWithLightPersonalInjuries: 'Lettere skadet',
    accidentsWithSeriousPersonalInjuries: 'Alvorlig skadet',
    accidentsWithSignificantPersonalInjuries: 'Meget alvorlig skadet',
    fatalAccidents: 'Drepte',
  };
  const accidentsWithoutInjuriesMapping = {
    hitRailings: 'Påkjørt rekkverk',
    otherAccidents: 'Andre ulykker uten personskade',
  };

  const secuityNumbersYearMonthMapper: (
    year: SecurityYearObject | undefined,
    selectedMonth: string,
    mappingDict: { [key: string]: string }
  ) => MonthOverviewData[] = (
    year: SecurityYearObject | undefined,
    selectedMonth: string,
    mappingDict: { [key: string]: string }
  ) => {
    const injuries: MonthOverviewData[] = [];
    Object.entries(mappingDict).forEach(([key, value]) => {
      injuries.push({
        id: value,
        monthlyOverview: year?.months?.[selectedMonth]?.[key],
        soFarSelectedYear: year?.[key],
      });
    });
    return injuries;
  };

  const reportYear =
    securityCounterData?.data?.years?.[yearStringConverter(selectedYear)];

  const OverviewCard = ({
    title,
    tableContent,
  }: {
    title: string;
    tableContent: { [key: string]: number | string }[];
  }) => {
    return (
      <Paper className={classes.overviewCard}>
        <CommonText textVariant="20 medium">{title}</CommonText>
        {isLoading ? (
          <div className={classes.centeringContainer}>
            <CircularProgress
              size={150}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        ) : (
          <div style={{ height: '100%', paddingTop: 30 }}>
            <CommonTable
              headers={[
                { id: 'id', label: '' },
                { id: 'monthlyOverview', label: 'Månedsoverskit' },
                {
                  id: 'soFarSelectedYear',
                  label: selectedYear + ' oppsumert',
                },
              ]}
              data={tableContent}
            />
          </div>
        )}
      </Paper>
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderTop}>
          <CommonText textVariant="20 medium" colorOverride="white">
            Månedsoversikt
          </CommonText>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="14 medium"
                colorOverride="white"
                className={classes.pickerLabel}
              >
                Periode:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                initialvalue={initialMonth}
                optionsList={labelMonthConverter}
                parentCallback={setSelectedMonth}
              />
            </div>

            <div className={classes.pickerContainer}>
              <CommonText
                textVariant="14 medium"
                colorOverride="white"
                className={classes.pickerLabel}
              >
                År:
              </CommonText>
              <OptionsPicker
                className={classes.picker}
                reverse
                optionsList={yearsListDropdown()}
                initialvalue={selectedYear}
                parentCallback={(value) => {
                  setSelectedYear(value);
                }}
              />
            </div>
          </div>
        </div>

        <Divider />
      </div>
      <div className={classes.paperBody}>
        <div className={classes.bodyLeftElement}>
          <OverviewCard
            title="Ulykker med personskade"
            tableContent={
              secuityNumbersYearMonthMapper(
                reportYear,
                selectedMonth,
                accidentsWithInjuriesMapping
              ) as unknown as {
                [key: string]: string | number;
              }[]
            }
          />
        </div>
        <div className={classes.bodyRightElement}>
          <OverviewCard
            title="Ulykker uten personskade"
            tableContent={
              secuityNumbersYearMonthMapper(
                reportYear,
                selectedMonth,
                accidentsWithoutInjuriesMapping
              ) as unknown as {
                [key: string]: string | number;
              }[]
            }
          />
        </div>
      </div>
    </Paper>
  );
}

export default MonthlyOverview;
