import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { GeneralContext } from 'utils/context/General';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      paddingTop: 60,
      backgroundColor: theme.colors.base.white,
    },
  })
);

export default function PageMenu({
  menuItems,
}: {
  menuItems: React.ReactElement[];
}) {
  const pageMenuGenerator = (mainMenuArray: React.ReactElement[]) => {
    return mainMenuArray.map((e, index) => (
      <React.Fragment key={'MenuItems' + index}>{e}</React.Fragment>
    ));
  };
  const { generalState } = useContext(GeneralContext);
  const classes = useStyles();
  return (
    <React.Fragment>
      {!generalState.hideMenus && (
        <div className={classes.root}>{pageMenuGenerator(menuItems)}</div>
      )}
    </React.Fragment>
  );
}
