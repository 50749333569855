import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileVideo,
  faFileImage,
  faFilePowerpoint,
} from '@fortawesome/free-regular-svg-icons';

import theme from 'config/theme';

const excelExtensions = [
  'xls',
  'xlt',
  'xlm',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'xla',
  'xlam',
  'xll',
  'xlw',
];
const wordExtensions = [
  'doc',
  'dot',
  'wbk',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'docb',
];

const powerpointExtensions = [
  'ppt',
  'pot',
  'pps',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppam',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm',
];

const videoExtensions = [
  'webm',
  'mkv',
  'vob',
  'ogv',
  'ogg',
  'drc',
  'gif',
  'gifv',
  'mng',
  'avi',
  'mts',
  'm2ts',
  'ts',
  'mov',
  'qt',
  'wmv',
  'yuv',
  'rm',
  'rmvb',
  'asf',
  'amv',
  'mp4',
  'm4p',
  'mp2',
  'mpe',
  'mpv',
  'mpg',
  'mpeg',
  'm2v',
  'm4v',
  'svi',
  '3gp',
  '3g2',
  'mxf',
  'roq',
  'nsv',
  'flv',
  'f4v',
  'f4p',
  'f4a',
  'f4b',
];

const imageExtensions = [
  'img',
  'bmp',
  'jpeg',
  'jfif',
  'png',
  'jpg',
  'svg',
  'tif',
  'tiff',
  'webp',
];

const isWordFile: (extension: string) => boolean = (extension: string) => {
  return wordExtensions.includes(extension);
};

const isExcelFile: (extension: string) => boolean = (extension: string) => {
  return excelExtensions.includes(extension);
};

const isPowerpointFile: (extension: string) => boolean = (
  extension: string
) => {
  return powerpointExtensions.includes(extension);
};

const isVideoFile: (extension: string) => boolean = (extension: string) => {
  return videoExtensions.includes(extension);
};

const isImageFile: (extension: string) => boolean = (extension: string) => {
  return imageExtensions.includes(extension);
};

const getDocumentIcon: (
  fileName: string
) => {
  icon: IconDefinition;
  color: string;
} = (fileName: string): { icon: IconDefinition; color: string } => {
  const extension = fileName.split('.').slice(-1)[0].toLowerCase();
  let icon: IconDefinition = faFileAlt;
  let color = theme.colors.icons.noState.light as string;

  if (isWordFile(extension)) {
    icon = faFileWord;
    color = '#2362bf';

    return { icon, color };
  }

  if (isExcelFile(extension)) {
    icon = faFileExcel;

    return { icon, color };
  }

  if (isPowerpointFile(extension)) {
    icon = faFilePowerpoint;

    return { icon, color };
  }

  if (isVideoFile(extension)) {
    icon = faFileVideo;

    return { icon, color };
  }

  if (isImageFile(extension)) {
    icon = faFileImage;
    color = '#c66fc0';
    return { icon, color };
  }

  switch (extension) {
    case 'pdf':
      icon = faFilePdf;
      color = '#F40F02';
      break;
    case 'csv':
      icon = faFileCsv;
      color = '#067d00';

      break;
    default:
      icon = faFileAlt;
  }

  return { icon, color };
};

export default getDocumentIcon;
