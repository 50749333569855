import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import CommonDialog from 'components/common/CommonDialog';
import CommonButton from 'components/common/CommonButton';
import YmMeasurementTextField from 'components/opsDash/ym/dialogs/YmMeasurementTextField';
import { MeasurementTypes } from 'components/opsDash/ym/MeasurementTypes';
import { format } from 'date-fns';
import { authorizedYmApi } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '50%',
      height: '90%',
      padding: 12,
    },
    label: {
      paddingLeft: 10,
      paddingTop: 10,
      width: '100%',
    },
    saveButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 50,
    },
    textFields: {
      float: 'left',
      width: '38%',
      display: 'flex',
      flexDirection: 'column',
    },
    measurementTypesDiv: {
      width: '100%',
      display: 'flex',
      height: '100%',
      flexWrap: 'wrap',
    },
    measurementTypesRow: {
      float: 'left',
      width: '22%',
      margin: 10,
    },
    measurementErrorMessage: {
      width: '100%',
      display: 'flex',
      paddingLeft: 10,
      paddingTop: 10,
    },
  })
);

interface dayData {
  [key: string]: measurementGathered;
}
interface measurement {
  date: Date;
  dateString: string;
  value?: string;
  dayData: dayData;
  measurementType: string;
}

interface measurementGathered {
  date?: string;
  measurementIndication: string;
  unit: string;
  value: string;
  measurementType: string;
  referenceId: string;
}

export default function EditYmMeasurementDialog({
  ymPointMeasurementId,
  ymPointId,
  isOpened,
  onClose,
  selectedMeasurement,
  measurementType,
  referenceId,
  editAll,
}: {
  ymPointId: string;
  ymPointMeasurementId: string;
  isOpened: boolean;
  onClose: () => void;
  selectedMeasurement?: measurement;
  measurementType?: string;
  referenceId: string;
  editAll?: boolean;
}) {
  const classes = useStyles();

  const initialFormData = () => {
    let finalObject: {
      [key: string]: {
        unit?: string;
        value?: string;
        measurementIndication?: string;
        referenceId: string;
      };
    } = {};
    MeasurementTypes.forEach((item) => {
      let measurementIndication = 'eq';
      if (selectedMeasurement?.dayData?.[item.label]?.value[0] === '<') {
        measurementIndication = 'lt';
      } else if (selectedMeasurement?.dayData?.[item.label]?.value[0] === '>') {
        measurementIndication = 'mt';
      }

      if (selectedMeasurement?.dayData?.[item.label]) {
        finalObject = {
          ...finalObject,
          [item.label]: {
            unit: item.unit,
            value: selectedMeasurement?.dayData?.[item.label]?.value,
            referenceId:
              selectedMeasurement?.dayData?.[item.label]?.referenceId,
            measurementIndication,
          },
        };
      }
    });
    return { data: finalObject };
  };

  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(MeasurementTypes).map((value) => [value.label, true])
  );

  const [formData, setFormData] = useState(initialFormData());
  const [vaildations, setValidations] = useState({
    initialStatesThresholdValuesValidation,
  });
  const [measurementErrorMessage, setMeasurementErrorMessage] = useState('');
  const [saved, setSaved] = useState(false);

  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
        break;
      }
    }
    if (countFalse !== 0) {
      setMeasurementErrorMessage(
        'Ugyldige tegn er brukt. Gyldige formater:: <51.5, >51.5, 51.5, <51, >51, 51'
      );
    } else if (Object.keys(formData.data).length === 0) {
      setMeasurementErrorMessage('Du må minst legge til en måleverdi');
    } else if (
      countFalse === 0 &&
      Object.keys(formData.data).length !== 0 &&
      selectedMeasurement?.date
    ) {
      editAll
        ? authorizedYmApi().then((api) => {
            api
              .createYmMeasurement({
                pointId: ymPointId,
                newYmMeasurement: {
                  measurementPointId: ymPointMeasurementId,
                  data: formData.data as { [key: string]: object },
                  date: format(selectedMeasurement?.date, 'yyyy-MM-dd'),
                },
              })
              .then(() => {
                setSaved(false);
                setFormData({
                  data: {},
                });
                setMeasurementErrorMessage('');
                onClose();
              });
          })
        : authorizedYmApi().then((api) => {
            api
              .updateYmMeasurement({
                referenceId,
                updateYmMeasurement: {
                  data: formData.data as { [key: string]: object },
                },
              })
              .then(() => {
                setSaved(false);
                setFormData({
                  data: {},
                });
                setMeasurementErrorMessage('');
                onClose();
              });
          });
    }
    setSaved(false);
  }
  const validThreshold = (value, label) => {
    const re = /^[<>]?[0-9\b]+(\.[0-9]+)?$/;
    if (value === '' || re.test(value)) {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: true,
        },
      });
    } else {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: false,
        },
      });
    }
  };

  const handleOnClose = () => {
    setSaved(false);
    setValidations({
      initialStatesThresholdValuesValidation,
    });
    setFormData({
      data: {},
    });
    setMeasurementErrorMessage('');
    onClose();
  };

  return (
    <React.Fragment>
      <CommonDialog
        title={
          editAll
            ? 'Rediger måledata'
            : 'Rediger måledata for ' + measurementType
        }
        className={classes.root}
        dialogContent={
          <div>
            <div className={classes.textFields}>
              <CommonText textVariant="12 light" className={classes.label}>
                Målepunkt Id:
              </CommonText>
              <TextField
                placeholder="MålepunktId..."
                editable={false}
                value={ymPointMeasurementId}
              />
              <CommonText textVariant="12 light" className={classes.label}>
                Dato:
              </CommonText>
              <TextField
                placeholder="MålepunktId..."
                editable={false}
                value={selectedMeasurement?.dateString}
              />
              <CommonText textVariant="12 light" className={classes.label}>
                Måletype:
              </CommonText>
            </div>
            <div className={classes.measurementTypesDiv}>
              {editAll
                ? MeasurementTypes.map((types, index) => {
                    return (
                      <div className={classes.measurementTypesRow} key={index}>
                        <YmMeasurementTextField
                          onChange={(value) => {
                            let measurementIndication = 'eq';
                            if (value[0] === '<') {
                              measurementIndication = 'lt';
                            } else if (value[0] === '>') {
                              measurementIndication = 'mt';
                            }
                            validThreshold(value, types.label);
                            if (value === '') {
                              const thresholdObject = Object.fromEntries(
                                Object.entries(formData.data).filter(
                                  ([key]) => key !== types.label
                                )
                              );
                              setFormData({
                                ...formData,
                                data: thresholdObject,
                              });
                            } else {
                              selectedMeasurement &&
                                setFormData({
                                  ...formData,
                                  data: {
                                    ...formData.data,
                                    [types.label]: {
                                      unit: types.unit,
                                      value,
                                      measurementIndication,
                                      referenceId: referenceId,
                                    },
                                  },
                                });
                              setMeasurementErrorMessage('');
                            }
                            setSaved(false);
                          }}
                          label={types.label}
                          unit={types.unit}
                          error={
                            !vaildations.initialStatesThresholdValuesValidation[
                              types.label
                            ]
                          }
                          defaultValue={
                            selectedMeasurement?.dayData?.[types.label]?.value
                          }
                        />
                      </div>
                    );
                  })
                : measurementType &&
                  selectedMeasurement && (
                    <div className={classes.measurementTypesRow}>
                      <YmMeasurementTextField
                        onChange={(value) => {
                          let measurementIndication = 'eq';
                          if (value[0] === '<') {
                            measurementIndication = 'lt';
                          } else if (value[0] === '>') {
                            measurementIndication = 'mt';
                          }
                          validThreshold(value, measurementType);
                          if (value === '') {
                            const thresholdObject = Object.fromEntries(
                              Object.entries(formData.data).filter(
                                ([key]) => key !== measurementType
                              )
                            );
                            setFormData({
                              ...formData,
                              data: thresholdObject,
                            });
                          } else {
                            selectedMeasurement &&
                              setFormData({
                                ...formData,
                                data: {
                                  ...formData.data,
                                  [measurementType]: {
                                    unit: selectedMeasurement.dayData[
                                      measurementType
                                    ].unit,
                                    value,
                                    measurementIndication,
                                    referenceId: referenceId,
                                  },
                                },
                              });
                            setMeasurementErrorMessage('');
                          }
                          setSaved(false);
                        }}
                        label={measurementType}
                        unit={selectedMeasurement.dayData[measurementType].unit}
                        error={
                          !vaildations.initialStatesThresholdValuesValidation[
                            measurementType
                          ]
                        }
                        defaultValue={
                          selectedMeasurement?.dayData?.[measurementType]?.value
                        }
                      />
                    </div>
                  )}
            </div>
            {measurementErrorMessage !== '' ? (
              <div className={classes.measurementErrorMessage}>
                <CommonText textVariant="12 light">
                  {measurementErrorMessage}
                </CommonText>
              </div>
            ) : (
              ''
            )}
            <div className={classes.saveButton}>
              <CommonButton
                onClick={() => {
                  if (!saved) {
                    setSaved(true);
                    onSaveClick();
                  }
                }}
                text="Rediger måledata"
              />
            </div>
          </div>
        }
        onClose={() => {
          handleOnClose();
        }}
        open={isOpened}
      />
    </React.Fragment>
  );
}
