import React from 'react';
import { GeoJSON } from 'react-leaflet';
import * as L from 'leaflet';
import { NvdbObject } from 'services/nvdbApi';

function NvdbMarkers({
  nvdbData,
  color,
  markerIcon,
}: {
  nvdbData: NvdbObject[];
  color:
    | 'red'
    | 'darkred'
    | 'orange'
    | 'green'
    | 'darkgreen'
    | 'blue'
    | 'purple'
    | 'darkpurple'
    | 'cadetblue'
    | undefined;
  markerIcon: string;
}) {
  if (!nvdbData) {
    return null;
  }

  return (
    <>
      {nvdbData.map((point, index) => {
        return (
          <GeoJSON
            key={index}
            fillColor={color}
            data={
              {
                geometry: {
                  coordinates: [
                    point.centerPoint?.longitude,
                    point.centerPoint?.latitude,
                  ],
                  type: 'Point',
                },
                type: 'Feature',
              } as GeoJSON.Feature
            }
            color={color}
            pointToLayer={(_, latlng) => {
              const marker = L.marker(latlng, {
                icon: L.AwesomeMarkers.icon({
                  icon: markerIcon,
                  markerColor: color,
                  prefix: 'fa',
                }),
              });
              return marker;
            }}
          />
        );
      })}
    </>
  );
}

export default NvdbMarkers;
