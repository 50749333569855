import React, { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';

import FederatedSignIn from 'components/frontPage/FederatedSignIn';
import CognitoSignIn from 'components/frontPage/CognitoSignIn';
import ForgotPassword from 'components/frontPage/ForgotPassword';
import {
  useQueryCognitoUser,
  useQueryDynamodbUser,
} from 'services/deliveryQueryHooks';

import theme from 'config/theme';
import { queryClient } from 'services/apiService';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    signInCardTop: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    signInCardMiddle: {
      height: '105%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    signInCardBottom: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    signOutButton: {
      width: 200,
      backgroundColor: theme.colors.messageAndAlerts.notificationInfo,
      color: 'white',
    },
    spinner: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },

    signInInputs: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 100,
    },
    resetPassword: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    resetPasswordText: { paddingBottom: 50 },
    loginText: {
      paddingBottom: 35,
    },
    backButton: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: '#768AA3',
      width: 200,
    },
  })
);

function SignInContent() {
  const classes = useStyles();
  const cognitoUserQuery = useQueryCognitoUser();
  const cognitoUser = cognitoUserQuery.data;
  const dynamodbUserQuery = useQueryDynamodbUser();
  const dynamodbUser = dynamodbUserQuery.data;
  const [forgotPassword, setForgotPassword] = useState(false);
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <div className={classes.root}>
      <div className={classes.signInCardTop}>
        <CommonText textVariant="20 medium">Hjem</CommonText>
        <div style={{ height: 100 }} />
      </div>
      <div className={classes.signInCardMiddle}>
        {cognitoUser && !dynamodbUser ? (
          <React.Fragment>
            {dynamodbUserQuery.isLoading ? (
              <div className={classes.spinner}>
                <CircularProgress
                  size={150}
                  classes={{ circle: classes.spinnerCircle }}
                />
              </div>
            ) : (
              <React.Fragment>
                <CommonText textVariant="20 medium">
                  Du har ikke tilgang til applikasjonen enda. Prøv igjen senere.
                </CommonText>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Fab
                    variant="extended"
                    className={classes.signOutButton}
                    onClick={() => {
                      Auth.signOut().then(() => {
                        queryClient.clear();
                      });
                    }}
                  >
                    Log ut
                  </Fab>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : forgotPassword ? (
          <div className={classes.resetPassword}>
            {passwordResetComplete ? (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CommonText textVariant="20 medium">
                  Passord reset er fullført
                </CommonText>
                <Fab
                  variant="extended"
                  className={classes.backButton}
                  onClick={() => {
                    setPasswordResetComplete(false);
                    setForgotPassword(false);
                  }}
                >
                  <CommonText textVariant="20 medium">
                    Tilbake til innlogging
                  </CommonText>
                </Fab>
              </div>
            ) : (
              <React.Fragment>
                <CommonText
                  className={classes.resetPasswordText}
                  textVariant="20 medium"
                >
                  Reset passord
                </CommonText>
                <ForgotPassword
                  onCancel={() => setForgotPassword(false)}
                  onSuccess={() => {
                    setPasswordResetComplete(true);
                  }}
                />
              </React.Fragment>
            )}
          </div>
        ) : (
          <React.Fragment>
            <CommonText
              textVariant="20 medium"
              style={{ color: 'white' }}
              className={classes.loginText}
            >
              Logg inn
            </CommonText>
            <div className={classes.signInInputs}>
              <FederatedSignIn complimentaryText="Har du en Google eller Microsoft bruker kan du logge på med en av dem." />
              <div style={{ height: 50 }} />
              <CognitoSignIn
                onSuccess={() => {
                  dynamodbUserQuery.refetch();
                  cognitoUserQuery.refetch();
                }}
                onForgotPassword={() => {
                  setForgotPassword(true);
                }}
              />
              {isDesktop ? (
                ''
              ) : (
                <div className={classes.signInCardBottom}>
                  <CommonText textVariant="20 medium">Personvern</CommonText>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CommonText textVariant="20 medium">NOR</CommonText>
                    <CommonText textVariant="20 medium">• ENG</CommonText>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      {isDesktop ? (
        <div className={classes.signInCardBottom}>
          <CommonText textVariant="20 medium">Personvern</CommonText>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CommonText textVariant="20 medium">NOR</CommonText>
            <CommonText textVariant="20 medium">• ENG</CommonText>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default SignInContent;
