import React, { useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import FeatherIcon from 'feather-icons-react';

import CommonText from 'components/common/textOutput/CommonText';
import {
  useQueryProjectCompaniesList,
  useQueryProjectUsersList,
  useQueryUserCompany,
} from 'services/deliveryQueryHooks';
import { SelectedProjectContext } from 'utils/context/SelectedProject';

import theme from 'config/theme';
import { UserCompanyRelation } from 'services/deliveryApi';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    avatar: {
      height: 45,
      width: 45,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      boxShadow: '0px 2px 4px rgba(217, 219, 225, 0.6)',
      backgroundColor: theme.colors.base.white,
    },
    icon: { color: theme.colors.text.main },
    number: {
      width: 40,
      height: 60,
      zIndex: 1,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })
);

function ShareIcons() {
  const classes = useStyles();
  const { selectedProjectId } = useContext(SelectedProjectContext);
  const projectCompanyListQuery =
    useQueryProjectCompaniesList(selectedProjectId);
  const projectUsersListQuery = useQueryProjectUsersList(selectedProjectId);

  const userCompanyQuery = useQueryUserCompany();
  const userCompany: UserCompanyRelation | undefined = userCompanyQuery.data;

  const projectCompanies = projectCompanyListQuery.data;
  const projectUsers = projectUsersListQuery.data;

  let numberOfUsers = 0;
  let numberOfCompanies = 0;

  projectCompanies &&
    userCompany &&
    projectCompanies
      .filter((company) => {
        return company.id !== userCompany.id;
      })
      .forEach(() => {
        numberOfCompanies += 1;
      });

  projectUsers &&
    userCompany &&
    projectUsers
      .filter((user) => {
        return user.id !== userCompany.id;
      })
      .forEach(() => {
        numberOfUsers += 1;
      });

  const ShareIcon = ({ type }: { type: 'user' | 'company' }) => {
    return (
      <div>
        <CommonText className={classes.number} textVariant="12 medium">
          {type === 'company' ? numberOfCompanies : numberOfUsers}
        </CommonText>
        <div className={classes.avatar}>
          <FeatherIcon
            className={classes.icon}
            size="20"
            icon={type === 'company' ? 'briefcase' : 'user'}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {numberOfUsers > 0 && <ShareIcon type="user" />}
      {numberOfUsers > 0 && numberOfCompanies > 0 && (
        <div style={{ width: 10 }} />
      )}
      {numberOfCompanies > 0 && <ShareIcon type="company" />}
    </div>
  );
}

export default ShareIcons;
