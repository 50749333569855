import { HMSCounter } from 'services/hmsApi/models';
import { useQuery } from 'react-query';
import { authorizedHmsCounterApi } from 'services/apiService';

export const useQueryHmsCounter = (): {
  data: HMSCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(['hmsCounter'], async () => {
    const api = await authorizedHmsCounterApi();
    return api.getHmsCounter();
  });

  return { data, refetch, isLoading };
};
