import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DesktopNavBar, {
  MainMenuItem,
} from 'components/common/navBar/DesktopNavBar';
import MapView from 'components/deliveryDash/pages/MapView';
import List from 'components/deliveryDash/pages/ListView';
import Project from 'components/deliveryDash/pages/Project';
import NewProject from 'components/deliveryDash/pages/NewProject';
import { SelectedProjectProvider } from 'utils/context/SelectedProject';
import isUserLoggedIn from 'utils/isUserLoggedIn';
import ProjectIdCookie from 'services/ProjectIdCookie';

const useStyles = makeStyles(() =>
  createStyles({
    mainContent: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: '#f1f7fb',
    },
  })
);

const MainMenuItems: MainMenuItem[] = [
  {
    title: 'Lag Prosjekt',
    icon: 'plus-circle',
    path: '/dashboard/new-project',
  },
  {
    title: 'Kart',
    icon: 'map-pin',
    path: '/dashboard/map',
  },
  {
    title: 'Liste oversikt',
    path: '/dashboard/list-view',
    icon: 'list',
  },
];

export default function DeliveryDashboard() {
  const classes = useStyles();
  const history = useHistory();

  const userStatus = isUserLoggedIn();

  useEffect(() => {
    if (userStatus === 'nouser') {
      history.push('/');
    }
  });

  return (
    <SelectedProjectProvider>
      <ProjectIdCookie />
      <div className={classes.mainContent}>
        <DesktopNavBar MainMenuItems={MainMenuItems} />
        <div className={classes.content}>
          <Route path="/dashboard" exact>
            <List />
          </Route>
          <Route path="/dashboard/list-view">
            <List />
          </Route>
          <Route path="/dashboard/map">
            <MapView />
          </Route>
          <Route path="/dashboard/project">
            <Project />
          </Route>
          <Route path="/dashboard/new-project">
            <NewProject />
          </Route>
        </div>
      </div>
    </SelectedProjectProvider>
  );
}
