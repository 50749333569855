import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import CommonText from 'components/common/textOutput/CommonText';
import BarIndicatior from 'components/opsDash/ym/bottomDrawer/BarIndicatior';

import CommonFab from 'components/common/CommonFab';

import theme from 'config/theme';
import CommonTooltip from 'components/common/CommonTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    root: { padding: 10 },
    paper: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
    },
    paperHeader: {
      display: 'flex',
      flexDirection: 'column',
    },
    paperHeaderTop: {
      padding: 5,
      paddingLeft: 15,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 5,
    },
    barContainer: { width: 75, height: '100%' },
    infoContainer: {
      height: '100%',

      padding: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);
export default function MeasurementCard({
  measurementType,
  measurementValue,
  measurementThreshold,
  barChartDomain,
  onHistoryButtonClicked,
  onClickEdit,
  date,
}: {
  measurementType: string;
  measurementValue: number;
  measurementThreshold?: number;
  barChartDomain?: number[];
  onHistoryButtonClicked: (selectedMeasurementType: string) => void;
  onClickEdit: (selectedMeasurementType: string) => void;
  date: string;
}) {
  const classes = useStyles();

  let barColor = theme.colors.base.lightGrey;

  if (measurementThreshold && measurementValue > measurementThreshold) {
    barColor = theme.colors.indicators.serious;
  } else if (
    measurementThreshold &&
    measurementValue >= 0.95 * measurementThreshold
  ) {
    barColor = theme.colors.indicators.significant;
  } else if (measurementThreshold && measurementValue < measurementThreshold) {
    barColor = theme.colors.indicators.positive;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <div className={classes.paperHeaderTop}>
            <CommonText textVariant="14 medium">{measurementType}</CommonText>
            <CommonFab
              noShadow
              icon="bar-chart-2"
              tooltip={{ placement: 'bottom', text: 'Se historisk data' }}
              onClick={() => {
                onHistoryButtonClicked(measurementType);
              }}
            />
            <CommonFab
              noShadow
              icon="list"
              tooltip={{
                placement: 'bottom',
                text: 'Se historisk data i listeform',
              }}
              onClick={() => {
                onClickEdit(measurementType);
              }}
            />
          </div>

          <Divider />
        </div>
        <div className={classes.paperBody}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div className={classes.barContainer}>
              <BarIndicatior
                domain={barChartDomain}
                value={measurementValue}
                threshold={measurementThreshold}
              />
            </div>
            <div className={classes.infoContainer}>
              <div
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: '50%',
                  padding: 2,
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: barColor,
                }}
              />
              <CommonText textVariant="12 medium">Verdi:</CommonText>
              <CommonText textVariant="12 light">{measurementValue}</CommonText>
              <CommonText textVariant="12 medium">Terskel:</CommonText>
              <CommonText textVariant="12 light">
                {measurementThreshold ? measurementThreshold : 'ingen'}
              </CommonText>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: 5,
            }}
          >
            <CommonTooltip placement="bottom" message="Sist oppdatert">
              <CommonText textVariant="12 light">{date}</CommonText>
            </CommonTooltip>
          </div>
        </div>
      </Paper>
    </div>
  );
}
