import React from 'react';

import Dropzone from 'components/common/Dropzone';
import InitialLoadingContent from 'components/deliveryDash/project/documentsCard/InitialLoadingContent';
import { ProjectDocumentRelation } from 'services/deliveryApi/models/ProjectDocumentRelation';
import DragAndDropIndicator from 'components/common/DragAndDropIndicator';
import DocumentTable from 'components/deliveryDash/project/documentsCard/DocumentTable';

function CardContent({
  editable,
  disabled,
  documents,
  filesToUpload,
  sortDirection,
  refetchDocuments,
  setFilesToUpload,
  clearFile,
}: {
  editable: boolean;
  disabled?: boolean;
  documents: ProjectDocumentRelation[] | undefined;
  filesToUpload: File[];
  sortDirection: 'asc' | 'desc';
  refetchDocuments: () => void;
  setFilesToUpload: (files: File[]) => void;
  clearFile: (file: File) => void;
}) {
  const documentTable = (
    <DocumentTable
      editable={editable}
      sortDirection={sortDirection}
      documents={documents}
      filesToUpload={filesToUpload}
      onFileUploadCompleted={(completedFile) => {
        // waiting five seconds before refeching files from sever and removing FileUploadItem
        setTimeout(() => {
          clearFile(completedFile);
          refetchDocuments();
        }, 5000);
      }}
      onFileUploadCanceled={(canceledFile) => {
        clearFile(canceledFile);
      }}
      refetchDocuments={refetchDocuments}
    />
  );

  return disabled ? (
    //When card is disabled
    <InitialLoadingContent loading={false} />
  ) : documents && editable ? (
    //When the project already has documents and is editable
    <Dropzone
      onDrop={(files) => {
        setFilesToUpload(files);
      }}
    >
      {documentTable}
    </Dropzone>
  ) : !documents && filesToUpload.length === 0 && editable ? (
    //When the project has no documents and no files currently uploading
    <Dropzone
      onDrop={(files) => {
        setFilesToUpload(files);
      }}
    >
      <DragAndDropIndicator text="Dra og slipp dine første filer her" />
    </Dropzone>
  ) : (
    documentTable
  );
}

export default CardContent;
