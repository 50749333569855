import React from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DashCard from 'components/common/cards/DashCard';
import ValueIndicator from 'components/common/cards/ValueIndicator';
import { format } from 'date-fns';

import { faCalendarAlt, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { HMSCounter } from 'services/hmsApi';

import { nb } from 'date-fns/locale';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 16,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: '100%',
      width: '100%',
    },
    valueIndicatior: {
      height: '50%',
      minHeight: '50%',
      width: '50%',
      minWidth: '50%',
      padding: 5,
    },
  })
);

function NandHValueCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const classes = useStyles();
  const history = useHistory();

  const monthConverter = (date: Date) => {
    const month = format(date, 'M');
    const paddingString = 'm';
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, 'yyyy');
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const currentDate = new Date();

  const previousMonthDate = new Date(currentDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  let currentMonth = format(previousMonthDate, 'LLLL', { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(previousMonthDate, 'yyyy');

  const nhReportYear =
    hmsCounterData?.data?.nh?.years?.[yearConverter(currentDate)];

  const nhPreviousMonth =
    hmsCounterData?.data?.nh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <DashCard
      title="HMS • N og H-verdi"
      subtitle={currentMonth + ' ' + currentYear}
      noBodyPaper
      onDetailsClick={() => {
        history.push('/dashboard/hms');
      }}
      bodyComponent={
        <div className={classes.content}>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="N-verdi"
              icon={faCalculator}
              value={
                nhReportYear?.months?.[monthConverter(currentDate)]?.nValue
              }
              previous={{
                value: nhPreviousMonth?.nValue,
                comparison: 'percentage',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'yes',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="N-verdi snitt hittil i år"
              icon={faCalendarAlt}
              value={
                nhReportYear?.avgN ? parseInt(nhReportYear.avgN) : undefined
              }
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="H-verdi"
              icon={faCalculator}
              value={
                nhReportYear?.months?.[monthConverter(currentDate)]?.hValue
              }
              previous={{
                value: nhPreviousMonth?.hValue,
                comparison: 'compare',
                endingText: 'enn forrige måned',
                isIncreaseGood: 'no',
              }}
            />
          </div>
          <div className={classes.valueIndicatior}>
            <ValueIndicator
              label="H-verdi snitt hittil i år"
              icon={faCalendarAlt}
              value={
                nhReportYear?.avgH ? parseInt(nhReportYear.avgH) : undefined
              }
            />
          </div>
        </div>
      }
    />
  );
}

export default NandHValueCard;
