import React from 'react';

import {
  Line,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  calculateGradientOffset,
  toPercent,
} from 'components/common/charts/graphUtils';

import CustomizedAxisTick from 'components/common/charts/CustomizedAxisTick';

import theme from 'config/theme';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    tooltipLabel: { color: 'black' },
    tooltipWrapper: {
      backgroundColor: 'white',
      borderRadius: '4px',
    },
  })
);

function LineGraph({
  data,
  xKey,
  yAxisDomain,
  dataProps,
  showAsPercentage,
  margin,
}: {
  data: object[];
  xKey: string;
  yAxisDomain?: number[];
  dataProps: {
    key: string;
    strokeColor?: string;
    color?: string;
    gradientThreshold?: number;
  }[];
  stackGraphs?: boolean;
  showAsPercentage?: boolean;
  margin?: { top: number; bottom: number; left: number; right: number };
}) {
  const classes = useStyles();
  const gradientContent = dataProps.map((lineProp, index) => {
    if (lineProp.gradientThreshold) {
      const offset = calculateGradientOffset(
        data,
        lineProp.key,
        lineProp.gradientThreshold
      );

      return (
        <defs key={index}>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset={offset}
              stopColor={theme.colors.icons.active.dark}
              stopOpacity={1}
            />
            <stop
              offset={offset}
              stopColor={
                lineProp.color ? lineProp.color : theme.colors.icons.active.dark
              }
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
      );
    } else {
      return null;
    }
  });

  const lineContent = dataProps.map((lineProp, index) => {
    return (
      <Line
        dot={{ strokeWidth: 10 }}
        type="monotone"
        key={index}
        dataKey={lineProp.key}
        fill={
          lineProp.gradientThreshold
            ? 'url(#splitColor)'
            : lineProp.color
            ? lineProp.color
            : theme.colors.icons.active.dark
        }
        stroke={
          lineProp.gradientThreshold
            ? 'url(#splitColor)'
            : lineProp.color
            ? lineProp.color
            : theme.colors.icons.active.dark
        }
        strokeWidth={4}
      />
    );
  });
  return (
    <ResponsiveContainer height="99%" width="99%">
      <LineChart
        data={data}
        margin={
          margin
            ? margin
            : {
                top: 10,
                right: 30,
                left: 10,
                bottom: 20,
              }
        }
        stackOffset={showAsPercentage ? 'expand' : 'none'}
      >
        <CartesianGrid strokeDasharray="9 9" />
        <XAxis tick={<CustomizedAxisTick />} dataKey={xKey} />
        <YAxis domain={yAxisDomain} />
        {showAsPercentage ? <YAxis tickFormatter={toPercent} /> : <YAxis />}
        <Tooltip
          labelClassName={classes.tooltipLabel}
          wrapperClassName={classes.tooltipWrapper}
          itemStyle={{
            color: 'black',
          }}
        />

        {gradientContent}
        {lineContent}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineGraph;
