import React, { useState } from 'react';
import { createStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from '@mui/lab/';
import clsx from 'clsx';
import { nb } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Box, TextField } from '@mui/material';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    dialog: { backgroundColor: 'red' },
    text: { color: 'white !important' },
  })
);

function isValidDate(d) {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export default function DateTimePickers({
  parentCallback,
  date,
  label,
  pickerType = 'date',
  className,
  hideUnderline,
}: {
  parentCallback(arg: Date): void;
  date: Date | undefined | null;
  label?: string;
  pickerType?: 'date' | 'time' | 'dateAndTime' | 'year-month';
  className?: string;
  hideUnderline?: boolean;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const formatString =
    pickerType === 'date'
      ? 'dd.MM.yyyy'
      : pickerType === 'time'
      ? 'HH:mm'
      : pickerType === 'year-month'
      ? 'MM.yyyy'
      : 'dd.MM.yyyy HH:mm';

  const renderInputHandler = (renderProps) => {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          px: 2,
          py: 0.1,
          borderRadius: 25,
        }}
      >
        <TextField
          error
          sx={{
            '& .MuiInput-underline:before': {
              visibility: hideUnderline ? 'hidden' : 'initial',
            },
          }}
          variant="standard"
          classes={{ input: classes.text }}
          onClick={() => setOpen(true)}
          {...renderProps}
        />
      </Box>
    );
  };

  return (
    <LocalizationProvider locale={nb} dateAdapter={AdapterDateFns}>
      <React.Fragment>
        {pickerType === 'date' ? (
          <DatePicker
            className={
              className
                ? clsx(classes.root, { [className]: true })
                : classes.root
            }
            open={open}
            renderInput={renderInputHandler}
            inputFormat={formatString}
            value={date}
            onChange={(materalUiDate) => {
              isValidDate(materalUiDate as Date) &&
                parentCallback(new Date(materalUiDate as Date));
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : pickerType === 'year-month' ? (
          <DatePicker
            renderInput={renderInputHandler}
            views={['year', 'month']}
            open={open}
            inputFormat={formatString}
            label={label}
            value={date}
            InputAdornmentProps={{ onClick: () => setOpen(true) }}
            onChange={(materalUiDate) => {
              isValidDate(materalUiDate as Date) &&
                parentCallback(new Date(materalUiDate as Date));
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : pickerType === 'time' ? (
          <TimePicker
            renderInput={renderInputHandler}
            className={classes.root}
            open={open}
            inputFormat={formatString}
            ampm={false}
            label={label}
            value={date}
            InputAdornmentProps={{ onClick: () => setOpen(true) }}
            onChange={(materalUiDate) => {
              isValidDate(materalUiDate as Date) &&
                parentCallback(new Date(materalUiDate as Date));
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : (
          <DateTimePicker
            renderInput={renderInputHandler}
            className={classes.root}
            open={open}
            ampm={false}
            inputFormat={formatString}
            label={label}
            value={date}
            InputAdornmentProps={{ onClick: () => setOpen(true) }}
            onChange={(materalUiDate) => {
              isValidDate(materalUiDate as Date) &&
                parentCallback(new Date(materalUiDate as Date));
            }}
            onClose={() => {
              setOpen(false);
            }}
          />
        )}
      </React.Fragment>
    </LocalizationProvider>
  );
}
