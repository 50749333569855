import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import QueryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';

import FederatedSignIn from 'components/frontPage/FederatedSignIn';
import CognitoSignUp from 'components/frontPage/CognitoSignUp';
import ConfirmCognitoSignUp from 'components/frontPage/ConfirmCognitoSignUp';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    signUpCardTop: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    signUpCardMiddle: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    signUpCardBottom: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    headerText: {
      marginBottom: 20,
    },
    signUpInputs: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    backButton: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: '#768AA3',
      width: 300,
    },
    spinner: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    invitationText: {
      paddingBottom: 20,
    },
    invitationEmailText: {
      paddingBottom: 20,
    },
  })
);

function SignUpContent() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryString = QueryString.parse(location.search);

  const [cognitoSignUpSuccess, setCognitoSignUpSuccess] =
    useState<boolean>(false);
  const [cognitoVerificationSuccess, setCognitoVerificationSuccess] =
    useState<boolean>(false);
  const [cognitoSignUpEmail, setCognitoSignUpEmail] =
    useState<string | null>(null);

  let invitationEmail: string | undefined;
  if (queryString.email) {
    invitationEmail = queryString.email as string;
  }

  useEffect(() => {
    if (queryString.email && queryString.verify) {
      Auth.resendSignUp(queryString.email as string);
      setCognitoSignUpSuccess(true);
      setCognitoSignUpEmail(queryString.email as string);
    }
  }, [queryString.email, queryString.verify]);

  const cardTop = (
    <div className={classes.signUpCardTop}>
      <CommonText textVariant="20 medium">Hjem</CommonText>
      <div style={{ height: 100 }} />
    </div>
  );

  const cardBottom = (
    <div className={classes.signUpCardBottom}>
      <CommonText textVariant="20 medium">Personvern</CommonText>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CommonText textVariant="20 medium">NOR</CommonText>
        <CommonText textVariant="20 medium">• ENG</CommonText>
      </div>
    </div>
  );

  const signUp = (
    <React.Fragment>
      {cardTop}
      <div className={classes.signUpCardMiddle}>
        <CommonText textVariant="20 medium" className={classes.headerText}>
          Registrer bruker
        </CommonText>
        {invitationEmail && (
          <React.Fragment>
            <CommonText
              textVariant="12 light"
              className={classes.invitationText}
            >
              Det er registrert en invitasjon på følgende epostadresse:
            </CommonText>
            <CommonText
              textVariant="20 medium"
              className={classes.invitationEmailText}
            >
              {invitationEmail}
            </CommonText>
          </React.Fragment>
        )}
        <div className={classes.signUpInputs}>
          <FederatedSignIn
            complimentaryText={
              invitationEmail
                ? 'Hvis denne eposten er tilknyttet en Google eller Mirosoft konto kan du logge inn med en av alternativene under'
                : 'Har du en Google eller Microsoft bruker kan du logge på med en av dem.'
            }
          />
          <div style={{ height: 50 }} />
          <CognitoSignUp
            invitationEmail={invitationEmail && invitationEmail}
            onSuccess={(email) => {
              setCognitoSignUpSuccess(true);
              setCognitoSignUpEmail(email);
            }}
          />
        </div>
      </div>
      {cardBottom}
    </React.Fragment>
  );

  const confirmSignUp = (
    <React.Fragment>
      {cardTop}
      <div className={classes.signUpCardMiddle}>
        {cognitoSignUpEmail && (
          <ConfirmCognitoSignUp
            confirmationEmail={cognitoSignUpEmail}
            onSuccess={() => {
              setCognitoVerificationSuccess(true);
            }}
          />
        )}
      </div>
      {cardBottom}
    </React.Fragment>
  );

  const verificationComplete = (
    <React.Fragment>
      {cardTop}
      <div className={classes.signUpCardMiddle}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CommonText textVariant="20 medium" className={classes.headerText}>
            Registreringen er fullført
          </CommonText>
          <Fab
            variant="extended"
            className={classes.backButton}
            onClick={() => {
              history.push('/');
            }}
          >
            <CommonText textVariant="20 medium">
              Tilbake til innlogging
            </CommonText>
          </Fab>
        </div>
      </div>
      {cardBottom}
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      {cognitoVerificationSuccess
        ? verificationComplete
        : cognitoSignUpSuccess
        ? confirmSignUp
        : signUp}
    </div>
  );
}

export default SignUpContent;
