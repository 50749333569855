import React, { useState } from 'react';
import CommonCard from 'components/common/cards/CommonCard';
import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import { sensorIdNameMap } from 'config/opsDash/sensorIdNameMap';
import LineGraph from 'components/common/charts/LineGraph';
import ChartLegends from 'components/common/charts/ChartLegends';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryTrafficSensorCounterByIdAndYear } from 'services/trafficQueryHooks';
import theme from 'config/theme';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    commonCardHeaderRight: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 50,
    },
    pickerLabel: { paddingRight: 10 },
    picker: { paddingRight: 10 },
    spinner: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    noData: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sensorName: { paddingLeft: 50 },
    commonCardBody: {
      padding: 10,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    graphNameAndLegendContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

const monthKeyToMonth = {
  m01: 'Jan.',
  m02: 'Feb.',
  m03: 'Mar.',
  m04: 'Apr.',
  m05: 'Mai.',
  m06: 'Jun.',
  m07: 'Jul.',
  m08: 'Aug.',
  m09: 'Sep.',
  m10: 'Okt.',
  m11: 'Nov.',
  m12: 'Des.',
};

const yearObjectToDataArray = (yearObject, year) => {
  const filteredCounterYear =
    yearObject &&
    Object.keys(yearObject)
      .filter(function (k) {
        return k.indexOf('m') === 0;
      })
      .sort()
      .reduce(function (newData, k) {
        newData[k] = yearObject[k];
        return newData;
      }, {});

  const calculatedDataArray =
    filteredCounterYear &&
    Object.keys(filteredCounterYear).map((monthKey) => {
      const hourDataObject = {
        time: monthKeyToMonth[monthKey],
        Passeringer: filteredCounterYear[monthKey].all
          ? filteredCounterYear[monthKey].all
          : 0,
      };
      return hourDataObject;
    });

  const chartDataArray =
    calculatedDataArray &&
    Object.values(monthKeyToMonth).map((monthValue) => {
      let newData = {
        time: monthValue,
        Vekst: undefined,
        'Forventet vekst': undefined,
      };
      calculatedDataArray.map((items) => {
        const currentDate = new Date();

        let currentMonth = format(currentDate, 'LLL', { locale: nb });

        currentMonth =
          currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
        const currentYear = currentDate.getFullYear().toString();
        if (
          items.time === monthValue &&
          (currentYear !== year ||
            (currentYear === year && currentMonth !== monthValue))
        ) {
          newData = items;
        }
        return newData;
      });
      return newData;
    });

  return chartDataArray;
};

function PassingsPastThreeYears() {
  const classes = useStyles();

  const [sensorId, setSensorId] = useState('54653V1751052');

  const year = format(new Date(), 'yyyy');
  const previousYear = String(+year - 1);
  const twoYearsAgo = String(+year - 2);

  const counterQuery = useQueryTrafficSensorCounterByIdAndYear(sensorId, year);
  const counterYear = counterQuery.data?.counter?.['y' + year];

  const counterQueryPrevious = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    previousYear
  );
  const counterPreviousYear =
    counterQueryPrevious.data?.counter?.['y' + previousYear];

  const counterQueryTwoYearsAgo = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    twoYearsAgo
  );
  const counterTwoYearsAgo =
    counterQueryTwoYearsAgo.data?.counter?.['y' + twoYearsAgo];

  const chartDataCurrentYear = yearObjectToDataArray(counterYear, year);
  const chartDataPreviousYear = yearObjectToDataArray(
    counterPreviousYear,
    previousYear
  );
  const chartDataTwoYearsAgo = yearObjectToDataArray(
    counterTwoYearsAgo,
    twoYearsAgo
  );
  let shownYears = '';
  let chartDataArray: [];

  if (chartDataCurrentYear && chartDataPreviousYear && chartDataTwoYearsAgo) {
    shownYears = twoYearsAgo + ' - ' + year;
    chartDataArray = chartDataTwoYearsAgo
      .concat(chartDataPreviousYear)
      .concat(chartDataCurrentYear);
  } else if (chartDataCurrentYear && chartDataPreviousYear) {
    shownYears = previousYear + ' - ' + year;
    chartDataArray = chartDataPreviousYear.concat(chartDataCurrentYear);
  } else if (chartDataCurrentYear) {
    shownYears = year;
    chartDataArray = chartDataCurrentYear;
  } else {
    chartDataArray = [];
  }

  return (
    <CommonCard
      title="Trafikkutvikling"
      headerRightComponent={
        <div className={classes.commonCardHeaderRight}>
          <CommonText
            className={classes.pickerLabel}
            textVariant="14 medium"
            colorOverride="white"
          >
            Sensor:
          </CommonText>
          <OptionsPicker
            optionsList={sensorIdNameMap}
            parentCallback={(sensorId) => {
              setSensorId(sensorId);
            }}
          />
        </div>
      }
      bodyComponent={
        counterQuery.isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress
              size={150}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        ) : !counterQuery.isLoading && !counterYear ? (
          <CommonText textVariant="20 medium" className={classes.noData}>
            {'Ingen data for ' + sensorIdNameMap[sensorId]}
          </CommonText>
        ) : (
          counterYear && (
            <div className={classes.commonCardBody}>
              <div className={classes.graphNameAndLegendContainer}>
                <CommonText
                  className={classes.sensorName}
                  textVariant="20 medium"
                >
                  {sensorIdNameMap[sensorId] + ', ' + shownYears}
                </CommonText>
                <ChartLegends
                  legends={[
                    {
                      name: 'Totale passeringer',
                      color: theme.colors.messageAndAlerts.notificationInfo,
                    },
                  ]}
                />
              </div>

              <LineGraph
                data={chartDataArray}
                xKey={'time'}
                dataProps={[
                  {
                    key: 'Passeringer',
                    color: theme.colors.messageAndAlerts.notificationInfo,
                  },
                ]}
              />
            </div>
          )
        )
      }
    />
  );
}

export default PassingsPastThreeYears;
