import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CommonText from 'components/common/textOutput/CommonText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { ProjectPointcloudRelation } from 'services/deliveryApi/models';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px 10px rgba(212, 220, 224, 0.36)',
      },
    },
    icon: { padding: 20, paddingLeft: 40 },
    viewPointcloud: {
      color: theme.colors.messageAndAlerts.warning,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export default function PointcloudTableItem({
  pointcloud,
}: {
  pointcloud: ProjectPointcloudRelation;
}) {
  const classes = useStyles();

  let statusText = '';
  let statusTextColor: 'success' | 'error' | undefined = undefined;

  switch (pointcloud.processStatus) {
    case 'processing':
      statusText = 'prosesserer punktsky...';
      break;
    case 'complete':
      statusText = 'ferdig prosessert';
      statusTextColor = 'success';
      break;
    case 'error':
      statusText = 'prosessering mislykket';
      statusTextColor = 'error';
      break;
  }

  return (
    <TableRow
      className={classes.root}
      onClick={() => {
        window.open(pointcloud.pointcloudS3Href, '_blank');
      }}
    >
      <TableCell className={classes.icon} align="center">
        <FontAwesomeIcon
          icon={faCloud}
          color={theme.colors.messageAndAlerts.warning}
          size="2x"
        />
      </TableCell>
      <TableCell align="left">
        <CommonText textVariant="12 medium">
          {pointcloud.pointcloudName}
        </CommonText>
      </TableCell>
      <TableCell align="center">
        <CommonText colorOverride={statusTextColor} textVariant="12 light">
          {statusText}
        </CommonText>
      </TableCell>
    </TableRow>
  );
}
