import React from 'react';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { YmPoint } from 'services/ymApi/models';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    iconSenter: {
      height: 25,
      width: 25,
      borderRadius: '50%',
      zIndex: 10,
      backgroundColor: 'white',
      padding: 2,
    },
    innerHoverIcon: {
      borderRadius: '50%',
      height: 25,
      width: 25,
      '&:hover': {
        height: 50,
        width: 50,
      },
      transition: 'height 250ms ease, width 250ms ease',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    outerHoverIcon: {
      position: 'relative',
      borderRadius: '50%',
      height: 25,
      top: 0,
      left: 0,
      width: 25,

      '&:hover': {
        height: 100,
        top: -37,
        width: 100,
        left: -37,
      },
      transition:
        'height 250ms ease, top 250ms ease, width 250ms ease, left 250ms ease',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

function EnvironmentCardMapMarker({ ymPoint }: { ymPoint: YmPoint }) {
  const classes = useStyles();
  const color =
    ymPoint.status === 'ok'
      ? theme.colors.indicators.positive
      : ymPoint.status === 'warning'
      ? theme.colors.indicators.significant
      : ymPoint.status === 'alarm'
      ? theme.colors.indicators.serious
      : theme.colors.indicators.neutral;

  return (
    <div
      className={classes.outerHoverIcon}
      style={{
        backgroundColor: alpha(color as string, 0.2),
      }}
    >
      <div
        className={classes.innerHoverIcon}
        style={{
          backgroundColor: alpha(color as string, 0.2),
        }}
      >
        <div className={classes.iconSenter}>
          <div
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '50%',
              backgroundColor: color,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EnvironmentCardMapMarker;
