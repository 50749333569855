/* tslint:disable */
/* eslint-disable */
/**
 * Ameta nvdb API
 * A rest api for managing the ameta nvdb system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NVDBObjectsResponseLastEvaluatedKey,
    NVDBObjectsResponseLastEvaluatedKeyFromJSON,
    NVDBObjectsResponseLastEvaluatedKeyFromJSONTyped,
    NVDBObjectsResponseLastEvaluatedKeyToJSON,
    NvdbObject,
    NvdbObjectFromJSON,
    NvdbObjectFromJSONTyped,
    NvdbObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface NVDBObjectsResponse
 */
export interface NVDBObjectsResponse {
    /**
     * 
     * @type {Array<NvdbObject>}
     * @memberof NVDBObjectsResponse
     */
    objects: Array<NvdbObject>;
    /**
     * 
     * @type {NVDBObjectsResponseLastEvaluatedKey}
     * @memberof NVDBObjectsResponse
     */
    lastEvaluatedKey?: NVDBObjectsResponseLastEvaluatedKey;
}

export function NVDBObjectsResponseFromJSON(json: any): NVDBObjectsResponse {
    return NVDBObjectsResponseFromJSONTyped(json, false);
}

export function NVDBObjectsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NVDBObjectsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objects': ((json['objects'] as Array<any>).map(NvdbObjectFromJSON)),
        'lastEvaluatedKey': !exists(json, 'lastEvaluatedKey') ? undefined : NVDBObjectsResponseLastEvaluatedKeyFromJSON(json['lastEvaluatedKey']),
    };
}

export function NVDBObjectsResponseToJSON(value?: NVDBObjectsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objects': ((value.objects as Array<any>).map(NvdbObjectToJSON)),
        'lastEvaluatedKey': NVDBObjectsResponseLastEvaluatedKeyToJSON(value.lastEvaluatedKey),
    };
}


