import React from 'react';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Skeleton } from '@mui/material';
import FeatherIcon from 'feather-icons-react';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '100%', width: '100%' },
    table: { height: '100%', width: '100%' },
  })
);

export default function TablePlaceholder({
  loading,
  tableHeight,
}: {
  loading: boolean;
  tableHeight: number;
}) {
  const classes = useStyles();

  const placeholderElements: number = Math.floor(tableHeight / 60);

  let iconSize = 12;
  let skeletonWidth1 = 100;
  let skeletonWidth2 = 300;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 22;
    skeletonWidth1 = 160;
    skeletonWidth2 = 400;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 38;
    skeletonWidth1 = 280;

    skeletonWidth2 = 500;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 38;
    skeletonWidth1 = 320;
    skeletonWidth2 = 800;
  }

  return (
    <div className={classes.root}>
      <TableContainer
        className={classes.table}
        style={{ height: tableHeight - 25 }} // minus 25 to account for paper header shadow
      >
        <Table>
          <TableBody>
            {new Array(placeholderElements).fill(0).map((_, i) => {
              return (
                <TableRow key={i}>
                  <TableCell align="center">
                    <FeatherIcon
                      style={{ color: '#e6e6e6', marginLeft: 25 }}
                      size={iconSize}
                      icon="cloud"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton
                      animation={loading ? 'wave' : false}
                      style={{ backgroundColor: '#e6e6e6' }}
                      height={20}
                      width={skeletonWidth2}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton
                      animation={loading ? 'wave' : false}
                      style={{ backgroundColor: '#e6e6e6' }}
                      height={20}
                      width={skeletonWidth1}
                    />
                  </TableCell>

                  <TableCell align="center">
                    <Skeleton
                      animation={loading ? 'wave' : false}
                      style={{ backgroundColor: '#e6e6e6' }}
                      variant="circular"
                      width={iconSize}
                      height={iconSize}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
