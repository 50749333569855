import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import TopView from 'components/opsDash/TopView';
import QuarterlyOverview from 'components/opsDash/availability/QuarterlyOverview';
import SectionOverview from 'components/opsDash/availability/SectionOverview';
import EventOverview from 'components/opsDash/availability/EventOverview';
import AddEventDialog from 'components/opsDash/availability/dialog/AddEventDialog';
import ReportDialog from 'components/opsDash/availability/dialog/ReportDialog';
import { useQuerySectionCounter } from 'services/availabilityQueryHooks';

import theme from 'config/theme';
import PageMenu from 'components/common/pageMenu/PageMenu';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.base.background,
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '4%',
      paddingTop: 60,
      backgroundColor: theme.colors.base.white,
    },
    papgeContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    leftSection: { width: '100%', flex: 1, padding: 10 },
    rightSection: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    rightSectionElement: {
      height: '100%',
      width: '100%',
      padding: 10,
    },
  })
);
export default function Availability() {
  const classes = useStyles();

  const sectionCounterQuery = useQuerySectionCounter();

  const sectionCounterData = sectionCounterQuery.data;

  return (
    <div className={classes.root}>
      <TopView title="Tilgjengelighet" subtitle="Oversikt" />
      <div className={classes.papgeContent}>
        <PageMenu
          menuItems={[
            <AddEventDialog
              refetch={sectionCounterQuery.refetch}
              key="AddEventDialog"
            />,
            <ReportDialog
              key="ReportDialog"
              onClose={sectionCounterQuery.refetch}
            />,
          ]}
        />
        <div className={classes.leftSection}>
          <QuarterlyOverview
            sectionCounterData={sectionCounterData}
            loadingData={sectionCounterQuery.isLoading}
          />
        </div>
        <div className={classes.rightSection}>
          <div className={classes.rightSectionElement}>
            <SectionOverview sectionCounterData={sectionCounterData} />
          </div>
          <div className={classes.rightSectionElement}>
            <EventOverview
              sectionCounterData={sectionCounterData}
              loadingData={sectionCounterQuery.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
