import React, { useState, RefObject } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Fab } from '@mui/material';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CommonText from 'components/common/textOutput/CommonText';
import clsx from 'clsx';
import FeatherIcon from 'feather-icons-react';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    touchRipple: {
      color: 'rgba(113, 146, 176, 0.22)',
    },
    root: {
      backgroundColor: 'white',
      '&:hover': {
        background: 'white',
        boxShadow: '0px 4px 10px rgba(212, 220, 224, 0.78)',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      boxShadow: '0px 2px 4px rgba(217, 219, 225, 0.6)',
    },
    static: {
      '&:active': {
        boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)',
      },
    },
    noShadow: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    },
    unselectedIcon: {
      color: theme.colors.icons.active.dark,
    },
    selectedIcon: {
      color: theme.colors.icons.active.dark,
    },
    textButton: {
      maxHeight: '3vh',
      minHeight: '3vh',
    },
    withIcon: { paddingRight: 5, paddingLeft: 10 },
    extendedButton: { paddingRight: 5, paddingLeft: 5 },
    spinnerContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.base.white,
    borderRadius: 15,
    padding: 15,
    margin: 0,
    boxShadow: '0px 4px 10px rgba(212, 220, 224, 0.78)',
  },
}))(Tooltip);

export default function CommonFab({
  className,
  text,
  icon,
  textColor,
  iconColor,
  selected,
  disabled,
  buttonSize = 'medium',
  iconSize,
  onClick,
  componentType,
  componentRef,
  divider,
  staticComponent,
  noShadow,
  noTextWrap,
  spinnerAsIcon,
  tooltip,
}: {
  className?: string;
  text?: string;
  icon?: string;
  textColor?: 'secondary' | 'light' | 'success' | 'warning' | 'error';
  iconColor?: string;
  selected?: boolean;
  disabled?: boolean;
  buttonSize?: 'medium' | 'large' | 'small';
  iconSize?: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  componentType?: React.ElementType;
  componentRef?: RefObject<HTMLButtonElement>;
  divider?: boolean;
  staticComponent?: boolean;
  noShadow?: boolean;
  noTextWrap?: boolean;
  spinnerAsIcon?: boolean;
  tooltip?: { text: string; placement: 'left' | 'right' | 'top' | 'bottom' };
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const noText = text === undefined;

  if (!iconSize) {
    iconSize = 12;
    switch (buttonSize) {
      case 'small':
        iconSize = 7;
        if (window.innerWidth >= theme.breakpoints.values.fullhd) {
          iconSize = 11;
        }
        if (window.innerWidth >= theme.breakpoints.values.wqhd) {
          iconSize = 16;
        }
        if (window.innerWidth >= theme.breakpoints.values.uhd) {
          iconSize = 20;
        }
        break;
      case 'medium':
        iconSize = 12;
        if (window.innerWidth >= theme.breakpoints.values.fullhd) {
          iconSize = 25;
        }
        if (window.innerWidth >= theme.breakpoints.values.wqhd) {
          iconSize = 30;
        }
        if (window.innerWidth >= theme.breakpoints.values.uhd) {
          iconSize = 42;
        }
        break;
      case 'large':
        iconSize = 18;
        if (window.innerWidth >= theme.breakpoints.values.fullhd) {
          iconSize = 32;
        }
        if (window.innerWidth >= theme.breakpoints.values.wqhd) {
          iconSize = 34;
        }
        if (window.innerWidth >= theme.breakpoints.values.uhd) {
          iconSize = 48;
        }
        break;
    }
  }

  const fabComponent = (
    <Fab
      ref={componentRef}
      component={componentType ? componentType : 'button'}
      aria-label="add"
      variant={!noText ? 'extended' : undefined}
      size={noText ? buttonSize : undefined}
      disabled={disabled}
      disableTouchRipple={staticComponent}
      className={clsx(classes.root, {
        [classes.static]: staticComponent,
        [classes.textButton]: text,
        [classes.noShadow]: noShadow,
        [className as string]: className,
      })}
      classes={{ extended: classes.extendedButton }}
      TouchRippleProps={{ classes: { ripple: classes.touchRipple } }}
      focusVisibleClassName={classes.static}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!staticComponent) {
          onClick(event);
        }
      }}
    >
      {text && (
        <CommonText
          style={{ textTransform: 'none' }}
          noWrap={noTextWrap}
          textVariant={selected || hover ? '12 medium' : '12 light'}
          colorOverride={
            textColor ? textColor : hover ? 'secondary' : undefined
          }
          className={icon ? classes.withIcon : ''}
        >
          {text}
        </CommonText>
      )}
      {icon && text && <div style={{ width: 5 }} />}
      {divider && (
        <Divider
          style={{ marginRight: 5, marginTop: 5, marginBottom: 5 }}
          light
          flexItem
          orientation="vertical"
        />
      )}
      {(icon || spinnerAsIcon) &&
        (spinnerAsIcon ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress
              size={iconSize}
              style={selected || hover ? { color: iconColor } : {}}
            />
          </div>
        ) : (
          <FeatherIcon
            size={iconSize}
            icon={icon}
            className={clsx(classes.unselectedIcon, {
              [classes.selectedIcon]: selected || hover,
            })}
            style={selected || hover ? { color: iconColor } : {}}
          />
        ))}
    </Fab>
  );

  return tooltip ? (
    <LightTooltip
      placement={tooltip.placement}
      title={<CommonText textVariant="12 light">{tooltip.text}</CommonText>}
    >
      <div>{fabComponent}</div>
    </LightTooltip>
  ) : (
    fabComponent
  );
}
