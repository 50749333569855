import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';

import ValueIndicator from 'components/common/cards/ValueIndicator';

import {
  faHardHat,
  faFileInvoice,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

import BarChart from 'components/common/charts/BarChart/BarChart';
import theme from 'config/theme';
import {
  HMSCounter,
  RuhYearDataObject,
  SjaYearDataObject,
} from 'services/hmsApi';
import { format } from 'date-fns';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
    },
    leftElement: {
      flex: 1,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      backgroundColor: theme.colors.base.base1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: 50,
    },
    middleElement: {
      flex: 3,
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    valueIndicatior: {
      height: '50%',
      width: '50%',
      padding: 10,
    },
    rightElement: {
      flex: 3,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    monthPicker: { paddingRight: 10, width: 130 },
    yearPicker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 10,
    },
  })
);

interface GraphData {
  label: string;
  RUH?: number;
  SJA?: number;
}

export default function RUHandSJA({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const classes = useStyles();

  const monthConverter = (date: Date) => {
    const month = format(date, 'M');
    const paddingString = 'm';
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, 'yyyy');
    const slicedYear = year.slice(2, 4);
    const paddingString = 'y';
    return paddingString.concat(slicedYear);
  };

  const initialDate = new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const previousMonthDate = new Date(selectedDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const labelMonthConverter = {
    m1: 'Jan',
    m2: 'Feb',
    m3: 'Mar',
    m4: 'Apr',
    m5: 'Mai',
    m6: 'Jun',
    m7: 'Jul',
    m8: 'Aug',
    m9: 'Sep',
    m10: 'Okt',
    m11: 'Nov',
    m12: 'Des',
  };

  const sjaRuhYearMonthMapper: (
    sjaYear: SjaYearDataObject | undefined,
    ruhYear: RuhYearDataObject | undefined
  ) => GraphData[] = (
    sjaYear: SjaYearDataObject | undefined,
    ruhYear: RuhYearDataObject | undefined
  ) => {
    const hmsNumbers: GraphData[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      hmsNumbers.push({
        label: value,
        ...(sjaYear?.months?.[key]?.sumSja && {
          SJA: sjaYear.months[key].sumSja,
        }),
        ...(ruhYear?.months?.[key]?.sumRuh && {
          RUH: ruhYear.months[key].sumRuh,
        }),
      });
    });
    return hmsNumbers;
  };

  const sjaReportYear =
    hmsCounterData?.data?.sja?.years?.[yearConverter(selectedDate)];

  const ruhReportYear =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(selectedDate)];

  const ruhPreviousMonth =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  const sjaPreviousMonth =
    hmsCounterData?.data?.sja?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <Paper className={classes.root}>
      <div className={classes.leftElement}>
        <CommonText textVariant="32 medium" colorOverride="white">
          RUH og SJA
        </CommonText>
        <div className={classes.pickerContainer}>
          <CommonText
            textVariant="14 medium"
            colorOverride="white"
            className={classes.pickerLabel}
          >
            År:
          </CommonText>
          <OptionsPicker
            className={classes.yearPicker}
            reverse
            optionsList={yearsListDropdown()}
            initialvalue={previousMonthDate.getFullYear().toString()}
            parentCallback={(e) => {
              const newDate = new Date(selectedDate);
              newDate.setFullYear(+e);
              setSelectedDate(new Date(newDate));
            }}
          />
        </div>
        <div className={classes.pickerContainer}>
          <CommonText
            textVariant="14 medium"
            colorOverride="white"
            className={classes.pickerLabel}
          >
            Periode:
          </CommonText>
          <OptionsPicker
            className={classes.monthPicker}
            reverse
            initialvalue={previousMonthDate.getMonth().toString()}
            optionsList={{
              0: 'Januar',
              1: 'Februar',
              2: 'Mars',
              3: 'April',
              4: 'Mai',
              5: 'Juni',
              6: 'Juli',
              7: 'August',
              8: 'September',
              9: 'Oktober',
              10: 'November',
              11: 'Desember',
            }}
            parentCallback={(e) => {
              const newDate = new Date(selectedDate);
              newDate.setMonth(+e);
              setSelectedDate(new Date(newDate));
            }}
          />
        </div>
      </div>
      <div className={classes.middleElement}>
        <div className={classes.valueIndicatior}>
          <ValueIndicator
            label="RUH"
            icon={faHardHat}
            value={
              ruhReportYear?.months?.[monthConverter(selectedDate)]?.sumRuh
            }
            previous={{
              value: ruhPreviousMonth?.sumRuh,
              comparison: 'compare',
              endingText: 'enn forrige måned',
              isIncreaseGood: 'no',
            }}
          />
        </div>
        <div className={classes.valueIndicatior}>
          <ValueIndicator
            label="RUH sum hittil i år"
            icon={faCalendarAlt}
            value={ruhReportYear?.sumRuh}
          />
        </div>
        <div className={classes.valueIndicatior}>
          <ValueIndicator
            label="SJA"
            icon={faFileInvoice}
            value={
              sjaReportYear?.months?.[monthConverter(selectedDate)]?.sumSja
            }
            previous={{
              value: sjaPreviousMonth?.sumSja,
              comparison: 'compare',
              endingText: 'enn forrige måned',
              isIncreaseGood: 'yes',
            }}
          />
        </div>
        <div className={classes.valueIndicatior}>
          <ValueIndicator
            label="SJA sum hittil i år"
            icon={faCalendarAlt}
            value={sjaReportYear?.sumSja}
          />
        </div>
      </div>
      <Divider orientation="vertical" />
      <div className={classes.rightElement}>
        <CommonText textVariant="14 medium">
          Så langt i {selectedDate.getFullYear()}:
        </CommonText>
        <BarChart
          margin={{ top: 0, bottom: 10, left: 0, right: 0 }}
          dataKeys={['RUH', 'SJA']}
          colorArray={[
            theme.colors.base.base2 as string,
            theme.colors.base.base1dark as string,
          ]}
          data={
            sjaRuhYearMonthMapper(sjaReportYear, ruhReportYear) as unknown as {
              [key: string]: string | number;
            }[]
          }
        />
      </div>
    </Paper>
  );
}
