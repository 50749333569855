import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import TextField from 'components/common/textInputs/TextField';
import CommonDialog from 'components/common/CommonDialog';
import { authorizedYmApi } from 'services/apiService';
import LocationPicker from 'components/common/map/locationPicker/LocationPicker';
import { NewYmPoint, YmPoint } from 'services/ymApi';
import YmMeasurementTextField from 'components/opsDash/ym/dialogs/YmMeasurementTextField';
import { MeasurementTypes } from 'components/opsDash/ym/MeasurementTypes';
import theme from 'config/theme';
import CommonButton from 'components/common/CommonButton';
import geohash from 'ngeohash';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '90%',
      width: '50%',
      padding: 10,
    },
    label: {
      padding: 10,
    },
    locationPicker: {
      borderRadius: 30,
    },
    mainDiv: {
      float: 'left',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    inputFieldsDiv: {
      flex: '0 0 50%',
      padding: '10px 40px 10px 40px',
    },
    inputFields: {
      borderRadius: 15,
      borderColor: theme.colors.base.background,
      border: 'solid',
    },
    oneElementDiv: {
      flex: '0 0 100%',
      padding: '10px 40px 10px 40px',
    },
    mapBoxDiv: {
      flex: '0 0 100%',
      padding: '30px 40px 10px 40px',
    },
    mapText: {
      display: 'inline-block',
    },
    commentsBox: {
      height: 80,
      borderColor: theme.colors.base.background,
      border: 'solid',
    },
    measurementTypesDiv: {
      width: '100%',
      display: 'flex',
      height: '100%',
      flexWrap: 'wrap',
    },
    measurementTypesRow: {
      width: '22%',
      marginTop: 10,
      marginBottom: 10,
      marginRight: 10,
      marginLeft: 15,
    },
    errorMessage: {
      width: '100%',
      paddingTop: 10,
      paddingLeft: 40,
    },
  })
);
export default function YmPointDialog({
  newYmPoint,
  ymPoint,
  isOpened,
  onClose,
  onSave,
}: {
  newYmPoint?: boolean;
  ymPoint?: YmPoint | undefined;
  isOpened: boolean;
  onClose: () => void;
  onSave: () => void;
}) {
  const classes = useStyles();
  const [formData, setFormData] = useState<NewYmPoint>({
    projectId: '',
    measurementPointId: '',
    waterSourceId: '',
    location: {
      longitude: 0,
      latitude: 0,
    },
    geohash: '',
  });
  useEffect(() => {
    if (ymPoint) {
      setFormData({
        measurementPointId: ymPoint.measurementPointId,
        projectId: ymPoint.projectId,
        waterSourceId: ymPoint.waterSourceId,
        location: ymPoint.centerPoint,
        thresholdValues: ymPoint.thresholdValues,
        place: ymPoint.place,
        comments: ymPoint.comments,
        geohash: ymPoint.geohash,
      });
    }
  }, [ymPoint, isOpened]);
  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(MeasurementTypes).map((value) => [value.label, true])
  );
  const [vaildations, setValidations] = useState({
    projectId: true,
    measurementPointId: true,
    waterSourceId: true,
    location: { longitude: true, latitude: true },
    initialStatesThresholdValuesValidation:
      initialStatesThresholdValuesValidation,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const validateForm = (form) => {
    const currentValidation = { ...vaildations };
    if (!form.location.longitude) {
      currentValidation.location.longitude = false;
      setErrorMessage('Posisjon i kart er ikke valgt');
    }
    if (!form.location.latitude) {
      currentValidation.location.latitude = false;
    }
    if (!form.projectId) {
      currentValidation.projectId = false;
      setErrorMessage(
        'Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart are viktig'
      );
    }
    if (!form.measurementPointId) {
      currentValidation.measurementPointId = false;
      setErrorMessage(
        'Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart are viktig'
      );
    }
    if (!form.waterSourceId) {
      currentValidation.waterSourceId = false;
      setErrorMessage(
        'Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart are viktig'
      );
    }

    setValidations(currentValidation);
    return (
      currentValidation.measurementPointId &&
      currentValidation.projectId &&
      currentValidation.waterSourceId &&
      currentValidation.location.latitude &&
      currentValidation.location.longitude
    );
  };
  const [saved, setSaved] = useState(false);
  const revertToInitialStates = () => {
    setErrorMessage('');
    setSaved(false);
    setFormData({
      projectId: '',
      measurementPointId: '',
      location: { longitude: 0, latitude: 0 },
      waterSourceId: '',
      geohash: '',
    });
  };
  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
      }
    }
    if (countFalse !== 0) {
      setErrorMessage('Ugyldige tegn er brukt. Gyldige formater:: 51.5, 51');
    } else if (validateForm(formData) && countFalse === 0) {
      authorizedYmApi().then((api) => {
        if (!ymPoint) {
          api
            .createYmPoint({
              newYmPoint: { ...formData },
            })
            .then(() => {
              revertToInitialStates();
              onSave();
              onClose();
            });
        } else {
          const updateData = {
            ...formData,
            data: ymPoint.data,
          };
          api
            .updateYmPoint({
              pointId: ymPoint.id,
              updateYmPoint: updateData,
            })
            .then(() => {
              revertToInitialStates();
              onSave();
              onClose();
            });
        }
      });
    }
    setSaved(false);
  }
  const validThreshold = (value, label) => {
    const re = /^[0-9\b]+(\.[0-9]+)?$/;
    let validated: boolean;
    if (value === '' || re.test(value)) {
      validated = true;
    } else {
      validated = false;
    }
    setValidations({
      ...vaildations,
      initialStatesThresholdValuesValidation: {
        ...vaildations.initialStatesThresholdValuesValidation,
        [label]: validated,
      },
    });
  };

  return (
    <CommonDialog
      title={newYmPoint ? 'Legg til nytt målepunkt' : 'Rediger'}
      className={classes.root}
      rightHeaderComponent={
        <CommonButton
          onClick={() => {
            if (!saved) {
              setSaved(true);
              onSaveClick();
            }
          }}
          text={newYmPoint ? 'Lagre' : 'Oppdater'}
        />
      }
      dialogContent={
        <React.Fragment>
          <div className={classes.mainDiv}>
            {errorMessage !== '' ? (
              <div className={classes.errorMessage}>
                <CommonText textVariant="12 light" colorOverride={'error'}>
                  {errorMessage}
                </CommonText>
              </div>
            ) : (
              ''
            )}
            <div className={classes.inputFieldsDiv}>
              <CommonText textVariant="14 light" className={classes.label}>
                Projekt Id:
              </CommonText>
              <TextField
                placeholder="ProsjektId..."
                editable
                defaultValue={ymPoint && ymPoint.projectId}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    projectId: value.target.value,
                  });
                  setValidations({ ...vaildations, projectId: true });
                  setSaved(false);
                }}
                error={!vaildations.projectId}
                className={classes.inputFields}
              />
            </div>
            <div className={classes.inputFieldsDiv}>
              <CommonText textVariant="12 light" className={classes.label}>
                Målepunkt Id:
              </CommonText>
              <TextField
                placeholder="MålepunktId..."
                editable
                defaultValue={ymPoint && ymPoint.measurementPointId}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    measurementPointId: value.target.value,
                  });
                  setValidations({
                    ...vaildations,
                    measurementPointId: true,
                  });
                  setSaved(false);
                }}
                error={!vaildations.measurementPointId}
                className={classes.inputFields}
              />
            </div>
            <div className={classes.inputFieldsDiv}>
              <CommonText textVariant="14 light" className={classes.label}>
                Vannkilde Id:
              </CommonText>
              <TextField
                placeholder="VannkildeId..."
                editable
                defaultValue={ymPoint && ymPoint.waterSourceId}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    waterSourceId: value.target.value,
                  });
                  setValidations({ ...vaildations, waterSourceId: true });
                  setSaved(false);
                }}
                error={!vaildations.waterSourceId}
                className={classes.inputFields}
              />
            </div>
            <div className={classes.inputFieldsDiv}>
              <CommonText textVariant="14 light" className={classes.label}>
                Sted:
              </CommonText>
              <TextField
                placeholder="Sted..."
                editable
                defaultValue={ymPoint && ymPoint.place}
                onChange={(value) => {
                  if (value.target.value === '') {
                    setFormData({ ...formData, place: undefined });
                  } else {
                    setFormData({
                      ...formData,
                      place: value.target.value,
                    });
                  }
                  setSaved(false);
                }}
                className={classes.inputFields}
              />
            </div>
          </div>
          <div className={classes.mainDiv}>
            <div className={classes.oneElementDiv}>
              <CommonText textVariant="14 light" className={classes.label}>
                Kommentar:
              </CommonText>
              <TextField
                placeholder="Kommentar..."
                editable
                defaultValue={ymPoint && ymPoint.comments}
                onChange={(value) => {
                  if (value.target.value === '') {
                    setFormData({ ...formData, comments: undefined });
                  } else {
                    setFormData({
                      ...formData,
                      comments: value.target.value,
                    });
                  }
                }}
                className={classes.commentsBox}
              />
            </div>
          </div>
          <div className={classes.mainDiv}>
            <div className={classes.oneElementDiv}>
              <CommonText textVariant="14 light" className={classes.label}>
                Terskelverdier:
              </CommonText>
              <div className={classes.measurementTypesDiv}>
                {MeasurementTypes.map((types, index) => {
                  return (
                    <div className={classes.measurementTypesRow} key={index}>
                      <YmMeasurementTextField
                        onChange={(value) => {
                          validThreshold(value, types.label);
                          if (value === '') {
                            const thresholdObject =
                              formData.thresholdValues &&
                              Object.fromEntries(
                                Object.entries(formData.thresholdValues).filter(
                                  ([key]) => key !== types.label
                                )
                              );
                            if (
                              thresholdObject &&
                              Object.entries(thresholdObject).length !== 0
                            ) {
                              setFormData({
                                ...formData,
                                thresholdValues: thresholdObject,
                              });
                            } else {
                              setFormData({
                                ...formData,
                                thresholdValues: undefined,
                              });
                            }
                          } else {
                            setFormData({
                              ...formData,
                              thresholdValues: {
                                ...formData.thresholdValues,
                                [types.label]: value,
                              },
                            });
                            setErrorMessage('');
                          }
                          setSaved(false);
                        }}
                        unit={types.unit}
                        label={types.label}
                        defaultValue={
                          ymPoint &&
                          ymPoint.thresholdValues &&
                          ymPoint.thresholdValues[types.label]
                        }
                        error={
                          !vaildations.initialStatesThresholdValuesValidation[
                            types.label
                          ]
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.mainDiv}>
            <div className={classes.mapBoxDiv}>
              <div className={classes.mapText}>
                <CommonText textVariant="14 light" className={classes.label}>
                  Plassering i kart:
                </CommonText>
              </div>
              <div
                className={classes.mapText}
                style={{
                  float: 'right',
                  marginRight: 15,
                }}
              >
                <CommonText textVariant="14 light" className={classes.label}>
                  {'Klikk i view for å plassere lokasjon'}
                </CommonText>
              </div>

              <div style={{ paddingTop: 10 }}>
                <LocationPicker
                  setLocation={(newLocation) => {
                    setFormData({
                      ...formData,
                      location: {
                        longitude: newLocation[0],
                        latitude: newLocation[1],
                      },
                      geohash: geohash.encode(
                        String(newLocation[1]),
                        String(newLocation[0]),
                        12
                      ),
                    });
                    setValidations({
                      ...vaildations,
                      location: { longitude: true, latitude: true },
                    });
                    setErrorMessage('');
                    setSaved(false);
                  }}
                  location={
                    (ymPoint &&
                      (ymPoint.geojson as unknown)) as GeoJSON.Feature<GeoJSON.Point>
                  }
                  className={classes.locationPicker}
                  editLocation={true}
                  parentCallback={() => undefined}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      onClose={() => {
        setValidations({
          projectId: true,
          measurementPointId: true,
          waterSourceId: true,
          location: { longitude: true, latitude: true },
          initialStatesThresholdValuesValidation:
            initialStatesThresholdValuesValidation,
        });
        revertToInitialStates();
        onClose();
      }}
      open={isOpened}
    />
  );
}
