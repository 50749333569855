import { useQueries } from 'react-query';
import { authorizedNvdbApi } from 'services/apiService';
import { NvdbObject, NvdbApi, NVDBObjectsResponse } from 'services/nvdbApi';

const paginationQuery = async (
  api: NvdbApi,
  hashKey: string,
  temporaryObjectStore: NvdbObject[],
  lastEvaluatedKey?: string
) => {
  await api
    .getNvdbObjects({ hashKey, lastEvaluatedKey })
    .then(async (response) => {
      response.objects &&
        response.objects.forEach((obj) => {
          temporaryObjectStore.push(obj);
        });
      if (response.lastEvaluatedKey) {
        await paginationQuery(
          api,
          hashKey,
          temporaryObjectStore,
          JSON.stringify(response.lastEvaluatedKey)
        );
      }
    });
};

export const useNvdbObjectsQueries = (hashKeyArray: string[]) => {
  const queryArray = hashKeyArray.map((element) => {
    return {
      queryKey: ['nvdbObjects', element],
      queryFn: async () => {
        const temporaryObjectStore: NvdbObject[] = [];
        const api = await authorizedNvdbApi();
        await paginationQuery(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
    };
  });
  const data: { [key: string]: NvdbObject } = {};
  const queriestResult = useQueries(queryArray);

  queriestResult.forEach((response) => {
    response.data &&
      (response.data as NVDBObjectsResponse).objects.forEach((obj) => {
        data[obj.id as string] = obj;
      });
  });

  return {
    data,
  };
};
