import * as materialColors from '@mui/material/colors';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLightbulb,
  faStoreAlt,
  faFireExtinguisher,
  faRoad,
  faThLarge,
  faCircle,
  faCogs,
  faPhone,
  faGripLines,
  faEllipsisH,
  faSign,
  faWater,
  faMapPin,
  faArchway,
  faCarBattery,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faLightbulb,
  faStoreAlt,
  faFireExtinguisher,
  faRoad,
  faThLarge,
  faCircle,
  faPhone,
  faCogs,
  faGripLines,
  faEllipsisH,
  faSign,
  faWater,
  faMapPin,
  faArchway,
  faCarBattery,
  faPlus
);

export const objectList = {
  Belysningspunkt: {
    clusterColor: materialColors.lightBlue[700],
    markerColor: 'blue',
    markerIcon: 'lightbulb',
  },
  Bomstasjon: {
    clusterColor: materialColors.amber[700],
    markerColor: 'orange',
    markerIcon: 'store-alt',
  },
  Brannslokningsapparat: {
    clusterColor: materialColors.red[700],
    markerColor: 'red',
    markerIcon: 'fire-extinguisher',
  },
  Fortau: {
    clusterColor: materialColors.brown[700],
    markerColor: 'red',
    markerIcon: 'road',
  },
  Kantstein: {
    clusterColor: materialColors.brown[700],
    markerColor: 'red',
    markerIcon: 'th-large',
  },
  Kum: {
    clusterColor: materialColors.grey[700],
    markerColor: 'cadetblue',
    markerIcon: 'circle',
  },
  Nødtelefon: {
    clusterColor: materialColors.red[700],
    markerColor: 'red',
    markerIcon: 'phone',
  },
  Pumpe: {
    clusterColor: materialColors.deepPurple[700],
    markerColor: 'purple',
    markerIcon: 'cogs',
  },
  Rekkverk: {
    clusterColor: materialColors.green[700],
    markerColor: 'red',
    markerIcon: 'grip-lines',
  },
  Rekkverksende: {
    clusterColor: materialColors.grey[700],
    markerColor: 'cadetblue',
    markerIcon: 'ellipsis-h',
  },
  Skiltplate: {
    clusterColor: materialColors.indigo[700],
    markerColor: 'darkpurple',
    markerIcon: 'sign',
  },
  'Stikkrenne/Kulvert': {
    clusterColor: materialColors.lightBlue[700],
    markerColor: 'red',
    markerIcon: 'water',
  },
  Trafikklomme: {
    clusterColor: materialColors.yellow[700],
    markerColor: 'red',
    markerIcon: 'map-pin',
  },
  Tunnel: {
    clusterColor: materialColors.lime[700],
    markerColor: 'green',
    markerIcon: 'archway',
  },
  Tunnelløp: {
    clusterColor: materialColors.orange[700],
    markerColor: 'green',
    markerIcon: 'archway',
  },
  UPS: {
    clusterColor: materialColors.green[700],
    markerColor: 'green',
    markerIcon: 'car-battery',
  },
  'Variabelt skilt': {
    clusterColor: materialColors.orange[700],
    markerColor: 'orange',
    markerIcon: 'sign',
  },
  Vegkryss: {
    clusterColor: materialColors.red[700],
    markerColor: 'red',
    markerIcon: 'plus',
  },
};
