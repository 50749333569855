import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonDialog from 'components/common/CommonDialog';

import { format } from 'date-fns';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';
import theme from 'config/theme';
import CommonDeleteDialog from 'components/common/CommonDeleteDialog';
import EditYmMeasurementDialog from 'components/opsDash/ym/dialogs/EditYmMeasurementDialog';

import { YmPoint } from 'services/ymApi';
import { authorizedYmApi } from 'services/apiService';
import YmHistoricalAllTable from './YmHistoricalAllTable';

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '90%', width: '90%' },
    pickers: { display: 'flex', alignItems: 'center', paddingRight: 20 },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
    table: {},
  })
);

export default function YmHistoricalListDialog({
  ymPoint,
  refetch,
  open,
  onClose,
}: {
  refetch: () => void;
  ymPoint: YmPoint;
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState(undefined);

  // str.substring(1), removes the first character of str

  const measurementHistoryDateConverter = (
    shortYear: string,
    shortMonth: string,
    shortDay: string
  ) => {
    const year = '20' + shortYear.substring(1);
    const monthZeroIndex = shortMonth.substring(1);
    const day = shortDay.substring(1);

    return new Date(+year, +monthZeroIndex - 1, +day);
  };

  interface historicalData {
    [key: string]: yearData;
  }
  interface yearData {
    [key: string]: monthData;
  }

  interface monthData {
    [key: string]: dayData;
  }
  interface dayData {
    [key: string]: measurement;
  }

  interface measurement {
    date?: string;
    measurementIndication: string;
    unit: string;
    value: string;
    referenceId: string;
  }

  const ymPointMeasurements: (historicalDataRecord: historicalData) => {
    reportData: object[];
    ymMeasurementHeaders: { id: string; label: string }[];
  } = (historicalDataRecord: historicalData) => {
    const reportData: object[] = [];
    const ymMeasurementHeaders: { id: string; label: string }[] = [];
    Object.entries(historicalDataRecord).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([month, monthData]) => {
        Object.entries(monthData).forEach(([day, dayData]) => {
          const dayDate = measurementHistoryDateConverter(year, month, day);
          var valueObject = {};
          var refId: string = '';
          Object.entries(dayData).forEach(([type, typeData]) => {
            valueObject = {
              ...valueObject,
              [type]: typeData.value,
            };
            refId = typeData.referenceId;
            const headerObject = { id: type, label: type };

            const exists = !ymMeasurementHeaders.some(
              (header) => header.id === headerObject.id
            );

            if (exists) {
              ymMeasurementHeaders.push(headerObject);
            }
          });

          const obj = {
            ...valueObject,
            dateString: format(dayDate, 'dd.MM.yyyy'),
            dayData,
            date: dayDate,
            refId,
          };

          dayData && reportData.push(obj);
        });
      });
    });

    return { reportData, ymMeasurementHeaders };
  };

  const reportData =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData)
      .reportData;

  const measurementHeaders =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData)
      .ymMeasurementHeaders;

  let dateHeaderArray: { id: string; label: string }[] = [];
  if (measurementHeaders) {
    dateHeaderArray = [{ id: 'dateString', label: 'Dato' }]
      .concat(measurementHeaders)
      .concat([
        { id: 'edit', label: 'Rediger' },
        { id: 'delete', label: 'Slett' },
      ]);
  }

  const temp = selectedMeasurement as
    | {
        dateString: string;
        value: string;
        dayData: dayData;
        date: Date;
        refId: string;
      }
    | undefined;
  const selectedrefId: string | undefined = temp?.refId;

  return (
    <React.Fragment>
      <CommonDialog
        open={open}
        onClose={onClose}
        title={'Historie liste for alle målinger'}
        className={classes.root}
        rightHeaderComponent={
          <div className={classes.pickers}>
            <CommonFab onClick={onClose} noShadow icon="x" />
          </div>
        }
        dialogContent={
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {reportData && reportData.length > 0 && measurementHeaders ? (
              <YmHistoricalAllTable
                className={classes.table}
                headers={dateHeaderArray}
                data={
                  reportData as unknown as {
                    [key: string]: string | number;
                  }[]
                }
                sortable
                onDeleteClick={(data) => {
                  setDeleteDialogOpen(true);
                  setSelectedMeasurement(data);
                }}
                onEditClick={(data) => {
                  setEditDialogOpen(true);
                  setSelectedMeasurement(data);
                }}
              />
            ) : (
              <div className={classes.centeringContainer}>
                <CommonText
                  textVariant="20 medium"
                  className={classes.pickerLabel}
                >
                  Ingen data denne perioden
                </CommonText>
              </div>
            )}
          </div>
        }
      />
      {deleteDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onClick={() => {
            if (selectedMeasurement && selectedrefId && selectedrefId !== '') {
              authorizedYmApi().then((api) => {
                api
                  .deleteYmMeasurement({
                    referenceId: selectedrefId,
                    deleteYmMeasurement: {
                      data: { _delete: 'all' },
                    },
                  })
                  .then(refetch);
              });
            }
          }}
          messageText="Er du sikker på at du vil slette alle verdier for denne dagen?"
          buttonText="Ja, slett verdiene"
        />
      )}
      {ymPoint && editDialogOpen && selectedrefId && selectedrefId !== '' && (
        <EditYmMeasurementDialog
          isOpened={editDialogOpen}
          ymPointId={ymPoint.id}
          ymPointMeasurementId={ymPoint.measurementPointId}
          selectedMeasurement={selectedMeasurement}
          editAll
          onClose={() => {
            setEditDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          referenceId={selectedrefId}
        />
      )}
    </React.Fragment>
  );
}
