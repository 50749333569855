import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from 'react';
import * as L from 'leaflet';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import YmMarkerCluster from 'components/opsDash/ym/mapView/YmMarkerCluster';
import geohash from 'ngeohash';
import { groupBy } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { YmMapContext } from 'utils/context/YmMap';
import { useYmPointQueries } from 'services/ymQueryHooks';

import theme from 'config/theme';

// Overriding leaflet's CSS
const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.leaflet-container': {
      height: '100%',
      width: '100%',
      minHeight: 350,
    },
    '.leaflet-control-layers:hover': {
      'text-align': 'left',
      'box-shadow': '0 1px 5px rgba(0, 0, 0, 0.4)',
      'border-radius': '5px',
    },
    '.leaflet-control-attribution.leaflet-control': {
      'margin-right': '30px',
    },
  },
})(() => null);

const useStyles = makeStyles(() =>
  createStyles({
    root: { height: '100%', width: '100%' },
    loadingContainer: {
      zIndex: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

export default function MapView({
  ymPointQueries,
}: {
  ymPointQueries: ReturnType<typeof useYmPointQueries>;
}) {
  const classes = useStyles();

  const leafletMapRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

  const { ymMapState, setYmMapState } = useContext(YmMapContext);
  const handlecords = useCallback(
    (mapRef) => {
      const southWest = mapRef.getBounds()._southWest;
      const northEast = mapRef.getBounds()._northEast;
      let precision = 5;
      let calculatedBBXHashArray = geohash.bboxes(
        southWest.lat,
        southWest.lng,
        northEast.lat,
        northEast.lng,
        precision
      );
      while (calculatedBBXHashArray.length > 5) {
        precision -= 1;
        calculatedBBXHashArray = geohash.bboxes(
          southWest.lat,
          southWest.lng,
          northEast.lat,
          northEast.lng,
          precision
        );
      }
      const updatedHashArray = [...ymMapState.hashArray];
      calculatedBBXHashArray.forEach((element) => {
        if (updatedHashArray.length === 0) {
          updatedHashArray.push(element);
        }

        if (!updatedHashArray.includes(element)) {
          updatedHashArray.push(element);
        }
      });

      setYmMapState((prevState) => {
        return { ...prevState, hashArray: updatedHashArray };
      });
    },
    [setYmMapState, ymMapState.hashArray]
  );

  const [initMap, setInitMap] = useState(false);

  useEffect(() => {
    if (!initMap) {
      handlecords(leafletMapRef.current.leafletElement);
      setInitMap(true);
    }
  }, [handlecords, initMap, setInitMap]);

  const ymPoints = ymPointQueries.data;
  const groupedData = ymPoints && groupBy(Object.values(ymPoints), 'status');
  let statuses = groupedData && Object.keys(groupedData);
  statuses = statuses.sort(); // Hack, but works for now. Probably not bulletproof. Used to avoid getting wrong colors if the amount of colors changes from 2 to 3 or vice versa, in some spesific cases.

  useEffect(() => {
    return () => {
      setInitMap(false);
      ymPointQueries.remove();

      setYmMapState((prevState) => {
        return {
          ...prevState,
          centerPoint: new L.LatLng(58.23622776977281, 8.306275497572745),
          zoomLevel: 12,
        };
      });
    };
    // the disable lint warning bellow is used as the effect hook should not run any other time
    // other than on component unmount. Adding other dependencies would break this behaviour.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <GlobalCss />
      {!ymPoints && (
        <div className={classes.loadingContainer}>
          <CircularProgress
            size={150}
            classes={{ circle: classes.spinnerCircle }}
          />
        </div>
      )}
      <LeafletMap
        ref={leafletMapRef}
        style={{
          backgroundColor: '#e1f4ff',

          zIndex: 0,
        }}
        key="mainmap"
        center={ymMapState.centerPoint}
        zoom={ymMapState.zoomLevel}
        maxZoom={20}
        minZoom={7}
        attributionControl={true}
        zoomControl={false}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        onzoomend={(e) => {
          handlecords(e.target);
        }}
        onclick={() => {
          setYmMapState((prevState) => {
            return { ...prevState, drawerOpen: false, sideMenuOpen: false };
          });
        }}
        ondragend={(e) => {
          handlecords(e.target);
        }}
      >
        <TileLayer
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}"
          attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
        />
        {statuses.map((status, index) => {
          return (
            <YmMarkerCluster
              key={index}
              clusterColor={
                status === 'ok'
                  ? 'green'
                  : status === 'warning'
                  ? 'orange'
                  : status === 'alarm'
                  ? 'red'
                  : 'cadetblue'
              }
              data={groupedData[status]}
            />
          );
        })}
      </LeafletMap>
    </div>
  );
}
