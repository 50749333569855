import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import CommonText from 'components/common/textOutput/CommonText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import documentIconSelector from 'utils/documentIconSelector';

import { ProjectDocumentRelation } from 'services/deliveryApi/models';
import { formatDateToLocalDate } from 'utils/dateTimeHandler';

import DocumentTableItemMenu from 'components/deliveryDash/project/documentsCard/DocumentTableItemMenu';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px 10px rgba(212, 220, 224, 0.36)',
      },
    },
    icon: { padding: 20, paddingLeft: 40 },
    popperPaper: {
      minWidth: 50,
      minHeight: 45,
      borderRadius: 7,
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    menuListItem: {
      display: 'flex',
      justifyContent: 'center',
      '&:hover': { backgroundColor: 'white' },
    },
    deleteText: {
      fontWeight: 500,
      color: theme.colors.messageAndAlerts.error,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    downloadText: {
      fontWeight: 500,
      color: theme.colors.messageAndAlerts.success,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    viewPointcloud: {
      color: theme.colors.messageAndAlerts.warning,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    dialogAlertContainer: {
      width: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export default function DocumentTableItem({
  document,
  hideDelete,
  refetchDocuments,
}: {
  document: ProjectDocumentRelation;
  hideDelete?: boolean;
  refetchDocuments: () => void;
}) {
  const classes = useStyles();

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.icon} align="center">
        <FontAwesomeIcon
          icon={documentIconSelector(document.documentType).icon}
          color={documentIconSelector(document.documentType).color}
          size="3x"
        />
      </TableCell>
      <TableCell align="left">
        <CommonText textVariant="12 medium">{document.documentName}</CommonText>
      </TableCell>
      <TableCell align="center">
        <CommonText textVariant="12 medium">
          Opprettet: {formatDateToLocalDate(document.documentTimeCreated)}
        </CommonText>
      </TableCell>
      <TableCell align="center">
        <DocumentTableItemMenu
          document={document}
          hideDelete={hideDelete}
          refetchDocuments={refetchDocuments}
        />
      </TableCell>
    </TableRow>
  );
}
