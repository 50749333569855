import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TopView from 'components/opsDash/TopView';
import NAndH from 'components/opsDash/hms/NAndH';
import RUHandSJA from 'components/opsDash/hms/RUHandSJA';
import RUHReportDialog from 'components/opsDash/hms/dialog/RUHReportDialog';
import SJAReportDialog from 'components/opsDash/hms/dialog/SJAReportDialog';
import PageMenu from 'components/common/pageMenu/PageMenu';
import NAndHReportDialog from 'components/opsDash/hms/dialog/NAndHReportDialog';
import AddMonthlyNumbersNAndHDialog from '../hms/dialog/AddMonthlyNumbersNAndHDialog';
import AddRUHDialog from '../hms/dialog/AddRUHDialog';
import AddSJADialog from '../hms/dialog/AddSJADialog';
import { useQueryHmsCounter } from 'services/hmsQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topElement: { flex: 1, width: '100%', padding: 40, paddingBottom: 20 },
    bottomElement: { flex: 1, width: '100%', padding: 40, paddingTop: 20 },
    cards: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
  })
);

export default function HMS() {
  const classes = useStyles();

  const HmsCounterQuery = useQueryHmsCounter();

  const hmsCounterData = HmsCounterQuery.data;

  return (
    <div className={classes.root}>
      <TopView title="HMS" />
      <div className={classes.body}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PageMenu
            menuItems={[
              <AddMonthlyNumbersNAndHDialog
                key="AddMonthlyNumbersNAndHDialog"
                refetch={HmsCounterQuery.refetch}
              />,
              <NAndHReportDialog
                key="NAndHReportDialog"
                hmsCounterData={hmsCounterData}
                isLoading={HmsCounterQuery.isLoading}
                refetch={HmsCounterQuery.refetch}
              />,
            ]}
          />
          <PageMenu
            menuItems={[
              <AddRUHDialog
                key={'AddRUHDialog'}
                refetch={HmsCounterQuery.refetch}
              />,
              <AddSJADialog
                key={'AddSJADialog'}
                refetch={HmsCounterQuery.refetch}
              />,
              <RUHReportDialog
                key={'RUHReportDialog'}
                hmsCounterData={hmsCounterData}
                isLoading={HmsCounterQuery.isLoading}
                refetch={HmsCounterQuery.refetch}
              />,
              <SJAReportDialog
                key={'SJAReportDialog'}
                hmsCounterData={hmsCounterData}
                isLoading={HmsCounterQuery.isLoading}
                refetch={HmsCounterQuery.refetch}
              />,
            ]}
          />
        </div>
        <div className={classes.cards}>
          <div className={classes.topElement}>
            <NAndH hmsCounterData={hmsCounterData} />
          </div>
          <div className={classes.bottomElement}>
            <RUHandSJA hmsCounterData={hmsCounterData} />
          </div>
        </div>
      </div>
    </div>
  );
}
