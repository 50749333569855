import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/common/textInputs/TextField';
import CommonText from 'components/common/textOutput/CommonText';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      zIndex: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
    },
    label: {
      paddingTop: 10,
      width: 250,
    },
    textField: {
      width: 200,
    },
    units: {
      paddingTop: 10,
      width: 240,
    },
    inputField: {
      borderRadius: 5,
      width: 42,
      height: 42,
      backgroundColor: theme.colors.base.white,
      borderColor: '#eff2f5',
      border: 'solid',
    },
  })
);

export default function YmMeasurementTextField({
  label,
  unit,
  onChange,
  error,
  defaultValue,
}: {
  label: string;
  unit: string;
  onChange: (value: string) => void;
  error?: boolean;
  defaultValue?: string | undefined;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <CommonText textVariant="14 light">{label}:</CommonText>
      </div>
      <div className={classes.textField}>
        <TextField
          editable={true}
          onChange={(value) => {
            onChange(value.target.value);
          }}
          error={error}
          defaultValue={defaultValue}
          className={classes.inputField}
        />
      </div>
      <div className={classes.units}>
        <CommonText textVariant="14 light">{unit}</CommonText>
      </div>
    </div>
  );
}
