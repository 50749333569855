import React, { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { LatLng } from 'leaflet';
import LocationMarker from 'components/common/map/locationPicker/LocationMarker';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

// Overriding leaflet's CSS
const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.leaflet-container': {
      height: '100%',
      width: '100%',
    },
    '.leaflet-control-layers:hover': {
      'text-align': 'left',
      'box-shadow': '0 1px 5px rgba(0, 0, 0, 0.4)',
      'border-radius': '5px',
    },
    '.leaflet-control-attribution.leaflet-control': {
      'margin-right': '70px',
    },
  },
})(() => null);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      height: '100%',
      zIndex: 0,

      backgroundColor: '#e1f4ff',
    },
  })
);

export default function LocationPicker({
  parentCallback,
  location,
  editLocation,
  className,
  setLocation,
}: {
  parentCallback(): void;
  location?: GeoJSON.Feature<GeoJSON.Point>;
  editLocation?: boolean;
  className?: string;
  setLocation: Dispatch<SetStateAction<string[]>>;
}) {
  const classes = useStyles();

  const [marker, setMarker] = useState<
    GeoJSON.Feature<GeoJSON.Point> | undefined
  >(location ? location : undefined);

  const [zoom, setZoom] = useState(7);

  // Leaflet map uses [lat, long], not [long, lat] as geoJSON does.
  // Therefore the numbers must be flipped and defined as a
  // LatLng object before they can be used as center point.
  const mapCenterPoint: LatLng =
    marker && marker.geometry.coordinates.length > 0
      ? new LatLng(
          marker.geometry.coordinates[1],
          marker.geometry.coordinates[0]
        )
      : new LatLng(58.5, 8.0);

  return (
    <div
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
    >
      <LeafletMap
        className={
          className ? clsx(classes.root, { [className]: true }) : classes.root
        }
        ref={(ref) => {
          if (ref && ref.leafletElement) {
            ref.leafletElement.on('zoomend', (event) => {
              setZoom(event.target._zoom);
            });
          }
        }}
        key="mainmap"
        center={mapCenterPoint}
        zoom={zoom}
        maxZoom={20}
        attributionControl={true}
        zoomControl={false}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        onclick={(e) => {
          const newMarker: GeoJSON.Feature<GeoJSON.Point> = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [e.latlng.lng, e.latlng.lat],
            },
            properties: {},
          };
          const strarr = [String(e.latlng.lng), String(e.latlng.lat)];

          setLocation(strarr);
          if (editLocation) {
            setMarker(newMarker);
            parentCallback();
          }
        }}
      >
        <GlobalCss />
        <TileLayer
          url="https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}"
          attribution='<a href="http://www.kartverket.no/">Kartverket</a>'
        />

        <LocationMarker location={marker} />
      </LeafletMap>
    </div>
  );
}
