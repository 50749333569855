/* tslint:disable */
/* eslint-disable */
/**
 * Ameta nvdb API
 * A rest api for managing the ameta nvdb system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NVDBObjectsResponseLastEvaluatedKey
 */
export interface NVDBObjectsResponseLastEvaluatedKey {
    /**
     * 
     * @type {string}
     * @memberof NVDBObjectsResponseLastEvaluatedKey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NVDBObjectsResponseLastEvaluatedKey
     */
    sort?: string;
    /**
     * 
     * @type {string}
     * @memberof NVDBObjectsResponseLastEvaluatedKey
     */
    geotype?: string;
    /**
     * 
     * @type {string}
     * @memberof NVDBObjectsResponseLastEvaluatedKey
     */
    geohash?: string;
}

export function NVDBObjectsResponseLastEvaluatedKeyFromJSON(json: any): NVDBObjectsResponseLastEvaluatedKey {
    return NVDBObjectsResponseLastEvaluatedKeyFromJSONTyped(json, false);
}

export function NVDBObjectsResponseLastEvaluatedKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NVDBObjectsResponseLastEvaluatedKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'geotype': !exists(json, 'geotype') ? undefined : json['geotype'],
        'geohash': !exists(json, 'geohash') ? undefined : json['geohash'],
    };
}

export function NVDBObjectsResponseLastEvaluatedKeyToJSON(value?: NVDBObjectsResponseLastEvaluatedKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'geotype': value.geotype,
        'geohash': value.geohash,
    };
}


