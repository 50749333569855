import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import CommonText from 'components/common/textOutput/CommonText';
import FeatherIcon from 'feather-icons-react';

import theme from 'config/theme';
import CommonFabMaterialUiIcons from 'components/CommonFabMaterialUiIcons';
import PageviewIcon from '@mui/icons-material/Pageview';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      borderRadius: 25,
      flexDirection: 'column',
      padding: 0,
      backgroundColor: theme.colors.base.base1,
      border: 'solid',
      borderWidth: 5,
      borderColor: theme.colors.base.white,
    },
    header: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 64,
    },
    headerTop: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 18,
      paddingRight: 18,
    },
    headerLeft: {
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    textContainer: {
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    body: {
      flex: 4,
      width: '100%',
      backgroundColor: theme.colors.base.background,
      padding: 15,
      borderBottomLeftRadius: 19,
      borderBottomRightRadius: 19,
    },
    innerPaper: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
    },
    subtitle: { paddingLeft: 4 },
    underText: { padding: 8, fontWeight: 400 },
  })
);

export default function DashCard({
  className,
  title,
  subtitle,
  bodyComponent,
  noBodyPaper,
  onDetailsClick,
}: {
  className?: string;
  title?: string;
  subtitle?: string;
  bodyComponent: React.ReactNode;
  noBodyPaper?: boolean;
  onDetailsClick?: () => void;
}) {
  const classes = useStyles();

  let iconSize = 20;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 25;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 30;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 42;
  }

  return (
    <Paper
      className={
        className ? clsx(classes.root, { [className]: true }) : classes.root
      }
    >
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <div style={{ width: 35 }} />
          <div className={classes.headerLeft}>
            <div
              style={{
                height: iconSize + 10,
                width: iconSize + 10,
                borderRadius: 25,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.colors.base.white,
              }}
            >
              <FeatherIcon
                size={iconSize}
                icon="bar-chart-2"
                color={theme.colors.icons.active.dark}
              />
            </div>
            <div className={classes.textContainer}>
              {title && (
                <CommonText colorOverride="white" textVariant="16 medium">
                  {title}
                </CommonText>
              )}
              {subtitle && (
                <CommonText
                  className={classes.subtitle}
                  colorOverride="white"
                  textVariant="14 light"
                >
                  {subtitle}
                </CommonText>
              )}
            </div>
          </div>
          <CommonFabMaterialUiIcons
            icon={
              <PageviewIcon style={{ color: theme.colors.icons.active.dark }} />
            }
            onClick={() => {
              onDetailsClick && onDetailsClick();
            }}
          />
        </div>
      </div>

      <div className={classes.body}>
        {noBodyPaper ? (
          bodyComponent
        ) : (
          <Paper className={classes.innerPaper}>{bodyComponent}</Paper>
        )}
      </div>
    </Paper>
  );
}
