import React, { useState, useRef, ReactElement } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  PieChart,
  Pie,
  Sector,
  SectorProps,
  Cell,
  ResponsiveContainer,
} from 'recharts';

import CommonText from 'components/common/textOutput/CommonText';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      width: '100%',
      position: 'relative',
    },
    chart: { zIndex: 1 },
    backLayer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '13%',
      position: 'absolute',
    },
    backLayerCircle: {
      width: '100%',
      aspectRatio: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: theme.colors.base.lightGrey,
    },
    centerTextContainer: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

declare type PieActiveShape =
  | ReactElement<SVGElement>
  | ((props: any) => ReactElement<SVGElement>)
  | SectorProps;

function CirclePieChart({
  className,
  data,
  onMouseOver,
  onMouseLeave,
}: {
  className?: string;
  data: {
    name: string;
    value: number;
    color: string | undefined;
  }[];
  onMouseOver?: (label: string) => void;
  onMouseLeave?: () => void;
}) {
  const classes = useStyles();
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<undefined | number>(undefined);

  const renderActiveShape: PieActiveShape = (props: SectorProps) => {
    return (
      <g>
        <Sector
          cx={props.cx}
          cy={props.cy}
          innerRadius={props.innerRadius ? props.innerRadius - 7 : 0}
          outerRadius={props.outerRadius ? props.outerRadius + 7 : 0}
          startAngle={props.startAngle}
          endAngle={props.endAngle}
          fill={props.fill}
        />
      </g>
    );
  };

  return (
    <div
      ref={chartRef}
      className={clsx(classes.root, {
        [className as string]: className,
      })}
    >
      <div className={classes.backLayer}>
        <div className={classes.backLayerCircle} />

        <div className={classes.centerTextContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            {activeIndex !== undefined ? (
              <React.Fragment>
                <CommonText style={{ paddingLeft: 10 }} textVariant="12 light">
                  {data[activeIndex].name}
                </CommonText>

                <CommonText textVariant="20 medium">
                  {data[activeIndex].value} stk.
                </CommonText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CommonText style={{ paddingLeft: 10 }} textVariant="12 light">
                  Alarmerende punkter
                </CommonText>

                <CommonText textVariant="20 medium">
                  {data[0].value + data[1].value} {'/ '}
                  {data[0].value + data[1].value + data[2].value}
                </CommonText>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <ResponsiveContainer className={classes.chart} height="99%" width="99%">
        <PieChart margin={{ bottom: 0, left: 5, right: 0, top: 2 }}>
          <Pie
            blendStroke
            data={data}
            onMouseEnter={(_, index) => {
              setActiveIndex(index);
              onMouseOver && onMouseOver(data[index].name);
            }}
            onMouseLeave={() => {
              setActiveIndex(undefined);
              onMouseLeave && onMouseLeave();
            }}
            innerRadius="85%"
            outerRadius="95%"
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={data[index % data.length].color}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CirclePieChart;
