import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: 100,
    },
    button: { margin: 20 },
  })
);

function SharingComplete({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CommonText textVariant="20 medium">{message}</CommonText>
      <CommonFab className={classes.button} onClick={onClose} text="Lukk" />
    </div>
  );
}

export default SharingComplete;
