import React, { createContext, useState } from 'react';

type SelectedProjectIdState = {
  selectedProjectId: string;
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string>>;
};

const SelectedProjectContext = createContext<SelectedProjectIdState>({
  selectedProjectId: '',
  setSelectedProjectId: () => {
    // do nothing
  },
});

const SelectedProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState('');

  return (
    <SelectedProjectContext.Provider
      value={{ selectedProjectId, setSelectedProjectId }}
    >
      {children}
    </SelectedProjectContext.Provider>
  );
};

export { SelectedProjectContext, SelectedProjectProvider };
