import React from 'react';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';

import CustomerSupport from 'components/common/CustomerSupport';

import ametaLogo from 'assets/img/Ameta-logo-hvit-flat_1.svg';
import frontpageBackground from 'assets/img/frontpage_background.jpg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    backgroundImage: {
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: '100%',
    },
    mainLayout: {
      zIndex: 2,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    leftColumn: {
      zIndex: 2,
      flex: 4,
      height: '100%',
      paddingLeft: 135,
      paddingTop: 135,
      paddingBottom: 135,
    },
    leftColumnContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    leftColumnTop: {
      width: '100%',
      flex: 1,
    },
    leftColumnMiddle: {
      width: '100%',
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
    },
    leftColumnBottom: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    logo: { height: 125 },
    rightColumn: {
      zIndex: 2,
      flex: 3.2,
      height: '100%',
      padding: 18,
    },
    rightColumnContent: {
      width: '100%',
      height: '100%',
      boxShadow: '-30px 4px 44px rgba(26, 48, 53, 0.1)',
      backgroundColor: 'white',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'column',
      padding: 128,
    },
    focusVisble: {
      color: 'rgba(113, 146, 176, 0.22)',
    },

    newUserButton: {
      minHeight: 55,
      width: '25%',
      backgroundColor: 'white',
      '&:hover': {
        background: 'white',
        boxShadow: '0px 4px 10px rgba(212, 220, 224, 0.78)',
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      boxShadow: '0px 2px 4px rgba(217, 219, 225, 0.6)',
    },
    textContent: { width: 550 },
  })
);

export default function FrontPageContent({
  rightColumnContent,
}: {
  rightColumnContent: JSX.Element;
}) {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={classes.root}>
      <img
        className={classes.backgroundImage}
        alt="Frontpage background"
        src={frontpageBackground}
      />
      <div className={classes.mainLayout}>
        <div className={classes.leftColumn}>
          <div className={classes.leftColumnContent}>
            <div className={classes.leftColumnTop}>
              <img className={classes.logo} alt="Ameta logo" src={ametaLogo} />
            </div>

            <div className={classes.leftColumnMiddle}>
              <div style={{ height: 20 }} />
              <div style={{ flex: 1 }}>
                <Fab
                  variant="extended"
                  className={classes.newUserButton}
                  TouchRippleProps={{
                    classes: { ripple: classes.focusVisble },
                  }}
                  onClick={() => {
                    history.push('/sign-up');
                  }}
                >
                  <CommonText textVariant="20 medium">
                    Lag ny bruker her
                  </CommonText>
                </Fab>
              </div>
            </div>
            <div className={classes.leftColumnBottom}>
              <CustomerSupport frontPage />
            </div>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.rightColumnContent}>{rightColumnContent}</div>
        </div>
      </div>
    </div>
  );
}
