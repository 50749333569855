import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GeoJSON } from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet.awesome-markers';
import 'assets/css/leaflet.awesome-markers/leaflet.awesome-markers.css';
import { CompanyProjectRelation } from 'services/deliveryApi/models/CompanyProjectRelation';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { renderToString } from 'react-dom/server';
import { SelectedProjectContext } from 'utils/context/SelectedProject';
import CommonText from 'components/common/textOutput/CommonText';
import { Divider } from '@mui/material';

import markerImage from 'assets/img/marker.svg';
import markerShadow from 'assets/img/markerShadow.svg';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 50,
      borderRadius: 7,
      padding: 20,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    divider: { marginLeft: 5, marginRight: 5, marginTop: 10, marginBottom: 10 },
    title: {
      marginBottom: 5,
    },
  })
);
function Markers({ projects }: { projects: CompanyProjectRelation[] }) {
  const { setSelectedProjectId } = useContext(SelectedProjectContext);
  const history = useHistory();
  const classes = useStyles();
  return (
    <React.Fragment>
      {projects.map((project) => {
        const GeoJson: GeoJSON.Feature =
          project.projectLocation as unknown as GeoJSON.Feature;
        return project &&
          project.projectLocation &&
          project.projectLocation.geometry.coordinates.length > 0 ? (
          <GeoJSON
            key={Math.random()}
            data={GeoJson}
            color="white"
            onclick={() => {
              setSelectedProjectId(project.sort);
              history.push('/dashboard/project');
            }}
            pointToLayer={(_, latlng) => {
              L.marker(latlng);
              const marker = L.marker(latlng, {
                icon: new L.Icon({
                  iconUrl: markerImage,
                  shadowUrl: markerShadow,
                  iconAnchor: [23, 46],
                  shadowAnchor: [37, 15],
                }),
              }).bindTooltip(
                renderToString(
                  <div>
                    <CommonText textVariant="14 medium">
                      {project.companyName}
                    </CommonText>
                    <Divider
                      light
                      orientation="horizontal"
                      className={classes.divider}
                    />
                    <CommonText
                      className={classes.title}
                      textVariant="12 light"
                    >
                      {project.projectName}
                    </CommonText>
                    <CommonText textVariant="12 light">
                      {project.projectNumber}
                    </CommonText>
                  </div>
                ),
                {
                  offset: [40, 35],
                  direction: 'right',
                  opacity: 1,
                  className: classes.root,
                }
              );
              return marker;
            }}
          ></GeoJSON>
        ) : null;
      })}
    </React.Fragment>
  );
}

export default Markers;
