import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonText from 'components/common/textOutput/CommonText';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
);

function CommonChartLegend({
  label,
  color,
  highlighted,
}: {
  label: string;
  color: string | undefined;
  highlighted?: boolean;
}) {
  const classes = useStyles();

  let iconSize = 12;
  if (window.innerWidth >= theme.breakpoints.values.fullhd) {
    iconSize = 16;
  }
  if (window.innerWidth >= theme.breakpoints.values.wqhd) {
    iconSize = 24;
  }
  if (window.innerWidth >= theme.breakpoints.values.uhd) {
    iconSize = 32;
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          height: iconSize,
          width: iconSize,
          borderRadius: '50%',
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: iconSize,
            width: iconSize,
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
      </div>
      <CommonText
        textVariant="12 light"
        colorOverride={highlighted ? 'secondary' : undefined}
      >
        {label}
      </CommonText>
    </div>
  );
}

export default CommonChartLegend;
