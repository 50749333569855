import { createTheme } from '@mui/material/styles';
import React from 'react';
declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      base: {
        base1: React.CSSProperties['color'];
        base1light: React.CSSProperties['color'];
        base1dark: React.CSSProperties['color'];
        base1darker: React.CSSProperties['color'];
        base2: React.CSSProperties['color'];
        lightGrey: React.CSSProperties['color'];
        grey: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        OpsRed: React.CSSProperties['color'];
        background: React.CSSProperties['color'];
      };
      text: {
        main: React.CSSProperties['color'];
        light: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        info: React.CSSProperties['color'];
      };
      icons: {
        active: {
          light: React.CSSProperties['color'];
          dark: React.CSSProperties['color'];
        };
        noState: {
          light: React.CSSProperties['color'];
          dark: React.CSSProperties['color'];
        };
      };
      buttons: {
        active: React.CSSProperties['color'];
        inActive: React.CSSProperties['color'];
        noState: React.CSSProperties['color'];
      };
      gradients: {
        green: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        neutral: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        horizontalYellow: React.CSSProperties['color'];
        horizontalOrange: React.CSSProperties['color'];
        horizontalRed: React.CSSProperties['color'];
      };
      indicators: {
        inactive: React.CSSProperties['color'];
        neutral: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        noticeable: React.CSSProperties['color'];
        significant: React.CSSProperties['color'];
        serious: React.CSSProperties['color'];
      };
      messageAndAlerts: {
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        highlightedInfo: React.CSSProperties['color'];
        cardInfo: React.CSSProperties['color'];
        notificationInfo: React.CSSProperties['color'];
      };
    };
  }
  interface ThemeOptions {
    colors: {
      base: {
        base1: React.CSSProperties['color'];
        base1light: React.CSSProperties['color'];
        base1dark: React.CSSProperties['color'];
        base1darker: React.CSSProperties['color'];
        base2: React.CSSProperties['color'];
        lightGrey: React.CSSProperties['color'];
        grey: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        OpsRed: React.CSSProperties['color'];
        background: React.CSSProperties['color'];
      };
      text: {
        main: React.CSSProperties['color'];
        light: React.CSSProperties['color'];
        white: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        info: React.CSSProperties['color'];
        unknown: React.CSSProperties['color'];
      };
      icons: {
        active: {
          light: React.CSSProperties['color'];
          dark: React.CSSProperties['color'];
        };
        noState: {
          light: React.CSSProperties['color'];
          dark: React.CSSProperties['color'];
        };
      };
      buttons: {
        active: React.CSSProperties['color'];
        inActive: React.CSSProperties['color'];
        noState: React.CSSProperties['color'];
      };
      gradients: {
        green: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        neutral: React.CSSProperties['color'];
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        horizontalYellow: React.CSSProperties['color'];
        horizontalOrange: React.CSSProperties['color'];
        horizontalRed: React.CSSProperties['color'];
      };

      indicators: {
        inactive: React.CSSProperties['color'];
        neutral: React.CSSProperties['color'];
        positive: React.CSSProperties['color'];
        noticeable: React.CSSProperties['color'];
        significant: React.CSSProperties['color'];
        serious: React.CSSProperties['color'];
      };
      messageAndAlerts: {
        error: React.CSSProperties['color'];
        warning: React.CSSProperties['color'];
        success: React.CSSProperties['color'];
        highlightedInfo: React.CSSProperties['color'];
        cardInfo: React.CSSProperties['color'];
        notificationInfo: React.CSSProperties['color'];
      };
    };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    hd: true;
    fullhd: true;
    wqhd: true;
    uhd: true;
  }
}

const theme = createTheme({
  colors: {
    base: {
      base1: '#7b94b5',
      base1light: '#E3ECF3',
      base1dark: '#122E4E',
      base1darker: '#0E2643',
      base2: '#17DFA8',
      lightGrey: '#E3ECF3',
      grey: '#97B6C8',
      white: '#FFFFFF',
      OpsRed: '#EC4115',
      background: '#EDF3F8',
    },
    text: {
      main: '#122E4E',
      light: '#E3ECF3',
      white: '#FFFFFF',
      success: '#3EB17E',
      warning: '#EBA836',
      error: '#C61D18',
      positive: '#17DFA8',
      info: '#446ACC',
      unknown: '#708090',
    },
    buttons: {
      active: '#122E4E',
      inActive: '#BBC9D8',
      noState: '#7894B2',
    },
    icons: {
      noState: {
        light: '#9BB5D1',
        dark: '#B9C9D9',
      },
      active: {
        light: '#9BB5D1',
        dark: '#7894B2',
      },
    },
    gradients: {
      neutral: 'linear-gradient(300.68deg, #285EE8 -19.53%, #8B54FF 99.13%)',
      positive: 'linear-gradient(303.27deg, #9FD376 -3.68%, #14D9A9 90.06%)',
      warning: 'linear-gradient(343.57deg, #FF9900 27.7%, #FFC225 85.95%)',
      error: 'linear-gradient(208.82deg, #FFA825 -15.69%, #FA4753 67.43%)',
      green: 'linear-gradient(90deg, #11E5B3 8.82%, #20CFAC 94.01%);',
      horizontalYellow: 'linear-gradient(270deg, #FFC225 15%, #FFDB1E 85%)',
      horizontalOrange: 'linear-gradient(90deg, #FFC225 15%, #FF9900 85%)',
      horizontalRed: 'linear-gradient(270deg, #FA5947 15%, #FA4753 85%)',
    },
    indicators: {
      inactive: '#EDF3F8',
      neutral: '#6159F6',
      positive: '#17DFA8',
      noticeable: '#FFDB1E',
      significant: '#FFBA1E',
      serious: '#FB4854',
    },
    messageAndAlerts: {
      error: '#C61D18',
      warning: '#EBA836',
      success: '#3EB17E',
      highlightedInfo: '#122E4E',
      cardInfo: '#7894B2',
      notificationInfo: '#446ACC',
    },
  },
  breakpoints: {
    values: {
      hd: 1200,
      fullhd: 1800,
      wqhd: 2400,
      uhd: 3700,
    },
  },
  typography: {
    fontFamily: ['Plus Jakarta Display', 'sans-serif'].join(','),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '0px 24px 34px rgba(212, 220, 224, 0.36), 0px 1px 13px rgba(113, 146, 176, 0.22)',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(36, 27, 49, 0.51)',
        },
      },
    },
  },
});

theme.components = {
  ...theme.components,
  MuiFab: {
    styleOverrides: {
      sizeSmall: {
        minHeight: 14,
        minWidth: 14,
        maxHeight: 14,
        maxWidth: 14,
        [theme.breakpoints.up('fullhd')]: {
          minHeight: 22,
          minWidth: 22,
          maxHeight: 22,
          maxWidth: 22,
        },
        [theme.breakpoints.up('wqhd')]: {
          minHeight: 32,
          minWidth: 32,
          maxHeight: 32,
          maxWidth: 32,
        },
        [theme.breakpoints.up('uhd')]: {
          minHeight: 40,
          minWidth: 40,
          maxHeight: 40,
          maxWidth: 40,
        },
      },
      sizeMedium: {
        minHeight: 22,
        minWidth: 22,
        maxHeight: 22,
        maxWidth: 22,
        [theme.breakpoints.up('fullhd')]: {
          minHeight: 35,
          minWidth: 35,
          maxHeight: 35,
          maxWidth: 35,
        },
        [theme.breakpoints.up('wqhd')]: {
          minHeight: 50,
          minWidth: 50,
          maxHeight: 50,
          maxWidth: 50,
        },
        [theme.breakpoints.up('uhd')]: {
          minHeight: 64,
          minWidth: 64,
          maxHeight: 64,
          maxWidth: 64,
        },
      },

      extended: {
        minHeight: 22,
        maxHeight: 22,
        [theme.breakpoints.up('fullhd')]: {
          minHeight: 35,
          maxHeight: 35,
        },
        [theme.breakpoints.up('wqhd')]: {
          minHeight: 50,
          maxHeight: 50,
        },
        [theme.breakpoints.up('uhd')]: {
          minHeight: 64,
          maxHeight: 64,
        },
      },
    },
  },
};

export default theme;

export type Theme = typeof theme;
