import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import CommonFab from 'components/common/CommonFab';
import { objectList } from 'components/opsDash/nvdb/nvdObjectsTypes';
import FilterItem from 'components/opsDash/nvdb/FilterItem';
import theme from 'config/theme';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dialogRide: {
    height: 550,
    width: 711,
  },
  filterButton: {
    zIndex: 2,
    position: 'absolute',
    marginTop: 20,
    marginLeft: 10,
  },
}));

function NvdbFilterItemlist({
  handleCategorySelect,
  selectedCategories,
  open,
  setOpen,
}: {
  handleCategorySelect: (category) => void;
  selectedCategories: string[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <CommonFab
        className={classes.filterButton}
        icon="list"
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        classes={{
          paperScrollPaper: classes.dialogRide,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {'Velg blant objektene under'}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 8 }}>
          <Grid container grid-xs-3="true" spacing={3}>
            {Object.keys(objectList).map((category, index) => {
              return (
                <Grid key={index} item grid-xs-7="true">
                  <FilterItem
                    selectedCategories={selectedCategories}
                    handleCategorySelect={handleCategorySelect}
                    category={category}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NvdbFilterItemlist;
