import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import FrontPageContent from 'components/frontPage/FrontPageContent';
import SignInContent from 'components/frontPage/SignInContent';

import { useQueryDynamodbUser } from 'services/deliveryQueryHooks';
import isUserLoggedIn from 'utils/isUserLoggedIn';

function SignIn() {
  const history = useHistory();
  const userStatus = isUserLoggedIn();
  const dynamodbUserQuery = useQueryDynamodbUser();
  const dynamodbUser = dynamodbUserQuery.data;

  useEffect(() => {
    if (userStatus === 'user' && dynamodbUser) {
      history.push('/dashboard');
    }
  });

  return <FrontPageContent rightColumnContent={<SignInContent />} />;
}
export default SignIn;
