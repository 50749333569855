import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

import { useHistory } from 'react-router-dom';
import DashCard from 'components/common/cards/DashCard';
import CirclePieChart from 'components/common/charts/CirclePieChart';
import CommonText from 'components/common/textOutput/CommonText';
import CommonChartLegend from 'components/common/charts/CommonChartLegend';
import theme from 'config/theme';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import EnvironmentCardMap from 'components/opsDash/mainView/environmentCard/EnvironmentCardMap';
import { useYmOverviewQuery } from 'services/ymQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 8,
    },
    leftPaper: {
      height: '100%',
      flex: 6,
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
    },
    rightPaper: { height: '100%', flex: 5, paddingLeft: 8 },
    pieChart: {
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      flex: 6,
      display: 'flex',
      border: '1px solid #E3ECF3',
    },
    pieInfo: {
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      border: '1px solid #E3ECF3',
      flex: 5,
      display: 'flex',
      flexDirection: 'column',
    },
    legends: {
      paddingTop: 25,
      paddingLeft: 25,
      paddingBottom: 25,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%',
      width: '100%',
    },
    mapContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      border: '1px solid #E3ECF3',
      borderRadius: 15,
    },
    centerContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

function EnvironmentCard() {
  const classes = useStyles();

  const overviewQuery = useYmOverviewQuery();

  const overviewData = overviewQuery.data;

  const history = useHistory();

  const [highlightedContext, setHighlightedContext] = useState('none');

  let currentMonth = format(new Date(), 'LLLL', { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(new Date(), 'yyyy');

  return (
    <DashCard
      title="Miljø • Utslippsgrense"
      subtitle={currentMonth + ' ' + currentYear}
      onDetailsClick={() => {
        history.push('/dashboard/ym-map');
      }}
      bodyComponent={
        overviewData ? (
          <div className={classes.body}>
            <div className={classes.leftPaper}>
              <CirclePieChart
                className={classes.pieChart}
                data={[
                  {
                    name: 'Over grenseverdi',
                    value: overviewData.statuses.alarm,
                    color: theme.colors.indicators.serious,
                  },
                  {
                    name: 'Nær grenseverdi',
                    value: overviewData.statuses.warning,
                    color: theme.colors.indicators.significant,
                  },
                  {
                    name: 'Under grenseverdi',
                    value: overviewData.statuses.ok,
                    color: theme.colors.indicators.positive,
                  },
                ]}
                onMouseLeave={() => {
                  setHighlightedContext('none');
                }}
                onMouseOver={(label) => {
                  setHighlightedContext(label);
                }}
              />
              <div className={classes.pieInfo}>
                <div className={classes.legends}>
                  <CommonChartLegend
                    color={theme.colors.indicators.serious}
                    label="Over grenseverdi"
                    highlighted={highlightedContext === 'Over grenseverdi'}
                  />
                  <CommonChartLegend
                    color={theme.colors.indicators.significant}
                    label="Nær grenseverdi"
                    highlighted={highlightedContext === 'Nær grenseverdi'}
                  />
                  <CommonChartLegend
                    color={theme.colors.indicators.positive}
                    label="Under grenseverdi"
                    highlighted={highlightedContext === 'Under grenseverdi'}
                  />
                </div>
              </div>
            </div>
            <div className={classes.rightPaper}>
              <div className={classes.mapContainer}>
                <EnvironmentCardMap
                  markers={
                    overviewData.markedPoints && overviewData.markedPoints
                  }
                />
              </div>
            </div>
          </div>
        ) : !overviewQuery.isLoading ? (
          <div className={classes.centerContainer}>
            <CommonText textVariant="20 medium">Fant ikke data</CommonText>
          </div>
        ) : (
          <div className={classes.centerContainer}>
            <CircularProgress
              size={100}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        )
      }
    />
  );
}

export default EnvironmentCard;
