import React, { useState, useRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import CommonFab from 'components/common/CommonFab';
import theme from 'config/theme';
import useComponentSize from 'utils/hooks/useComponentSize';
import CommonDeleteDialog from 'components/common/CommonDeleteDialog';

import ProjectSharing from 'components/deliveryDash/project/projectSharing/ProjectSharing';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'width 350ms ease-out',
      borderRadius: 25,
    },
    menuButton: { padding: 10 },
    menuList: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
    },
  })
);

function ProjectMenu({ isDeleted }: { isDeleted: () => void }) {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  const [projectSharingOpen, setProjectSharingOpen] = useState(false);
  const [clickAway, setClickAway] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const menuButtonWidth = useComponentSize(
    menuButtonRef.current as HTMLElement,
    60
  ).height;

  return (
    <React.Fragment>
      <ClickAwayListener
        onClickAway={() => {
          if (clickAway) {
            setMenuOpen(false);
          } else {
            if (!projectSharingOpen) {
              setClickAway(true);
            }
          }
        }}
      >
        <Paper
          className={classes.root}
          style={{
            width: menuOpen ? '50%' : menuButtonWidth,
            justifyContent: menuOpen ? 'flex-start' : 'center',
          }}
        >
          <CommonFab
            componentRef={menuButtonRef}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            selected={menuOpen}
            noShadow={menuOpen}
            icon="menu"
          ></CommonFab>
          {menuOpen && (
            <div className={classes.menuList}>
              <CommonFab
                noTextWrap
                noShadow
                className={classes.menuButton}
                onClick={() => {
                  setClickAway(false);
                  setProjectSharingOpen(true);
                }}
                icon="share-2"
                text="Del prosjekt"
                iconColor={theme.colors.messageAndAlerts.notificationInfo}
              ></CommonFab>
              <CommonFab
                noTextWrap
                noShadow
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
                icon="trash-2"
                text="Slett prosjekt"
                textColor="error"
                iconColor={theme.colors.messageAndAlerts.error}
              ></CommonFab>
            </div>
          )}
        </Paper>
      </ClickAwayListener>
      <ProjectSharing
        open={projectSharingOpen}
        onClose={() => setProjectSharingOpen(false)}
      />
      {deleteDialogOpen && (
        <CommonDeleteDialog
          isOpened={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onClick={() => {
            isDeleted();
          }}
          messageText="Er du sikker på at du vil slette dette prosjektet?"
          buttonText="Ja, slett dette prosjektet"
        />
      )}
    </React.Fragment>
  );
}

export default ProjectMenu;
