import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import TopView from 'components/opsDash/TopView';
import MapView from 'components/opsDash/ym/mapView/MapView';
import SideMenu from 'components/opsDash/ym/SideMenu';
import BottomDrawer from 'components/opsDash/ym/bottomDrawer/BottomDrawer';
import { YmMapContext } from 'utils/context/YmMap';
import { useYmPointQueries } from 'services/ymQueryHooks';

const useStyles = makeStyles(() =>
  createStyles({
    mainContent: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    menuButton: {
      zIndex: 3,
      position: 'absolute',
      marginLeft: 10,
      marginTop: 10,
    },
  })
);

export default function YmMap() {
  const classes = useStyles();

  const { ymMapState } = useContext(YmMapContext);
  const ymPointQueries = useYmPointQueries(ymMapState.hashArray);

  return (
    <React.Fragment>
      <TopView title="Miljø" subtitle="Kartvisning" />

      <div className={classes.mainContent}>
        <SideMenu ymPointQueries={ymPointQueries} />
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <MapView ymPointQueries={ymPointQueries} />
          <BottomDrawer ymPointQueries={ymPointQueries} />
        </div>
      </div>
    </React.Fragment>
  );
}
