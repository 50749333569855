import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';

import CommonText from 'components/common/textOutput/CommonText';
import CommonFab from 'components/common/CommonFab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import documentIconSelector from 'utils/documentIconSelector';

import { uploadFile } from 'services/fileService';
import { SelectedProjectContext } from 'utils/context/SelectedProject';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px 10px rgba(212, 220, 224, 0.36)',
      },
    },
    icon: { padding: 25, paddingLeft: 40 },
    middleCellContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    fileName: { paddingBottom: 10 },
    bar: {
      height: 10,
      borderRadius: 25,
      background: 'linear-gradient(270deg, #11E5B3 0%, #3C4F87 180%);',
    },
    barRoot: {
      height: 10,
      borderRadius: 25,
      backgroundColor: theme.colors.icons.noState.light,
    },
  })
);

export function LinearProgressWithLabel({ value }: { value: number }) {
  const classes = useStyles();

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div style={{ flex: 5, height: '100%', paddingRight: 10 }}>
        <LinearProgress
          classes={{
            root: classes.barRoot,
            bar: classes.bar,
          }}
          variant="determinate"
          value={value}
        />
      </div>
      <div style={{ flex: 1, height: '100%' }}>
        <CommonText textVariant="12 medium">{`${Math.round(
          value
        )}%`}</CommonText>
      </div>
    </div>
  );
}

function FileUploadItem({
  file,
  onUploadSuccess,
  onCancel,
}: {
  file: File;
  onUploadSuccess: (completedFile: File) => void;
  onCancel: (canceledFile: File) => void;
}) {
  const classes = useStyles();

  const [cancelToken] = useState(axios.CancelToken.source());
  const [uploadProgress, setUploadProgress] = useState(0);
  const { selectedProjectId } = useContext(SelectedProjectContext);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  useEffect(() => {
    if (!uploadStarted) {
      setUploadStarted(true);
      uploadFile(
        selectedProjectId,
        {
          fileContent: file,
          cancelToken: cancelToken,
        },
        (progress) => {
          setUploadProgress(progress);
        },
        () => {
          setUploadComplete(true);
          onUploadSuccess(file);
        },
        () => {
          setUploadFailed(true);
        }
      );
    }
  }, [
    setUploadStarted,
    cancelToken,
    uploadStarted,
    file,
    onUploadSuccess,
    selectedProjectId,
  ]);

  const extension = file.name.substr(file.name.lastIndexOf('.') + 1);

  return (
    <TableRow className={classes.root}>
      <TableCell width="10%" className={classes.icon} align="center">
        <FontAwesomeIcon
          icon={documentIconSelector(extension).icon}
          color={documentIconSelector(extension).color}
          size="3x"
        />
      </TableCell>
      <TableCell width="80%" align="left">
        <div className={classes.middleCellContainer}>
          <CommonText className={classes.fileName} textVariant="12 medium">
            {file.name}
          </CommonText>
          {!uploadFailed && <LinearProgressWithLabel value={uploadProgress} />}
        </div>
      </TableCell>
      <TableCell width="5%" align="center">
        <CommonText textVariant="12 medium">
          {uploadFailed
            ? 'Opplasting misslykket'
            : uploadComplete
            ? 'Ferdig'
            : 'Lastes opp'}
        </CommonText>
      </TableCell>
      <TableCell width="5%" align="center">
        {uploadComplete ? (
          <CommonFab
            icon="check-circle"
            noShadow
            selected
            iconColor={theme.colors.messageAndAlerts.success}
            onClick={() => {
              // do nothing
            }}
          />
        ) : (
          <CommonFab
            icon="x-circle"
            noShadow
            iconColor={theme.colors.messageAndAlerts.error}
            onClick={() => {
              cancelToken.cancel();
              onCancel(file);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default FileUploadItem;
