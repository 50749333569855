import React, { createContext, useState } from 'react';

type GeneralStateContent = {
  hideMenus: boolean;
};

type GeneralState = {
  generalState: GeneralStateContent;
  setGeneralState: React.Dispatch<React.SetStateAction<GeneralStateContent>>;
};

const initialState = {
  hideMenus: false,
};

const GeneralContext = createContext<GeneralState>({
  generalState: initialState,
  setGeneralState: () => {
    // do nothing
  },
});

const GeneralProvider = ({ children }: { children: React.ReactNode }) => {
  const [generalState, setGeneralState] =
    useState<GeneralStateContent>(initialState);

  return (
    <GeneralContext.Provider value={{ generalState, setGeneralState }}>
      {children}
    </GeneralContext.Provider>
  );
};

export { GeneralContext, GeneralProvider };
