import React, { createContext, useState } from 'react';

import * as L from 'leaflet';

type YmMapStateContent = {
  hashArray: string[];
  centerPoint: L.LatLng;
  zoomLevel: number;
  drawerOpen: boolean;
  sideMenuOpen: boolean;
  selectedYmPointId: string;
};

type YmMapState = {
  ymMapState: YmMapStateContent;
  setYmMapState: React.Dispatch<React.SetStateAction<YmMapStateContent>>;
};

const initialState = {
  hashArray: [],
  centerPoint: new L.LatLng(58.23622776977281, 8.306275497572745),
  zoomLevel: 12,
  drawerOpen: false,
  sideMenuOpen: false,
  selectedYmPointId: 'undefined',
};

const YmMapContext = createContext<YmMapState>({
  ymMapState: initialState,
  setYmMapState: () => {
    // do nothing
  },
});

const YmMapProvider = ({ children }: { children: React.ReactNode }) => {
  const [ymMapState, setYmMapState] = useState<YmMapStateContent>(initialState);

  return (
    <YmMapContext.Provider value={{ ymMapState, setYmMapState }}>
      {children}
    </YmMapContext.Provider>
  );
};

export { YmMapContext, YmMapProvider };
