import React, { useState } from 'react';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

import CommonText from 'components/common/textOutput/CommonText';
import BarChart from 'components/common/charts/BarChart/BarChart';
import OptionsPicker from 'components/common/pickers/OptionsPicker';
import * as materialColors from '@mui/material/colors';

import theme from 'config/theme';

import { SectionCounter } from 'services/availabilityApi/models';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      border: 'solid',
      borderColor: 'white',
      backgroundColor: theme.colors.base.base1,
      borderWidth: 5,
    },
    paperHeader: {
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      flex: 4.4,
      backgroundColor: theme.colors.base.white,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      borderBottomLeftRadius: 18,
      borderBottomRightRadius: 18,
    },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

function EventOverview({
  sectionCounterData,
  loadingData,
}: {
  sectionCounterData: SectionCounter | undefined;
  loadingData: boolean;
}) {
  const classes = useStyles();

  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, 'M')
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, 'yyyy')
  );

  const sections =
    sectionCounterData?.data?.deducted?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const barChartData: { [key: string]: string | number }[] = [
    { label: 'Seksjon 1' },
    { label: 'Seksjon 2' },
    { label: 'Seksjon 3' },
    { label: 'Seksjon 4' },
    { label: 'Seksjon 5' },
    { label: 'Seksjon 6' },
  ];

  sections &&
    Object.entries(sections).forEach((section) => {
      barChartData.forEach((dataElement, index) => {
        if (dataElement.label === 'Seksjon ' + section[0]) {
          barChartData.splice(index, 1);
        }
      });

      const sectionBarChartData = { label: 'Seksjon ' + section[0] };

      section[1].eventTimes &&
        Object.entries(section[1].eventTimes).forEach((event) => {
          sectionBarChartData[event[0]] = Number(event[1]);
        });

      barChartData.push(sectionBarChartData);
    });

  barChartData.sort((a, b) => {
    const aNum = Number(String(a.label).split('Seksjon ')[1]);
    const bNum = Number(String(b.label).split('Seksjon ')[1]);

    return aNum - bNum;
  });

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div className={classes.paperHeaderTop}>
          <CommonText textVariant="20 medium" colorOverride="white">
            Stengning asfalt
          </CommonText>
          <div className={classes.pickerContainer}>
            <CommonText
              textVariant="14 medium"
              colorOverride="white"
              className={classes.pickerLabel}
            >
              År:
            </CommonText>
            <OptionsPicker
              className={classes.picker}
              reverse
              optionsList={yearsListDropdown()}
              initialvalue={selectedYear}
              parentCallback={(value) => {
                setSelectedYear(value);
              }}
            />
          </div>
          <div className={classes.pickerContainer}>
            <CommonText
              textVariant="14 medium"
              colorOverride="white"
              className={classes.pickerLabel}
            >
              Periode:
            </CommonText>
            <OptionsPicker
              className={classes.picker}
              reverse
              optionsList={{
                '1': 'Januar',
                '2': 'Februar',
                '3': 'Mars',
                '4': 'April',
                '5': 'Mai',
                '6': 'Juni',
                '7': 'Juli',
                '8': 'August',
                '9': 'September',
                '10': 'Oktober',
                '11': 'November',
                '12': 'Desember',
              }}
              parentCallback={(value) => {
                setSelectedMonth(value);
              }}
              initialvalue={selectedMonth}
            />
          </div>
        </div>

        <Divider />
      </div>
      <div className={classes.paperBody}>
        {loadingData ? (
          <div className={classes.centeringContainer}>
            <CircularProgress
              size={150}
              classes={{ circle: classes.spinnerCircle }}
            />
          </div>
        ) : sections ? (
          <BarChart
            margin={{ top: 20, bottom: 70, left: 0, right: 50 }}
            dataKeys={['Tunnelvask', 'Stegning asfalt', 'Vedlikehold', 'Annet']}
            colorArray={[
              materialColors.lightBlue[900],
              materialColors.lightGreen[700],
              materialColors.lightBlue[300],
              materialColors.orange[500],
            ]}
            yAxisLabel="Minutter"
            data={barChartData}
          />
        ) : (
          <div className={classes.centeringContainer}>
            <CommonText textVariant="20 medium" className={classes.pickerLabel}>
              Ingen data denne perioden
            </CommonText>
          </div>
        )}
      </div>
    </Paper>
  );
}

export default EventOverview;
