import React, { useState, useRef, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import CommonFab from 'components/common/CommonFab';
import CommonText from 'components/common/textOutput/CommonText';
import CommonTextField from 'components/common/textInputs/CommonTextField';
import CommonAutocomplete from 'components/common/textInputs/CommonAutocomplete';
import ProjectSharingList from 'components/deliveryDash/project/projectSharing/ProjectSharingList';
import isEmail from 'utils/validation/isEmail';
import isEmpty from 'utils/validation/isEmpty';
import { SelectedProjectContext } from 'utils/context/SelectedProject';
import {
  useQueryAllCompanies,
  useQueryProjectCompaniesList,
} from 'services/deliveryQueryHooks';
import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      paddingTop: 50,
      paddingBottom: 50,
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 50,
      paddingRight: 50,
    },
    labelText: { paddingLeft: 50 },
    addButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    companyInput: {
      width: '100%',
      height: '100%',
    },
    nextButtonContainer: {
      minHeight: 30,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 80,
    },
  })
);

function SharingForm({
  sharingList,
  onNext,
  onShareAdd,
  onShareRemove,
}: {
  sharingList: {
    type: 'user' | 'company';
    name: string;
    value: string;
    access: 'reader' | 'contributor';
  }[];
  onNext: () => void;
  onShareAdd: (element) => void;
  onShareRemove: (element) => void;
}) {
  const classes = useStyles();

  const { selectedProjectId } = useContext(SelectedProjectContext);

  const companiesWithAccessQuery =
    useQueryProjectCompaniesList(selectedProjectId);
  const companiesWithAccess = companiesWithAccessQuery.data;

  const allCompaniesQuery = useQueryAllCompanies();
  const allCompanies = allCompaniesQuery.data;

  const [newUser, setNewUser] = useState<string | undefined>(undefined);
  const [newCompany, setNewCompany] = useState<{
    name: string;
    value: string;
  } | null>(null);
  const [companyOptions, setCompanyOptions] = useState<
    {
      name: string;
      value: string;
    }[]
  >([]);
  const [optionsFetched, setOptionsFetched] = useState(false);

  const [emailError, setEmailError] = useState(false);

  if (!optionsFetched) {
    if (allCompanies && companiesWithAccess) {
      let temporaryOptionsStore = allCompanies.map((company) => {
        return { name: company.companyName, value: company.id };
      });

      temporaryOptionsStore.forEach((option) => {
        companiesWithAccess.forEach((companyWithAccess) => {
          if (option.value === companyWithAccess.id) {
            temporaryOptionsStore = temporaryOptionsStore.filter((option) => {
              return option.value !== companyWithAccess.id;
            });
          }
        });
        setCompanyOptions(temporaryOptionsStore);
      });

      setOptionsFetched(true);
    }
  }

  const autocompleteRef = useRef();

  return (
    <div className={classes.root}>
      <CommonText className={classes.labelText} textVariant="12 light">
        Legg til ny bruker :
      </CommonText>
      <div className={classes.inputContainer}>
        <CommonTextField
          id="newuser"
          label={emailError ? 'Epost mangler eller ugyldig' : 'Epostadresse'}
          type="email"
          error={emailError}
          value={newUser}
          onChange={(event) => {
            if (emailError) {
              setEmailError(false);
            }

            setNewUser(event.target.value);
          }}
        />
        <div className={classes.addButtonContainer}>
          <CommonFab
            noTextWrap
            onClick={() => {
              if (newUser) {
                setEmailError(isEmpty(newUser) || !isEmail(newUser));
                if (!isEmpty(newUser) && isEmail(newUser)) {
                  onShareAdd({
                    type: 'user' as 'user',
                    name: newUser,
                    value: newUser,
                    access: 'reader',
                  });
                  setNewUser('');
                }
              }
            }}
            icon="plus"
            selected
            textColor="success"
            iconColor={theme.colors.messageAndAlerts.success}
            text="Legg til"
          />
        </div>
      </div>
      <CommonText className={classes.labelText} textVariant="12 light">
        Legg til nytt firma :
      </CommonText>
      <div className={classes.inputContainer}>
        <CommonAutocomplete
          ref={autocompleteRef}
          id="newcompany"
          label="Firmanavn"
          options={companyOptions}
          loading={allCompaniesQuery.isFetching}
          onChange={(value) => {
            if (value) {
              setNewCompany({ name: value.name, value: value.value });
            }
          }}
        />

        <div className={classes.addButtonContainer}>
          <CommonFab
            noTextWrap
            onClick={() => {
              if (newCompany) {
                onShareAdd({
                  type: 'company' as 'company',
                  name: newCompany.name,
                  value: newCompany.value,
                  access: 'reader',
                });
                setCompanyOptions(
                  companyOptions.filter((option) => {
                    return option.name !== newCompany.name;
                  })
                );
                // @ts-ignore
                autocompleteRef && autocompleteRef.current.clearSelected();
                setNewCompany(null);
              }
            }}
            icon="plus"
            selected
            textColor="success"
            iconColor={theme.colors.messageAndAlerts.success}
            text="Legg til"
          />
        </div>
      </div>
      <ProjectSharingList
        onRemove={(element) => {
          onShareRemove(element);
          if (element.type === 'company') {
            setCompanyOptions([
              ...companyOptions,
              { name: element.name, value: element.value },
            ]);
          }
        }}
        listElements={sharingList}
      />

      <div className={classes.nextButtonContainer}>
        {sharingList.length > 0 && (
          <CommonFab
            onClick={() => {
              onNext();
            }}
            selected
            text="Gå videre"
            iconColor="success"
            icon="arrow-right"
          />
        )}
      </div>
    </div>
  );
}

export default SharingForm;
