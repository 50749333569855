import React from 'react';
import clsx from 'clsx';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    textField: { height: '100%', borderRadius: 10 },
    text: {
      fontSize: '10px',
      lineHeight: '12px',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
      color: theme.colors.text.main,
    },
    textStored: {
      textOverflow: 'ellipsis',
      color: theme.colors.text.main,
    },
    fieldEdit: { backgroundColor: theme.colors.base.background },
    error: {
      borderStyle: 'solid',
      borderWidth: 1.5,
      color: theme.colors.messageAndAlerts.error,
    },
  })
);
export default function Description({
  text,
  editable,
  className,
  onChange,
  onClick,
  placeholder,
  error,
}: {
  text?: string;
  editable?: boolean;
  className?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClick?: () => void;
  placeholder?: string;
  error?: boolean;
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.textField, {
        [classes.fieldEdit]: editable,
        [classes.error]: error,
      })}
    >
      <form
        className={
          className ? clsx(classes.root, { [className]: true }) : classes.root
        }
        noValidate
        autoComplete="off"
      >
        <InputBase
          type="text"
          onClick={onClick}
          defaultValue={text ? text : null}
          placeholder={text ? '' : placeholder}
          fullWidth
          readOnly={!editable}
          multiline
          classes={{
            input: clsx(classes.text, {
              [classes.textStored]: !editable,
            }),
          }}
          rows={6}
          onChange={onChange}
        />
      </form>
    </div>
  );
}
