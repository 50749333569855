import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Strict mode is commented out temporarily. This should
// be reverted once material ui is updated to v5.
ReactDOM.render(
  // <React.StrictMode>

  <App />,

  // </React.StrictMode>,
  document.getElementById('root')
);
