import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Popover from '@mui/material/Popover';
import CommonText from 'components/common/textOutput/CommonText';

import CommonFab from 'components/common/CommonFab';
import { Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    popoverContent: {
      width: '100%',
      padding: 20,
    },
    textHelperText: {
      paddingTop: 5,
      paddingBottom: 10,
      paddingLeft: theme.spacing(1),
    },
    textWithHover: {
      paddingLeft: theme.spacing(1),
      paddingTop: 10,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        fontWeight: 500,
      },
    },
    frontPageFab: {
      boxShadow: 'none',
    },
  })
);

const RoundedPopover = withStyles({
  paper: {
    borderRadius: 25,
    boxShadow: '0px 2px 7px rgba(209, 214, 228, 0.4)',
  },
})(Popover);

export default function CustomerSupport({
  frontPage,
}: {
  frontPage?: boolean;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <CommonFab
        buttonSize="large"
        icon="message-circle"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        selected={open}
        className={frontPage ? classes.frontPageFab : undefined}
      />

      <RoundedPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: -20,
        }}
        disableRestoreFocus
      >
        <div className={classes.popoverContent}>
          <CommonText textVariant="12 light" className={classes.textHelperText}>
            For support, please contact us
          </CommonText>
          <Divider />
          <div style={{ height: 5 }}></div>
          <CommonText
            textVariant="12 light"
            component="a"
            href="mailto:support@ameta.app"
            className={classes.textWithHover}
          >
            M: post@smartinspection.no
          </CommonText>
          <div style={{ height: 5 }}></div>
          <CommonText
            textVariant="12 light"
            component="a"
            href="tel:+4738795840"
            className={classes.textWithHover}
          >
            T: +47 38 79 58 40
          </CommonText>
        </div>
      </RoundedPopover>
    </div>
  );
}
