import React, { useState } from 'react';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import CommonText from 'components/common/textOutput/CommonText';
import OptionsPicker from 'components/common/pickers/OptionsPicker';

import QuarterlyOverviewSection from 'components/opsDash/availability/QuarterlyOverviewSection';

import theme from 'config/theme';

import { SectionCounter } from 'services/availabilityApi/models';
import { yearsListDropdown } from 'utils/yearsListDropDown';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      borderRadius: 25,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 10px rgba(209, 214, 228, 0.88)',
      border: 'solid',
      borderColor: 'white',
      backgroundColor: theme.colors.base.base1,
      borderWidth: 5,
    },
    centeringContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paperHeader: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paperHeaderTop: {
      height: '100%',
      padding: 10,
      paddingLeft: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperBody: {
      flex: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderBottomLeftRadius: 18,
      borderBottomRightRadius: 18,
      backgroundColor: theme.colors.base.background,
    },
    totalsOuterContainer: {
      flex: 1,
      width: '100%',
      padding: 10,
      backgroundColor: theme.colors.base.background,
    },
    totalsInnerContainer: {
      width: '100%',
      height: '100%',
      borderRadius: 25,
      backgroundColor: theme.colors.base.white,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sectionsContainer: {
      flex: 5,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    sectionElement: { width: '50%', height: '25%', padding: 10 },
    textContainer: {
      position: 'relative',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    picker: { paddingRight: 10 },
    pickerLabel: { paddingRight: 10 },
    pickerContainer: { display: 'flex', alignItems: 'center' },
    spinnerCircle: { color: theme.colors.icons.active.dark },
  })
);

const stringRound = (floatString) => {
  return Number(parseFloat(floatString).toFixed(2));
};

function QuarterlyOverview({
  sectionCounterData,
  loadingData,
}: {
  sectionCounterData: SectionCounter | undefined;
  loadingData: boolean;
}) {
  const classes = useStyles();

  const thresholdNumber = 98.0;

  const [selectedYear, setSelectedYear] = useState(format(Date.now(), 'yyyy'));
  const [selectedQuarter, setSelectedQuarter] = useState(
    String(Math.ceil(Number(format(Date.now(), 'MM')) / 3))
  );

  const totalThisQuarter = sectionCounterData?.data?.deducted?.[selectedYear]
    ?.quarters?.[selectedQuarter]
    ? stringRound(
        sectionCounterData.data?.deducted?.[selectedYear].quarters[
          selectedQuarter
        ].availability
      )
    : undefined;

  const soFarThisYear = sectionCounterData?.data?.deducted?.[selectedYear]
    ?.availability
    ? stringRound(
        sectionCounterData.data?.deducted?.[selectedYear].availability
      )
    : undefined;

  const sectionsData =
    sectionCounterData?.data?.deducted?.[selectedYear]?.quarters?.[
      selectedQuarter
    ]?.sections;

  const sections = {
    1: { availability: undefined },
    2: { availability: undefined },
    3: { availability: undefined },
    4: { availability: undefined },
    5: { availability: undefined },
    6: { availability: undefined },
  };

  sectionsData &&
    Object.entries(sectionsData).forEach((element) => {
      Object.entries(sections).forEach((sectionEl) => {
        if (element[0] === sectionEl[0]) {
          delete sections[element[0]];
        }
      });

      sections[element[0]] = element[1];
    });

  return (
    <Paper className={classes.root}>
      {loadingData ? (
        <div className={classes.centeringContainer}>
          <CircularProgress
            size={150}
            classes={{ circle: classes.spinnerCircle }}
          />
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.paperHeader}>
            <div className={classes.paperHeaderTop}>
              <CommonText textVariant="20 medium" colorOverride="white">
                Kvartalsoversikt
              </CommonText>
              <div className={classes.pickerContainer}>
                <CommonText
                  textVariant="14 medium"
                  colorOverride="white"
                  className={classes.pickerLabel}
                >
                  År:
                </CommonText>
                <OptionsPicker
                  className={classes.picker}
                  reverse
                  optionsList={yearsListDropdown()}
                  initialvalue={selectedYear}
                  parentCallback={(value) => {
                    setSelectedYear(value);
                  }}
                />
              </div>
              <div className={classes.pickerContainer}>
                <CommonText
                  textVariant="14 medium"
                  colorOverride="white"
                  className={classes.pickerLabel}
                >
                  Kvartal:
                </CommonText>
                <OptionsPicker
                  className={classes.picker}
                  reverse
                  optionsList={{
                    '1': '1. kvartal',
                    '2': '2. kvartal',
                    '3': '3. kvartal',
                    '4': '4. kvartal',
                  }}
                  initialvalue={selectedQuarter}
                  parentCallback={(value) => {
                    setSelectedQuarter(value);
                  }}
                />
              </div>
            </div>

            <Divider />
          </div>
          <div className={classes.paperBody}>
            <div className={classes.totalsOuterContainer}>
              <div className={classes.totalsInnerContainer}>
                <div className={classes.textContainer}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    <CommonText style={{ padding: 20 }} textVariant="14 medium">
                      Dette kvartalet:
                    </CommonText>
                  </div>
                  {totalThisQuarter ? (
                    <CommonText
                      style={{ padding: 15 }}
                      colorOverride={
                        totalThisQuarter < thresholdNumber ? 'error' : 'success'
                      }
                      textVariant="50 medium"
                    >
                      {totalThisQuarter && totalThisQuarter + '%'}
                    </CommonText>
                  ) : (
                    <CommonText style={{ padding: 15 }} textVariant="50 medium">
                      -
                    </CommonText>
                  )}
                </div>

                <Divider
                  orientation="vertical"
                  style={{
                    backgroundColor: 'white',

                    height: '80%',
                  }}
                />

                <div className={classes.textContainer}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    <CommonText style={{ padding: 20 }} textVariant="14 medium">
                      Hittil dette året:
                    </CommonText>
                  </div>
                  {soFarThisYear ? (
                    <CommonText
                      style={{ padding: 15 }}
                      colorOverride={
                        soFarThisYear < thresholdNumber ? 'error' : 'success'
                      }
                      textVariant="50 medium"
                    >
                      {soFarThisYear && soFarThisYear + '%'}
                    </CommonText>
                  ) : (
                    <CommonText style={{ padding: 15 }} textVariant="50 medium">
                      -
                    </CommonText>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className={classes.sectionsContainer}>
              {Object.entries(sections).map((section, index) => {
                const soFarThisYear = sectionCounterData?.data?.deducted?.[
                  selectedYear
                ]?.sectionAvailability?.[section[0]]
                  ? stringRound(
                      sectionCounterData.data?.deducted?.[selectedYear]
                        .sectionAvailability[section[0]].availability
                    )
                  : undefined;

                return (
                  <div key={index} className={classes.sectionElement}>
                    <QuarterlyOverviewSection
                      lastQuarter={stringRound(section[1].availability)}
                      soFarThisYear={soFarThisYear}
                      sectionNumber={stringRound(section[0])}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
}

export default QuarterlyOverview;
