import React, { useState } from 'react';
import clsx from 'clsx';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import InputBase from '@mui/material/InputBase';

import theme from 'config/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 5,
      paddingLeft: 10,
      borderRadius: 10,
    },
    text: {
      fontSize: '10px',
      lineHeight: '12px',
      [theme.breakpoints.up('fullhd')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      [theme.breakpoints.up('wqhd')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
      [theme.breakpoints.up('uhd')]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
      fontStyle: 'normal',
      fontWeight: 400,
      color: theme.colors.text.main,
    },
    textStored: {
      textOverflow: 'ellipsis',
      color: theme.colors.text.main,
    },
    fieldEdit: { backgroundColor: theme.colors.base.background },
    disabled: { backgroundColor: theme.colors.base.background },
    error: {
      borderStyle: 'solid',
      borderWidth: 1.5,
      color: theme.colors.messageAndAlerts.error,
    },
  })
);

export default function TextField({
  defaultValue,
  value,
  placeholder,
  editable,
  keepActive,
  className,
  onChange,
  onClick,
  error,
  disabled,
  numericInput,
}: {
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  editable?: boolean;
  keepActive?: boolean;
  className?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClick?: () => void;
  error?: boolean;
  disabled?: boolean;
  numericInput?: boolean;
}) {
  const classes = useStyles();

  const [active, setActive] = useState(false);

  return (
    <InputBase
      disabled={disabled}
      type="text"
      inputProps={
        numericInput ? { inputMode: 'numeric', pattern: '[0-9]*' } : undefined
      }
      className={
        className
          ? clsx(classes.root, {
              [className]: true,
              [classes.fieldEdit]: keepActive ? editable : active,
            })
          : clsx(classes.root, {
              [classes.fieldEdit]: keepActive ? editable : active,
            })
      }
      onChange={onChange}
      onClick={onClick}
      onFocus={() => {
        setActive(true);
      }}
      onBlur={() => {
        setActive(false);
      }}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      fullWidth
      error={error}
      readOnly={!editable}
      classes={{
        input: clsx(classes.text, {
          [classes.textStored]: !editable,
        }),
        disabled: classes.fieldEdit,
        error: classes.error,
      }}
    />
  );
}
