import React from 'react';

import theme from 'config/theme';

function CustomizedAxisTick({
  x,
  y,
  payload,
}: {
  x?: number;
  y?: number;
  payload?: { value: string };
}) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={16}
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fontWeight={500}
        fill={theme.colors.text.main}
        transform="rotate(-35)"
      >
        {payload ? payload.value : ''}
      </text>
    </g>
  );
}

export default CustomizedAxisTick;
