import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CommonText from 'components/common/textOutput/CommonText';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';

import CommonTextField from 'components/common/textInputs/CommonTextField';
import isEmail from 'utils/validation/isEmail';
import isEmpty from 'utils/validation/isEmpty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import theme from 'config/theme';
library.add(faEye, faEyeSlash);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    labelText: {
      paddingBottom: 30,
    },
    formInput: { width: '100%' },
    registrationButton: {
      backgroundImage: 'linear-gradient(180deg, #11E5B3 0%, #20CFAC 100%)',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 25,
      color: 'white',
      paddingLeft: 40,
      paddingRight: 40,
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 15,
      paddingRight: 15,
    },
    textButton: {
      textTransform: 'none',
      fontSize: 16,
      color: '#C7D3E0',
    },
    buttonIcon: { marginRight: 10 },
    serverError: {
      padding: 20,
    },
  })
);

export default function CognitoSignUp({
  invitationEmail,
  onSuccess,
}: {
  invitationEmail?: string;
  onSuccess?: (username: string) => void;
}) {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState(invitationEmail ? invitationEmail : '');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailError] = useState<string | null>(null);
  const [password1Error, setPassword1Error] = useState<string | null>(null);
  const [password2Error, setPassword2Error] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);

  const vaildateInputs = () => {
    if (isEmpty(email)) {
      setEmailError('Epost mangler');
    }
    if (!isEmail(email)) {
      setEmailError('Epost er ugyldig');
    }
    if (isEmpty(password1)) {
      setPassword1Error('Passord mangler');
    }
    if (isEmpty(password2)) {
      setPassword2Error('Passord mangler');
    }
    if (!isEmpty(password1) && !isEmpty(password2) && password1 !== password2) {
      setPassword1Error('Passord ikke identisk');
      setPassword2Error('Passord ikke identisk');
    }
  };

  return (
    <div className={classes.root}>
      <CommonText textVariant="12 light" className={classes.labelText}>
        Du kan også registrere en ny bruker med din epostadresse.
      </CommonText>
      <form className={classes.formInput} noValidate autoComplete="off">
        <CommonTextField
          id="signupemail"
          label={invitationEmail && emailError ? emailError : 'Epostadresse'}
          type="email"
          error={Boolean(emailError)}
          value={invitationEmail && invitationEmail}
          disabled={Boolean(invitationEmail)}
          onFocus={() => {
            setEmailError(null);
          }}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <div style={{ height: 26 }} />
        <CommonTextField
          id="signuppassword1"
          label={password1Error ? password1Error : 'Passord'}
          type={showPassword ? 'text' : 'password'}
          error={Boolean(password1Error)}
          onFocus={() => {
            setPassword1Error(null);
          }}
          onChange={(event) => {
            setPassword1(event.target.value);
          }}
        />
        <div style={{ height: 26 }} />
        <CommonTextField
          id="signuppassword2"
          label={password2Error ? password2Error : 'Gjenta passord'}
          type={showPassword ? 'text' : 'password'}
          error={Boolean(password2Error)}
          onFocus={() => {
            setPassword2Error(null);
          }}
          onChange={(event) => {
            setPassword2(event.target.value);
          }}
        />
        <div style={{ height: 10 }} />
        <div
          className={classes.buttonContainer}
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="text"
            className={classes.textButton}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <FontAwesomeIcon
              className={classes.buttonIcon}
              color={theme.colors.icons.noState.light}
              icon={!showPassword ? faEye : faEyeSlash}
            />
            <CommonText textVariant="12 light">
              {showPassword ? 'Skjul passord' : 'Vis passord'}
            </CommonText>
          </Button>
        </div>
        <div style={{ height: 10 }} />
        <div className={classes.buttonContainer}>
          <Fab
            variant="extended"
            className={classes.registrationButton}
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              setServerError(null);
              vaildateInputs();

              if (
                !isEmpty(email) &&
                isEmail(email) &&
                !isEmpty(password1) &&
                !isEmpty(password2) &&
                password1 === password2
              ) {
                Auth.signUp({ username: email, password: password1 })
                  .then(() => {
                    onSuccess && onSuccess(email);
                  })
                  .catch((err) => {
                    switch (err.message) {
                      case 'An account with the given email already exists.':
                        setServerError(
                          'En bruker med denne epostadressen finnes allerede'
                        );
                        break;
                      case 'Password did not conform with policy: Password not long enough':
                        setServerError(
                          'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                        );
                        break;
                      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
                        setServerError(
                          'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                        );
                        break;
                      case 'Password did not conform with policy: Password must have uppercase characters':
                        setServerError(
                          'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                        );
                        break;
                      case 'Password did not conform with policy: Password must have numeric characters':
                        setServerError(
                          'Passord må ha minst seks bokstaver, minst en stor bokstav og ett numerisk tall'
                        );
                        break;

                      default:
                        setServerError('Ukjent systemfeil ved registrering');
                    }
                  });
              }
            }}
          >
            <CommonText style={{ color: 'white' }} textVariant="20 medium">
              Registrer
            </CommonText>
          </Fab>
          <Button
            className={classes.textButton}
            onClick={() => {
              history.push('/');
            }}
          >
            <CommonText textVariant="12 light">
              Tilbake til innlogging
            </CommonText>
          </Button>
        </div>
        <CommonText
          colorOverride="error"
          textVariant="12 light"
          className={classes.serverError}
        >
          {serverError}
        </CommonText>
      </form>
    </div>
  );
}
